import { Alignment, Column, ColumnType, createFromDefinition } from 'components/Table/types/column';
import { WhoIsLoggedInUser } from 'redux/reducers/whoIsLoggedInReducer';
import { LoggedInStatusCell } from 'routes/ReportsRoute/WhoIsLoggedIn/components/LoggedInStatusCell';

const columns: ReadonlyArray<Column<WhoIsLoggedInUser>> = [
  createFromDefinition({
    header: '',
    key: 'loggedIn',
    sortable: false,
    weight: 1,
    columnType: ColumnType.custom,
    alignment: Alignment.center,
    filterable: false,
    component: LoggedInStatusCell,
  }),
  createFromDefinition({
    header: 'User Name',
    key: 'userName',
    sortable: true,
    weight: 8,
    columnType: ColumnType.text,
  }),
  createFromDefinition({
    header: 'User ID',
    key: 'userID',
    sortable: true,
    weight: 8,
    columnType: ColumnType.text,
  }),
  createFromDefinition({
    header: 'IP Address',
    key: 'ipAddress',
    sortable: true,
    weight: 4,
    columnType: ColumnType.text,
    alignment: Alignment.center,
  }),
  createFromDefinition({
    header: 'Application',
    key: 'application',
    sortable: true,
    weight: 8,
    columnType: ColumnType.text,
  }),
  createFromDefinition({
    header: 'Last Activity',
    key: 'lastActivity',
    sortable: true,
    weight: 4,
    columnType: ColumnType.dateTime,
    alignment: Alignment.center,
  }),
];

export default columns;
