import { FormControlLabel, Radio } from '@mui/material';
import { Entity } from 'api/interfaces/firm';
import React from 'react';
import styles from 'routes/EntityManagement/FirmsManagement/components/FirmsForm/form.module.scss';

interface Props {
  readonly entity: Entity;
}

export const EntityWidget: React.FC<Props> = (props: Props): React.ReactElement => {
  const { entity } = props;

  return (
    <div className={styles.entity}>
      <FormControlLabel
        value={entity.legalEntityID}
        classes={{ root: styles.radioButton }}
        control={<Radio />}
        label={''}
      />
      <div className={styles.text}>
        <div className={styles.entityTitle}>{entity.legalName}</div>
        <div className={styles.entityId}>boc: {entity.brokerOfCredit}</div>
      </div>
      <div className={styles.defaultIndicator}>{entity.default === 1 ? 'Default' : ''}</div>
    </div>
  );
};
