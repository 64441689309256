import { ButtonBase } from '@mui/material';
import styles from 'components/StatusBar/components/TotalEntry/total-entry.module.scss';
import { toClassName } from 'helpers/toClassName';
import React from 'react';

interface Props {
  readonly label: string;
  readonly value: number;
  readonly variant: 'info' | 'success' | 'warning' | 'danger' | 'neutral' | 'inactive' | 'total';
  readonly selected: boolean;

  onClick?(): void;
}

export const TotalEntry = (props: Props): React.ReactElement => {
  const className = toClassName(styles.button, styles[props.variant], {
    [styles.selected]: props.selected,
  });

  return (
    <div className={styles.container}>
      <ButtonBase disabled={props.onClick === undefined} onClick={props.onClick}>
        <div className={className}>
          <div className={styles.label}>{props.label}</div>
          <i className="fa fa-separator" />
          <div className={styles.value}>{props.value}</div>
        </div>
      </ButtonBase>
    </div>
  );
};
