import { Api } from 'api';
import { PrismApplication } from 'api/interfaces/prismApplication';
import { SonicGroup } from 'api/interfaces/sonicGroup';
import { OktaGroup } from 'api/interfaces/user';
import { Dispatch } from 'redux';
import { createAction } from 'redux/actions/creator';
import { ApplicationState } from 'redux/applicationState';
import { UsersFormActions } from 'redux/reducers/usersFormReducer';
import { Task } from 'task';

export const initializeUsersForm =
  () =>
  (dispatch: Dispatch, getState: () => ApplicationState, api: Api): VoidFunction => {
    const abortController = new AbortController();
    const tasks: Array<Task<any>> = [];
    const companiesTask = api.getCompanies();
    const firmsTask = api.getFXOFirms();

    tasks.push(companiesTask);
    tasks.push(firmsTask);

    // Reset all
    const run = async (): Promise<any> => {
      const applications = await api.getApplications(abortController.signal);

      const extendedApplications = await Promise.all(
        applications.map(async (application: PrismApplication): Promise<PrismApplication> => {
          const sonicTask = api.getSonicGroupsByApp(application.app_id);
          const oktaTask = api.getOktaGroupsByApp(application.app_id);

          tasks.push(sonicTask);
          tasks.push(oktaTask);

          return {
            ...application,
            sonic: distinct(
              await sonicTask.run(),
              (v1: SonicGroup, v2: SonicGroup): boolean => v1.group_name === v2.group_name
            ),
            okta: distinct(await oktaTask.run(), (v1: OktaGroup, v2: OktaGroup): boolean => {
              return v1.id === v2.id;
            }),
          };
        })
      );

      return {
        companies: await companiesTask.run(),
        applications: extendedApplications,
        firms: await firmsTask.run(),
      };
    };

    run()
      .then((data: any): void => {
        dispatch(createAction(UsersFormActions.initializeCompleted, data));
      })
      .catch((error: any): void => {
        console.warn(error);
      });

    return (): void => {
      abortController.abort();

      tasks.forEach((task: Task<any>): void => {
        task.cancel();
      });
    };
  };

const distinct = <T>(data: readonly T[], equal: (v1: T, v2: T) => boolean): readonly T[] => {
  return data.filter(
    (item: T, index: number, array: readonly T[]): boolean =>
      array.slice(index + 1).find((next: T): boolean => equal(next, item)) === undefined
  );
};
