import { Typography } from '@mui/material';
import { toClassName } from 'helpers/toClassName';
import React from 'react';
import styles from 'routes/common-routes.module.scss';

interface Props {
  readonly title: string | React.Component | React.FunctionComponent;
  readonly width?: 'narrow' | 'normal';
}

const isComponent = (value: any): value is React.ComponentType => typeof value !== 'string';

export const Scaffold: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>
): React.ReactElement => {
  const { title: Component, width = 'normal' } = props;

  const BasicTitle: React.FC = (): React.ReactElement => {
    if (isComponent(Component)) {
      return (
        <div className={styles.title}>
          <Component />
        </div>
      );
    }

    return (
      <div className={styles.title}>
        <Typography variant="h1">
          <>{props.title}</>
        </Typography>
      </div>
    );
  };

  return (
    <div className={toClassName(styles.container, styles[width])}>
      <BasicTitle />
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};
