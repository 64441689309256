import { LogLevel } from 'components/LogLevel';
import {
  Alignment,
  ColumnDefinition,
  ColumnType,
  createFromDefinition,
} from 'components/Table/types/column';
import { SolaceLogEntry } from 'types/solace';

export const columnDefinitions: ReadonlyArray<ColumnDefinition<SolaceLogEntry>> = [
  {
    columnType: ColumnType.text,
    header: 'ID',
    weight: 1,
    key: 'id',
    alignment: Alignment.center,
  },
  {
    columnType: ColumnType.dateTime,
    header: 'Timestamp',
    weight: 4,
    key: 'timestamp',
    alignment: Alignment.center,
    sortable: true,
  },
  {
    columnType: ColumnType.custom,
    header: 'Level',
    weight: 2,
    key: 'logLevel',
    sortable: true,
    filterable: true,
    component: LogLevel,
    alignment: Alignment.center,
  },
  {
    columnType: ColumnType.text,
    header: 'Client ID',
    weight: 3,
    key: 'clientId',
    sortable: true,
    filterable: true,
  },
  {
    columnType: ColumnType.text,
    header: 'Session ID',
    weight: 3,
    key: 'sessionId',
    sortable: true,
    filterable: true,
  },
  {
    columnType: ColumnType.text,
    header: 'Message',
    weight: 16,
    key: 'message',
  },
];

export const columns = columnDefinitions.map(createFromDefinition);
