import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import React from 'react';
import { useSelector } from 'react-redux';
import { databaseServersSelector } from 'redux/reducers/referenceDataReducer';
import { DatabaseServer } from 'types/databaseServer';

interface Props {
  readonly value: string;

  onChange(value: string): void;
}

export const ServersDropdown: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value, onChange } = props;
  const list = useSelector(databaseServersSelector);

  const handleChange = React.useCallback(
    (event: SelectChangeEvent<string>): void => {
      const { value } = event.target;
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select value={value} fullWidth={true} onChange={handleChange}>
      <MenuItem value="" disabled>
        Select One
      </MenuItem>

      {list.map((item: DatabaseServer): React.ReactElement => {
        return (
          <MenuItem key={item.dbservername} value={item.dbservername}>
            {item.dbservername}
          </MenuItem>
        );
      })}
    </Select>
  );
};
