export interface RecordsPage<T> {
  readonly records: readonly T[];
  readonly total: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class RecordsPage<T> {
  public static filter<T>(page: RecordsPage<T>, predicate: (input: T) => boolean): RecordsPage<T> {
    const { records, total } = page;
    const filteredRecords = records.filter(predicate);
    const removedCount = records.length - filteredRecords.length;

    return {
      records: filteredRecords,
      total: total - removedCount,
    };
  }

  public static map<T, O>(page: RecordsPage<T>, mapper: (input: T) => O): RecordsPage<O> {
    const { records, total } = page;

    return {
      records: records.map(mapper),
      total: total,
    };
  }

  public static empty<T>(): RecordsPage<T> {
    return {
      records: [],
      total: 0,
    };
  }
}
