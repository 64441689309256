import styles from 'components/Form/form.module.scss';
import { FieldError, FieldErrorType } from 'components/Form/index';
import React from 'react';

interface Props {
  readonly error?: FieldError | undefined | null;
}

export const FieldErrorWidget: React.FC<Props> = (props: Props): React.ReactElement | null => {
  const { error } = props;

  const message = React.useMemo((): string | undefined => {
    if (!error) {
      return undefined;
    }

    if (error.message) {
      return error.message;
    }

    switch (error.errorType) {
      case FieldErrorType.none:
        return undefined;
      case FieldErrorType.requiredMissing:
        return "This field is required but it's empty";
      case FieldErrorType.badValue:
        return 'This field has a bad value';
    }
  }, [error]);

  if (!message) {
    return null;
  }

  return (
    <div className={styles.error}>
      <div className={styles.errorContent}>
        <div className={styles.decorationIcon}>
          <i className="fa fa-exclamation-circle" />
        </div>
        <p className={styles.errorMessage}>{message}</p>
      </div>
    </div>
  );
};
