import { HeaderCell } from 'components/Table/components/HeaderCell';
import styles from 'components/Table/components/HeaderRow/header-row.module.scss';
import { Column } from 'components/Table/types/column';
import { SortDirection } from 'components/Table/types/sortDirection';
import { toClassName } from 'helpers/toClassName';
import React from 'react';

interface Props<T> {
  readonly columns: ReadonlyArray<Column<T>>;
  readonly filters?: Record<keyof T, string>;
  readonly sorting?: Record<keyof T, SortDirection>;

  onColumnMoved?(from: number, to: number): void;
  onFiltered?(columnKey: keyof T, value: string): void;
  onColumnSorted?(columnKey: keyof T, value: SortDirection): void;
  onColumnResized?(columnKey: keyof T, value: SortDirection): void;
}

export function HeaderRow<T>(props: Props<T>): React.ReactElement {
  // Properties
  const { columns, filters, sorting } = props;
  // Actions
  const { onColumnMoved, onFiltered, onColumnSorted } = props;
  // Local State
  const [dragging, setDragging] = React.useState<boolean>(false);

  const onDragStart = React.useCallback((): void => setDragging(true), []);
  const onDragEnd = React.useCallback((): void => setDragging(false), []);

  const mapper = React.useCallback(
    (column: Column<T>): JSX.Element => {
      const filter = filters ? filters[column.key] : undefined;
      const sortDirection = (sorting ? sorting[column.key] : undefined) ?? SortDirection.None;

      return (
        <HeaderCell
          key={column.key as string}
          column={column}
          filter={filter}
          sortDirection={sortDirection}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onColumnMoved={onColumnMoved}
          onFiltered={onFiltered}
          onColumnSorted={onColumnSorted}
        />
      );
    },
    [filters, onColumnMoved, onColumnSorted, onDragEnd, onDragStart, onFiltered, sorting]
  );

  return <tr className={toClassName({ [styles.dragging]: dragging })}>{columns.map(mapper)}</tr>;
}
