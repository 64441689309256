import { FormLabel, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { CustomDatePicker } from 'components/CustomDatePicker';
import { toClassName } from 'helpers/toClassName';
import React from 'react';
import { useSelector } from 'react-redux';
import { executionsSelector } from 'redux/reducers/executionsReducer';
import styles from 'styles/common-filter-forms.module.scss';
import { Execution, SummaryReportQuery } from 'types/summaryReport';

interface Props {
  readonly filters: SummaryReportQuery;
  readonly venue: string;

  onChange(filters: SummaryReportQuery, venue: string): void;
}

export const Filters: React.FC<Props> = (props: Props): React.ReactElement => {
  const executions = useSelector(executionsSelector);
  const { filters, venue } = props;
  const { onChange } = props;

  const handleFieldChange = React.useCallback(
    (name: keyof SummaryReportQuery, value: any): void => {
      onChange({ ...filters, [name]: value }, venue);
      return;
    },
    [filters, onChange, venue]
  );

  const venues = React.useMemo(
    (): readonly string[] =>
      Array.from(new Set(executions.map((execution: Execution): string => execution.venue))),
    [executions]
  );

  const handleVenueChange = React.useCallback(
    (event: SelectChangeEvent, _: React.ReactNode): void => {
      const { value } = event.target;
      onChange(filters, value);
    },
    [filters, onChange]
  );

  return (
    <form className={styles.form}>
      <div className={styles.field}>
        <FormLabel className={styles.label}>Venue</FormLabel>
        <Select value={venue} onChange={handleVenueChange}>
          <MenuItem value="">All</MenuItem>
          {venues.map(
            (venue: string): React.ReactElement => (
              <MenuItem key={venue} value={venue}>
                {venue}
              </MenuItem>
            )
          )}
        </Select>
      </div>
      <div className={styles.field}>
        <FormLabel className={styles.label}>From Date</FormLabel>
        <CustomDatePicker
          name="fromDate"
          value={filters.from_time}
          time={{ hour: 23, minute: 59, second: 59 }}
          className={toClassName(styles.input, styles.datePicker)}
          onDateChange={handleFieldChange}
        />
      </div>
      <div className={styles.field}>
        <FormLabel className={styles.label}>To Date</FormLabel>
        <CustomDatePicker
          name="toDate"
          value={filters.to_time}
          time={{ hour: 23, minute: 59, second: 59 }}
          className={toClassName(styles.input, styles.datePicker)}
          onDateChange={handleFieldChange}
        />
      </div>
    </form>
  );
};
