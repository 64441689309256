const strings: Record<string, string> = {
  // Status Labels
  Unknown: 'Unknown',
  On: 'On',
  Off: 'Off',
  Overtime: 'Overtime',
  Error: 'Error',
  Down: 'Down',
  // Day of week
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  // Flow Columns
  FlowType: 'Flow Type',
  FlowStatus: 'Flow Status',
  Client: 'Client',
  ClientStatus: 'Client Status',
  ClientLastLogon: 'Client Last Logon',
  Destination: 'Destination',
  Desk: 'Desk',
  DestinationStatus: 'Destination Status',
  DestinationLastLogon: 'Destination Last Logon',
  Start: 'Start',
  End: 'End',
  // Common
  Search: 'Search',
  From: 'From',
  To: 'To',
  SelectOne: 'Select One',
  All: 'All',
  Any: 'Any',
  results: 'results',
  Found: 'Found',
  // Messages search form
  OrderOriginationId: 'Order Origination Id',
  Venues: 'Venues',
  Sides: 'Sides',
  Symbols: 'Symbols',
  ClientOrderId: 'Client Order Id',
  // Authentication Flow
  WeAreRedirectingYou:
    'Verifying authentication, if all is good we can redirect you to the application',
  HideShowColumns: 'Hide/Show fields',
  UserForm: 'User FirmsForm',
  AddUser: 'Add User',
  UserListTitle: 'WhoIsLoggedIn List',
  Filter: 'Filter',
};

export default strings;
