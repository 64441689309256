import { RowData } from 'components/Table/types/rowData';
import { Status } from 'enums/status';
import { WithStatus } from 'interfaces/withStatus';
import React from 'react';

export type SetFilterFn = (status: Status) => void;

interface WithIdAndStatus extends WithStatus {
  readonly id: string;
}

export const useStatusFilter = <T extends WithIdAndStatus>(
  rows: readonly T[]
): [Record<string, RowData<T>>, Status, SetFilterFn] => {
  const [filter, setFilter] = React.useState<Status>(Status.None);

  const filteredRows = React.useMemo(
    (): Record<string, RowData<T>> =>
      rows
        .filter((item: T): boolean => {
          if (filter === Status.None) {
            return true;
          }

          return item.status === filter;
        })
        .reduce(
          (rows: Record<string, RowData<T>>, item: T): Record<string, RowData<T>> => ({
            ...rows,
            [item.id]: { data: item, id: item.id, children: {} },
          }),
          {}
        ),
    [filter, rows]
  );

  return [filteredRows, filter, setFilter];
};
