import { Box, Button } from '@mui/material';
import { Dispatch } from '@reduxjs/toolkit';
import { CenteredModal } from 'components/CenteredModal';
import { ErrorModal } from 'components/ErrorModal';
import { Scaffold } from 'components/Scaffold';
import { SuccessModal } from 'components/SuccessModal';
import deepEqual from 'fast-deep-equal';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import {
  fetchRiskEnginesAction,
  fetchRiskMatrixAction,
  updateRiskMatrixAction,
} from 'redux/actions/riskActions';
import { AsyncAction } from 'redux/asyncAction';
import {
  resetErrorMessage,
  resetSuccessMessage,
  restoreOriginalRiskMatrix,
  riskSelector,
} from 'redux/reducers/riskReducer';
import { Matrix } from 'routes/Risk/components/Matrix';
import { RiskHeader } from 'routes/Risk/components/RiskHeader';
import classes from 'routes/Risk/risk.module.scss';

export const Risk: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<Dispatch<AsyncAction | AnyAction>>();
  const risk = useSelector(riskSelector);
  const modified = React.useMemo(
    (): boolean => risk.matrix !== risk.originalMatrix,
    [risk.matrix, risk.originalMatrix]
  );

  const disableSubmit = React.useMemo((): boolean => {
    return risk.savingRiskMatrix || deepEqual(risk.matrix, risk.originalMatrix);
  }, [risk.matrix, risk.originalMatrix, risk.savingRiskMatrix]);

  const closeSuccessModal = React.useCallback((): void => {
    dispatch(resetSuccessMessage());
  }, [dispatch]);

  const closeErrorModal = React.useCallback((): void => {
    dispatch(resetErrorMessage());
  }, [dispatch]);

  React.useEffect((): void => {
    if (risk.sessionID === '') {
      return;
    }

    dispatch(fetchRiskMatrixAction(risk.sessionID, risk.clientID));
  }, [dispatch, risk.clientID, risk.sessionID]);

  React.useEffect((): void => {
    dispatch(fetchRiskEnginesAction());
  }, [dispatch]);

  const handleReset = React.useCallback((): void => {
    dispatch(restoreOriginalRiskMatrix());
  }, [dispatch]);

  const handleSubmit = React.useCallback((): void => {
    dispatch(updateRiskMatrixAction(risk.matrix));
  }, [dispatch, risk.matrix]);

  return (
    <Scaffold title="Risk">
      <RiskHeader disabled={risk.savingRiskMatrix} />
      <Matrix matrix={risk.matrix} />
      <Box className={classes.buttonsContainer}>
        <Button onClick={handleReset} disabled={!modified}>
          Reset
        </Button>
        <Button variant="contained" onClick={handleSubmit} disabled={disableSubmit}>
          Submit
        </Button>
      </Box>
      <CenteredModal open={!!risk.errorMessage} onClose={closeErrorModal}>
        <ErrorModal
          title="There was an error"
          message="An error occurred when executing your last operation"
          error={risk.errorMessage}
          onClose={closeErrorModal}
        />
      </CenteredModal>
      <CenteredModal open={!!risk.successMessage} onClose={closeSuccessModal}>
        <SuccessModal
          title="Operation Completed"
          message={risk.successMessage}
          onClose={closeSuccessModal}
        />
      </CenteredModal>
    </Scaffold>
  );
};
