import { createTheme, Theme } from '@mui/material';

const css = getComputedStyle(document.body);
export const getCssProperty = (id: string): string => css.getPropertyValue(id).trim();

const inputHorizontalPadding = 6;
const inputVerticalPadding = 0;

const inputCommon = {
  paddingTop: inputVerticalPadding,
  paddingBottom: inputVerticalPadding,
  paddingLeft: inputHorizontalPadding,
  paddingRight: inputHorizontalPadding,
  minHeight: 'auto',
  lineHeight: 2,
  borderRadius: 4,
  height: 'auto',
};

const theme: Theme = createTheme({
  transitions: {
    create: (): string => 'none',
  },
  palette: {
    primary: {
      main: getCssProperty('--colors-primary'),
      dark: getCssProperty('--colors-primary-dark'),
      light: getCssProperty('--colors-primary-light'),
    },
    secondary: {
      main: getCssProperty('--colors-secondary-text'),
    },
    text: {
      primary: getCssProperty('--colors-primary-text'),
    },
  },
  typography: {
    h1: {
      fontSize: 18,
      textTransform: 'uppercase',
      fontWeight: 600,
    },
  },
  components: {
    MuiInputBase: {
      defaultProps: {
        spellCheck: false,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: 800,
        },
        paperFullWidth: {
          maxWidth: '100%',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: getCssProperty('--colors-paper'),
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.15)',
        },
        invisible: {
          background: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          verticalAlign: 'top',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        displayEmpty: true,
      },
      styleOverrides: {
        select: {
          ...inputCommon,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: getCssProperty('--small-font-size'),
          fontWeight: 500,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 13,
          fontWeight: 500,
          padding: 4,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: getCssProperty('--colors-paper'),
          '&Mui-disabled': {
            opacity: 0.5,
          },
        },
        multiline: {
          padding: 0,
          lineHeight: 1,
        },
        input: {
          fontWeight: 500,
          fontSize: 13,
          ...inputCommon,
        },
      },
    },
  },

  unstable_strictMode: true,
});

export default theme;
