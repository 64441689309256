import { Scaffold } from 'components/Scaffold';
import { Table } from 'components/Table';
import { RowData } from 'components/Table/types/rowData';
import { ProcessingState } from 'enums/processingState';
import { useApi } from 'hooks/useApi';
import { useTable } from 'hooks/useTable';
import { Moment } from 'moment/moment';
import React from 'react';
import baseColumns from 'routes/ProfitabilityReport/columns';
import { Filters } from 'routes/ProfitabilityReport/Filters';
import { ProfitabilityReport } from 'types/profitabilityReport';

export interface FiltersForm {
  readonly mpid: string;
  readonly fromDate: Moment | null;
  readonly toDate: Moment | null;
}

const emptyFilters: FiltersForm = {
  mpid: 'all',
  fromDate: null,
  toDate: null,
};

export const Profitability: React.FC = (): React.ReactElement => {
  const [filters, setFilters] = React.useState<FiltersForm>(emptyFilters);
  const [reports, setReports] = React.useState<readonly ProfitabilityReport[]>([]);
  const [processingState, setProcessingState] = React.useState<ProcessingState>(
    ProcessingState.idle()
  );

  const api = useApi();
  const tableProps = useTable<ProfitabilityReport>('reports', baseColumns);
  const rows = React.useMemo(
    (): Record<string, RowData<ProfitabilityReport>> =>
      reports.reduce(
        (
          rows: Record<string, RowData<ProfitabilityReport>>,
          next: ProfitabilityReport
        ): Record<string, RowData<ProfitabilityReport>> => {
          return {
            ...rows,
            [next.firm]: {
              id: next.firm,
              data: next,
            },
          };
        },
        {}
      ),
    [reports]
  );

  React.useEffect((): VoidFunction | void => {
    if (filters.fromDate === null || filters.toDate === null) {
      return;
    }

    const task = api.getProfitabilityReports({
      customer: filters.mpid === 'all' ? undefined : filters.mpid,
      fromTime: filters.fromDate,
      toTime: filters.toDate,
    });

    setProcessingState(ProcessingState.processing());
    setReports([]);

    task
      .run()
      .then((reports: readonly ProfitabilityReport[]): void => {
        setReports(reports);
        setProcessingState(ProcessingState.idle());
      })
      .catch((error: any): void => {
        setProcessingState(ProcessingState.error(error.message));
      });

    return (): void => {
      task.cancel();
    };
  }, [api, filters.fromDate, filters.mpid, filters.toDate]);

  return (
    <Scaffold title="Profitability Report">
      <Filters values={filters} onFiltersChange={setFilters} />

      <Table
        rowKey="firm"
        rows={rows}
        loading={ProcessingState.isProcessing(processingState)}
        {...tableProps}
      />
    </Scaffold>
  );
};
