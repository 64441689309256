// noinspection SpellCheckingInspection

export const GET_SNAPSHOT = 'prism/getsnapshot';
export const GET_CHAINED_MESSAGES = 'prism/getchainedmsgs';
export const GET_MESSAGES = 'prism/getmessages';
export const GET_RAW_MESSAGE = 'prism/getrawmsg';
export const GET_VENUES = 'prism/getvenues';
export const GET_ORDER_ORIGINATION_IDS = 'prism/getorderoriginationids';
export const GET_FLOW = 'prism/getflow';
export const GET_ERRORS = 'prism/getsolacelog';
export const GET_SUMMARY_REPORT = 'prism/getsummaryreport';
export const BROWSE_SOLACE_QUEUE = 'prism/browsesolacequeue';
export const DELETE_SOLACE_MESSAGES = 'prism/deletesolacemsg';
export const GET_SIDES = 'prism/sides';
export const ADD_USER = 'prism/adduser';
export const GET_USERS = 'prism/apps/getusers';
export const GET_ACTIVE_LOGIN = 'prism/apps/getactivelogin';
export const OKTA_GET_APPS = 'prism/okta/getapps';
export const OKTA_GET_GROUPS_BY_APP = 'prism/okta/getgroupsbyapp';
export const OKTA_GET_GROUPS = 'prism/okta/getgroups';
export const PRISM_GET_ROLE = 'prism/getrole';
export const PRISM_ME = 'prism/apps/users/me';
export const PRISM_FXO_GET_FIRMS = 'prism/fxoconfig/getfirms';
export const PRISM_FXO_DELETE_FIRM = 'prism/fxoconfig/deletefirm';
export const PRISM_FXO_CREATE_FIRM = 'prism/fxoconfig/createfirm';
export const PRISM_FXO_UPDATE_FIRM = 'prism/fxoconfig/updatefirm';

export const PRISM_FXO_DELETE_USER = 'prism/apps/deleteuser';
export const PRISM_FXO_CREATE_USER = 'prism/apps/adduser';
export const PRISM_FXO_UPDATE_USER = 'prism/apps/updateuser';

export const SONIC_GET_GROUPS = 'prism/sonic/getappgroups';
export const PRISM_REFRESH_CACHE = 'prism/cache/purge';

export const PRISM_GET_COMPANIES = 'prism/getcompanies';
export const PRISM_GET_APPS = 'prism/getapps';
export const PRISM_GET_USER_DETAILS = 'prism/apps/getuser';
export const PRISM_GET_DATABASE_SERVERS = 'prism/getdbservers';
export const PRISM_DOWNLOAD_OUTER_SM_CONFIG = 'prism/sm/downloadouterconfig';

export const SM_SYNC_CONFIG = 'prism/sm/synconfig';
export const SM_GET_CONFIG = 'prism/sm/getconfig';
export const SM_GET_DEFAULTS = 'prism/sm/getsmdefaults';
export const SM_SAVE_CONFIG = 'prism/sm/saveconfig';
export const SM_RELOAD_RULE = 'prism/sm/reloadrule';
export const SM_IMPORT_CONFIG = 'prism/sm/importconfig';
export const SM_DELETE_CONFIG = 'prism/sm/deleteconfig';
export const SM_UPDATE_SEQUENCE_NUMBERS = 'prism/sm/sequencereset';

export const RISK_GET_SESSIONS = 'prism/risk/getrisksessions';
export const RISK_GET_SESSIONS_BY_RISK_ENGINE_ID = 'prism/risk/getsessionsbyriskengineid';
export const RISK_GET_MATRIX = 'prism/risk/getmatrix';
export const CREATE_RISK_MATRIX = 'prism/risk/savematrix';
export const UPDATE_RISK_MATRIX = 'prism/risk/updatematrix';
export const DELETE_RISK_MATRIX = 'prism/risk/deletematrix';

export const GET_SOLACE_SERVERS = 'prism/getsolaceservers';

export const GET_PROFITABILITY_REPORT = 'prism/reporting/getprofitabilityreport';
export const GET_CUSTOMERS = 'prism/user/getcustomers';
