import { ServersDropdown } from 'ComplexModals/SessionEditor/forms/DatabaseDetailsView/ServersDropdown';
import { FieldDefinition, FieldType } from 'components/Form/components/Field/types';
import { SMDatabaseDetails } from 'redux/definitions/central';

export const fields: ReadonlyArray<FieldDefinition<SMDatabaseDetails>> = [
  {
    name: 'dbServerName',
    label: 'Server Name',
    fieldType: FieldType.custom,
    component: ServersDropdown,
    display: true,
    required: true,
  },
];
