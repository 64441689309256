export class QueryString {
  private readonly fields: Record<string, string>;

  constructor(fields: Record<string, string>) {
    this.fields = fields;
  }

  public toString(): string {
    const entries = Object.entries(this.fields);

    return entries.map(([key, value]: [string, any]): string => `${key}=${value}`).join('&');
  }
}
