import { Button, CircularProgress } from '@mui/material';
import styles from 'components/RunningTaskModal/running-task-modal.module.scss';
import React from 'react';

interface Props {
  readonly title: string;
  readonly body: string;

  onCancel?(): void;
}

export interface RunningTaskData {
  readonly title: string;
  readonly body: string;
}

export const RunningTaskModal: React.FC<Props> = (props: Props): React.ReactElement => {
  const { title, body } = props;
  const { onCancel } = props;

  return (
    <div className={styles.container}>
      <div className={styles.titleContent}>
        <CircularProgress />
        <div className={styles.text}>
          <h4>{title}</h4>
          <p>{body}</p>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button color="warning" onClick={onCancel} disabled={!onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};
