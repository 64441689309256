import classes from 'components/Table/table.module.scss';
import { Column, ColumnType } from 'components/Table/types/column';
import { ignore } from 'helpers/ignoreEvent';
import React from 'react';
import { Identifiable } from 'types/identifiable';

interface Props<T extends Identifiable> {
  readonly columns: ReadonlyArray<Column<T>>;
  onSubmit?(item: T): void;
}

export function GrowTableRow<T extends Identifiable>(props: Props<T>): React.ReactElement {
  const [item, setItem] = React.useState<T>({} as T);
  const [_, ...actualColumns] = props.columns;
  const { onSubmit } = props;

  const handleNumericInput = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      switch (event.key) {
        case 'Backspace':
        case 'Delete':
        case 'Enter':
        case 'Return':
        case 'ArrowLeft':
        case 'ArrowRight':
        case 'End':
        case 'Home':
        case 'Tab':
          return;
        default: {
          const { value } = event.target as HTMLInputElement;

          const numeric = Number(value + event.key);
          if (isNaN(numeric)) {
            event.preventDefault();
          }
        }
      }
    },
    []
  );

  const handleTextValueChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = event.target;
      const key = name as keyof T;

      setItem((currentItem: T): T => ({ ...currentItem, [key]: value }));
    },
    []
  );

  const handleNumericValueChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = event.target;
      const key = name as keyof T;

      setItem((currentItem: T): T => ({ ...currentItem, [key]: Number(value) }));
    },
    []
  );

  // FIXME: make navigation more user friendly (like backspace with empty input goes back)
  const handleKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key !== 'Return' && event.key !== 'Enter') {
        // Do nothing in these cases
        return;
      }

      const input: HTMLInputElement = event.target as HTMLInputElement;
      // Parent is form then the TD
      const parent = input.parentElement?.parentElement;
      if (parent?.tagName !== 'TD') {
        throw new Error('unexpected problem, I do not think I can navigate in this situation');
      }

      const next = parent.nextElementSibling;
      if (next === null) {
        // Find the very first one
        let found = parent.previousElementSibling;
        while (found !== null && found.previousElementSibling !== null) {
          found = found.previousElementSibling;
        }
        // First element is not really the input element, right?
        found = found?.nextElementSibling;

        // The form and then the input?
        const firstChild = found?.firstElementChild?.firstElementChild;
        if (firstChild?.tagName !== 'INPUT') {
          throw new Error('first child of current cell is not an input element');
        }
        const firstInput = firstChild as HTMLInputElement;

        setTimeout((): void => {
          firstInput.scrollIntoView(true);
          firstInput.focus();
        }, 0);

        // We seem to have reached the last column
        onSubmit?.(item);
        setItem({} as T);
      } else {
        const firstChild = next.firstElementChild?.firstElementChild;
        if (firstChild?.tagName !== 'INPUT') {
          // FIXME: should really skip no? If it is not editable
          throw new Error('first child of current cell is not an input element');
        }
        const nextInput = firstChild as HTMLInputElement;

        setTimeout((): void => {
          nextInput.scrollIntoView(true);
          nextInput.focus();
        }, 0);
      }
    },
    [item, onSubmit]
  );

  return (
    <tr>
      <td className={classes.growableCell}></td>
      {actualColumns.map((column: Column<T>): React.ReactElement => {
        if (column.editable) {
          switch (column.columnType) {
            case ColumnType.text:
              return (
                <td key={String(column.key)} className={classes.growableCell}>
                  <form autoComplete="off" onSubmit={ignore}>
                    <input
                      name={String(column.key)}
                      className={classes[column.alignment]}
                      value={String(item[column.key] ?? '')}
                      onKeyUp={handleKeyUp}
                      onChange={handleTextValueChange}
                    />
                  </form>
                </td>
              );
            case ColumnType.integer:
              return (
                <td key={String(column.key)} className={classes.growableCell}>
                  <form autoComplete="off" onSubmit={ignore}>
                    <input
                      name={String(column.key)}
                      className={classes[column.alignment]}
                      value={String(item[column.key] ?? '')}
                      onKeyDown={handleNumericInput}
                      onKeyUp={handleKeyUp}
                      onChange={handleNumericValueChange}
                    />
                  </form>
                </td>
              );
            case ColumnType.none:
            case ColumnType.mapped:
            case ColumnType.currency:
            case ColumnType.status:
            case ColumnType.dateTime:
            case ColumnType.dayAndTime:
            case ColumnType.custom:
            default:
              throw new Error(
                'currently only integer and text columns can be editable: ' + column.columnType
              );
          }
        } else {
          // An empty cell is ok
          return <td key={column.key as string} />;
        }
      })}
    </tr>
  );
}
