import { toClassName } from 'helpers/toClassName';
import { SMSession } from 'interfaces/session';
import React from 'react';
import { useDispatch } from 'react-redux';
import { resetSMSequenceAction } from 'redux/services/sessionsServices';
import styles from 'routes/SessionsScreen/components/SeuquenceNumberCell/number-input-cell.module.scss';

interface Props {
  readonly value?: number;
  readonly name?: string;
  readonly data?: SMSession;
}

export const SequenceNumberCell: React.FC<Props> = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const { value = null, name, data } = props;

  const [internalValue, setInternalValue] = React.useState<number | null>(null);
  const [flashing, setFlashing] = React.useState<boolean>(false);

  const textValue = React.useMemo((): string => {
    if (internalValue !== null) {
      return internalValue.toString();
    } else if (value !== null) {
      return value.toString();
    } else {
      return '';
    }
  }, [internalValue, value]);

  const handleSubmit = React.useCallback((): void => {
    switch (name) {
      case 'inSequence':
        dispatch(
          resetSMSequenceAction({
            sessionId: data.id,
            gapFillFlag: true,
            incomingSeq: internalValue,
            outgoingSeq: -1,
          })
        );
        break;
      case 'outSequence':
        dispatch(
          resetSMSequenceAction({
            sessionId: data.id,
            gapFillFlag: true,
            incomingSeq: -1,
            outgoingSeq: internalValue,
          })
        );
        break;
    }
  }, [data.id, dispatch, internalValue, name]);

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const numeric = Number(value);
    if (isNaN(numeric)) {
      return;
    }

    setInternalValue(numeric);
  }, []);

  const handleBlur = React.useCallback((): void => {
    setInternalValue(null);
  }, []);

  const handleFocus = React.useCallback((): void => {
    setInternalValue(value);
  }, [value]);

  const handleKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      const { key } = event;
      if (key === 'Enter' || key === 'Return') {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  React.useEffect((): VoidFunction | void => {
    if (value === null) {
      return;
    }

    setFlashing(true);
    const timeout = setTimeout((): void => {
      setFlashing(false);
    }, 1200);

    return (): void => {
      clearTimeout(timeout);
      setFlashing(false);
    };
  }, [value]);

  return (
    <input
      className={toClassName(styles.numberInputCell, {
        [styles.flashing]: flashing,
      })}
      value={textValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      onChange={handleChange}
    />
  );
};
