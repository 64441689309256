export const swapColumns = (
  original: readonly number[],
  source: number,
  target: number
): readonly number[] => {
  if (target === -1 || source === -1) {
    return original;
  }
  const value = original[source];
  const temporary = [...original.slice(0, source), ...original.slice(source + 1)];
  temporary.splice(target, 0, value);

  return temporary;
};
