import { createSlice } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/applicationState';

export interface StatusBarState {
  connected: boolean;
  lastUpdate: Date;
}

const initialState: StatusBarState = {
  connected: false,
  lastUpdate: new Date(),
};

const slice = createSlice({
  name: 'statusBar',
  initialState: initialState,
  reducers: {
    connected: (state: StatusBarState): void => {
      state.connected = true;
    },
    disconnected: (state: StatusBarState): void => {
      state.connected = false;
    },
    updated: (state: StatusBarState): void => {
      state.lastUpdate = new Date();
    },
  },
});

export const statusBarSelector = (state: ApplicationState): StatusBarState => state.statusBar;

export const { connected, disconnected, updated } = slice.actions;

export default slice.reducer;
