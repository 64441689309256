import 'extensions-impl';

import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Api, ApiContext } from 'api';
import { Session } from 'auth/context';
import config from 'config';
import { LOGIN_FLOW, OKTA_CALLBACK, ROOT } from 'constants/routes';
import React from 'react';
import { Provider } from 'react-redux';
import { Routes } from 'react-router';
import { Route } from 'react-router-dom';
import { Store } from 'redux';
import createPrismStore from 'redux/store';
import LoginCallback from 'routes/LoginCallback';
import LoginFlow from 'routes/LoginFlow';
import Prism from 'routes/Prism';
import { SignalR, SignalRContext } from 'signalR';
import theme from 'theme';

class Main extends React.PureComponent {
  private readonly session: Session;
  private readonly api: Api;
  private readonly store: Store;

  constructor(props: never) {
    super(props);

    this.session = new Session();
    this.api = new Api(`${config.apiUrl}/api`);
    this.store = createPrismStore(this.api);

    this.initialize();
  }

  private initialize(): void {
    const { session, api } = this;

    if (session.accessToken) {
      api.setAccessToken(session.accessToken);
    }
  }

  public render(): React.ReactElement | null {
    if (!this.store) {
      return null;
    }

    return (
      <Provider store={this.store}>
        <SignalRContext.Provider value={new SignalR(config.signalRUrl, this.store)}>
          <ApiContext.Provider value={this.api}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Routes>
                  <Route path={LOGIN_FLOW} element={<LoginFlow />} />
                  <Route path={OKTA_CALLBACK} element={<LoginCallback />} />
                  <Route path={ROOT} element={<Prism />} />
                </Routes>
              </LocalizationProvider>
            </ThemeProvider>
          </ApiContext.Provider>
        </SignalRContext.Provider>
      </Provider>
    );
  }
}

export default Main;
