import styles from 'components/LoadingOverlay/loading-overlay.module.scss';
import React from 'react';

interface Props {
  readonly loading: boolean;
}

export const LoadingOverlay: React.FC<Props> = (props: Props): React.ReactElement => {
  const { loading } = props;
  if (!loading) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.spinner} />
    </div>
  );
};
