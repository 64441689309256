export const parseResponse = async <T>(response: Response): Promise<T | null> => {
  const responseText = await response.text();

  switch (response.status) {
    case 200:
      try {
        return JSON.parse(responseText);
      } catch (error: any) {
        console.warn(`bad http request response: ${responseText}`);
        return Promise.resolve(null);
      }
    case 204:
      return Promise.resolve(null);
    case 500:
      console.warn('internal server error occurred.', responseText);
      return Promise.resolve(null);
    default:
      throw Error(response.statusText);
  }
};

export const wrapResolver =
  <T>(resolve: (result: T) => void, reject: (reason: any) => void, empty: T): ((_: any) => void) =>
  (untyped: any): void => {
    if (untyped === null) {
      resolve(empty);
    } else if ('error_msg' in untyped) {
      reject(untyped.error_msg);
    } else {
      resolve(untyped as T);
    }
  };
