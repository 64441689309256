import { CenteredModal } from 'components/CenteredModal';
import { ErrorModal } from 'components/ErrorModal';
import { RunningTaskModal } from 'components/RunningTaskModal';
import { SuccessModal } from 'components/SuccessModal';
import { ModalType } from 'components/TaskRunningStatus/reducer';
import React from 'react';

interface Props {
  readonly message: string;
  readonly modalType: ModalType;

  onCancel(): void;
  onSucceeded(): void;
  onErrored(): void;
}

export const TaskRunningStatus: React.FC<Props> = (props: Props): React.ReactElement => {
  const { message, modalType } = props;
  const { onSucceeded, onErrored, onCancel } = props;

  switch (modalType) {
    case ModalType.progress:
      return (
        <CenteredModal title="Operation Running" open={true}>
          <RunningTaskModal title="Please wait" body={message} onCancel={onCancel} />
        </CenteredModal>
      );
    case ModalType.error:
      return (
        <CenteredModal open={true}>
          <ErrorModal
            title="Something Went Wrong"
            message={message}
            error={message}
            onClose={onErrored}
          />
        </CenteredModal>
      );
    case ModalType.success:
      return (
        <CenteredModal open={true}>
          <SuccessModal title="Everything is good" message={message} onClose={onSucceeded} />
        </CenteredModal>
      );
    case ModalType.none:
      return null;
  }
};
