import { Action } from 'redux/action';

export enum ModalType {
  progress,
  error,
  success,
  none,
}

export enum TaskRunnerAction {
  closeModal,
  taskStarted,
  taskSucceeded,
  taskErrored,
}

export interface State {
  readonly currentModal: ModalType;
  readonly errorMessage: string;
}

const reducer = (state: State, action: Action<TaskRunnerAction>): State => {
  const { type, data } = action;
  switch (type) {
    case TaskRunnerAction.taskStarted:
      return { ...state, currentModal: ModalType.progress };
    case TaskRunnerAction.taskSucceeded:
      return { ...state, currentModal: ModalType.success };
    case TaskRunnerAction.taskErrored:
      return { ...state, currentModal: ModalType.error, errorMessage: data };
    case TaskRunnerAction.closeModal:
      return { ...state, currentModal: ModalType.none };
    default:
      return state;
  }
};

export default reducer;
