import styles from 'components/Table/cell.module.scss';
import { CellClassFactory } from 'components/Table/types/column';

export const emptyStyleCellClassCreator =
  (data: Record<string, string>): CellClassFactory =>
  (value: any): string => {
    const values = Object.values(data);
    if (!values.includes(value)) {
      return styles.emptyOrInvalid;
    } else {
      return '';
    }
  };
