import { QuestionMarkTwoTone } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import styles from 'ComplexModals/SessionEditor/SaveDialog/save-dialog.module.scss';
import React from 'react';

interface Props {
  onRespond(response: 'all' | 'db-only'): void;
  onDiscard(): void;
}

export const SaveDialog: React.FC<Props> = React.forwardRef(function QuestionModal(
  props: Props,
  _: React.Ref<HTMLDivElement>
): React.ReactElement {
  const { onRespond, onDiscard } = props;

  const onSaveAll = React.useCallback((): void => {
    onRespond('all');
  }, [onRespond]);

  const onSaveDbOnly = React.useCallback((): void => {
    onRespond('db-only');
  }, [onRespond]);

  return (
    <Box p={2} className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.icon}>
            <QuestionMarkTwoTone fontSize="inherit" color="primary" />
          </div>
          <div className={styles.titleText}>
            <Typography variant="h1">Save Configuration?</Typography>
            <Typography className={styles.message} color="secondary">
              Please choose one of the options below to save the configuration
            </Typography>
          </div>
        </div>

        <div className={styles.buttons}>
          <Button color="secondary" onClick={onDiscard}>
            Cancel
          </Button>
          <Button color="primary" onClick={onSaveAll}>
            Create file
          </Button>
          <Button variant="contained" color="primary" onClick={onSaveDbOnly}>
            Database ONLY
          </Button>
        </div>
      </div>
    </Box>
  );
});
