import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { Api } from 'api';

type Dispatcher = (dispatch: Dispatch, api: Api) => VoidFunction;

export interface AsyncAction extends AnyAction {
  (dispatch: Dispatch, api: Api): VoidFunction;
}

export const createAsyncAction = (
  executor: (...args: any[]) => AsyncGenerator<AnyAction | VoidFunction>,
  ...args: any[]
): AsyncAction => {
  const fork = async (
    dispatch: Dispatch,
    actions: AsyncGenerator<AnyAction | VoidFunction>
  ): Promise<void> => {
    for await (const action of actions) {
      if (typeof action === 'function') {
        console.warn(action);
      } else {
        if (action.done) {
          return;
        }

        dispatch(action);
      }
    }
  };

  const cancellable = (dispatch: Dispatch, api: Api): VoidFunction => {
    const abortController = new AbortController();
    const actions = executor.apply(cancellable, [api, abortController.signal, ...args]);

    void fork(dispatch, actions);

    return (): void => {
      abortController.abort();
    };
  };

  Object.defineProperty<Dispatcher>(cancellable, 'type', {
    writable: false,
    value: cancellable.name,
  });

  if (!isAsyncAction(cancellable)) {
    throw new Error('cannot build an async action object out of the provided function');
  }

  return cancellable;
};

export const isAsyncAction = (fn: any): fn is AsyncAction => {
  if (typeof fn !== 'function') {
    return false;
  }

  return fn.type === fn.name;
};

export interface AsyncDispatch {
  (action: AsyncAction): VoidFunction;
}
