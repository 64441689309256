import { Form, FormChangeResult } from 'components/Form';
import { FlowForm } from 'interfaces/flow';
import React from 'react';
import { fields } from 'routes/FlowsScreen/fields';

export const CreateFlowForm: React.FC = (): React.ReactElement => {
  const handleFormChange = React.useCallback(
    (name: keyof FlowForm, value: any, _: FlowForm): FormChangeResult => {
      return {
        error: null,
        value: value,
      };
    },
    []
  );

  const handleFormSubmit = React.useCallback((data: FlowForm): void => {
    console.warn(data);
  }, []);

  return (
    <Form
      fields={fields}
      value={FlowForm.empty()}
      onChange={handleFormChange}
      onSubmit={handleFormSubmit}
    />
  );
};
