import { FieldDefinition, FieldType } from 'components/Form/components/Field/types';
import { DeskType, FlowForm, NetworkType } from 'interfaces/flow';
import { DropdownOption } from 'types/dropdownOption';

const optionsFromEnum = (enumType: Record<string, string>): readonly DropdownOption[] => {
  return Object.entries(enumType).map(([, value]: [string, string]): DropdownOption => {
    return {
      name: value,
      id: value,
    };
  });
};

const networkTypes = optionsFromEnum(NetworkType);

export const fields: ReadonlyArray<FieldDefinition<FlowForm>> = [
  {
    fieldType: FieldType.fieldset,
    name: 'Sending/Target Firms',
    label: 'Sending/Target Firms',
    display: true,
    children: [
      {
        fieldType: FieldType.text,
        name: 'sendingFirmName',
        label: 'Sending Firm',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'sendingFirmId',
        label: 'Sending Firm Identifier',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'targetFirmName',
        label: 'Target Firm',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'targetFirmId',
        label: 'Target Firm Identifier',
        display: true,
      },
    ],
  },
  {
    fieldType: FieldType.fieldset,
    name: 'requestedBy',
    label: 'Requested By',
    display: true,
    children: [
      {
        fieldType: FieldType.text,
        name: 'name',
        label: 'Name',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'email',
        label: 'Email',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'phone',
        label: 'Phone',
        display: true,
      },
    ],
  },
  {
    fieldType: FieldType.multiBool,
    name: 'desk',
    label: 'Desk',
    options: optionsFromEnum(DeskType),
    display: true,
  },
  {
    fieldType: FieldType.fieldset,
    name: 'contacts',
    label: 'Contacts',
    display: true,
    children: [
      {
        fieldType: FieldType.text,
        name: 'name',
        label: 'Name',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'email',
        label: 'Email',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'phone',
        label: 'Phone',
        display: true,
      },
    ],
  },
  {
    fieldType: FieldType.fieldset,
    name: 'network',
    label: 'Network',
    display: true,
    children: [
      {
        fieldType: FieldType.numeric,
        name: 'bandwidth',
        label: 'Bandwidth',
        display: true,
      },
      {
        fieldType: FieldType.dropdown,
        name: 'primaryNetwork',
        label: 'Primary Network',
        display: true,
        options: networkTypes,
      },
      {
        fieldType: FieldType.dropdown,
        name: 'backupNetwork',
        label: 'Backup Network',
        display: true,
        options: networkTypes,
      },
    ],
  },
];
