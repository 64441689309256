import styles from 'ComplexModals/SessionEditor/central.module.scss';
import { Section } from 'ComplexModals/SessionEditor/components/Section';
import { fields } from 'ComplexModals/SessionEditor/forms/DatabaseDetailsView/fields';
import { Form, FormChangeResult } from 'components/Form';
import React from 'react';
import { SMDatabaseDetails } from 'redux/definitions/central';
import { SMConfig } from 'redux/definitions/smConfig';

interface Props {
  readonly value: SMDatabaseDetails;

  onChange(name: keyof SMConfig, value: SMDatabaseDetails): void;
}

const DatabaseDetailsView: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value, onChange } = props;

  const handleChange = React.useCallback(
    (
      name: keyof SMDatabaseDetails,
      fieldValue: SMDatabaseDetails[keyof SMDatabaseDetails],
      _: SMDatabaseDetails
    ): FormChangeResult => {
      onChange('databaseDetails', { ...value, [name]: fieldValue });

      return {
        error: null,
        value: fieldValue,
      };
    },
    [onChange, value]
  );

  return (
    <Section title="Database Details">
      <Form className={styles.form} value={props.value} fields={fields} onChange={handleChange} />
    </Section>
  );
};

export default DatabaseDetailsView;
