export interface FlowUpdate {
  readonly ClientSessionID: string;
  readonly DestinationSessionID: string;
  readonly Status: number;
}

export interface FlowMessage {
  readonly Flow: readonly FlowUpdate[];
}

export const isFlowMessage = (message: any | FlowMessage): message is FlowMessage => {
  return 'Flow' in message;
};
