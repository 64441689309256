import styles from 'components/Table/renderers/DateTimeCellRenderer/timestamp-renderer.module.scss';
import moment, { isMoment, Moment } from 'moment';
import React from 'react';

interface Props {
  readonly value: {
    readonly value: number | Date | Moment;
  };
}

export type DateTimeCellProps = Props;

const convertToMoment = (value: number | Date | Moment | null): Moment | null => {
  if (value === null) {
    return null;
  } else if (typeof value === 'number') {
    return moment(1000 * value);
  } else if (value instanceof Date) {
    return moment(value);
  } else if (isMoment(value)) {
    return value;
  } else {
    return null;
  }
};

export const DateTimeCell = (props: Props): React.ReactElement | null => {
  // FIXME: this is really funny
  const {
    value: { value },
  } = props;

  const converted = React.useMemo((): Moment | null => convertToMoment(value), [value]);

  if (converted === null) {
    return <div />;
  } else if (value === 0 || value === undefined || value === null) {
    return <div />;
  }

  return (
    <div className={styles.cell}>
      <div className={styles.date}>{converted.format('L')}</div>
      <div className={styles.time}>{converted.format('LTS')}</div>
    </div>
  );
};
