import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { isMoment, Moment } from 'moment';
import React from 'react';

interface Props {
  readonly value: Moment;
  readonly name: string;
  readonly time: {
    readonly second: number;
    readonly minute: number;
    readonly hour: number;
  };
  readonly className?: string;

  onDateChange(name: string, date: Moment): void;
}

export const CustomDatePicker = (props: Props): React.ReactElement => {
  const { time, className } = props;

  const onChange = (date: Moment | null): void => {
    if (date === null) {
      return;
    }
    const currentDate = date.clone();
    if (isMoment(currentDate)) {
      currentDate.set({
        hour: time.hour,
        minute: time.minute,
        second: time.second,
      });
      props.onDateChange(props.name, currentDate);
    } else {
      throw new Error('unexpected date type');
    }
  };

  const renderInput = React.useCallback(
    (inputProps: TextFieldProps): React.ReactElement => {
      return <TextField className={className} {...inputProps} />;
    },
    [className]
  );

  return (
    <DatePicker
      value={props.value}
      openTo="day"
      inputFormat="MM/DD/YYYY"
      renderInput={renderInput}
      onChange={onChange}
    />
  );
};
