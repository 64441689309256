import { ProcessingState } from 'enums/processingState';
import { Reducer } from 'redux';
import { Action } from 'redux/action';

export enum WhitelistAction {
  setFormValue = '/actions/whitelist/set-form-value',
  resetForm = '/actions/whitelist/reset-form',
}

export interface AddUserForm {
  readonly email_address: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly work_phone: string;
  readonly home_phone: string;
  readonly cell: string;
  readonly business_address: string;
  readonly password: string;
  readonly recovery_question: string;
  readonly recovery_answer: string;
  readonly okta_groupids: readonly string[];
}
export interface WhitelistState {
  readonly processingState: ProcessingState;
  readonly isReadyForSubmission: boolean;
  readonly form: AddUserForm;
}

const emptyForm: AddUserForm = {
  email_address: '',
  first_name: '',
  last_name: '',
  work_phone: '',
  home_phone: '',
  cell: '',
  business_address: '',
  password: '',
  recovery_question: '',
  recovery_answer: '',
  okta_groupids: [],
};

const initialWhitelistState: WhitelistState = {
  processingState: ProcessingState.idle(),
  isReadyForSubmission: false,
  form: emptyForm,
};

const isFormValid = (form: AddUserForm): boolean => {
  const values = Object.values(form);
  return values.every((value: any): boolean => {
    if (typeof value === 'string') {
      return value !== '';
    } else if (Array.isArray(value)) {
      return value.length > 0;
    } else {
      return false;
    }
  });
};

const reducer: Reducer<WhitelistState, Action<WhitelistAction>> = (
  state: WhitelistState = initialWhitelistState,
  action: Action<WhitelistAction>
): WhitelistState => {
  const { type, data } = action;
  switch (type) {
    case WhitelistAction.setFormValue: {
      const form = { ...state.form, [data.name]: data.value };
      // Compute the new state
      return { ...state, form: form, isReadyForSubmission: isFormValid(form) };
    }
    case WhitelistAction.resetForm:
      return { ...state, form: emptyForm, isReadyForSubmission: false };
    default:
      return state;
  }
};

export default reducer;
