import { Api } from 'api';
import { RowData } from 'components/Table/types/rowData';
import { fixMessagesToRowChildren } from 'helpers/fixMessagesToRowChildren';
import { UiMessage } from 'interfaces/message';

export const expandRow = async (
  api: Api,
  value: string
): Promise<Record<string, RowData<UiMessage>>> => {
  const task = api.getChainedMessages(value);
  const chainedMessages = await task.run();

  // Skip the first message as it is the same that we already have
  return fixMessagesToRowChildren(chainedMessages);
};
