type ActivationMapOrName = string | Record<string, boolean>;

const getActiveClasses = (state: Record<string, boolean>): readonly string[] => {
  return Object.entries(state)
    .map(([name, active]: [string, boolean]): string | undefined => {
      if (active) {
        return name;
      } else {
        return undefined;
      }
    })
    .filter((item: string | undefined): boolean => !!item) as readonly string[];
};

export const toClassName = (...args: ActivationMapOrName[]): string | undefined => {
  const result = args
    .reduce(
      (
        finalArray: readonly string[],
        item: ActivationMapOrName | string | undefined
      ): readonly string[] => {
        if (typeof item === 'string') return [...finalArray, item];
        if (typeof item === 'undefined') return finalArray;
        return [...finalArray, ...getActiveClasses(item)];
      },
      []
    )
    .join(' ');

  return result === '' ? undefined : result;
};
