import { FormLabel, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { CustomDatePicker } from 'components/CustomDatePicker';
import { toClassName } from 'helpers/toClassName';
import { useApi } from 'hooks/useApi';
import React from 'react';
import { FiltersForm } from 'routes/ProfitabilityReport/index';
import styles from 'styles/common-filter-forms.module.scss';

interface Props {
  readonly values: FiltersForm;

  onFiltersChange(filters: FiltersForm): void;
}

export const Filters: React.FC<Props> = ({
  values: filters,
  onFiltersChange,
}: Props): React.ReactElement => {
  const [savedFilters, setSavedFilters] = React.useState<FiltersForm>(filters);
  const [customers, setCustomers] = React.useState<readonly string[] | null>(null);

  const api = useApi();

  const handleMPIDChange = React.useCallback((event: SelectChangeEvent): void => {
    const { value } = event.target;
    setSavedFilters((filters: FiltersForm): FiltersForm => ({ ...filters, mpid: value }));
  }, []);

  const handleFieldChange = React.useCallback((name: keyof FiltersForm, value: any): void => {
    setSavedFilters((filters: FiltersForm): FiltersForm => ({ ...filters, [name]: value }));
  }, []);

  React.useEffect((): void => {
    onFiltersChange(savedFilters);
  }, [onFiltersChange, savedFilters]);

  React.useEffect((): VoidFunction => {
    const task = api.getCustomers();
    task.run().then(setCustomers).catch(console.warn);

    return (): void => {
      task.cancel();
    };
  }, [api]);

  return (
    <form className={styles.form}>
      <div className={styles.field}>
        <FormLabel className={styles.label}>From Date</FormLabel>
        <CustomDatePicker
          name="fromDate"
          value={filters.fromDate}
          time={{ hour: 23, minute: 59, second: 59 }}
          className={toClassName(styles.input, styles.datePicker)}
          onDateChange={handleFieldChange}
        />
      </div>

      <div className={styles.field}>
        <FormLabel className={styles.label}>To Date</FormLabel>
        <CustomDatePicker
          name="toDate"
          value={filters.toDate}
          time={{ hour: 23, minute: 59, second: 59 }}
          className={toClassName(styles.input, styles.datePicker)}
          onDateChange={handleFieldChange}
        />
      </div>

      <div className={styles.field}>
        <FormLabel className={styles.label}>MPID</FormLabel>
        <Select value={filters.mpid} onChange={handleMPIDChange} fullWidth={true}>
          <MenuItem value="all">All</MenuItem>
          {customers === null ? (
            <div>loading</div>
          ) : customers.length === 0 ? null : (
            customers.map(
              (each: string): React.ReactElement => <MenuItem value={each}>{each}</MenuItem>
            )
          )}
        </Select>
      </div>
    </form>
  );
};
