import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PrismApplication } from 'api/interfaces/prismApplication';
import { ProcessingState } from 'enums/processingState';
import { ApplicationState } from 'redux/applicationState';
import { DatabaseServer } from 'types/databaseServer';

export enum ReferenceDataType {
  applicationsList = 'applicationsList',
  databaseServersList = 'databaseServersList',
}

export interface ReferenceDataState {
  applications: PrismApplication[];
  databaseServers: DatabaseServer[];
  processingState: ProcessingState;
}

const initialState: ReferenceDataState = {
  applications: [],
  databaseServers: [],
  processingState: ProcessingState.idle(),
};

const slice = createSlice({
  name: 'referenceData',
  initialState: initialState,
  reducers: {
    loadApplicationsStarted: (state: ReferenceDataState): void => {
      state.processingState = ProcessingState.processing(ReferenceDataType.applicationsList);
    },
    loadApplicationsCompleted: (
      state: ReferenceDataState,
      action: PayloadAction<PrismApplication[]>
    ): void => {
      state.processingState = ProcessingState.idle();
      state.applications = action.payload;
    },
    loadApplicationsFailed: (state: ReferenceDataState, action: PayloadAction<any>): void => {
      state.processingState = ProcessingState.error(action.payload);
    },
    loadDatabaseServersStarted: (state: ReferenceDataState): void => {
      state.processingState = ProcessingState.processing(ReferenceDataType.databaseServersList);
    },
    loadDatabaseServersCompleted: (
      state: ReferenceDataState,
      action: PayloadAction<DatabaseServer[]>
    ): void => {
      state.processingState = ProcessingState.idle();
      state.databaseServers = action.payload;
    },
    loadDatabaseServersFailed: (state: ReferenceDataState, action: PayloadAction<any>): void => {
      state.processingState = ProcessingState.error(action.payload);
    },
  },
});

export const applicationsListSelector = (state: ApplicationState): readonly PrismApplication[] =>
  state.referenceData.applications;

export const databaseServersSelector = (state: ApplicationState): readonly DatabaseServer[] =>
  state.referenceData.databaseServers;

export const {
  loadApplicationsStarted,
  loadApplicationsFailed,
  loadApplicationsCompleted,
  loadDatabaseServersStarted,
  loadDatabaseServersFailed,
  loadDatabaseServersCompleted,
} = slice.actions;

export default slice.reducer;
