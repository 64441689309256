import { OktaGroup } from 'api/interfaces/user';
import React from 'react';
import styles from 'routes/EntityManagement/UsersManagement/users-management.module.scss';

interface Props {
  readonly value: OktaGroup[];
}

export const OktaGroupsCell: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value: groups } = props;

  return (
    <div className={styles.oktaGroups}>
      {groups.map(
        (group: OktaGroup): React.ReactElement => (
          <div key={group.name} className={styles.oktaGroup}>
            {group.name}
          </div>
        )
      )}
    </div>
  );
};
