import { FixTagEntry } from 'types/fixEntry';

const fixMap: Record<string, FixTagEntry> = {
  '1': {
    name: 'Account',
    type: 'string',
  },
  '6': {
    name: 'AvgPx',
    type: 'number',
  },
  '11': {
    name: 'ClOrdID',
    type: 'string',
  },
  '14': {
    name: 'CumQty',
    type: 'number',
  },
  '15': {
    name: 'Currency',
    type: 'string',
  },
  '17': {
    name: 'ExecID',
    type: 'string',
  },
  '18': {
    name: 'ExecInst',
    type: 'string',
  },
  '20': {
    name: 'ExecTransType',
    type: 'string',
  },
  '21': {
    name: 'HandlInst',
    type: 'string',
  },
  '22': {
    name: 'IDSource',
    type: 'string',
  },
  '30': {
    name: 'LastMkt',
    type: 'string',
  },
  '31': {
    name: 'LastPX',
    type: 'Number',
  },
  '32': {
    name: 'LastQty',
    type: 'Number',
  },
  '35': {
    name: 'MsgType',
    type: 'string',
  },
  '37': {
    name: 'OrderID',
    type: 'string',
  },
  '38': {
    name: 'OrderQty',
    type: 'Number',
  },
  '39': {
    name: 'OrdStatus',
    type: 'string',
  },
  '40': {
    name: 'OrdType',
    type: 'string',
  },
  '41': {
    name: 'OrigClOrdID',
    type: 'string',
  },
  '44': {
    name: 'Price',
    type: 'Number',
  },
  '47': {
    name: 'Rule80A',
    type: 'string',
  },
  '48': {
    name: 'SecurityID',
    type: 'string',
  },
  '49': {
    name: 'SenderCompID',
    type: 'string',
  },
  '50': {
    name: 'SenderSubID',
    type: 'string',
  },
  '52': {
    name: 'SendingTime',
    type: 'string',
  },
  '54': {
    name: 'Side',
    type: 'string',
  },
  '55': {
    name: 'Symbol',
    type: 'string',
  },
  '56': {
    name: 'TargetCompID',
    type: 'string',
  },
  '57': {
    name: 'TargetSubID',
    type: 'string',
  },
  '58': {
    name: 'Text',
    type: 'string',
  },
  '59': {
    name: 'TimeInForce',
    type: 'string',
  },
  '60': {
    name: 'TransactTime',
    type: 'string',
  },
  '64': {
    name: 'SettlDate',
    type: 'string',
  },
  '76': {
    name: 'PositionEffect',
    type: 'string',
  },
  '99': {
    name: 'StopPx',
    type: 'number',
  },
  '100': {
    name: 'ExDestination',
    type: 'string',
  },
  '103': {
    name: 'OrdRejReason',
    type: 'string',
  },
  '109': {
    name: 'ClientID',
    type: 'string',
  },
  '110': {
    name: 'MinQty',
    type: 'number',
  },
  '115': {
    name: 'OnBehalfOfCompID',
    type: 'string',
  },
  '128': {
    name: 'DeliverToCompID',
    type: 'string',
  },
  '150': {
    name: 'ExecType',
    type: 'string',
  },
  '151': {
    name: 'LeavesQty',
    type: 'number',
  },
  '167': {
    name: 'SecurityType',
    type: 'string',
  },
  '207': {
    name: 'SecurityExchange',
    type: 'string',
  },
  '439': {
    name: 'ClearingFirm',
    type: 'string',
  },
  '440': {
    name: 'ClearingAccount',
    type: 'string',
  },
};

export default fixMap;
