import { Table } from 'components/Table/index';
import styles from 'components/Table/table.module.scss';
import { Column } from 'components/Table/types/column';
import { RowData } from 'components/Table/types/rowData';
import { toClassName } from 'helpers/toClassName';
import { noop } from 'lodash';
import React from 'react';
import { Identifiable } from 'types/identifiable';

interface Props<T extends Identifiable> {
  readonly columns: ReadonlyArray<Column<T>>;
  readonly rows: Record<string, RowData<T>>;
}

export function ChildRows<T extends Identifiable>(props: Props<T>): React.ReactElement | null {
  const { columns, rows } = props;
  if (rows === undefined || Object.values(rows).length === 0) {
    return null;
  }

  return (
    <tr className={toClassName(styles.childrenRow)}>
      <td className={styles.childrenTableContainer} colSpan={columns.length}>
        <Table
          columns={columns}
          rows={rows}
          headless={true}
          scrollable={false}
          onResetOriginalLayout={noop}
        />
      </td>
    </tr>
  );
}
