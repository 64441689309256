import { TotalEntry } from 'components/StatusBar/components/TotalEntry';
import { Status } from 'enums/status';
import { Totals } from 'interfaces/totals';
import strings from 'locales';
import React from 'react';

interface Props {
  readonly totals: Totals;
  readonly filter: Status;

  onChange?(status: Status): void;
}

export const TotalsView = (props: Props): React.ReactElement => {
  const { totals, filter, onChange } = props;

  const createFilterHandlerFor = React.useCallback(
    (status: Status): VoidFunction => {
      return (): void => {
        if (onChange === undefined) {
          return;
        }
        if (status === filter) {
          onChange(Status.None);
        } else {
          onChange(status);
        }
      };
    },
    [filter, onChange]
  );

  return (
    <>
      <TotalEntry
        label={strings.All}
        value={totals.all}
        variant="total"
        selected={false}
        onClick={createFilterHandlerFor(Status.None)}
      />
      <TotalEntry
        label={strings.On}
        value={totals.on}
        variant="success"
        selected={filter === Status.On}
        onClick={createFilterHandlerFor(Status.On)}
      />
      <TotalEntry
        label={strings.Off}
        value={totals.off}
        variant="info"
        selected={filter === Status.Off}
        onClick={createFilterHandlerFor(Status.Off)}
      />
      <TotalEntry
        label={strings.Overtime}
        value={totals.overtime}
        variant="warning"
        selected={filter === Status.Overtime}
        onClick={createFilterHandlerFor(Status.Overtime)}
      />
      <TotalEntry
        label={strings.Down}
        value={totals.error}
        variant="danger"
        selected={filter === Status.Down}
        onClick={createFilterHandlerFor(Status.Down)}
      />
      <TotalEntry
        label={strings.Unknown}
        value={totals.unknown}
        variant="inactive"
        selected={filter === Status.Unknown}
        onClick={createFilterHandlerFor(Status.Unknown)}
      />
    </>
  );
};
