import styles from 'components/Table/cell.module.scss';

export const numericCellClassCreator = (value: string): string => {
  const classes = [styles.numericCell];
  const numeric = Number(value);
  if (numeric < 0) {
    classes.push(styles.negative);
  } else if (numeric === 0) {
    classes.push(styles.zero);
  }
  return classes.join(' ');
};
