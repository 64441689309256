import { RowData } from 'components/Table/types/rowData';
import { UiMessage } from 'interfaces/message';

export const messagesArrayToMessagesRows = (
  messages?: readonly UiMessage[] | null
): Record<string, RowData<UiMessage>> => {
  if (!messages) {
    return {};
  }

  return messages.reduce(
    (
      rows: Record<string, RowData<UiMessage>>,
      message: UiMessage
    ): Record<string, RowData<UiMessage>> => ({
      ...rows,
      [message.clientOrderId]: {
        data: message,
        id: message.clientOrderId,
        children: {},
      },
    }),
    {}
  );
};
