import { Api } from 'api';
import { FlowItem } from 'api/interfaces/flowItem';
import { SMSessionDTO } from 'api/interfaces/snapshot';
import { Flow } from 'interfaces/flow';
import { AnyAction } from 'redux';
import { AsyncAction, createAsyncAction } from 'redux/asyncAction';
import { loadFlowsCompleted, loadFlowsStarted } from 'redux/reducers/flowsReducer';

async function* fetchFlows(api: Api, signal: AbortSignal): AsyncGenerator<AnyAction> {
  yield loadFlowsStarted();

  const snapshot = await api.getSnapshot(signal);
  const flows = await api.getFlows(signal);
  if (!snapshot) {
    // There's no session information, but that is alrigth I guess
    yield loadFlowsCompleted(
      flows.map((item: FlowItem): Flow => {
        const partial = Flow.fromFlowItem(item);
        return {
          ...partial,
          clientSessionID: item.clientSession,
          destinationSessionID: item.destinationSession,
        };
      })
    );
  } else {
    const { Sessions } = snapshot;

    const result = flows.map((item: FlowItem): Flow => {
      const client = Sessions.find(
        (session: SMSessionDTO): boolean => session.SessionID === item.clientSession
      );

      const destination = Sessions.find(
        (session: SMSessionDTO): boolean => session.SessionID === item.destinationSession
      );

      return Flow.fromFlowItemAndSession(item, client, destination);
    });

    yield loadFlowsCompleted(result);
  }
}

export const fetchFlowsAction = (): AsyncAction => createAsyncAction(fetchFlows);
