import moment from 'moment';
import { Action } from 'redux/action';

export enum WhoIsLoggedInActions {
  SetUsers = '/actions/who-is-logged-in/set-users',
}

export interface WhoIsLoggedInUser {
  readonly userName: string;
  readonly userID: string;
  readonly ipAddress: string;
  readonly application: string;
  readonly loggedIn: boolean;
  readonly lastActivity?: moment.Moment;
}

export interface WhoIsLoggedInState {
  readonly users: readonly WhoIsLoggedInUser[];
}

const initialState: WhoIsLoggedInState = {
  users: [],
};

const reducer = (
  state = initialState,
  action: Action<WhoIsLoggedInActions>
): WhoIsLoggedInState => {
  const { type, data } = action;
  switch (type) {
    case WhoIsLoggedInActions.SetUsers:
      return { ...state, users: data };
    default:
      return state;
  }
};

export default reducer;
