import { Entity, Firm } from 'api/interfaces/firm';
import { Form, FormChangeResult } from 'components/Form';
import { onIpAddressesAdded } from 'helpers/ipAddressesListParser';
import { SMSession } from 'interfaces/session';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sessionsSelector } from 'redux/reducers/sessionsReducer';
import { fetchSessionsAction } from 'redux/services/sessionsServices';
import fields from 'routes/EntityManagement/FirmsManagement/components/FirmsForm/fields';
import { Brokerage, isValidBrokerageItem } from 'types/brokerage';

interface Props {
  readonly firm: Firm;

  onSave(firm: Firm): void;

  onModifiedChanged?(modified: boolean): void;
  onErrorsChanged?(hasErrors: boolean): void;
}

const FirmsForm: React.FC<Props> = (props: Props): React.ReactElement => {
  const { onSave, onErrorsChanged, onModifiedChanged } = props;
  const { firm } = props;

  const dispatch = useDispatch();
  const sessions = useSelector(sessionsSelector);

  const onEntitiesChange = React.useCallback((value: any, _: Firm): FormChangeResult => {
    const entities: readonly Entity[] = value;

    if (entities.length === 1) {
      return {
        value: entities.map((entity: Entity): Entity => ({ ...entity, default: 1 })),
        error: null,
      };
    }

    const error =
      entities.length === 0
        ? 'No entities defined'
        : entities.every((entity: Entity): boolean => !entity.default)
        ? 'No default entity selected'
        : null;

    return {
      value: entities,
      error: error,
    };
  }, []);

  const onBrokerageChange = React.useCallback((value: Brokerage): FormChangeResult => {
    const keys = Object.keys(value);
    return keys.reduce(
      (result: FormChangeResult, key: string): FormChangeResult => {
        if (result.error !== undefined) {
          return result;
        }

        const error = isValidBrokerageItem(value[key as keyof Brokerage]);
        if (error !== null) {
          return {
            error: `${error} ${key.toUpperCase()}`,
            value: value,
          };
        }

        return result;
      },
      {
        error: undefined,
        value: value,
      }
    );
  }, []);

  const onFormValueChange = React.useCallback(
    (name: keyof Firm, value: any, firm: Firm): FormChangeResult => {
      switch (name) {
        case 'entities':
          return onEntitiesChange(value, firm);
        case 'brokerage':
          return onBrokerageChange(value);
        case 'whitelist':
          return onIpAddressesAdded(value);
        default:
          return { value: value, error: null };
      }
    },
    [onBrokerageChange, onEntitiesChange]
  );

  const firmsProvider = useMemo((): readonly SMSession[] => sessions, [sessions]);

  useEffect((): void => {
    dispatch(fetchSessionsAction());
  }, [dispatch]);

  return (
    <Form
      fields={fields}
      value={firm}
      provider={firmsProvider}
      onChange={onFormValueChange}
      onSubmit={onSave}
      onErrorsChanged={onErrorsChanged}
      onModifiedChanged={onModifiedChanged}
    />
  );
};

export default FirmsForm;
