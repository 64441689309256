import { ErrorMessageRenderer } from 'components/Table/renderers/SolaceQueueMessageRenderer/ErrorMessageRenderer';
import { FIXMessageRenderer } from 'components/Table/renderers/SolaceQueueMessageRenderer/FIXMessageRenderer';
import { JSONMessageRenderer } from 'components/Table/renderers/SolaceQueueMessageRenderer/JSONMessageRenderer';
import React from 'react';
import { SolaceMessageType, SolaceQueueMessage } from 'types/solace';

interface Props {
  readonly value: SolaceQueueMessage;
}

export const SolaceQueueMessageRenderer: React.FC<Props> = ({
  value,
}: Props): React.ReactElement => {
  switch (value.type) {
    case SolaceMessageType.fix:
      return <FIXMessageRenderer message={value.content} />;
    case SolaceMessageType.json:
      return <JSONMessageRenderer message={value.content} />;
    case SolaceMessageType.error:
      return <ErrorMessageRenderer />;
  }
  return <div />;
};
