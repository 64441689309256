const execTypes: Record<string, string> = {
  '0': 'New',
  '1': 'Partial Fill',
  '2': 'Fill',
  '3': 'Done for day',
  '4': 'Canceled',
  '5': 'Replace',
  '6': 'Pending Cancel',
  '7': 'Stopped',
  '8': 'Rejected',
  '9': 'Suspended',
  A: 'Pending New',
  B: 'Calculated',
  C: 'Expired',
  D: 'Restated',
  E: 'Pending Replace',
  F: 'Trade',
  G: 'Trade Correct',
  H: 'Trade Cancel',
  I: 'Order Status',
};

export default execTypes;

/*
default:
      if (msgType === "8") return "Unknown",
      else return "",
 */
