import { MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import styles from 'components/DayTimeInput/day-time-input.module.scss';
import { DayAndTime } from 'interfaces/dayAndTime';
import React from 'react';
import { DropdownOption } from 'types/dropdownOption';
import { toTime } from 'utils/timeUtils';

interface Props {
  readonly value: DayAndTime;

  onChange(value: DayAndTime): void;
}

export const DayTimeInput: React.FC<Props> = (props: Props): React.ReactElement => {
  const { dayOfWeek = -1, time } = props.value;
  const { onChange } = props;

  const formattedTime = React.useMemo((): string => (time ? toTime(time) : ''), [time]);

  const onDayOfTheWeekChange = React.useCallback(
    (event: SelectChangeEvent<number>): void => {
      const { value } = event.target;
      onChange(DayAndTime.fromDayOfWeekAndMoment(Number(value), time));
    },
    [onChange, time]
  );

  const onTimeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    onChange(DayAndTime.fromDayOfWeekAndTimeString(dayOfWeek, value));
  };

  return (
    <div className={styles.container}>
      <Select onChange={onDayOfTheWeekChange} value={dayOfWeek} fullWidth={true}>
        <MenuItem value={-1} disabled={true}>
          Select One
        </MenuItem>
        {daysOfWeek.map(
          (item: DropdownOption): React.ReactElement => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          )
        )}
      </Select>
      <OutlinedInput
        type="time"
        placeholder="00:00"
        value={formattedTime}
        className={styles.time}
        onChange={onTimeChange}
      />
    </div>
  );
};

const daysOfWeek = [0, 1, 2, 3, 4, 5, 6].map(
  (day: number): DropdownOption => ({
    id: day,
    name: new Date(2022, 4, 15 + day).toLocaleDateString('en-US', {
      weekday: 'long',
    }),
  })
);
