import { NetworkDetailsDataProvider } from 'ComplexModals/SessionEditor/forms/NetworkDetailsView/providerStore';
import { FieldDefinition, FieldType } from 'components/Form/components/Field/types';
import { VPNForm } from 'redux/definitions/central';
import { DropdownOption } from 'types/dropdownOption';
import { EncryptionAlgorithm, HashAlgorithm, KeyPasswordType } from 'types/vpn';

const keyPasswordOptions = (
  _: VPNForm,
  provider: NetworkDetailsDataProvider
): readonly DropdownOption[] => {
  return (provider?.keyPasswordAlgorithms ?? []).map(
    (key: KeyPasswordType): DropdownOption => ({
      id: key.key_pass_id,
      name: key.key_pass_name,
    })
  );
};

const authenticationMethods = (
  _: VPNForm,
  provider: NetworkDetailsDataProvider
): readonly DropdownOption[] => {
  return (provider?.peerAuthMethods ?? []).map(
    (method): DropdownOption => ({
      id: method.peer_auth_method_id,
      name: method.peer_auth_method_name,
    })
  );
};

const encryptionAlgs = (
  _: VPNForm,
  provider: NetworkDetailsDataProvider
): readonly DropdownOption[] => {
  return (provider?.encryptionAlgorithms ?? []).map(
    (encryption: EncryptionAlgorithm): DropdownOption => ({
      id: encryption.encryption_algo_id,
      name: encryption.encryption_algo_name,
    })
  );
};

const hashAlgs = (_: VPNForm, provider: NetworkDetailsDataProvider): readonly DropdownOption[] => {
  return (provider?.hashAlgorithms ?? []).map(
    (hash: HashAlgorithm): DropdownOption => ({ id: hash.hash_algo_id, name: hash.hash_algo_name })
  );
};

export const fields: ReadonlyArray<FieldDefinition<VPNForm, NetworkDetailsDataProvider>> = [
  {
    name: 'party',
    label: 'Party',
    fieldType: FieldType.fieldset,
    display: true,
    children: [
      {
        fieldType: FieldType.text,
        name: 'terminationPoint',
        label: 'Termination Point',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'terminationEquipment',
        label: 'Termination Equipment',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'endpointAddress',
        label: 'Endpoint Address (Peer, Gateway)',
        display: true,
      },
    ],
  },
  {
    name: 'isakmpPhase1',
    label: 'ISAKMP (Phase 1)',
    fieldType: FieldType.fieldset,
    display: true,
    children: [
      {
        name: 'isakmpEncryptionAlgorithm',
        label: 'Encryption Algorithm (AES/3DES)',
        fieldType: FieldType.dropdown,
        options: encryptionAlgs,
        display: true,
      },
      {
        name: 'isakmpHashAlgorithm',
        label: 'Integrity (hash) Algorithm (MD5/SHA)',
        fieldType: FieldType.dropdown,
        options: hashAlgs,
        display: true,
      },
      {
        name: 'isakmpDiffieHellmanGroup',
        label: 'Diffie-Hellman Group',
        fieldType: FieldType.numeric,
        display: true,
      },
      {
        name: 'isakmpAuthenticationMethod',
        label: 'Peer Authentication Method',
        fieldType: FieldType.dropdown,
        options: authenticationMethods,
        display: true,
      },
      {
        name: 'isakmpKey',
        label: 'Key/Password',
        fieldType: FieldType.dropdown,
        options: keyPasswordOptions,
        display: true,
      },
      {
        name: 'isakmpIKEKeyExchangeParameters',
        label: 'IKE Key Exchange Parameters\n(Diffie-Hellman Group ID)',
        fieldType: FieldType.text,
        display: true,
      },
      {
        name: 'isakmpIKEEstablishedSecurityAssociationLifetime',
        label: "IKE-Established Security\nAssociation's Lifetime",
        fieldType: FieldType.numeric,
        display: true,
      },
    ],
  },
  {
    name: 'ipSecPhase2',
    label: 'IPSec (Phase 2)',
    fieldType: FieldType.fieldset,
    display: true,
    children: [
      {
        name: 'ipSecEncryptionAlgorithm',
        label: 'Encryption Algorithm (AES/3DES)',
        fieldType: FieldType.dropdown,
        options: encryptionAlgs,
        display: true,
      },
      {
        name: 'ipSecHashAlgorithm',
        label: 'Integrity (hash) Algorithm (MD5/SHA)',
        fieldType: FieldType.dropdown,
        options: hashAlgs,
        display: true,
      },
      {
        name: 'ipSecPerfectForwardSecrecy',
        label: 'Perfecto Forward Secrecy',
        fieldType: FieldType.bool,
        display: true,
      },
      {
        name: 'ipSecLifetime',
        label: 'Lifetime',
        fieldType: FieldType.numeric,
        display: true,
      },
      {
        name: 'ipSecCompression',
        label: 'Compression',
        fieldType: FieldType.bool,
        display: true,
      },
    ],
  },
  {
    name: 'application',
    label: 'Application',
    fieldType: FieldType.fieldset,
    display: true,
    children: [
      {
        fieldType: FieldType.text,
        name: 'applicationName',
        label: 'Application Name',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'applicationListeningPort',
        label: 'Application Listening Port/Protcol',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'ipNetworkReached',
        label: 'IP Network(s) Reached',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'directionOfTraffic',
        label: 'Direction of Traffic (Exp: ____ >> Client)',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'bandwidth',
        label: 'Bandwidth',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'servicesAllowed',
        label: 'Services Allowed',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'proxyIdVendorId',
        label: 'Proxy ID/Vendor ID',
        display: true,
      },
      {
        fieldType: FieldType.text,
        name: 'antiReplay',
        label: 'Anti-Replay',
        display: true,
      },
    ],
  },
];
