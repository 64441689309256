import styles from 'ComplexModals/SessionEditor/central.module.scss';
import { Section } from 'ComplexModals/SessionEditor/components/Section';
import { fields } from 'ComplexModals/SessionEditor/forms/SMFeaturesView/fields';
import { Form, FormChangeResult } from 'components/Form';
import React from 'react';
import { SMFeatures } from 'redux/definitions/central';
import { SMConfig } from 'redux/definitions/smConfig';

interface Props {
  readonly value: SMFeatures;

  onChange(name: keyof SMConfig, value: SMFeatures): void;
}

const SMFeaturesView: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value, onChange } = props;

  const handleChange = React.useCallback(
    (
      name: keyof SMFeatures,
      fieldValue: SMFeatures[keyof SMFeatures],
      _: SMFeatures
    ): FormChangeResult => {
      onChange('features', { ...value, [name]: fieldValue });

      return {
        error: null,
        value: fieldValue,
      };
    },
    [onChange, value]
  );

  return (
    <Section title="SM Features">
      <Form className={styles.form} value={props.value} fields={fields} onChange={handleChange} />
    </Section>
  );
};

export default SMFeaturesView;
