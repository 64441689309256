import { FormLabel, OutlinedInput } from '@mui/material';
import React from 'react';

interface Props<T> {
  readonly name: keyof T;
  readonly value: string | null;
  readonly label: string;
  readonly readOnly?: boolean;

  onChange(name: keyof T, value: string | null): void;
}

export function TextInput<T>(props: Props<T>): React.ReactElement {
  const { name, value, label, readOnly = false, onChange } = props;

  const stringValue = React.useMemo((): string => {
    return value === null ? '' : value;
  }, [value]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      onChange(name, value);
    },
    [name, onChange]
  );

  return (
    <>
      <FormLabel htmlFor={String(name)}>{label}</FormLabel>
      <OutlinedInput
        id={String(name)}
        value={stringValue}
        fullWidth={true}
        readOnly={readOnly}
        onChange={handleChange}
      />
    </>
  );
}
