import { Box, Pagination } from '@mui/material';
import { rawMessageToFixMap } from 'api/interfaces/rawMessage';
import { FIXFieldsSelector } from 'components/FIXFieldsSelector';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { Scaffold } from 'components/Scaffold';
import { Table } from 'components/Table';
import {
  Column,
  ColumnDefinition,
  ColumnType,
  createFromDefinition,
} from 'components/Table/types/column';
import { SortDirection } from 'components/Table/types/sortDirection';
import dictionary from 'data/fixDictionary';
import { ProcessingType } from 'enums/processingState';
import deepEqual from 'fast-deep-equal';
import { usePagination } from 'hooks/usePagination';
import { useTable } from 'hooks/useTable';
import moment, { Moment } from 'moment';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  executionsProcessingStateSelector,
  executionsSelector,
} from 'redux/reducers/executionsReducer';
import { fetchExecutionsAction } from 'redux/services/executionsService';
import baseColumns from 'routes/Executions/columns';
import { Filters } from 'routes/Executions/Filters';
import styles from 'routes/Messages/messages-route.module.scss';
import { Execution, fixMap, SummaryReportQuery } from 'types/summaryReport';

export const Executions: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const processingState = useSelector(executionsProcessingStateSelector);
  const executions = useSelector(executionsSelector);
  const [venue, setVenue] = React.useState<string>('');
  const [query, setQuery] = React.useState<SummaryReportQuery>(initialQuery);
  const [sortedColumns, setSortedColumns] = React.useState<
    Record<keyof Execution, SortDirection> | undefined
  >();
  const [columnFilters, setColumnFilters] = React.useState<
    Record<keyof Execution, string> | undefined
  >();
  const tableProps = useTable<Execution>('executions', baseColumns);
  const { columns } = tableProps;

  const filterFn = React.useCallback(
    (row: Execution): boolean => {
      if (venue !== '' && row.venue !== venue) {
        return false;
      }

      if (!columnFilters) {
        return true;
      }

      const entries = Object.entries(columnFilters);
      return entries.reduce((result: boolean, [key, value]: [string, any]): boolean => {
        if (result === false) {
          return false;
        }

        const rowValue = row[key as keyof Execution];
        if (typeof rowValue === 'string') {
          return rowValue.trim().toLowerCase().includes(value.trim().toLowerCase());
        } else if (rowValue instanceof Date) {
          const asMoment = moment(rowValue);
          const asText = asMoment.format('MM/DD/YYYY');

          return asText.includes(value);
        } else {
          return false;
        }
      }, true);
    },
    [columnFilters, venue]
  );

  const sortFn = React.useCallback(
    (row1: Execution, row2: Execution): number => {
      if (!sortedColumns) {
        return 0;
      }

      const entries = Object.entries(sortedColumns);
      return entries.reduce((sum: number, [key, direction]: [string, SortDirection]): number => {
        const value1 = row1[key as keyof Execution];
        const value2 = row2[key as keyof Execution];
        if (typeof value1 === 'string' && typeof value2 === 'string') {
          return value1.localeCompare(value2) * (direction === SortDirection.Ascending ? 1 : -1);
        } else if (typeof value1 === 'number' && typeof value2 === 'number') {
          return (value1 - value2) * (direction === SortDirection.Ascending ? 1 : -1);
        } else {
          return sum;
        }
      }, 0);
    },
    [sortedColumns]
  );

  const [page, setPage] = usePagination(executions, filterFn, sortFn);

  React.useEffect((): VoidFunction => {
    return dispatch(fetchExecutionsAction(query));
  }, [dispatch, query]);

  const handlePageChange = useCallback(
    (_: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [setPage]
  );

  const handleSort = React.useCallback((key: keyof Execution, direction: SortDirection): void => {
    setSortedColumns(
      (
        sortedColumns: Record<keyof Execution, SortDirection>
      ): Record<keyof Execution, SortDirection> => {
        if (direction === SortDirection.None) {
          const { [key]: _, ...resultingColumns } = sortedColumns;
          return resultingColumns as Record<keyof Execution, SortDirection>;
        }

        return {
          ...sortedColumns,
          [key]: direction,
        };
      }
    );
  }, []);

  const handleColumnFiltered = React.useCallback(
    (columnKey: keyof Execution, value: string): void => {
      setColumnFilters(
        (columnFilters: Record<keyof Execution, string>): Record<keyof Execution, string> => {
          if (value.trim() === '') {
            const { [columnKey]: _, ...resultingColumns } = columnFilters;
            return resultingColumns as Record<keyof Execution, string>;
          }

          return {
            ...columnFilters,
            [columnKey]: value.trim(),
          };
        }
      );
    },
    []
  );

  const handleFieldsSelectionChange = React.useCallback(
    (selection: readonly string[]): void => {
      const newColumns = selection
        .map((tag: string): Column<Execution> | undefined => {
          const base: ColumnDefinition<Execution> | undefined = baseColumns.find(
            (definition: ColumnDefinition<Execution>): boolean => {
              return fixMap[definition.key] === tag;
            }
          );
          if (base === undefined) {
            const field = dictionary[tag] as {
              readonly name: string;
              readonly type: string;
            };

            if (!field) {
              return undefined;
            }

            return createFromDefinition<Execution>({
              key: tag as keyof Execution,
              header: field.name,
              columnType: ColumnType.text,
              weight: 1,
            } as ColumnDefinition<any>);
          } else {
            return createFromDefinition(base);
          }
        })
        .filter(
          (column: Column<Execution> | undefined): column is Column<Execution> =>
            column !== undefined
        );

      tableProps.onColumnsChanged(newColumns);
    },
    [tableProps]
  );

  const fields = React.useMemo(
    (): readonly string[] =>
      columns.map((column: Column<Execution>): string => {
        if (column.key in rawMessageToFixMap) {
          return fixMap[column.key];
        } else {
          return column.key as string;
        }
      }),
    [columns]
  );

  const handleFiltersChange = React.useCallback(
    (filters: SummaryReportQuery, newVenue: string): void => {
      if (!deepEqual(filters, query)) {
        setQuery(filters);
      }

      setVenue((oldVenue: string): string => {
        if (oldVenue === newVenue) {
          return oldVenue;
        }

        return newVenue;
      });
    },
    [query]
  );

  return (
    <Scaffold title="Executions Blotter">
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={0.5}>
        <Filters filters={query} venue={venue} onChange={handleFiltersChange} />
        <div className={styles.toolbarContainer}>
          <div className={styles.toolbarFooter}>
            <FIXFieldsSelector selection={fields} onSelectionChange={handleFieldsSelectionChange} />
          </div>
        </div>
      </Box>
      <Table
        columns={columns}
        rows={page.rows}
        sorting={sortedColumns}
        onColumnSorted={handleSort}
        onFiltered={handleColumnFiltered}
        onResetOriginalLayout={tableProps.onResetOriginalLayout}
      />
      <Box sx={paginatorSx}>
        <Pagination
          count={page.totalPageCount}
          page={page.currentPage}
          onChange={handlePageChange}
        />
      </Box>
      <LoadingOverlay loading={processingState.processingType === ProcessingType.processing} />
    </Scaffold>
  );
};

const paginatorSx = {
  marginTop: 2,
  display: 'flex',
  justifyContent: 'center',
};

const startOfToday = (): Moment => {
  return moment().set('hours', 0).set('minutes', 0).set('seconds', 0);
};

const endOfToday = (): Moment => {
  return moment().set('hours', 23).set('minutes', 59).set('seconds', 59);
};

const initialQuery: SummaryReportQuery = {
  from_time: startOfToday(),
  to_time: endOfToday(),
  assetType: '',
};
