import { Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import styles from 'components/MultiSelect/multi-select.module.scss';
import React from 'react';

interface Props {
  readonly items: readonly string[];
  readonly value: readonly string[];
  readonly className?: string;
  readonly name: string;

  onChange(name: string, list: readonly string[]): void;
}

export const MultiSelect = (props: Props): React.ReactElement => {
  const { items, value, name } = props;
  const { onChange } = props;

  const onChangeWrapper = React.useCallback(
    (event: SelectChangeEvent<readonly string[]>): void => {
      const { value } = event.target;
      if (typeof value === 'string') {
        throw new Error('invalid type of value');
      }
      onChange(name, value);
    },
    [onChange, name]
  );

  const renderValue = (value: readonly string[]): React.ReactNode => {
    if (value.length === 0) {
      return '-';
    } else {
      return value.join(', ');
    }
  };

  return (
    <Select<readonly string[]>
      name={props.name}
      className={props.className}
      value={props.value}
      multiple={true}
      displayEmpty={true}
      renderValue={renderValue}
      onChange={onChangeWrapper}
    >
      {items.map((itemValue: string) => (
        <MenuItem key={itemValue} classes={{ root: styles.menuItemRoot }} value={itemValue}>
          <Checkbox
            disableRipple={true}
            classes={{ root: styles.checkboxRoot }}
            checked={value.includes(itemValue)}
          />
          <ListItemText primary={itemValue} />
        </MenuItem>
      ))}
    </Select>
  );
};
