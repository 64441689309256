import { Button } from '@mui/material';
import styles from 'components/Table/renderers/SolaceQueueMessageRenderer/solace-queue-message-renderer.module.scss';
import React from 'react';

interface Props {
  readonly message: Record<string, any>;
}

export const JSONMessageRenderer: React.FC<Props> = (props: Props): React.ReactElement => {
  const { message } = props;

  return (
    <div className={styles.message}>
      <div className={styles.type}>JSON</div>
      <div className={styles.json}>
        {Object.entries(message).map(
          ([key, value]: [string, any]): React.ReactElement => (
            <div className={styles.jsonEntry}>
              <span className={styles.key}>{key}</span>
              <span className={styles.value}>{value}</span>
            </div>
          )
        )}
      </div>
      <div className={styles.viewDetailsButton}>
        <Button classes={viewDetailsButton}>Expand</Button>
      </div>
    </div>
  );
};

const viewDetailsButton = { root: styles.viewDetailsButtonRoot };
