import { Api } from 'api';
import { AnyAction } from 'redux';
import { AsyncAction, createAsyncAction } from 'redux/asyncAction';
import {
  loadApplicationsCompleted,
  loadApplicationsFailed,
  loadApplicationsStarted,
  loadDatabaseServersCompleted,
  loadDatabaseServersFailed,
  loadDatabaseServersStarted,
} from 'redux/reducers/referenceDataReducer';

async function* fetchApplications(api: Api, signal: AbortSignal): AsyncGenerator<AnyAction> {
  yield loadApplicationsStarted();

  try {
    const applications = await api.getApplications(signal);
    yield loadApplicationsCompleted(applications);
  } catch (error: any) {
    yield loadApplicationsFailed(error);
  }
}

async function* fetchDatabaseServers(api: Api, signal: AbortSignal): AsyncGenerator<AnyAction> {
  yield loadDatabaseServersStarted();

  try {
    const servers = await api.getDatabaseServers(signal);
    yield loadDatabaseServersCompleted(servers);
  } catch (error: any) {
    yield loadDatabaseServersFailed(error);
  }
}

export const fetchDatabaseServersAction = (): AsyncAction =>
  createAsyncAction(fetchDatabaseServers);
export const fetchApplicationsAction = (): AsyncAction => createAsyncAction(fetchApplications);
