import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SMSessionDTO } from 'api/interfaces/snapshot';
import { ProcessingState } from 'enums/processingState';
import { SMSession } from 'interfaces/session';
import { Totals } from 'interfaces/totals';
import { ApplicationState } from 'redux/applicationState';
import { computeTotals } from 'ui-utils/computeTotals';

export interface SessionsScreenState {
  processingState: ProcessingState;
  importSessionProcessingState: ProcessingState;
  flushSubscribedSymbolsProcessingState: ProcessingState;
  totals: Totals;
  sessions: readonly SMSession[];
}

export interface SequenceUpdateDTO {
  readonly sessionID: string;
  readonly inSequence: number;
  readonly outSequence: number;
}

const initialTotals: Totals = {
  all: 0,
  on: 0,
  off: 0,
  overtime: 0,
  error: 0,
  unknown: 0,
};

const initialState: SessionsScreenState = {
  processingState: ProcessingState.idle(),
  importSessionProcessingState: ProcessingState.idle(),
  flushSubscribedSymbolsProcessingState: ProcessingState.idle(),
  sessions: [],
  totals: initialTotals,
};

const slice = createSlice({
  name: 'sessions',
  initialState: initialState,
  reducers: {
    loadSessionsStarted: (state: SessionsScreenState): void => {
      state.sessions = [];
      state.totals = initialTotals;
      state.processingState = ProcessingState.processing();
    },
    loadSessionsCompleted: (
      state: SessionsScreenState,
      { payload }: PayloadAction<readonly SMSessionDTO[]>
    ): void => {
      const sessions = payload.map(SMSession.fromSession);
      state.sessions = sessions.sort(({ id: s1 }: SMSession, { id: s2 }: SMSession): number =>
        s1.localeCompare(s2)
      );
      state.totals = computeTotals(sessions);
      state.processingState = ProcessingState.idle();
    },
    loadSessionsFailed: (state: SessionsScreenState, action: PayloadAction<string>): void => {
      state.processingState = ProcessingState.error(action.payload);
    },
    sessionAdded: (state: SessionsScreenState, action: PayloadAction<SMSession>): void => {
      state.sessions = [...state.sessions, action.payload];
    },
    sessionsUpdated: (
      state: SessionsScreenState,
      action: PayloadAction<readonly SMSessionDTO[]>
    ): void => {
      const { sessions } = state;
      const { payload } = action;
      state.sessions = sessions.map((session: SMSession): SMSession => {
        const matchingSession = payload.find(
          (s: SMSessionDTO): boolean => s.SessionID === session.sessionID
        );
        if (matchingSession) {
          return {
            ...session,
            lastLogon: matchingSession.LastLogon,
            maxQueueSizeMB: matchingSession.MaxQueueSizeMB,
            queueSize: matchingSession.QueueSize,
            status: matchingSession.Status ?? session.status,
            statusText: matchingSession.StatusText ?? session.statusText,
          };
        } else {
          return session;
        }
      }, []);
    },
    updateSequences: (
      state: SessionsScreenState,
      action: PayloadAction<SequenceUpdateDTO>
    ): void => {
      const { sessions } = state;
      const { payload } = action;
      state.sessions = sessions.map((session: SMSession): SMSession => {
        if (session.sessionID === payload.sessionID) {
          return {
            ...session,
            inSequence: payload.inSequence,
            outSequence: payload.outSequence,
          };
        } else {
          return session;
        }
      });
    },
    sessionActionStarted: (state: SessionsScreenState, action: PayloadAction<string>): void => {
      const { sessions } = state;

      state.sessions = sessions.map((session: SMSession): SMSession => {
        if (session.sessionID === action.payload) {
          return { ...session, processingState: ProcessingState.processing(action.payload) };
        } else {
          return session;
        }
      });
    },
    sessionActionStopped: (state: SessionsScreenState, action: PayloadAction<string>): void => {
      const { sessions } = state;

      state.sessions = sessions.map((session: SMSession): SMSession => {
        if (session.sessionID === action.payload) {
          return { ...session, processingState: ProcessingState.idle() };
        } else {
          return session;
        }
      });
    },
    sessionActionSucceeded: (
      state: SessionsScreenState,
      action: PayloadAction<{
        readonly actionType: 'delete' | 'reload' | 'sync';
        readonly sessionId: string;
        readonly message: string;
      }>
    ): void => {
      const { sessions } = state;
      const { sessionId, message, actionType } = action.payload;

      if (actionType === 'delete') {
        state.sessions = sessions.filter(
          (session: SMSession): boolean => session.sessionID !== sessionId
        );
        return;
      } else {
        state.sessions = sessions.map((session: SMSession): SMSession => {
          if (session.sessionID === sessionId) {
            return { ...session, processingState: ProcessingState.success(message) };
          } else {
            return session;
          }
        });
      }
    },
    sessionActionFailed: (
      state: SessionsScreenState,
      action: PayloadAction<{ sessionID: string; message: string }>
    ): void => {
      const { sessions } = state;
      const { payload } = action;

      state.sessions = sessions.map((session: SMSession): SMSession => {
        if (session.sessionID === payload.sessionID) {
          return { ...session, processingState: ProcessingState.error(payload.message) };
        } else {
          return session;
        }
      });
    },
    importSessionStarted: (state: SessionsScreenState): void => {
      state.importSessionProcessingState = ProcessingState.processing();
    },
    importSessionFailed: (state: SessionsScreenState, action: PayloadAction<string>): void => {
      state.importSessionProcessingState = ProcessingState.error(action.payload);
    },
    importSessionSucceeded: (state: SessionsScreenState): void => {
      state.importSessionProcessingState = ProcessingState.success();
    },
    resetImportSessionProcessingState: (state: SessionsScreenState): void => {
      state.importSessionProcessingState = ProcessingState.idle();
    },
    flushSubscribedSymbolsStarted: (
      state: SessionsScreenState,
      action: PayloadAction<string>
    ): void => {
      state.flushSubscribedSymbolsProcessingState = ProcessingState.processing(action.payload);
    },
    flushSubscribedSymbolsFailed: (
      state: SessionsScreenState,
      action: PayloadAction<string>
    ): void => {
      state.flushSubscribedSymbolsProcessingState = ProcessingState.error(action.payload);
    },
    flushSubscribedSymbolsSucceeded: (state: SessionsScreenState): void => {
      state.flushSubscribedSymbolsProcessingState = ProcessingState.success();
    },
    flushSubscribedSymbolsCompleted: (state: SessionsScreenState): void => {
      state.flushSubscribedSymbolsProcessingState = ProcessingState.idle();
    },
  },
});

export const sessionsSelector = (state: ApplicationState): readonly SMSession[] =>
  state.sessions.sessions;

export const sessionsTotalsSelector = (state: ApplicationState): Totals => state.sessions.totals;

export const processingStateSelector = (state: ApplicationState): ProcessingState =>
  state.sessions.processingState;

export const importSessionProcessingStateSelector = (state: ApplicationState): ProcessingState =>
  state.sessions.importSessionProcessingState;

export const flushSubscribedSymbolsProcessingStateSelector = (
  state: ApplicationState
): ProcessingState => state.sessions.flushSubscribedSymbolsProcessingState;

export const sessionProcessingStateSelector = (
  state: ApplicationState
): Record<string, ProcessingState> =>
  state.sessions.sessions.reduce(
    (
      mapped: Record<string, ProcessingState>,
      session: SMSession
    ): Record<string, ProcessingState> => ({
      ...mapped,
      [session.sessionID]: session.processingState,
    }),
    {}
  );

export const {
  loadSessionsStarted,
  loadSessionsCompleted,
  loadSessionsFailed,
  sessionsUpdated,
  updateSequences,

  sessionActionStarted,
  sessionActionStopped,
  sessionActionSucceeded,
  sessionActionFailed,

  importSessionStarted,
  importSessionSucceeded,
  importSessionFailed,
  resetImportSessionProcessingState,

  flushSubscribedSymbolsStarted,
  flushSubscribedSymbolsFailed,
  flushSubscribedSymbolsSucceeded,
  flushSubscribedSymbolsCompleted,

  sessionAdded,
} = slice.actions;

export default slice.reducer;
