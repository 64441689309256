import { versionNumber } from 'version';

export const insertVersionMetaTag = (): void => {
  const meta = document.createElement('meta');
  // Set the base attributes
  meta.setAttribute('name', 'version');
  meta.setAttribute('content', versionNumber);
  // Append the version meta tag
  document.head.prepend(meta);
};
