import { parseResponse } from 'api/parseResponse';

const requestWithoutBody = async <T>(
  url: string,
  empty: T,
  headers: Record<string, string>,
  method: 'GET' | 'DELETE',
  signal: AbortSignal
): Promise<T> => {
  const response = await fetch(url, {
    method: method,
    headers: headers,
    signal: signal,
  });

  return parseResponse(response);
};

export const POST = async <T>(
  url: string,
  data: Record<string, any> | string,
  empty: T,
  headers: Record<string, string>,
  signal: AbortSignal
): Promise<T> => {
  const getContentType = (): string | undefined => {
    if (data instanceof FormData) {
      return undefined;
    } else if (typeof data === 'string') {
      return 'application/x-www-form-urlencoded';
    }

    return 'application/json';
  };

  const getRequestBody = (): string | FormData => {
    if (data instanceof FormData) {
      return data;
    } else if (typeof data === 'string') {
      return data;
    } else {
      return JSON.stringify(data);
    }
  };

  const contentType = getContentType();

  const response = await fetch(url, {
    method: 'POST',
    signal: signal,
    body: getRequestBody(),
    headers: {
      ...headers,
      // Force content type
      ...(contentType ? { 'Content-Type': contentType } : {}),
    },
  });

  return parseResponse(response);
};

export const DELETE = <T>(
  url: string,
  empty: T,
  headers: Record<string, string>,
  signal: AbortSignal
): Promise<T> => {
  return requestWithoutBody(url, empty, headers, 'DELETE', signal);
};

export const GET = <T>(
  url: string,
  empty: T,
  headers: Record<string, string>,
  signal: AbortSignal
): Promise<T> => {
  return requestWithoutBody(url, empty, headers, 'GET', signal);
};
