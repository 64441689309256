const isPromise = (thing: any): thing is Promise<any> => {
  return 'then' in thing;
};

type PromiseExecutor<T> = (resolve: (result: T) => void, reject: (reason?: any) => void) => void;

export class Task<T> {
  private readonly _promise: Promise<T>;
  private readonly _cancel: VoidFunction;

  constructor(executor: Promise<T> | PromiseExecutor<T>, cancel: VoidFunction) {
    if (isPromise(executor)) {
      this._promise = executor;
    } else {
      this._promise = new Promise(executor);
    }
    this._cancel = cancel;
  }

  public run(): Promise<T> {
    return this._promise;
  }

  public cancel(): void {
    this._cancel();
  }
}

export const NoopTask = <T = void>(value: T): Task<T> =>
  new Task(
    (resolve: (value: T) => void): void => resolve(value),
    (): void => {
      return;
    }
  );
