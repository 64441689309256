import { ExitToApp, Settings } from '@mui/icons-material';
import { ButtonBase, Menu, MenuItem } from '@mui/material';
import { User } from 'auth/user';
import styles from 'components/SideBar/application-side-bar.module.scss';
import { toClassName } from 'helpers/toClassName';
import React from 'react';
import { versionNumber } from 'version';

interface Props {
  readonly user: User;

  onSignOut(): void;
}

export const UserDropdownMenu = (props: Props): React.ReactElement => {
  const { user } = props;
  const [isMenuOpen, setMenuOpen] = React.useState<boolean>(false);
  const anchor = React.useRef<HTMLDivElement>(null);

  return (
    <div ref={anchor} className={styles.userButton} onClick={(): void => setMenuOpen(!isMenuOpen)}>
      <ButtonBase sx={buttonSx}>
        <div className={styles.buttonTitle}>
          <i className={toClassName('fa fa-user-circle', styles.large)} />
          <div className={styles.userName}>
            <div className={styles.title}>{user.email}</div>
            <div className={styles.subTitle}>
              {user.firstName} {user.lastName}
            </div>
          </div>
          <i className={toClassName('fa fa-caret-down', styles.arrow)} />
        </div>
        <Menu
          anchorEl={anchor.current}
          open={isMenuOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={(): void => setMenuOpen(false)}
        >
          <MenuItem className={styles.menuItem} disabled>
            <Settings className={styles.menuItemIcon} /> Settings
          </MenuItem>
          <MenuItem onClick={props.onSignOut}>
            <div className={toClassName(styles.menuItem, styles.signOut)}>
              <ExitToApp className={styles.menuItemIcon} /> Sign Out
            </div>
          </MenuItem>
          <div className={toClassName(styles.menuItem, styles.versionNumber)}>
            Prism {versionNumber} &copy; 2022
          </div>
        </Menu>
      </ButtonBase>
    </div>
  );
};

const buttonSx = { width: '100%' };
