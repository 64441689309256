import { DayTimeInput } from 'components/DayTimeInput';
import { FieldDefinition, FieldType } from 'components/Form/components/Field/types';
import { SMLogControl } from 'redux/definitions/central';

export const fields: ReadonlyArray<FieldDefinition<SMLogControl>> = [
  {
    name: 'fileLogPath',
    label: 'File Log Path',
    fieldType: FieldType.text,
    required: false,
    display: true,
  },
  {
    name: 'fileName',
    label: 'File Name',
    fieldType: FieldType.text,
    required: false,
    display: true,
  },
  {
    name: 'logRotateEnabled',
    label: 'Log Rotate',
    fieldType: FieldType.bool,
    required: false,
    display: true,
  },
  {
    name: 'sizeLimitForRotate',
    label: 'Size limit for rotate',
    fieldType: FieldType.numeric,
    required: false,
    display: true,
  },
  {
    name: 'dayTimeToRotate',
    label: 'Day/Time to rotate',
    fieldType: FieldType.custom,
    required: false,
    display: true,
    component: DayTimeInput,
    isValid: (_: unknown): boolean => true,
  },
  {
    name: 'multiDayStore',
    label: 'Multi-day store',
    fieldType: FieldType.bool,
    required: false,
    display: true,
  },
  {
    name: 'storeKeyTags',
    label: 'Store Key Tags',
    fieldType: FieldType.text,
    required: false,
    display: (value: SMLogControl): boolean => {
      return value.multiDayStore;
    },
    isValid: (value: SMLogControl): boolean => {
      const { storeKeyTags } = value;
      if (!storeKeyTags) {
        return true;
      }

      return storeKeyTags?.split(',').every((item: string): boolean => !isNaN(Number(item)));
    },
  },
  {
    name: 'storeValueTags',
    label: 'Store Value Tags',
    fieldType: FieldType.text,
    required: false,
    display: (value: SMLogControl): boolean => {
      return value.multiDayStore;
    },
    isValid: (value: SMLogControl): boolean => {
      const { storeValueTags } = value;
      if (!storeValueTags) {
        return true;
      }

      return storeValueTags?.split(',').every((item: string): boolean => !isNaN(Number(item)));
    },
  },
];
