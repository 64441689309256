import { Box, Button, FormControl, FormLabel, OutlinedInput } from '@mui/material';
import { ErrorModal } from 'components/ErrorModal';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { SuccessModal } from 'components/SuccessModal';
import { ProcessingState } from 'enums/processingState';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { importSessionProcessingStateSelector } from 'redux/reducers/sessionsReducer';
import { importSMConfigurationAction } from 'redux/services/sessionsServices';

interface Props {
  onClose(): void;
}

export const ImportModal: React.FC<Props> = (props: Props): React.ReactElement => {
  const [sessionId, setSessionId] = React.useState<string>('');
  const { onClose } = props;
  const processingState = useSelector(importSessionProcessingStateSelector);
  const dispatch = useDispatch();

  const handleSessionIdChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setSessionId(value);
    },
    []
  );

  const handleImportConfiguration = React.useCallback((): void => {
    dispatch(importSMConfigurationAction(sessionId));
  }, [dispatch, sessionId]);

  const empty = React.useMemo((): boolean => {
    const trimmed = sessionId.trim();
    return trimmed.length === 0;
  }, [sessionId]);

  if (ProcessingState.isError(processingState)) {
    return (
      <ErrorModal
        title="We couldn't import the configuration"
        message="There was an error importing the configuration. Please try again."
        error={processingState}
        onClose={onClose}
      />
    );
  } else if (ProcessingState.isSuccess(processingState)) {
    return (
      <SuccessModal
        title="Imported successfully"
        message={`Configuration for session with id ${sessionId} was imported correctly`}
        onClose={onClose}
      />
    );
  } else {
    return (
      <Box position="relative" width={400} padding={2}>
        <Box>
          <FormControl fullWidth={true}>
            <FormLabel>Session ID</FormLabel>
            <OutlinedInput onChange={handleSessionIdChange} value={sessionId} />
          </FormControl>
          <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2} marginTop={2}>
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" disabled={empty} onClick={handleImportConfiguration}>
              Import
            </Button>
          </Box>
        </Box>
        <LoadingOverlay loading={ProcessingState.isProcessing(processingState)} />
      </Box>
    );
  }
};
