// FIXME: startDay and startTime can be derived from a single value
import { FlowItem } from 'api/interfaces/flowItem';
import { SMSessionDTO } from 'api/interfaces/snapshot';
import { Status } from 'enums/status';
import { DayAndTime } from 'interfaces/dayAndTime';
import { DropdownOption } from 'types/dropdownOption';

export enum DeskType {
  eqCash = 'EQ CASH',
  eqAlgoOpt = 'EQ ALGO OPT',
  optAlgo = 'OPT ALGO',
  fut = 'FUT',
  fx = 'F/X',
}

export enum NetworkType {
  xconn = 'Xconn',
  btr = 'BTR',
  tns = 'TNS',
  ipc = 'IPC',
}

const toFlowStatus = (client?: Status, destination?: Status): Status => {
  if (client !== destination) {
    if (client === Status.Down || destination === Status.Down) {
      return Status.Down;
    } else if (client === Status.Off || destination === Status.Off) {
      return Status.Off;
    } else {
      return Status.None;
    }
  } else {
    return client; // or destination
  }
};

export class Flow {
  public static fromFlowItem(flow: FlowItem): Flow {
    return {
      id: flow.idFlows,
      flowType: flow.flowType,
      status: Status.None,
      client: flow.client,
      clientSessionID: '',
      clientStatus: Status.None,
      clientLastLogon: 0,
      desk: flow.desk,
      destination: flow.destination,
      destinationSessionID: '',
      destinationStatus: Status.None,
      destinationLastLogon: 0,
      startDayTime: DayAndTime.fromDayOfWeekAndTimeString(flow.startday, flow.starttime),
      endDayTime: DayAndTime.fromDayOfWeekAndTimeString(flow.endday, flow.endtime),
    };
  }

  public static fromFlowItemAndSession(
    flow: FlowItem,
    client?: SMSessionDTO,
    destination?: SMSessionDTO
  ): Flow {
    return {
      id: flow.idFlows,
      flowType: flow.flowType,
      status: toFlowStatus(client?.Status, destination?.Status),
      client: flow.client,
      clientSessionID: flow.clientSession,
      clientStatus: client?.Status,
      clientLastLogon: client?.LastLogon,
      desk: flow.desk,
      destination: flow.destination,
      destinationSessionID: flow.destinationSession,
      destinationStatus: destination?.Status,
      destinationLastLogon: destination?.LastLogon,
      startDayTime: DayAndTime.fromDayOfWeekAndTimeString(flow.startday, flow.starttime),
      endDayTime: DayAndTime.fromDayOfWeekAndTimeString(flow.endday, flow.endtime),
    };
  }

  public static empty(): Flow {
    return {
      client: '',
      clientLastLogon: 0,
      clientSessionID: '',
      clientStatus: 0,
      desk: '',
      destination: '',
      destinationLastLogon: 0,
      destinationSessionID: '',
      destinationStatus: Status.Unknown,
      endDayTime: DayAndTime.invalid(),
      flowType: '',
      id: '',
      startDayTime: DayAndTime.invalid(),
      status: Status.Unknown,
    };
  }
}

export class Person {
  public static empty(): Person {
    return { email: '', name: '', phone: '' };
  }
}

export class FlowForm {
  public static empty(): FlowForm {
    return {
      backupNetwork: null,
      bandwidth: 0,
      contacts: Person.empty(),
      desk: [],
      primaryNetwork: null,
      requestedBy: Person.empty(),
      sendingFirmId: '',
      sendingFirmName: '',
      targetFirmId: '',
      targetFirmName: '',
    };
  }
}

export interface Person {
  readonly name: string;
  readonly email: string;
  readonly phone: string;
}

export interface Flow {
  readonly id: string;
  readonly flowType: string;
  readonly status: Status;
  readonly client: string;
  readonly clientSessionID: string;
  readonly clientStatus: Status;
  readonly clientLastLogon: number;
  readonly desk: string;
  readonly destination: string;
  readonly destinationSessionID: string;
  readonly destinationStatus: Status;
  readonly destinationLastLogon: number;
  readonly startDayTime: DayAndTime;
  readonly endDayTime: DayAndTime;
}

export interface FlowForm {
  readonly sendingFirmName: string;
  readonly sendingFirmId: string;
  readonly targetFirmName: string;
  readonly targetFirmId: string;

  readonly requestedBy: Person;
  readonly desk: readonly DropdownOption[];
  readonly contacts: Person;
  readonly bandwidth: number;

  readonly primaryNetwork: NetworkType | null;
  readonly backupNetwork: NetworkType | null;
}
