export interface CRUDResponse {
  readonly message: string;
  readonly success: boolean;

  [key: string]: unknown;
}

export const defaultResponseValue: CRUDResponse = {
  message: 'there was no response at all',
  success: false,
};

export const isCRUDResponse = (response: any): response is CRUDResponse => {
  return 'success' in response && 'message' in response;
};
