import styles from 'components/SideBar/application-side-bar.module.scss';
import { ToolbarItem } from 'components/SideBar/components/ToolbarItem';
import { ApplicationRouteDef } from 'interfaces/applicationRouteDef';
import React from 'react';

interface ChildMenuProps {
  readonly label: string;
  readonly items: readonly ApplicationRouteDef[];
}

export const SubMenu: React.FC<ChildMenuProps> = (props: ChildMenuProps): React.ReactElement => {
  const { items } = props;

  return (
    <div className={styles.dropdown}>
      <div className={styles.sectionHeader}>{props.label}</div>

      <ul className={styles.subMenu}>
        {items.map(
          (child: ApplicationRouteDef): React.ReactElement => (
            <ToolbarItem key={child.label} item={child} />
          )
        )}
      </ul>
    </div>
  );
};
