import { AddCircle, DeleteOutline, DriveFileRenameOutline } from '@mui/icons-material';
import { ButtonBase } from '@mui/material';
import { CenteredModal } from 'components/CenteredModal';
import { DummyContainer } from 'components/Form/components/Array/dummyContainer';
import { FieldDefinition, isArrayFieldDefinition } from 'components/Form/components/Field/types';
import { RequiredIndicator } from 'components/Form/components/RequiredIndicator';
import styles from 'components/Form/form.module.scss';
import { Form, FormChangeResult } from 'components/Form/index';
import { toClassName } from 'helpers/toClassName';
import React from 'react';

interface Props {
  readonly definition: FieldDefinition<any>;
  readonly value?: readonly any[];
  readonly disabled?: boolean;
  readonly required: boolean;

  onChange(array: readonly any[]): void;
  onRemove(value: any): void;
  onAdd(value: any): void;
  onEdit(index: number, value: any): void;
}

export const ArrayField: React.FC<Props> = (props: Props): React.ReactElement => {
  const { onAdd, onEdit, onRemove, onChange } = props;
  const { required, definition, value = [] } = props;
  if (isArrayFieldDefinition(definition)) {
    const { render } = definition;

    const [mode, setMode] = React.useState<'edit' | 'add' | null>(null);
    const [editItemIndex, setEditItemIndex] = React.useState<number>(-1);
    const [editItem, setEditItem] = React.useState<any>({});
    const Container = definition.container ? definition.container : DummyContainer;

    React.useEffect((): void => {
      if (!Array.isArray(value) || value.length === 0) {
        return;
      }

      setEditItem(value[0]);
    }, [value]);

    const addNew = React.useCallback((): void => {
      setEditItem({});
      setMode('add');
    }, []);

    const editExisting = React.useCallback((index: number, item: unknown): void => {
      setEditItem(item);
      setEditItemIndex(index);
      setMode('edit');
    }, []);

    const onSubmit = React.useCallback(
      (item: any): void => {
        switch (mode) {
          case 'edit':
            onEdit(editItemIndex, item);
            setEditItemIndex(-1);
            break;
          case 'add':
            onAdd(item);
            break;
        }
        setEditItem({});
        setMode(null);
      },
      [editItemIndex, mode, onAdd, onEdit]
    );

    const onEditItemChange = (name: string, fieldValue: any, _: any): FormChangeResult => {
      return { value: fieldValue, error: null };
    };

    return (
      <>
        <fieldset className={toClassName(styles.fieldset, styles.arrayContainer)}>
          <legend>
            <div>
              <span>{definition.label}</span> <RequiredIndicator required={required} />
            </div>
            <span className={styles.legendSeparator} />
            {!definition.readOnly && (
              <ButtonBase component="a" onClick={addNew}>
                <AddCircle classes={{ root: styles.icon }} /> <span>Add</span>
              </ButtonBase>
            )}
          </legend>
          <div className={styles.array}>
            <Container array={value} onChange={onChange}>
              {(Array.isArray(value) ? value : []).map(
                (item: any, index: number): React.ReactNode => {
                  return (
                    <div key={index.toString()} className={styles.arrayItem}>
                      <div className={styles.itemContent}>{render(item)}</div>
                      {!definition.readOnly && (
                        <div className={styles.buttonsContainer}>
                          <div
                            className={styles.editButton}
                            onClick={(): void => editExisting(index, item)}
                          >
                            <DriveFileRenameOutline />
                          </div>
                          <div className={styles.deleteButton} onClick={(): void => onRemove(item)}>
                            <DeleteOutline />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }
              )}
            </Container>
          </div>
        </fieldset>
        <CenteredModal title="New Item" open={mode !== null} onClose={(): void => setMode(null)}>
          <Form
            value={editItem}
            fields={definition.fields}
            submitLabel={mode === 'edit' ? 'Update' : 'Add Item'}
            onChange={onEditItemChange}
            onSubmit={onSubmit}
          />
        </CenteredModal>
      </>
    );
  } else {
    throw new Error('attempting to render a group as if it was an array');
  }
};
