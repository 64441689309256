import { Add, Cached } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { ApplicationUser } from 'api/interfaces/applicationUser';
import { CenteredModal } from 'components/CenteredModal';
import { FormModal } from 'components/FormModal';
import { QuestionModal } from 'components/QuestionModal';
import { Scaffold } from 'components/Scaffold';
import { Table, TableMode } from 'components/Table';
import { RowData } from 'components/Table/types/rowData';
import { TaskRunningStatus } from 'components/TaskRunningStatus';
import { CRUDActionsContext } from 'contexts/CRUDActionsContext';
import { useApi } from 'hooks/useApi';
import { useCRUD } from 'hooks/useCRUD';
import { useTable } from 'hooks/useTable';
import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { closeTaskModal } from 'redux/actions/users/closeTaskModal';
import { createUser } from 'redux/actions/users/createUser';
import { deleteUser } from 'redux/actions/users/deleteUser';
import { getUsers } from 'redux/actions/users/getUsers';
import { updateUser } from 'redux/actions/users/updateUser';
import { initializeUsersForm } from 'redux/actions/usersForm/initializeUsersForm';
import { ApplicationState } from 'redux/applicationState';
import { UsersState } from 'redux/reducers/usersReducer';
import styles from 'routes/EntityManagement/FirmsManagement/firms-management.module.scss';
import columns from 'routes/EntityManagement/UsersManagement/columns';
import UsersForm from 'routes/EntityManagement/UsersManagement/components/UsersForm';

interface DispatchProps {
  closeTaskModal(): void;

  createUser(user: ApplicationUser): any;
  updateUser(user: ApplicationUser): any;
  deleteUser(user: ApplicationUser): any;
  getUsers(): any;

  initializeUsersForm(): any;
}

type Props = UsersState & DispatchProps;

const UsersManagement: React.FC<Props> = (props: Props): React.ReactElement => {
  const { users, loading } = props;
  const { closeTaskModal, createUser, updateUser, deleteUser, getUsers, initializeUsersForm } =
    props;
  const [crud, mode, currentUser] = useCRUD(users, emptyUser);
  const cancelTaskFnRef = React.useRef<VoidFunction>(null);

  const tableProps = useTable('users-tool', columns);
  // const mode = columnData.mode;
  const closeModal = React.useCallback((): void => {
    crud.reset();
  }, [crud]);
  const api = useApi();

  const rows = React.useMemo((): Record<string, RowData<ApplicationUser>> => {
    return users.reduce(
      (
        newData: Record<string, RowData<ApplicationUser>>,
        systemUser: ApplicationUser
      ): Record<string, RowData<ApplicationUser>> => {
        return {
          ...newData,
          [systemUser.email]: {
            id: systemUser.email,
            data: systemUser,
          },
        };
      },
      {}
    );
  }, [users]);

  const save = React.useCallback(
    (user: ApplicationUser): void => {
      switch (mode) {
        case TableMode.create:
          cancelTaskFnRef.current = createUser(user);
          break;
        case TableMode.edit:
          cancelTaskFnRef.current = updateUser(user);
          break;
      }
    },
    [createUser, mode, updateUser]
  );

  const remove = React.useCallback((): void => {
    deleteUser(currentUser);
  }, [currentUser, deleteUser]);

  const onTaskErrored = React.useCallback((): void => {
    closeTaskModal();
  }, [closeTaskModal]);

  const onTaskCompleted = React.useCallback((): void => {
    closeTaskModal();
    closeModal();
  }, [closeModal, closeTaskModal]);

  const refresh = React.useCallback((): void => {
    api.refreshUserGroupCache();
  }, [api]);

  React.useEffect((): VoidFunction => {
    const cancel1 = initializeUsersForm();
    const cancel2 = getUsers();

    return (): void => {
      cancel1();
      cancel2();
    };
  }, [getUsers, initializeUsersForm]);

  return (
    <CRUDActionsContext.Provider value={crud}>
      <Scaffold title="Users Tool">
        <Box className={styles.header}>
          <Button onClick={crud.add}>
            <Add /> Add New
          </Button>
          <Button onClick={refresh}>
            <Cached />
            REFRESH
          </Button>
        </Box>
        <Table rowKey="email" rows={rows} loading={loading} {...tableProps} />
        <FormModal
          entityName="User"
          mode={mode}
          open={mode === TableMode.edit || mode === TableMode.create}
          onClose={closeModal}
        >
          <UsersForm email={currentUser.email} onSave={save} />
        </FormModal>
        <CenteredModal title="Delete User" open={mode === TableMode.remove} onClose={closeModal}>
          <QuestionModal
            title="Are you sure?"
            message={`You are going to delete ${currentUser.email}`}
            onYes={remove}
            onNo={closeModal}
          />
        </CenteredModal>
        <TaskRunningStatus
          modalType={props.modalType}
          message={props.taskMessage}
          onErrored={onTaskErrored}
          onSucceeded={onTaskCompleted}
          onCancel={cancelTaskFnRef.current}
        />
      </Scaffold>
    </CRUDActionsContext.Provider>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, any> = {
  createUser,
  updateUser,
  deleteUser,
  getUsers,
  closeTaskModal,
  initializeUsersForm,
};

const mapStateToProps: MapStateToProps<UsersState, any, ApplicationState> = (
  applicationState: ApplicationState
): UsersState => applicationState.users;

export default connect(mapStateToProps, mapDispatchToProps)(UsersManagement);

const emptyUser = ApplicationUser.defaultUser();
