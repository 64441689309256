import { OutlinedInput } from '@mui/material';
import styles from 'components/DayTimeInput/day-time-input.module.scss';
import moment, { isMoment, Moment } from 'moment';
import React from 'react';

interface Props {
  readonly id: string;
  readonly value: Moment | null;

  onChange(value: Moment): void;
}

export const TimeInput: React.FC<Props> = (props: Props): React.ReactElement => {
  const { id, value, onChange } = props;

  const formattedValue = React.useMemo(
    (): string => (!isMoment(value) ? '' : value.format('HH:mm')),
    [value]
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;

      onChange(moment(value + ':00', 'HH:mm:ss'));
    },
    [onChange]
  );

  return (
    <OutlinedInput
      id={id}
      type="time"
      placeholder="00:00"
      value={formattedValue}
      fullWidth={true}
      className={styles.time}
      onChange={handleChange}
    />
  );
};
