import styles from 'components/Table/components/Cell/cell-content.module.scss';
import { Column } from 'components/Table/types/column';
import { toClassName } from 'helpers/toClassName';
import React from 'react';

interface Props<T> {
  readonly column: Column<T>;
  readonly cellValue: any;
  readonly data: T;
}

export function Cell<T>(props: Props<T>): React.ReactElement {
  const { column, data, cellValue } = props;
  const { component, formatter, cellClass, alignment } = column;

  const value = React.useMemo((): any => {
    if (formatter !== undefined) {
      return formatter.format(cellValue, formatter.options);
    } else {
      return cellValue;
    }
  }, [cellValue, formatter]);

  const className = React.useMemo((): string | undefined => {
    if (typeof cellClass === 'function') {
      return toClassName(styles.cell, cellClass(value), styles[alignment]);
    } else if (typeof cellClass === 'string') {
      return toClassName(
        styles.cell,
        cellClass,

        styles[alignment]
      );
    } else {
      return toClassName(styles.cell, styles[alignment]);
    }
  }, [alignment, cellClass, value]);

  if (component) {
    const element = React.createElement<any>(component, {
      value: value,
      name: column.key,
      data: data,
    });

    return (
      <td className={className}>
        <div className={styles.container}>
          <div className={styles.content}>{element}</div>
        </div>
      </td>
    );
  } else {
    return (
      <td className={className}>
        <div className={styles.container}>
          <div className={toClassName(styles.content, styles.text)}>{value}</div>
        </div>
      </td>
    );
  }
}
