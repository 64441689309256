import { MoreVert, Restore } from '@mui/icons-material';
import { ButtonBase, Menu, MenuItem } from '@mui/material';
import { ColumnGroup } from 'components/Table/components/ColumnGroup';
import { HeaderRow } from 'components/Table/components/HeaderRow';
import styles from 'components/Table/table.module.scss';
import { Column } from 'components/Table/types/column';
import { SortDirection } from 'components/Table/types/sortDirection';
import React from 'react';

interface Props<T> {
  readonly columns: ReadonlyArray<Column<T>>;
  readonly filters?: Record<keyof T, string>;
  readonly sorting?: Record<keyof T, SortDirection>;

  onColumnMoved?(from: number, to: number): void;
  onFiltered?(columnKey: keyof T, value: string): void;
  onColumnSorted?(columnKey: keyof T, value: SortDirection): void;
  onColumnResized?(columnKey: keyof T, value: SortDirection): void;
  onResetOriginalLayout(): void;
}

interface State {
  readonly isOptionsOpen: boolean;
}

export class TableHeader<T> extends React.Component<Props<T>, State> {
  public state: State = { isOptionsOpen: false };
  private optionsButtonRef: React.RefObject<HTMLButtonElement> =
    React.createRef<HTMLButtonElement>();

  private handleResetOriginalLayout = (): void => {
    const { onResetOriginalLayout } = this.props;
    onResetOriginalLayout();
  };

  private toggleOptions = (): void => {
    const { isOptionsOpen } = this.state;
    this.setState({ isOptionsOpen: !isOptionsOpen });
  };

  public render(): React.ReactElement {
    // Properties
    const { columns, filters, sorting } = this.props;
    // Actions
    const { onColumnMoved, onFiltered, onColumnSorted, onColumnResized } = this.props;
    // State
    const { isOptionsOpen } = this.state;

    return (
      <div className={styles.headerContainer}>
        <ButtonBase
          ref={this.optionsButtonRef}
          className={styles.moreIcon}
          onClick={this.toggleOptions}
        >
          <MoreVert fontSize="small" />
          <Menu open={isOptionsOpen} anchorEl={this.optionsButtonRef.current}>
            <MenuItem classes={{ root: styles.menuItem }} onClick={this.handleResetOriginalLayout}>
              <Restore className={styles.menuItemIcon} /> <div>Restore Original Layout</div>
            </MenuItem>
          </Menu>
        </ButtonBase>
        <div className={styles.contentWrapper}>
          <table className={styles.header}>
            <ColumnGroup columns={columns} />
            <thead>
              <HeaderRow
                columns={columns}
                filters={filters}
                sorting={sorting}
                onColumnMoved={onColumnMoved}
                onFiltered={onFiltered}
                onColumnSorted={onColumnSorted}
                onColumnResized={onColumnResized}
              />
            </thead>
          </table>
        </div>
      </div>
    );
  }
}
