import styles from 'components/Table/cell.module.scss';
import formatters from 'components/Table/formatters';
import { formatIndexedObject } from 'components/Table/formatters/indexed';
import { DateTimeCell } from 'components/Table/renderers/DateTimeCellRenderer';
import { DayAndTimeCell } from 'components/Table/renderers/DayAndTimeCellRenderer';
import { StatusCell } from 'components/Table/renderers/StatusCellRenderer';
import { emptyStyleCellClassCreator } from 'components/Table/styles/empty';
import { numericCellClassCreator } from 'components/Table/styles/numeric';
import { Status } from 'enums/status';
import React from 'react';

export type FormatterOptions = Record<string, any>;

export type FormatFn<T, R = string> = (value: T, options?: FormatterOptions) => R;

export type CellClassFactory = (value: any) => string;

export interface Formatter<T> {
  readonly options?: FormatterOptions;
  format: FormatFn<T, any>;
}

export enum ColumnType {
  none,
  mapped,
  text,
  currency,
  status,
  dateTime,
  dayAndTime,
  integer,
  custom,
}

export enum Alignment {
  center = 'center',
  left = 'left',
}

export interface ColumnMethods<T> {
  readonly formatter?: Formatter<T>;
  readonly component?: React.FunctionComponent<any> | React.ComponentClass<any> | string;
  readonly cellClass?: string | CellClassFactory;

  sortingDifference(v1: any, v2: any): number;
  textuallyOverlap(value: any, keyword: string): boolean;
}

export type ColumnFactory<T> = () => ReadonlyArray<ColumnDefinition<T>>;

export interface ColumnDefinitionBase<T> {
  readonly header: string;
  readonly key: keyof T;
  readonly sortable?: boolean;
  readonly weight?: number;
  readonly columnType: ColumnType;
  readonly data?: Record<string, string>;
  readonly alignment?: Alignment;
  readonly filterable?: boolean;
  readonly minWidth?: number;
  readonly editable?: boolean;
  readonly required?: boolean;
}

export type OtherColumnDefinition<T> = ColumnDefinitionBase<T>;

export interface CustomColumnDefinition<T> extends ColumnDefinitionBase<T> {
  readonly columnType: ColumnType.custom;
  readonly component: React.FunctionComponent<any>;
}

export type ColumnDefinition<T> = CustomColumnDefinition<T> | OtherColumnDefinition<T>;
export type Column<T> = ColumnDefinition<T> & ColumnMethods<T>;

export const createFromDefinition = <T>(definition: ColumnDefinition<T>): Column<T> => {
  switch (definition.columnType) {
    case ColumnType.none:
      throw new Error("cannot use a `None' column type, this is just an invalid value");
    case ColumnType.mapped:
      return ((): Column<T> => {
        const data = definition.data;
        return {
          filterable: true,
          ...definition,
          formatter: {
            format: formatIndexedObject,
            options: { data: data },
          },
          cellClass: emptyStyleCellClassCreator(data),
          sortingDifference(_: any, __: any): number {
            return -1;
          },
          textuallyOverlap(key: any, keyword: string): boolean {
            const value = data[key];
            if (typeof value !== 'string') {
              return true;
            }

            return value.isSimilarTo(keyword);
          },
        };
      })();
    case ColumnType.text:
      return {
        filterable: true,
        ...definition,
        sortingDifference(_: any, __: any): number {
          return -1;
        },
        textuallyOverlap(value: any, keyword: string): boolean {
          if (typeof value === undefined) {
            return false;
          } else if (typeof value !== 'string') {
            return false;
          }

          return value.isSimilarTo(keyword);
        },
      };
    case ColumnType.currency:
      return {
        filterable: true,
        ...definition,
        formatter: formatters.currency,
        cellClass: styles.currencyCell,
        sortingDifference(_: any, __: any): number {
          return -1;
        },
        textuallyOverlap(_: any, __: string): boolean {
          return false;
        },
      };
    case ColumnType.status:
      return {
        filterable: true,
        ...definition,
        formatter: formatters.status,
        component: StatusCell,
        sortingDifference(_: any, __: any): number {
          return -1;
        },
        textuallyOverlap(value: Status, keyword: string): boolean {
          switch (value) {
            case Status.None:
              return false;
            case Status.On:
              return 'on'.isSimilarTo(keyword);
            case Status.Off:
              return 'off'.isSimilarTo(keyword);
            case Status.Overtime:
              return 'overtime'.isSimilarTo(keyword);
            case Status.Down:
              return 'error'.isSimilarTo(keyword);
            case Status.Unknown:
              return 'unknown'.isSimilarTo(keyword);
          }
          return false;
        },
      };
    case ColumnType.dateTime:
      return {
        filterable: true,
        ...definition,
        component: DateTimeCell,
        formatter: formatters.dateTime,
        sortingDifference(_: any, __: any): number {
          return -1;
        },
        textuallyOverlap(_: any, __: string): boolean {
          return false;
        },
      };
    case ColumnType.dayAndTime:
      return {
        filterable: true,
        ...definition,
        component: DayAndTimeCell,
        sortingDifference(_: any, __: any): number {
          return -1;
        },
        textuallyOverlap(_: any, __: string): boolean {
          return false;
        },
      };
    case ColumnType.integer:
      return {
        filterable: true,
        ...definition,
        formatter: formatters.integer,
        cellClass: numericCellClassCreator,
        sortingDifference(_: any, __: any): number {
          return -1;
        },
        textuallyOverlap(_: any, __: string): boolean {
          return false;
        },
      };
    case ColumnType.custom:
      return {
        filterable: true,
        ...definition,
        sortingDifference(_: any, __: any): number {
          return 0;
        },
        textuallyOverlap(_: any, __: string): boolean {
          return false;
        },
      };
  }
};
