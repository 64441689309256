import { Dialog, ThemeProvider } from '@mui/material';
import React from 'react';
import { createRoot } from 'react-dom/client';
import theme from 'theme';

export type ImperativeModalProps<T> = {
  onRespond(value: T): void;
  onDiscard(): void;
};

async function execute<T>(
  render: (props: ImperativeModalProps<T>) => React.ReactElement
): Promise<T> {
  return new Promise<T>((resolve: (response: T) => void, reject: () => void): void => {
    const { body } = document;

    const container = document.createElement('div');
    const root = createRoot(container);

    const closeModal = (): void => {
      root.unmount();
      container.remove();
    };

    const onDiscard = (): void => {
      setTimeout(closeModal, 0);
      reject();
    };

    const onRespond = (response: T): void => {
      setTimeout(closeModal, 0);
      resolve(response);
    };

    const element: React.ReactElement = (
      <ThemeProvider theme={theme}>
        <Dialog open={true}>{render({ onDiscard, onRespond })}</Dialog>
      </ThemeProvider>
    );
    body.append(container);

    root.render(element);
  });
}

const exports = {
  execute: execute,
};

export default exports;
