import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProcessingState } from 'enums/processingState';
import { ApplicationState } from 'redux/applicationState';
import { Execution } from 'types/summaryReport';

export interface ExecutionsState {
  processingState: ProcessingState;
  list: Execution[];
}

const initialState: ExecutionsState = {
  processingState: ProcessingState.idle(),
  list: [],
};

const slice = createSlice({
  name: 'executions',
  initialState: initialState,
  reducers: {
    fetchExecutionsStarted: (state: ExecutionsState): void => {
      state.processingState = ProcessingState.processing();
    },
    fetchExecutionsCompleted: (
      state: ExecutionsState,
      { payload }: PayloadAction<Execution[]>
    ): void => {
      state.list = payload;
      state.processingState = ProcessingState.idle();
    },
    fetchExecutionsFailed: (state: ExecutionsState, { payload }: PayloadAction<any>): void => {
      state.processingState = ProcessingState.error(payload);
    },
  },
});

export const executionsSelector = (state: ApplicationState): Execution[] => state.executions.list;

export const executionsProcessingStateSelector = (state: ApplicationState): ProcessingState =>
  state.executions.processingState;

export const { fetchExecutionsStarted, fetchExecutionsFailed, fetchExecutionsCompleted } =
  slice.actions;

export default slice.reducer;
