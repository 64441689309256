import { OutlinedInput } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import React from 'react';
import classes from 'routes/Risk/risk.module.scss';

interface Props<T> {
  readonly name: keyof T;
  readonly value: number | null;
  readonly label?: string;
  readonly fullWidth?: boolean;

  onChange(name: keyof T, value: number | null): void;
}

export function NumericInput<T>(props: Props<T>): React.ReactElement {
  const { name, value, label, fullWidth = true, onChange } = props;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      if (value.trim() === '') {
        onChange(name, null);
      } else {
        const numeric = Number(value);
        if (isNaN(numeric)) {
          return;
        }

        onChange(name, numeric);
      }
    },
    [name, onChange]
  );

  const stringValue = React.useMemo(
    (): string => (value === null ? '' : value.toFixed(0)),
    [value]
  );

  return (
    <>
      {label && <FormLabel>{label}</FormLabel>}
      <OutlinedInput
        name={String(name)}
        value={stringValue}
        classes={{ input: classes.numericInput }}
        fullWidth={fullWidth}
        onChange={handleChange}
      />
    </>
  );
}
