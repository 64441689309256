import { Column, ColumnDefinition, createFromDefinition } from 'components/Table/types/column';
import { SortDirection } from 'components/Table/types/sortDirection';
import { swapColumns } from 'helpers/columnSwapper';
import React from 'react';
import { Action } from 'redux/action';
import tableReducer, {
  initialTableState,
  TableAction,
  TableState,
} from 'redux/reducers/tableReducer';

export interface TableHookState<T> extends TableState<T> {
  onColumnMoved?(from: number, to: number): void;
  onColumnResized?(columnKey: keyof T, value: number): void;
  onFiltered?(columnKey: keyof T, value: string): void;
  onColumnSorted?(columnKey: keyof T, value: SortDirection): void;
  onColumnsChanged(columns: ReadonlyArray<Column<T>>): void;
  onResetOriginalLayout(): void;
}

export const useTable = <T>(
  key: string,
  originalColumns: ReadonlyArray<ColumnDefinition<T>>
): TableHookState<T> & Partial<TableState<T>> => {
  const [state, dispatch] = React.useReducer<React.Reducer<TableState<T>, Action<TableAction>>>(
    tableReducer,
    initialTableState(key, originalColumns)
  );
  const { columnsOrder, columns, sortingOrder, filters } = state;

  React.useEffect((): void => {
    if (columns.length > 0) return;
    // Otherwise, do set the base
    dispatch({
      type: TableAction.setColumns,
      data: originalColumns.map(createFromDefinition),
    });
  }, [originalColumns, columns]);

  const onColumnSorted = React.useCallback(
    (columnKey: keyof T, sortDirection: SortDirection): void => {
      dispatch({
        type: TableAction.setSorting,
        data: { ...sortingOrder, [columnKey]: sortDirection },
      });
    },
    [sortingOrder]
  );

  const onResetOriginalLayout = React.useCallback((): void => {
    dispatch({
      type: TableAction.resetColumns,
      data: originalColumns.map(createFromDefinition),
    });
  }, [originalColumns]);

  const onColumnResized = React.useCallback((columnKey: keyof T, width: number): void => {
    console.warn(columnKey, width);
    return;
  }, []);

  const onColumnMoved = React.useCallback(
    (source: number, target: number): void => {
      if (columnsOrder === undefined) {
        const defaultOrder = columns.map((_, index: number): number => index);
        dispatch({
          type: TableAction.setColumnsOrder,
          data: swapColumns(defaultOrder, source, target),
        });
      } else {
        dispatch({
          type: TableAction.setColumnsOrder,
          data: swapColumns(columnsOrder, source, target),
        });
      }
    },
    [columns, columnsOrder]
  );

  const onFiltered = React.useCallback(
    (columnKey: keyof T, value: string): void => {
      if (value === '') {
        const copy: Record<keyof T, string> = { ...filters };
        delete copy[columnKey];
        dispatch({ type: TableAction.setFilters, data: copy });
      } else {
        dispatch({
          type: TableAction.setFilters,
          data: { ...filters, [columnKey]: value },
        });
      }
    },
    [filters]
  );

  const onColumnsChanged = React.useCallback((columns: ReadonlyArray<Column<T>>): void => {
    dispatch({ type: TableAction.setColumns, data: columns });
  }, []);

  return {
    ...state,
    onColumnMoved,
    onFiltered,
    onResetOriginalLayout,
    onColumnSorted,
    onColumnsChanged,
    onColumnResized,
  };
};
