import { Status } from 'enums/status';
import strings from 'locales';

export const statuses: { [key in Status]: string } = {
  [Status.None]: '',
  [Status.On]: strings.On,
  [Status.Off]: strings.Off,
  [Status.Overtime]: strings.Overtime,
  [Status.Down]: strings.Down,
  [Status.Unknown]: strings.Unknown,
};

export const statusesIcons: { [key in Status]: string } = {
  [Status.None]: 'question-circle',
  [Status.On]: 'check-circle',
  [Status.Off]: 'times-circle',
  [Status.Overtime]: 'clock',
  [Status.Down]: 'exclamation-circle',
  [Status.Unknown]: 'question-circle',
};
