import { ColumnDefinition, ColumnType } from 'components/Table/types/column';
import { SMSession } from 'interfaces/session';
import { Actions } from 'routes/SessionsScreen/components/Actions';
import { SequenceNumberCell } from 'routes/SessionsScreen/components/SeuquenceNumberCell';

const columns: ReadonlyArray<ColumnDefinition<SMSession>> = [
  {
    header: '',
    columnType: ColumnType.custom,
    key: 'id',
    filterable: false,
    weight: 0.05,
    minWidth: 40,
    component: Actions,
  },
  {
    header: 'Name',
    columnType: ColumnType.text,
    key: 'sessionID',
    weight: 2,
  },
  {
    header: 'Status',
    key: 'status',
    columnType: ColumnType.status,
    weight: 0.5,
  },
  {
    header: 'Last Logon',
    key: 'lastLogon',
    columnType: ColumnType.dateTime,
  },
  {
    header: 'Queue Depth',
    key: 'queueSize',
    columnType: ColumnType.integer,
  },
  {
    header: 'Max Queue Depth',
    key: 'maxQueueSizeMB',
    columnType: ColumnType.integer,
  },
  {
    header: 'In Sequence',
    key: 'inSequence',
    columnType: ColumnType.custom,
    component: SequenceNumberCell,
  },
  {
    header: 'Out Sequence',
    key: 'outSequence',
    columnType: ColumnType.custom,
    component: SequenceNumberCell,
  },
];

export default columns;
