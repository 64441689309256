import { Api } from 'api';
import { AnyAction } from 'redux';
import { AsyncAction, createAsyncAction } from 'redux/asyncAction';
import {
  deleteSolaceMessagesFailed,
  deleteSolaceMessagesStarted,
  deleteSolaceMessagesSucceeded,
  fetchSolaceQueueCompleted,
  fetchSolaceQueueFailed,
  fetchSolaceQueueStarted,
} from 'redux/reducers/solaceQueueReducer';
import { RecordsPage } from 'types/page';
import { SolaceQueueEntry } from 'types/solace';
import { SolaceBrowserQuery } from 'types/solaceBrowser';

async function* fetchSolaceQueue(
  api: Api,
  signal: AbortSignal,
  query: SolaceBrowserQuery
): AsyncGenerator<AnyAction> {
  yield fetchSolaceQueueStarted();

  try {
    const rawSolaceQueueEntries = await api.browseSolaceQueue(signal, query);
    yield fetchSolaceQueueCompleted(
      RecordsPage.map(rawSolaceQueueEntries, SolaceQueueEntry.fromRaw)
    );
  } catch (error: any) {
    yield fetchSolaceQueueFailed(error);
  }
}

async function* deleteSolaceMessages(
  api: Api,
  signal: AbortSignal,
  ids: readonly string[],
  query: SolaceBrowserQuery
): AsyncGenerator<AnyAction> {
  yield deleteSolaceMessagesStarted();

  try {
    await api.deleteSolaceMessages(signal, ids, query);
    yield deleteSolaceMessagesSucceeded(ids);
  } catch (error: any) {
    yield deleteSolaceMessagesFailed(error);
  }
}

export const fetchSolaceQueueAction = (query: SolaceBrowserQuery): AsyncAction =>
  createAsyncAction(fetchSolaceQueue, query);

export const deleteSolaceMessagesAction = (
  ids: readonly string[],
  query: SolaceBrowserQuery
): AsyncAction => createAsyncAction(deleteSolaceMessages, ids, query);
