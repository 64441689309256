import { Add, ImportExport } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { SessionEditor } from 'ComplexModals/SessionEditor';
import { CenteredModal } from 'components/CenteredModal';
import { QuestionModal } from 'components/QuestionModal';
import { Scaffold } from 'components/Scaffold';
import { StatusBar } from 'components/StatusBar';
import { Table, TableMode } from 'components/Table';
import { CRUDActionsContext } from 'contexts/CRUDActionsContext';
import { ProcessingState } from 'enums/processingState';
import { useCRUD } from 'hooks/useCRUD';
import { useStatusFilter } from 'hooks/useStatusFilter';
import { useTable } from 'hooks/useTable';
import { SMSession } from 'interfaces/session';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  importSessionProcessingStateSelector,
  processingStateSelector,
  resetImportSessionProcessingState,
  SequenceUpdateDTO,
  sessionsSelector,
  sessionsTotalsSelector,
  updateSequences,
} from 'redux/reducers/sessionsReducer';
import { statusBarSelector } from 'redux/reducers/statusBarReducer';
import { deleteSessionAction, fetchSessionsAction } from 'redux/services/sessionsServices';
import columns from 'routes/SessionsScreen/columns';
import { ImportModal } from 'routes/SessionsScreen/components/ImportModal';
import { SignalR, SignalRContext } from 'signalR';

const SessionsScreen: React.FC = (): React.ReactElement => {
  // Properties
  const sessions = useSelector(sessionsSelector);
  const totals = useSelector(sessionsTotalsSelector);
  const statusBar = useSelector(statusBarSelector);
  const processingState = useSelector(processingStateSelector);
  const importSessionProcessingState = useSelector(importSessionProcessingStateSelector);
  // Actions
  const tableProps = useTable('sessions', columns);
  const [crud, mode, currentSession] = useCRUD(sessions, SMSession.empty());
  const dispatch = useDispatch<any>();
  const signalR = React.useContext<SignalR>(SignalRContext);

  const [filteredSessions, filter, setFilter] = useStatusFilter<SMSession>(sessions);
  const [importModalOpen, setImportModalOpen] = useState<boolean>(false);

  const deleteSession = React.useCallback((): void => {
    crud.reset();
    dispatch(deleteSessionAction(currentSession.sessionID));
  }, [crud, currentSession.sessionID, dispatch]);

  React.useEffect((): VoidFunction => {
    return dispatch(fetchSessionsAction());
  }, [dispatch]);

  const openImportModal = React.useCallback((): void => {
    setImportModalOpen(true);
  }, []);

  const closeImportModal = React.useMemo((): VoidFunction | undefined => {
    if (ProcessingState.isProcessing(importSessionProcessingState)) {
      return undefined;
    }

    return (): void => {
      dispatch(resetImportSessionProcessingState());
      setImportModalOpen(false);
    };
  }, [dispatch, importSessionProcessingState]);

  const onRowMount = React.useCallback(
    (sessionId: string, mounted: boolean): void => {
      if (mounted) {
        signalR.subscribeForSequenceNumbers(sessionId, (data: SequenceUpdateDTO): void => {
          dispatch(updateSequences(data));
        });
      } else {
        signalR.unsubscribeForSequenceNumbers(sessionId);
      }
    },
    [dispatch, signalR]
  );

  return (
    <CRUDActionsContext.Provider value={crud}>
      <Scaffold title="Sessions Monitor">
        <StatusBar
          filter={filter}
          totals={totals}
          lastUpdate={statusBar.lastUpdate}
          connected={statusBar.connected}
          onFiltered={setFilter}
        />
        <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={1}>
          <Button onClick={crud.add}>
            <Add /> Add New
          </Button>
          <Button variant="contained" onClick={openImportModal}>
            <ImportExport />
            Import Configuration
          </Button>
        </Box>
        <Table
          rowKey="sessionID"
          rows={filteredSessions}
          loading={ProcessingState.isProcessing(processingState)}
          onRowMount={onRowMount}
          {...tableProps}
        />
      </Scaffold>

      <CenteredModal open={mode === TableMode.edit} onClose={crud.reset}>
        <SessionEditor sessionId={currentSession.sessionID} onClose={crud.reset} />
      </CenteredModal>

      <CenteredModal open={mode === TableMode.clone} onClose={crud.reset}>
        <SessionEditor sessionId={currentSession.sessionID} create={true} onClose={crud.reset} />
      </CenteredModal>

      <CenteredModal open={mode === TableMode.create} onClose={crud.reset}>
        <SessionEditor create={true} onClose={crud.reset} />
      </CenteredModal>

      <CenteredModal open={importModalOpen} onClose={closeImportModal}>
        <ImportModal onClose={closeImportModal} />
      </CenteredModal>

      <CenteredModal open={mode === TableMode.remove} onClose={crud.reset}>
        <QuestionModal
          title="Are you sure?"
          message={`You are going to delete ${currentSession.sessionID}`}
          onYes={deleteSession}
          onNo={crud.reset}
        />
      </CenteredModal>
    </CRUDActionsContext.Provider>
  );
};

export default SessionsScreen;
