import { Api } from 'api';
import { ApplicationUser } from 'api/interfaces/applicationUser';
import { PrismApplication } from 'api/interfaces/prismApplication';
import { SonicGroup } from 'api/interfaces/sonicGroup';
import {
  FieldDefinition,
  FieldType,
  MappableFieldsArray,
  MappedGroup,
} from 'components/Form/components/Field/types';
import React from 'react';
import { UsersFormState } from 'redux/reducers/usersFormReducer';
import { MappedGroupListItem } from 'routes/EntityManagement/UsersManagement/components/MappedGroupListItem';
import { DropdownOption } from 'types/dropdownOption';

const displayForFXOOnly = (user: ApplicationUser, provider: UsersFormState): boolean => {
  const { applications } = provider;
  const currentApplication = applications.find(
    (app: PrismApplication): boolean => app.app_id === user.applicationId
  );
  if (currentApplication) {
    const { app_name } = currentApplication;
    const applicationName = app_name.toLowerCase();

    return applicationName.includes('fxo');
  }

  return false;
};

const fxoApplicationForm: MappableFieldsArray<ApplicationUser, UsersFormState> = [
  {
    name: 'sonicGroups',
    fieldType: FieldType.multiBool,
    label: 'Sonic Groups',
    display: displayForFXOOnly,
    options: (_: ApplicationUser, state: UsersFormState): readonly DropdownOption[] =>
      state.applications
        .find((application: PrismApplication): boolean =>
          application.app_name.toLowerCase().includes('fxo')
        )
        .sonic.map((sonicGroup: SonicGroup): DropdownOption => {
          return {
            name: sonicGroup.group_name,
            id: sonicGroup.group_name,
          };
        }),
    required: false,
  },
  {
    name: 'FXOFirm',
    fieldType: FieldType.group,
    display: (user: ApplicationUser, provider: UsersFormState): boolean => {
      const application = provider.applications.find(
        (item: PrismApplication): boolean => item.app_id === user.applicationId
      );
      if (application === undefined) {
        return false;
      }

      return application.product === 'FXO';
    },
    required: false,
    children: [
      {
        name: 'firm',
        fieldType: FieldType.fieldset,
        display: true,
        label: 'Firm',
        children: [
          {
            name: 'firmId',
            fieldType: FieldType.dropdown,
            label: 'Firm',
            display: true,
            options: (
              _: ApplicationUser,
              referenceDataProvider: UsersFormState
            ): readonly DropdownOption[] => referenceDataProvider.firms,
            required: true,
          },
          {
            name: 'MSTraderID',
            fieldType: FieldType.text,
            label: 'MS Trader ID',
            display: true,
            required: true,
          },
          {
            name: 'UBSTraderID',
            fieldType: FieldType.text,
            label: 'UBS Trader ID',
            display: true,
            required: true,
          },
          {
            name: 'TradingEntityCode',
            fieldType: FieldType.text,
            label: 'Trading Entity Code',
            display: true,
            required: true,
          },
          {
            name: 'TRTNTraderID',
            fieldType: FieldType.text,
            label: 'TRTN Trader ID',
            display: true,
            required: false,
          },
          {
            name: 'MSCOTraderID',
            fieldType: FieldType.text,
            label: 'MSCO Trader ID',
            display: true,
            required: false,
          },
        ],
      },
    ],
  },
];

const generateApplicationsAndRolesFields = async (
  api: Api,
  user: ApplicationUser,
  provider: UsersFormState
): Promise<MappedGroup<ApplicationUser, UsersFormState>> => {
  const { applications } = provider;

  return applications.reduce(
    (
      currentMap: MappedGroup<ApplicationUser, UsersFormState>,
      application: PrismApplication
    ): MappedGroup<ApplicationUser, UsersFormState> => {
      const { app_name } = application;

      const fields: MappableFieldsArray<ApplicationUser, UsersFormState> = [
        {
          name: 'oktaGroups',
          fieldType: FieldType.multiBool,
          label: 'Okta Groups',
          display: true,
          options: application.okta,
          required: true,
        },
      ];
      const applicationName = app_name.toLowerCase();
      // Add FXO specific fields
      if (applicationName.includes('fxo')) {
        fields.push(...fxoApplicationForm);
      }

      return { ...currentMap, [application.app_id]: fields };
    },
    {}
  );
};

const fields: ReadonlyArray<FieldDefinition<ApplicationUser, UsersFormState>> = [
  {
    name: 'RootGroup',
    fieldType: FieldType.group,
    display: true,
    children: [
      {
        name: 'basicInformation',
        fieldType: FieldType.fieldset,
        label: 'Basic Information',
        display: true,
        children: [
          {
            name: 'companyId',
            fieldType: FieldType.dropdown,
            label: 'Organization',
            display: true,
            options: (
              _: ApplicationUser,
              referenceDataProvider: UsersFormState
            ): readonly DropdownOption[] => referenceDataProvider.companies,
            required: true,
          },
          {
            name: 'email',
            fieldType: FieldType.email,
            label: 'Email',
            display: true,
            required: true,
          },
          {
            name: 'firstName',
            fieldType: FieldType.text,
            label: 'First Name',
            display: true,
            required: true,
          },
          {
            name: 'lastName',
            fieldType: FieldType.text,
            label: 'Last Name',
            display: true,
            required: true,
          },
          {
            name: 'password',
            fieldType: FieldType.text,
            label: 'Password',
            display: (value: ApplicationUser): boolean => value.id === '',
            required: true,
          },
        ],
      },
    ],
  },
  {
    name: 'addressesAndPhoneNumbers',
    fieldType: FieldType.group,
    display: true,
    children: [
      {
        name: 'phoneNumbers',
        fieldType: FieldType.fieldset,
        label: 'Phone Numbers',
        display: true,
        children: [
          {
            name: 'workPhone',
            fieldType: FieldType.phone,
            label: 'Work Phone',
            display: true,
            required: false,
          },
          {
            name: 'cellPhone',
            fieldType: FieldType.phone,
            label: 'Cell Phone',
            display: true,
            required: false,
          },
        ],
      },
    ],
  },
  {
    name: 'security',
    fieldType: FieldType.group,
    display: (user: ApplicationUser): boolean => user.id === '',
    children: [
      {
        name: 'questionAndAnswer',
        fieldType: FieldType.fieldset,
        display: true,
        label: 'Security Question & Answer',
        children: [
          {
            name: 'recoveryQuestion',
            fieldType: FieldType.text,
            label: 'Recovery Question',
            display: true,
            required: false,
          },
          {
            name: 'recoveryAnswer',
            fieldType: FieldType.text,
            label: 'Recovery Answer',
            display: true,
            required: false,
          },
        ],
      },
    ],
  },
  {
    name: 'applications',
    fieldType: FieldType.mappedGroup,
    display: true,
    label: 'Roles and Entitlements',
    description:
      'Users can be entitled specific permissions within specific applications in the next section. ' +
      'The checkbox is an indicator that the users has roles belonging to such application. ' +
      'To see the detailed roles assigned to this user, click on the application item. ' +
      'The checkbox is read only.',
    fields: generateApplicationsAndRolesFields,
    renderListItem: (
      user: ApplicationUser,
      state: UsersFormState,
      key: string
    ): React.ReactNode => {
      return <MappedGroupListItem state={state} user={user} itemKey={key} />;
    },
  },
];

export default fields;
