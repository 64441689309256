import {
  isArrayFieldDefinition,
  isBasicFieldDefinition,
  isFieldsetDefinition,
} from 'components/Form/components/Field/types';
import { FieldDefinition } from 'components/Form/components/Field/types';

export function isFieldValid<T, _>(
  definition: FieldDefinition<any> | undefined,
  value: T
): boolean {
  if (!definition) {
    return false;
  }

  if (typeof definition.isValid === 'function') {
    return definition.isValid(value);
  }

  return true;
}

export function isFieldRequired<T, P>(
  definition: FieldDefinition<any> | undefined,
  value: T,
  provider: P
): boolean {
  if (!definition) {
    return false;
  }

  if (
    !isBasicFieldDefinition(definition) &&
    !isFieldsetDefinition(definition) &&
    !isArrayFieldDefinition(definition)
  ) {
    return false;
  }
  const { required } = definition;

  if (typeof required === 'function') {
    return required(value, provider);
  } else {
    return required;
  }
}

export function isFieldVisible<T, P>(
  value: T,
  provider: P
): (definition: FieldDefinition<T, P>) => boolean {
  return (definition: FieldDefinition<T, P>): boolean => {
    if (typeof definition.display === 'function') {
      return definition.display(value, provider);
    } else {
      return definition.display;
    }
  };
}
