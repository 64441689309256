import { Typography } from '@mui/material';
import { TotalsView } from 'components/StatusBar/components/Totals';
import styles from 'components/StatusBar/status-bar.module.scss';
import { Status } from 'enums/status';
import { toClassName } from 'helpers/toClassName';
import { Totals } from 'interfaces/totals';
import React from 'react';
import { formatDateTime } from 'ui-utils/formatDateTime';

interface Props {
  readonly totals: Totals;
  readonly connected: boolean;
  readonly lastUpdate: Date;
  readonly filter: Status;

  onFiltered?(status: Status): void;
}

export const StatusBar = (props: Props): React.ReactElement => (
  <div className={styles.container}>
    <div
      className={toClassName(styles.status, {
        [styles.connected]: props.connected,
      })}
    >
      <div className={styles.connectionIndicator}>
        <Typography className={styles.connectionIndicatorLabel}>
          {props.connected ? 'Connected' : 'Not Connected'}
        </Typography>
      </div>
      <div className={styles.lastUpdate}>
        <div className={styles.label}>
          <Typography variant="subtitle2">Last update</Typography>
        </div>
        <div className={styles.value}>
          <Typography variant="subtitle2">{formatDateTime(props.lastUpdate)}</Typography>
        </div>
      </div>
    </div>
    <div className={styles.totals}>
      <TotalsView filter={props.filter} totals={props.totals} onChange={props.onFiltered} />
    </div>
  </div>
);
