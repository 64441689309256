import styles from 'components/FIXTableField/fix-table-field.module.scss';
import { FIXTaggedField } from 'interfaces/FIXTaggedField';
import React from 'react';

type Props = FIXTaggedField;

export const FIXTableFieldCell: React.FC<Props> = (props: Props): React.ReactElement => {
  if (props.label === 'LegSide') {
    const value = props.value;
    if (typeof value !== 'string') {
      throw new Error('unexpected value for leg side');
    }
    return <td className={styles.string}>{SIDES[value]}</td>;
  } else {
    return (
      <td className={styles[props.type]}>
        <>{props.value}</>
      </td>
    );
  }
};

const SIDES: Record<string, string> = {
  '1': 'Buy',
  '2': 'Sell',
  '3': 'Buy minus',
  '4': 'Sell plus',
  '5': 'Sell short',
  '6': 'Sell short exempt',
  '7': 'Undisclosed',
  '8': 'Cross',
  '9': 'Cross short',
  A: 'Cross short exempt',
  B: 'As Defined',
  C: 'Opposite',
};
