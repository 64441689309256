import { Column } from 'components/Table/types/column';
import React from 'react';

interface Props {
  columns: ReadonlyArray<Column<any>>;
}

const getColumnsWeight = (columns: ReadonlyArray<Column<any>>): number =>
  columns.reduce((total: number, column: Column<any>): number => total + (column.weight ?? 1), 0);

export const ColumnGroup = (props: Props): React.ReactElement => {
  const { columns } = props;

  const totalWeight = React.useMemo((): number => getColumnsWeight(columns), [columns]);

  const mapper = React.useCallback(
    (column: Column<any>): JSX.Element => (
      <col
        key={column.key as string}
        style={{
          width: `${(100 * (column.weight ?? 1)) / totalWeight}%`,
          minWidth: column.minWidth ? `${column.minWidth}px` : undefined,
        }}
      />
    ),
    [totalWeight]
  );

  return <colgroup>{columns.map(mapper)}</colgroup>;
};
