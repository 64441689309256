import styles from 'ComplexModals/SessionEditor/central.module.scss';
import { Section } from 'ComplexModals/SessionEditor/components/Section';
import { fields } from 'ComplexModals/SessionEditor/forms/BaseConfigurationView/fields';
import { Form, FormChangeResult } from 'components/Form';
import React from 'react';
import { SMBaseConfiguration } from 'redux/definitions/central';
import { SMConfig } from 'redux/definitions/smConfig';

interface Props {
  readonly value: SMBaseConfiguration;

  onChange(name: keyof SMConfig, value: SMBaseConfiguration): void;
}

const BaseConfigurationView: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value, onChange } = props;

  const handleChange = React.useCallback(
    (
      name: keyof SMBaseConfiguration,
      fieldValue: any,
      _: SMBaseConfiguration
    ): FormChangeResult => {
      onChange('base', { ...value, [name]: fieldValue });

      return {
        error: null,
        value: fieldValue,
      };
    },
    [onChange, value]
  );

  return (
    <Section title="Operational Hours">
      <Form className={styles.form} fields={fields} value={props.value} onChange={handleChange} />
    </Section>
  );
};

export default BaseConfigurationView;
