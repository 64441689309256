import { CenteredModal } from 'components/CenteredModal';
import { FIXMessageView } from 'components/FIXMessage';
import { FIXMessageRendererDetailsHelper } from 'components/Table/renderers/SolaceQueueMessageRenderer/FIXMessageRendererDetailsHelper';
import {
  FIXMessage,
  FIXMessageType,
} from 'components/Table/renderers/SolaceQueueMessageRenderer/fixMessageTypes';
import styles from 'components/Table/renderers/SolaceQueueMessageRenderer/solace-queue-message-renderer.module.scss';
import { FIXTaggedField } from 'interfaces/FIXTaggedField';
import React from 'react';

interface Props {
  readonly message: readonly FIXTaggedField[];
}

export const FIXMessageRenderer: React.FC<Props> = (props: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { message } = props;

  const fixMessage = React.useMemo((): FIXMessage => {
    return message.reduce(
      (fixMessage: Partial<FIXMessage>, FIXTaggedField: FIXTaggedField): Partial<FIXMessage> => ({
        ...fixMessage,
        [FIXTaggedField.label]: FIXTaggedField.value,
      }),
      {}
    ) as FIXMessage;
  }, [message]);

  const messageType = React.useMemo((): FIXMessageType => fixMessage.MsgType, [fixMessage.MsgType]);

  const iconClassName = React.useMemo((): string => {
    return [
      'fa',
      messageTypeIcons[messageType] ?? 'fa-question-circle',
      messageTypeColors[messageType],
    ].join(' ');
  }, [messageType]);

  const handleShowFIXMessage = React.useCallback((): void => {
    setIsOpen(true);
  }, []);

  const handleHideFIXMessage = React.useCallback((): void => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <div className={styles.message}>
        <div className={styles.type}>FIX</div>
        <div className={styles.fixMessageType}>
          <i className={iconClassName} />
          <span>{messageTypeText[messageType] ?? messageType}</span>
        </div>

        <FIXMessageRendererDetailsHelper
          message={fixMessage}
          onShowFIXMessage={handleShowFIXMessage}
        />
      </div>
      <CenteredModal
        open={isOpen}
        title={`Details of Execution: ${fixMessage.MsgType}`}
        onClose={handleHideFIXMessage}
      >
        <FIXMessageView message={message} />
      </CenteredModal>
    </>
  );
};

const messageTypeIcons: Record<string, string> = {
  EXECUTION_REPORT: 'fa-file-alt',
  BUSINESS_MESSAGE_REJECT: 'fa-times',
  ORDER_SINGLE: 'fa-asterisk',
};

const messageTypeColors: Record<string, string> = {
  EXECUTION_REPORT: styles.info,
  BUSINESS_MESSAGE_REJECT: styles.error,
  ORDER_SINGLE: styles.new,
};

const messageTypeText: Record<string, string> = {
  EXECUTION_REPORT: 'Execution Report',
  BUSINESS_MESSAGE_REJECT: 'Business Message Reject',
  ORDER_SINGLE: 'Order Single',
};
