import { Box, Tab, Tabs } from '@mui/material';
import {
  NetworkDetailsDataProvider,
  NetworkDetailsDataProviderContext,
} from 'ComplexModals/SessionEditor/forms/NetworkDetailsView/providerStore';
import { fields } from 'ComplexModals/SessionEditor/forms/TabbedFormField/fields';
import classes from 'ComplexModals/SessionEditor/forms/TabbedFormField/tabbedFormField.module.scss';
import { Form, FormChangeResult } from 'components/Form';
import React from 'react';
import { VPNForm } from 'redux/definitions/central';

interface TabProps {
  readonly value: string;
  readonly tabValue: string;
}

const CustomTabPanel: React.FC<React.PropsWithChildren<TabProps>> = (
  props: React.PropsWithChildren<TabProps>
): React.ReactElement => {
  const { children } = props;
  if (props.value !== props.tabValue) {
    return <></>;
  }

  return <div className={classes.tabPanel}>{children}</div>;
};

interface ValueType {
  readonly CIB: VPNForm;
  readonly DAS: VPNForm;
}

export const TabbedFormField: React.FC = (): React.ReactElement => {
  const provider = React.useContext<NetworkDetailsDataProvider>(NetworkDetailsDataProviderContext);

  const [currentTab, setCurrentTab] = React.useState<'CIB' | 'DAS'>('CIB');
  const [value, setValue] = React.useState<ValueType>({
    CIB: {} as VPNForm,
    DAS: {} as VPNForm,
  });

  const handleFieldChange = React.useCallback(
    (name: keyof VPNForm, fieldValue: any, _: VPNForm): FormChangeResult => {
      setValue(
        (currentValue: ValueType): ValueType => ({
          ...currentValue,
          [currentTab]: { ...currentValue[currentTab], [name]: fieldValue },
        })
      );
      return {} as FormChangeResult;
    },
    [currentTab]
  );

  const handleTabChange = React.useCallback(
    (_: React.SyntheticEvent, value: 'CIB' | 'DAS'): void => {
      setCurrentTab(value);
    },
    []
  );

  return (
    <Box>
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab value="CIB" label="CIB" />
        <Tab value="DAS" label="DAS" />
      </Tabs>
      <CustomTabPanel value={currentTab} tabValue="CIB">
        <Form
          component="fieldset"
          embedded={true}
          fields={fields}
          value={value.CIB}
          provider={provider}
          onChange={handleFieldChange}
        />
      </CustomTabPanel>

      <CustomTabPanel value={currentTab} tabValue="DAS">
        <Form
          component="fieldset"
          embedded={true}
          fields={fields}
          value={value.DAS}
          provider={provider}
          onChange={handleFieldChange}
        />
      </CustomTabPanel>
    </Box>
  );
};
