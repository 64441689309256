import { User } from 'auth/user';
import styles from 'components/SideBar/application-side-bar.module.scss';
import { ToolbarItem } from 'components/SideBar/components/ToolbarItem';
import { UserDropdownMenu } from 'components/SideBar/components/UserDropdownMenu';
import config from 'config';
import { useSession } from 'hooks/useSession';
import { ApplicationRouteDef } from 'interfaces/applicationRouteDef';
import React from 'react';
import { isStringEmpty } from 'ui-utils/isStringEmpty';

const fallbackLogo = '/assets/logos/cib.png';

// FIXME: user should be provided to this component instead of accessing the context
interface Props {
  readonly menu: readonly ApplicationRouteDef[];
  readonly user: User;
}

export const SideBar = (props: Props): React.ReactElement => {
  const { menu, user } = props;
  const session = useSession();

  const logoUrl = React.useMemo(
    (): string => (isStringEmpty(user.logoUrl) ? fallbackLogo : user.logoUrl),
    [user.logoUrl]
  );

  const onSignOut = (): void => {
    session
      .signOut()
      .then((): void => {
        window.location.href = config.loginUrl;
      })
      .catch(console.warn);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <img src={logoUrl} alt="customer logo" />
      </div>
      <UserDropdownMenu user={user} onSignOut={onSignOut} />
      <ul className={styles.menu}>
        {menu.map(
          (item: ApplicationRouteDef): React.ReactElement => (
            <ToolbarItem key={String(item.key)} item={item} />
          )
        )}
      </ul>
    </div>
  );
};
