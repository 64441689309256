import { Entity, Firm, FirmType, MPID, STPMsgType, STPRoute } from 'api/interfaces/firm';
import {
  CustomFieldProps,
  FieldDefinition,
  FieldType,
} from 'components/Form/components/Field/types';
import { SMSession } from 'interfaces/session';
import React from 'react';
import { BrokerageWidget } from 'routes/EntityManagement/FirmsManagement/components/BrokerageWidget';
import { EntityGroup } from 'routes/EntityManagement/FirmsManagement/components/EntityGroup';
import { EntityWidget } from 'routes/EntityManagement/FirmsManagement/components/EntityWidget';
import { ApplicationsDropdown } from 'routes/EntityManagement/FirmsManagement/components/FirmsForm/components/ApplicationsDropdown';
import { MPIDItem } from 'routes/EntityManagement/FirmsManagement/components/FirmsForm/components/MPIDItem';
import { Brokerage } from 'types/brokerage';
import { DropdownOption } from 'types/dropdownOption';
import { WhitelistItem } from 'types/whitelistItem';

const isNDForFXO = (firm: Firm): boolean => {
  const { firmCategory } = firm;
  return firmCategory.some(
    (item: DropdownOption): boolean => item.id === 'fxo' || item.id === 'ndf'
  );
};

const firmCategories = [
  {
    id: 'fxo',
    name: 'FXO',
  },
  {
    id: 'ndf',
    name: 'NDF',
  },
];

const fields: ReadonlyArray<FieldDefinition<Firm, readonly SMSession[]>> = [
  {
    name: 'basicInformation',
    fieldType: FieldType.group,
    display: true,
    children: [
      {
        name: 'basicInformation',
        fieldType: FieldType.fieldset,
        label: 'Basic Information',
        display: true,
        children: [
          {
            name: 'firmLabel',
            fieldType: FieldType.text,
            label: 'Firm Name',
            display: true,
            required: true,
          },
          {
            name: 'firmType',
            fieldType: FieldType.dropdown,
            label: 'Type',
            options: Object.values(FirmType).map(
              (value: string): DropdownOption => ({ name: value, id: value })
            ),
            display: true,
            required: true,
          },
          {
            name: 'firmCategory',
            label: 'Firm Type',
            fieldType: FieldType.multiBool,
            required: (firm: Firm): boolean => {
              switch (firm.firmType) {
                case FirmType.Bank:
                case FirmType.Broker:
                case FirmType.STP:
                case FirmType.SEF:
                  return true;
                case FirmType.Connectivity:
                default:
                  return false;
              }
            },
            display: (firm: Firm): boolean => {
              switch (firm.firmType) {
                case FirmType.Bank:
                case FirmType.Broker:
                case FirmType.STP:
                case FirmType.SEF:
                  return true;
                case FirmType.Connectivity:
                default:
                  return false;
              }
            },
            options: firmCategories,
          },
        ],
      },
    ],
  },
  {
    name: 'addressesAndPhoneNumbers',
    fieldType: FieldType.group,
    display: true,
    children: [
      {
        name: 'addresses',
        fieldType: FieldType.fieldset,
        label: 'Business Address',
        display: true,
        children: [
          {
            name: 'businessAddress',
            fieldType: FieldType.longText,
            label: 'Business Address',
            display: true,
            required: false,
          },
        ],
      },
      {
        name: 'phoneNumbers',
        fieldType: FieldType.fieldset,
        label: 'Phone Numbers',
        display: true,
        children: [
          {
            name: 'workPhone',
            fieldType: FieldType.phone,
            label: 'Work Phone',
            display: true,
            required: false,
          },
          {
            name: 'cellPhone',
            fieldType: FieldType.phone,
            label: 'Cell Phone',
            display: true,
            required: false,
          },
        ],
      },
    ],
  },
  {
    fieldType: FieldType.fieldset,
    label: 'Route Information',
    name: 'route',
    display: (firm: Firm): boolean => {
      return (
        firm.firmType !== FirmType.Broker && (isNDForFXO(firm) || firm.firmType === FirmType.STP)
      );
    },
    children: [
      {
        name: 'STPRoute',
        fieldType: FieldType.dropdown,
        label: 'STP Route',
        options: Object.values(STPRoute).map(
          (value: string): DropdownOption => ({ name: value, id: value })
        ),
        display: true,
        required: (firm: Firm): boolean =>
          firm.firmType !== FirmType.Broker && (isNDForFXO(firm) || firm.firmType === FirmType.STP),
      },
      {
        name: 'STPMsgType',
        fieldType: FieldType.dropdown,
        label: 'STP Message Type',
        options: Object.values(STPMsgType).map(
          (value: string): DropdownOption => ({ name: value, id: value })
        ),
        display: (firm: Firm): boolean =>
          firm.firmType !== FirmType.Broker && firm.STPRoute === STPRoute.DIRECT,
        required: (firm: Firm): boolean =>
          firm.firmType !== FirmType.Broker && firm.STPRoute === STPRoute.DIRECT,
      },
      {
        name: 'STPDestination',
        fieldType: FieldType.text,
        label: 'STP Destination',
        display: (firm: Firm): boolean =>
          firm.firmType === FirmType.SEF ||
          firm.firmType === FirmType.STP ||
          firm.STPRoute === STPRoute.DIRECT,
        required: (firm: Firm): boolean =>
          firm.firmType === FirmType.SEF ||
          firm.firmType === FirmType.STP ||
          firm.STPRoute === STPRoute.DIRECT,
      },
    ],
  },
  {
    name: 'MPIDS',
    fieldType: FieldType.array,
    label: 'MPIDs',
    display: true,
    required: true,
    fields: [
      {
        name: 'mpid',
        fieldType: FieldType.text,
        label: 'MPID',
        required: true,
        display: true,
      },
      {
        name: 'appid',
        label: 'Application',
        fieldType: FieldType.custom,
        required: true,
        display: true,
        component: ApplicationsDropdown,
      },
    ],
    render: (value: MPID): React.ReactElement => {
      return <MPIDItem mpid={value} />;
    },
  },
  {
    name: 'sessionIds',
    label: 'Session IDs',
    fieldType: FieldType.multiBool,
    display: (firm: Firm): boolean => firm.firmType === FirmType.Connectivity,
    required: (firm: Firm): boolean => firm.firmType === FirmType.Connectivity,
    options: (_: any, sessions: readonly SMSession[]): readonly DropdownOption[] => {
      return sessions.map(
        (session: SMSession): DropdownOption => ({
          id: session.id,
          name: session.id,
        })
      );
    },
  },
  {
    name: 'whitelist',
    fieldType: FieldType.array,
    label: 'Whitelisted IPs',
    display: (firm: Firm): boolean => isNDForFXO(firm),
    render: (value: WhitelistItem): React.ReactNode => {
      return <div>{value.ipAddress}</div>;
    },
    required: false,
    fields: [
      {
        name: 'ipAddress',
        fieldType: FieldType.ipAddress,
        label: 'IP Address',
        display: true,
        required: true,
      },
    ],
  },
  {
    name: 'entities',
    fieldType: FieldType.array,
    label: 'Entities',
    display: (firm: Firm): boolean =>
      isNDForFXO(firm) && firm.firmType !== FirmType.STP && firm.firmType !== FirmType.SEF,
    container: EntityGroup,
    render: (value: Entity): React.ReactNode => <EntityWidget entity={value} />,
    required: false,
    fields: [
      {
        name: 'legalName',
        fieldType: FieldType.text,
        label: 'Legal Name',
        display: true,
        required: true,
      },
      {
        name: 'businessAddress',
        fieldType: FieldType.longText,
        label: 'Address',
        display: true,
        required: false,
      },
      {
        name: 'workPhone',
        fieldType: FieldType.phone,
        label: 'Phone Number',
        display: true,
        required: false,
      },
      {
        name: 'isNDF',
        fieldType: FieldType.bool,
        label: 'NDF',
        display: true,
        required: false,
      },
      {
        name: 'isFXO',
        fieldType: FieldType.bool,
        label: 'FXO',
        display: true,
        required: false,
      },
      {
        name: 'orgCode',
        fieldType: FieldType.text,
        label: 'Organization Code',
        display: true,
        required: true,
      },
      {
        name: 'tradingEntityCode',
        fieldType: FieldType.text,
        label: 'Trading Entity Code',
        display: true,
        required: true,
      },
      {
        name: 'legalEntityID',
        fieldType: FieldType.text,
        label: 'Legal Entity ID',
        display: true,
        required: true,
      },
      {
        name: 'MSEntityID',
        fieldType: FieldType.text,
        label: 'MS Entity ID',
        display: true,
        required: false,
      },
      {
        name: 'MSBranchID',
        fieldType: FieldType.text,
        label: 'MS Branch ID',
        display: true,
        required: false,
      },
      {
        name: 'TRTNEntityID',
        fieldType: FieldType.text,
        label: 'TRTN Entity ID',
        display: true,
        required: false,
      },
      {
        name: 'UBSEntityID',
        fieldType: FieldType.text,
        label: 'UBS EntityID',
        display: true,
        required: false,
      },
      {
        name: 'MSCOEntityID',
        fieldType: FieldType.text,
        label: 'MSCO Entity ID',
        display: true,
        required: false,
      },
      {
        name: 'SANTEntityID',
        fieldType: FieldType.text,
        label: 'SANT Entity ID',
        display: true,
        required: false,
      },
      {
        name: 'brokerOfCredit',
        fieldType: FieldType.email,
        label: 'Broker Of Credit',
        display: true,
        required: true,
      },
    ],
  },

  {
    name: 'brokerage',
    fieldType: FieldType.fieldset,
    display: (firm: Firm): boolean => {
      return isNDForFXO(firm) && firm.firmType !== FirmType.STP && firm.firmType !== FirmType.SEF;
    },
    label: 'Brokerage',
    children: [
      {
        name: 'brokerage',
        fieldType: FieldType.custom,
        required: false,
        display: true,
        component: (props: CustomFieldProps<Brokerage>): React.ReactElement => {
          return <BrokerageWidget brokerage={props.value} onChange={props.onChange} />;
        },
        isValid: (_: Brokerage): boolean => true,
      },
    ],
  },
];

export default fields;
