import { Api } from 'api';
import { AnyAction } from 'redux';
import { AsyncAction, createAsyncAction } from 'redux/asyncAction';
import {
  fetchLogsCompleted,
  fetchLogsFailed,
  fetchLogsStarted,
} from 'redux/reducers/logsBlotterReducer';
import { SolaceLogEntry } from 'types/solace';

export interface FetchErrorsParameters {
  readonly sessionId?: string;
}

async function* fetchErrors(api: Api, signal: AbortSignal): AsyncGenerator<AnyAction> {
  yield fetchLogsStarted();

  try {
    const solaceLogs = await api.getSolaceLogs(signal);
    yield fetchLogsCompleted(solaceLogs.map(SolaceLogEntry.fromRaw));
  } catch (error: any) {
    yield fetchLogsFailed(error.message);
  }
}

export const fetchErrorsAction = (): AsyncAction => createAsyncAction(fetchErrors);
