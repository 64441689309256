import React from 'react';
import { BrokerageField } from 'routes/EntityManagement/FirmsManagement/components/BrokerageField';
import styles from 'routes/EntityManagement/FirmsManagement/components/BrokerageWidget/brokerage.module.scss';
import { Brokerage, BrokerageItem } from 'types/brokerage';

interface Props {
  readonly brokerage: Brokerage;

  onChange(value: Brokerage): void;
}

type SingleArgFn<T, R> = (arg: T) => R;

export const BrokerageWidget: React.FC<Props> = (props: Props): React.ReactElement => {
  const { brokerage } = props;
  const { onChange } = props;

  const onItemChange = React.useCallback(
    (key: keyof Brokerage): SingleArgFn<BrokerageItem, void> =>
      (value: BrokerageItem): void => {
        onChange({ ...brokerage, [key]: value });
      },
    [brokerage, onChange]
  );

  return (
    <fieldset className={styles.container}>
      <BrokerageField label="LATAM" brokerage={brokerage.latam} onChange={onItemChange('latam')} />
      <BrokerageField label="G10" brokerage={brokerage.g10} onChange={onItemChange('g10')} />
      <BrokerageField label="ASIA" brokerage={brokerage.asia} onChange={onItemChange('asia')} />
      <BrokerageField
        label="CEEMEA"
        brokerage={brokerage.ceemea}
        onChange={onItemChange('ceemea')}
      />
      <BrokerageField label="PM" brokerage={brokerage.pm} onChange={onItemChange('pm')} />
    </fieldset>
  );
};
