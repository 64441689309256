import { configureStore } from '@reduxjs/toolkit';
import { Api } from 'api';
import { combineReducers, Store } from 'redux';
import { ApplicationState } from 'redux/applicationState';
import { asyncMiddleware } from 'redux/asyncMiddleware';
import currentUserReducer from 'redux/reducers/currentUserReducer';
import dealsBlotterReducer from 'redux/reducers/dealsBlotterReducer';
import executionsReducer from 'redux/reducers/executionsReducer';
import firmsReducer from 'redux/reducers/firmsReducer';
import flowsReducer from 'redux/reducers/flowsReducer';
import errorsBlotterReducer from 'redux/reducers/logsBlotterReducer';
import messagesReducer from 'redux/reducers/messagesReducer';
import referenceDataReducer from 'redux/reducers/referenceDataReducer';
import riskReducer from 'redux/reducers/riskReducer';
import sessionsReducer from 'redux/reducers/sessionsReducer';
import smConfigReducer from 'redux/reducers/smConfigReducer';
import solaceQueueReducer from 'redux/reducers/solaceQueueReducer';
import statusBarReducer from 'redux/reducers/statusBarReducer';
import usersFormReducer from 'redux/reducers/usersFormReducer';
import usersReducer from 'redux/reducers/usersReducer';
import whitelistReducer from 'redux/reducers/whitelistReducer';
import whoIsLoggedInReducer from 'redux/reducers/whoIsLoggedInReducer';
import thunk from 'redux-thunk';

const createPrismStore = (api: Api): Store =>
  configureStore({
    reducer: combineReducers<ApplicationState>({
      flows: flowsReducer,
      sessions: sessionsReducer,
      messages: messagesReducer,
      statusBar: statusBarReducer,
      whitelist: whitelistReducer,
      whoIsLoggedIn: whoIsLoggedInReducer,
      currentUser: currentUserReducer,
      firms: firmsReducer,
      users: usersReducer,
      usersForm: usersFormReducer,
      risk: riskReducer,
      smConfig: smConfigReducer,
      referenceData: referenceDataReducer,
      solaceQueue: solaceQueueReducer,
      errorsBlotter: errorsBlotterReducer,
      dealsBlotter: dealsBlotterReducer,
      executions: executionsReducer,
    }),
    middleware: [
      // Temporarily disable this
      // createImmutableStateInvariantMiddleware(),
      asyncMiddleware(api),
      thunk.withExtraArgument(api),
    ],
  });

export default createPrismStore;
