import { User } from 'auth/user';
import BaseConfigurationView from 'ComplexModals/SessionEditor/forms/BaseConfigurationView';
import DatabaseDetailsView from 'ComplexModals/SessionEditor/forms/DatabaseDetailsView';
import FIXSessionDetailsView from 'ComplexModals/SessionEditor/forms/FIXSessionDetailsView';
import LogControlView from 'ComplexModals/SessionEditor/forms/LogControlView';
import NetworkDetailsView from 'ComplexModals/SessionEditor/forms/NetworkDetailsView';
import SMFeaturesView from 'ComplexModals/SessionEditor/forms/SMFeaturesView';
import SolaceDetailsView from 'ComplexModals/SessionEditor/forms/SolaceDetailsView';
import {
  CENTRAL_DATABASE_DETAILS,
  CENTRAL_FIX_SESSION_BASE,
  CENTRAL_FIX_SESSION_DETAILS,
  CENTRAL_LOG_CONTROL,
  CENTRAL_NETWORK_DETAILS,
  CENTRAL_SM_FEATURE,
  CENTRAL_SOLACE_DETAILS,
} from 'ComplexModals/SessionEditor/routes';
import { ApplicationRouteDef } from 'interfaces/applicationRouteDef';
import { SMConfig } from 'redux/definitions/smConfig';

export const createMenu: (config: SMConfig) => ReadonlyArray<ApplicationRouteDef<SMConfig>> = (
  config: SMConfig
): ReadonlyArray<ApplicationRouteDef<SMConfig>> => [
  {
    label: 'Main',
    path: CENTRAL_FIX_SESSION_BASE,
    component: BaseConfigurationView,
    key: 'base',
    enabled: true,
    available: (_: User): boolean => true,
    exact: true,
    icon: 'cogs',
  },
  {
    label: 'FIX Session Details',
    path: CENTRAL_FIX_SESSION_DETAILS,
    component: FIXSessionDetailsView,
    key: 'fixSessionDetails',
    enabled: true,
    available: (_: User): boolean => true,
    exact: true,
    icon: 'folder',
  },
  {
    label: 'Network Details',
    path: CENTRAL_NETWORK_DETAILS,
    component: NetworkDetailsView,
    key: 'network',
    enabled: config.base.connectionType !== 'acceptor',
    available: (_: User): boolean => true,
    exact: true,
    icon: 'network-wired',
  },
  {
    label: 'Solace Details',
    path: CENTRAL_SOLACE_DETAILS,
    component: SolaceDetailsView,
    key: 'solace',
    enabled: true,
    available: (_: User): boolean => true,
    exact: true,
    icon: 'envelope',
  },
  {
    label: 'SM Features',
    path: CENTRAL_SM_FEATURE,
    component: SMFeaturesView,
    key: 'features',
    enabled: true,
    available: (_: User): boolean => true,
    exact: true,
    icon: 'toolbox',
  },
  {
    label: 'Database Details',
    path: CENTRAL_DATABASE_DETAILS,
    component: DatabaseDetailsView,
    key: 'databaseDetails',
    enabled: true,
    available: (_: User): boolean => true,
    exact: true,
    icon: 'database',
  },
  {
    label: 'Log Control',
    path: CENTRAL_LOG_CONTROL,
    component: LogControlView,
    key: 'logControl',
    enabled: true,
    available: (_: User): boolean => true,
    exact: true,
    icon: 'list',
  },
];
