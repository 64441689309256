import { BackendFirm, BrokerageLimit, FirmPayload } from 'api/interfaces/firm';

export interface BrokerageItem {
  readonly enabled: boolean;
  readonly top: number | null;
  readonly floor: number | null;
}

export interface Brokerage {
  readonly latam: BrokerageItem;
  readonly asia: BrokerageItem;
  readonly ceemea: BrokerageItem;
  readonly g10: BrokerageItem;
  readonly pm: BrokerageItem;
}

export class Brokerage {
  public static empty(): Brokerage {
    return {
      latam: {
        enabled: false,
        top: null,
        floor: null,
      },
      asia: {
        enabled: false,
        top: null,
        floor: null,
      },
      ceemea: {
        enabled: false,
        top: null,
        floor: null,
      },
      g10: {
        enabled: false,
        top: null,
        floor: null,
      },
      pm: {
        enabled: false,
        top: null,
        floor: null,
      },
    };
  }

  private static fromLimits(floor?: BrokerageLimit, top?: BrokerageLimit): Brokerage {
    function getBrokerageLimitGroup(floor: number | undefined, top: number | undefined) {
      return {
        floor: floor === -1 || floor === undefined || floor === null ? null : floor,
        top: top === -1 || top === undefined || top === null ? null : top,
        enabled: !(
          (floor === -1 || floor === null || floor === undefined) &&
          (top === -1 || top === null || top === undefined)
        ),
      };
    }

    return {
      latam: getBrokerageLimitGroup(floor?.latam, top?.latam),
      asia: getBrokerageLimitGroup(floor?.asia, top?.asia),
      g10: getBrokerageLimitGroup(floor?.g10, top?.g10),
      ceemea: getBrokerageLimitGroup(floor?.ceemea, top?.ceemea),
      pm: getBrokerageLimitGroup(floor?.pm, top?.pm),
    };
  }

  public static fromFirm(firm: BackendFirm): Brokerage {
    // NOTE: this is one of the crazy things
    const floor = firm.BrokerageFloor?.[0];
    const top = firm.BrokerageTop?.[0];

    return Brokerage.fromLimits(floor, top);
  }

  public static fromPayload(firm: FirmPayload): Brokerage {
    // NOTE: this is one of the crazy things
    const floor = firm.BrokerageFloor;
    const top = firm.BrokerageTop;

    return Brokerage.fromLimits(floor, top);
  }
}

export const brokerageTop = (brokerage: Brokerage): BrokerageLimit => {
  const { latam, asia, g10, ceemea, pm } = brokerage;

  return {
    ...(latam.enabled ? { latam: latam.top } : { latam: -1 }),
    ...(asia.enabled ? { asia: asia.top } : { asia: -1 }),
    ...(g10.enabled ? { g10: g10.top } : { g10: -1 }),
    ...(ceemea.enabled ? { ceemea: ceemea.top } : { ceemea: -1 }),
    ...(pm.enabled ? { pm: pm.top } : { pm: -1 }),
  };
};

export const brokerageFloor = (brokerage: Brokerage): BrokerageLimit => {
  const { latam, asia, g10, ceemea, pm } = brokerage;

  return {
    ...(latam.enabled ? { latam: latam.floor } : { latam: -1 }),
    ...(asia.enabled ? { asia: asia.floor } : { asia: -1 }),
    ...(g10.enabled ? { g10: g10.floor } : { g10: -1 }),
    ...(ceemea.enabled ? { ceemea: ceemea.floor } : { ceemea: -1 }),
    ...(pm.enabled ? { pm: pm.floor } : { pm: -1 }),
  };
};

export const isValidBrokerageItem = (item: BrokerageItem): string | null => {
  if (item.enabled === false) {
    return null;
  }

  if (item.top === null || item.floor === null) {
    return 'You must set top and floor';
  }

  return item.floor > item.top ? 'Top must be greater than floor' : null;
};
