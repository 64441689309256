import { CRUDButtons } from 'components/CRUDButtons';
import { Alignment, Column, ColumnType } from 'components/Table/types/column';
import { SymbolTableRow } from 'types/risk';

export const columns: Array<Column<SymbolTableRow>> = [
  {
    minWidth: 48,
    weight: 0.0001,
    columnType: ColumnType.custom,
    key: 'id',
    filterable: false,
    header: '',
    textuallyOverlap(value: any, keyword: string): boolean {
      return value.includes(keyword);
    },
    sortingDifference(v1: any, v2: any): number {
      return v1.localeCompare(v2);
    },
    component: CRUDButtons,
  },
  {
    key: 'account',
    columnType: ColumnType.text,
    header: 'Account',
    weight: 1,
    alignment: Alignment.left,
    editable: true,
    filterable: true,
    sortingDifference(v1: string, v2: string): number {
      return v1.localeCompare(v2);
    },
    textuallyOverlap(value: string, keyword: string): boolean {
      return value.includes(keyword);
    },
  },
  {
    key: 'symbol',
    columnType: ColumnType.text,
    alignment: Alignment.center,
    header: 'Symbol',
    editable: true,
    filterable: true,
    textuallyOverlap(value: any, keyword: string): boolean {
      return value.includes(keyword);
    },
    sortingDifference(v1: any, v2: any): number {
      return v1.localeCompare(v2);
    },
  },
  {
    key: 'maxqty',
    columnType: ColumnType.integer,
    alignment: Alignment.center,
    header: 'Max Qty',
    editable: true,
    textuallyOverlap(value: any, keyword: string): boolean {
      return String(value).includes(keyword);
    },
    sortingDifference(v1: any, v2: any): number {
      return Number(v1) - Number(v2);
    },
  },
  {
    key: 'maxpercentaway',
    columnType: ColumnType.integer,
    alignment: Alignment.center,
    header: 'Max Price % Away',
    editable: true,
    textuallyOverlap(value: any, keyword: string): boolean {
      return String(value).includes(keyword);
    },
    sortingDifference(v1: any, v2: any): number {
      return Number(v1) - Number(v2);
    },
  },
  {
    key: 'maxnotional',
    columnType: ColumnType.integer,
    alignment: Alignment.center,
    header: 'Max Notional',
    editable: true,
    textuallyOverlap(value: any, keyword: string): boolean {
      return String(value).includes(keyword);
    },
    sortingDifference(v1: any, v2: any): number {
      return Number(v1) - Number(v2);
    },
  },
];
