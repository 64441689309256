import { FormatFn, FormatterOptions } from 'components/Table/types/column';

export const formatIndexedObject: FormatFn<any> = (
  index: string | number,
  options?: FormatterOptions
): string => {
  const value = options?.data[index];
  if (!value) {
    if (options?.defaultValue === null) {
      return index?.toString();
    } else if (options?.defaultValue === undefined) {
      return '';
    } else {
      return '';
    }
  }

  return value;
};
