import { RadioGroup } from '@mui/material';
import { Entity } from 'api/interfaces/firm';
import React from 'react';

interface Props {
  readonly array: readonly Entity[];

  onChange(value: readonly Entity[]): void;
}

export const EntityGroup: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>
): React.ReactElement => {
  const { onChange } = props;
  const { array, children } = props;

  const current = React.useMemo(
    (): Entity | undefined =>
      Array.isArray(array)
        ? array.find((entity: Entity): boolean => entity.default === 1)
        : undefined,
    [array]
  );

  const onChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    onChange(
      array.map((entity: Entity): Entity => {
        if (entity.legalEntityID === value) {
          return { ...entity, default: 1 };
        } else {
          return { ...entity, default: 0 };
        }
      })
    );
  };

  return (
    <RadioGroup value={current?.legalEntityID ?? ''} onChange={onChangeWrapper}>
      {children}
    </RadioGroup>
  );
};
