const messageTypes: Record<string, string> = {
  '0': 'Heartbeat (0)',
  '1': 'Test Request (1)',
  '2': 'Resend Request (2)',
  '3': 'Reject (3)',
  '4': 'Sequence Reset (4)',
  '5': 'Logout (5)',
  '6': 'Indication of Interest (6)',
  '7': 'Advertisement (7)',
  '8': 'Execution Report (8)',
  '9': 'Order Cancel Reject (9)',
  A: 'Logon (A)',
  B: 'News (B)',
  C: 'Email (C)',
  D: 'New Order - Single (D)',
  E: 'New Order - List (E)',
  F: 'Order Cancel Request (F)',
  G: 'Order Cancel/Replace Request (G)',
  H: 'Order Status Request (H)',
  J: 'Allocation (J)',
  K: 'List Cancel Request (K)',
  L: 'List Execute (L)',
  M: 'List Status Request (M)',
  N: 'List Status (N)',
  P: 'Allocation ACK (P)',
  Q: "Don't Know Trade (Q)",
  R: 'Quote Request (R)',
  S: 'Quote (S)',
  T: 'Settlement Instructions (T)',
  V: 'Market Data Request (V)',
  W: 'Market Data - Snapshot/Full Refresh (W)',
  X: 'Market Data - Incremental Refresh (X)',
  Y: 'Market Data Request Reject (Y)',
  Z: 'Quote Cancel (Z)',
  a: 'Quote Status Request (a)',
  b: 'Quote Acknowledgement (b)',
  c: 'Security Definition Request (c)',
  d: 'Security Definition (d)',
  e: 'Security Status Request (e)',
  f: 'Security Status (f)',
  g: 'Trading Session Status Request (g)',
  h: 'Trading Session Status (h)',
  i: 'Mass Quote (i)',
  j: 'Business Message Reject (j)',
  k: 'Bid Request (k)',
  l: 'Bid Response (l)',
  m: 'List Strike Price (m)',
  AB: 'New Order - Multi (AB)',
};

export default messageTypes;
