import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from 'config';
import { ProcessingState } from 'enums/processingState';
import deepEqual from 'fast-deep-equal';
import { ApplicationState } from 'redux/applicationState';
import { SMConfig } from 'redux/definitions/smConfig';

export const emptySmConfiguration = SMConfig.initial();

export enum ProcessingOperationType {
  loading,
  updating,
  importing,
}

export interface SolaceServer {
  readonly routername: string;
}

export interface SMConfigState {
  value: SMConfig;
  processingState: ProcessingState;
  previousValue: SMConfig;
  solaceServers: readonly SolaceServer[];
}

const initialState: SMConfigState = {
  value: emptySmConfiguration,
  processingState: ProcessingState.idle(),
  previousValue: emptySmConfiguration,
  solaceServers: [],
};

const slice = createSlice({
  name: 'sm-config',
  initialState: initialState,
  reducers: {
    setProcessingState: (state: SMConfigState, action: PayloadAction<ProcessingState>): void => {
      state.processingState = action.payload;
    },
    saveSMConfigStart: (state: SMConfigState): void => {
      state.processingState = ProcessingState.processing(ProcessingOperationType.updating);
    },
    saveSMConfigStopWithSuccess: (state: SMConfigState): void => {
      state.processingState = ProcessingState.success();
    },
    saveSMConfigStopWithError: (state: SMConfigState, action: PayloadAction<string>): void => {
      state.processingState = ProcessingState.error(action.payload);
    },
    setSMConfig: (state: SMConfigState, action: PayloadAction<SMConfig>): void => {
      state.value = action.payload;
      state.previousValue = action.payload;
    },
    resetProcessingState: (state: SMConfigState): void => {
      state.processingState = ProcessingState.idle();
    },
    resetAll: (state: SMConfigState): void => {
      state.value = emptySmConfiguration;
      state.processingState = ProcessingState.idle();
      state.previousValue = emptySmConfiguration;
    },
    setSolaceServers: (
      state: SMConfigState,
      action: PayloadAction<readonly SolaceServer[]>
    ): void => {
      state.solaceServers = action.payload;
    },
    updateSection: (
      state: SMConfigState,
      action: PayloadAction<{
        readonly name: keyof SMConfig;
        readonly section: any;
      }>
    ): void => {
      const { fixSessionDetails, solace, base, logControl } = state.value;
      const { name, section } = action.payload;

      switch (name) {
        case 'fixSessionDetails':
          if (section.fixVersion !== fixSessionDetails.fixVersion) {
            const { fixVersion } = section;
            const homePath = '$HOME';
            const logFileName =
              base.targetComp !== '' && base.senderComp !== ''
                ? `${section.fixVersion}-${base.senderComp}-${base.targetComp}.log`
                : logControl.fileName;

            state.value = {
              ...state.value,
              fixSessionDetails: {
                ...section,
                dataDictionary: `${homePath}/dict/${fixVersion.replaceAll('.', '')}.xml`,
              },
              logControl: {
                ...logControl,
                fileName: logFileName,
              },
            };
          } else {
            state.value = { ...state.value, [name]: section };
          }
          break;
        case 'solace':
          if (solace.solaceClientId !== section.solaceClientId) {
            const solaceClientId = section.solaceClientId;

            const homePath = '$HOME';
            state.value = {
              ...state.value,
              fixSessionDetails: {
                ...fixSessionDetails,
                rulesInModule: `rules_inbound_${base.senderComp}_${base.targetComp}.so`,
                rulesOutModule: `rules_outbound_${base.senderComp}_${base.targetComp}.so`,
                persistPath: `${homePath}/config/${solaceClientId}/store`,
                fileStorePath: `${homePath}/config/${solaceClientId}/store`,
                rulesPath: `${homePath}/config/${solaceClientId}`,
              },
              solace: {
                ...section,
                listenTopic: `${config.environment}/*/*/NYC/${solaceClientId}`,
                adminTopic: `cfg/NYC/${solaceClientId}`,
              },
            };
          } else {
            state.value = { ...state.value, [name]: section };
          }
          break;

        case 'base':
          if (
            section.senderComp !== '' &&
            section.targetComp !== '' &&
            (section.senderComp !== base.senderComp || section.targetComp != base.targetComp)
          ) {
            const solaceClientId = `${section.senderComp}_${section.targetComp}`;
            const homePath = '$HOME';
            const logFileName =
              fixSessionDetails.fixVersion !== null
                ? `${fixSessionDetails.fixVersion}-${section.senderComp}-${section.targetComp}.log`
                : logControl.fileName;

            state.value = {
              ...state.value,
              base: section,
              fixSessionDetails: {
                ...fixSessionDetails,
                rulesInModule: `rules_inbound_${base.senderComp}_${base.targetComp}.so`,
                rulesOutModule: `rules_outbound_${base.senderComp}_${base.targetComp}.so`,
                persistPath: `${homePath}/config/${solaceClientId}/store`,
                fileStorePath: `${homePath}/config/${solaceClientId}/store`,
                rulesPath: `${homePath}/config/${solaceClientId}`,
              },
              solace: {
                ...solace,
                solaceClientId: solaceClientId,
                listenTopic: `${config.environment}/*/*/NYC/${solaceClientId}`,
                adminTopic: `cfg/NYC/${solaceClientId}`,
                heartbeatTopic: `${config.environment}/heartHeatTopic`,
              },
              logControl: {
                ...logControl,
                fileName: logFileName,
              },
            };
          } else if (!deepEqual(section.end, base.end)) {
            const { time, dayOfWeek } = section.end;
            const clonedTime = time.clone();
            const newTime = clonedTime.add(5, 'minutes');
            const newDayOfWeek =
              newTime.dayOfWeek > time.dayOfWeek
                ? dayOfWeek + 1 > 6
                  ? 0
                  : dayOfWeek + 1
                : dayOfWeek;

            state.value = {
              ...state.value,
              [name]: section,
              logControl: {
                ...logControl,
                dayTimeToRotate: {
                  time: newTime,
                  dayOfWeek: newDayOfWeek,
                },
              },
            };
          } else {
            state.value = { ...state.value, [name]: section };
          }
          break;

        default:
          state.value = { ...state.value, [name]: section };
      }
    },
  },
});

export default slice.reducer;

export const smConfigSelector = (state: ApplicationState): SMConfig => state.smConfig.value;

export const processingStateSelector = (state: ApplicationState): ProcessingState =>
  state.smConfig.processingState;

export const smModifiedSelector = (state: ApplicationState): boolean =>
  !deepEqual(state.smConfig.value, state.smConfig.previousValue);

export const solaceServersSelector = (state: ApplicationState): readonly SolaceServer[] =>
  state.smConfig.solaceServers;

export const {
  setSMConfig,
  saveSMConfigStopWithError,
  saveSMConfigStopWithSuccess,
  saveSMConfigStart,
  resetProcessingState,
  updateSection,
  setProcessingState,
  resetAll,
  setSolaceServers,
} = slice.actions;
