import { SMSessionDTO } from 'api/interfaces/snapshot';
import { ProcessingState } from 'enums/processingState';
import { Status } from 'enums/status';
import { SMConfig } from 'redux/definitions/smConfig';

export interface SMSession {
  readonly id: string;
  readonly lastLogon: number;
  readonly maxQueueSizeMB: number;
  readonly queueSize: number;
  readonly sessionID: string;
  readonly status: Status;
  readonly statusText: string;

  readonly inSequence: number;
  readonly outSequence: number;

  readonly processingState: ProcessingState;
}

export class SMSession {
  public static fromConfig(config: SMConfig): SMSession {
    const { solace } = config;
    return {
      id: solace.solaceClientId,
      lastLogon: 0,
      maxQueueSizeMB: 0,
      queueSize: 0,
      sessionID: solace.solaceClientId,
      status: Status.None,
      statusText: '',
      inSequence: 0,
      outSequence: 0,
      processingState: ProcessingState.idle(),
    };
  }

  public static fromSession(session: SMSessionDTO): SMSession {
    return {
      id: session.SessionID,
      lastLogon: session.LastLogon,
      maxQueueSizeMB: session.MaxQueueSizeMB,
      queueSize: session.QueueSize,
      sessionID: session.SessionID,
      status: session.Status,
      statusText: session.StatusText,
      inSequence: 0,
      outSequence: 0,
      processingState: ProcessingState.idle(),
    };
  }

  public static empty(): SMSession {
    return emptySession;
  }
}

const emptySession: SMSession = {
  id: '',
  lastLogon: 0,
  maxQueueSizeMB: 0,
  queueSize: 0,
  sessionID: '',
  status: Status.None,
  statusText: '',
  inSequence: 0,
  outSequence: 0,
  processingState: ProcessingState.idle(),
};
