import { toClassName } from 'helpers/toClassName';
import React from 'react';
import styles from 'routes/ReportsRoute/WhoIsLoggedIn/who-is-logged-in.module.scss';

export enum Status {
  OktaLoggedIn,
  LoggedIn,
  NotLoggedIn,
}

interface Props {
  readonly value: boolean;
}

export const LoggedInStatusCell: React.FC<Props> = (props: Props): React.ReactElement => {
  return (
    <div className={styles.connectionStatus}>
      <div className={toClassName({ [styles.active]: props.value })}>
        {props.value ? <i className="fa fa-check " /> : <i className="fa fa-times" />}
      </div>
    </div>
  );
};
