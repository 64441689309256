import { formatDate, formatDateTime, formatTime } from 'components/FIXMessage/helpers';
import dictionary, { FIXEnum, FIXType } from 'data/fixDictionary';
import { FIXTaggedField } from 'interfaces/FIXTaggedField';

const NoLegs = 555;

export class FIXTagValue {
  constructor(tag: number, value: any) {
    this.tag = tag;
    this.value = value;
  }

  public static fromString(input: string): FIXTagValue {
    const parts = input.split('=');
    const tag = Number(parts[0]);
    const value = parts[1];

    return new FIXTagValue(tag, value);
  }

  public toField(): FIXTaggedField {
    const { tag } = this;
    const spec = dictionary[tag];
    const enumValue: FIXEnum = spec?.enum?.find((e) => e.value === this.value);
    const value = enumValue ? enumValue.description : this.value;
    switch (spec?.type) {
      case FIXType.Amt:
        return {
          label: spec?.name ?? 'Amt',
          value: value,
          type: 'string',
          tag: tag,
        };
      case FIXType.Currency:
        return {
          label: spec?.name ?? 'Currency',
          value: value,
          type: 'string',
          tag: tag,
        };
      case FIXType.Price:
        return {
          label: spec?.name ?? 'Price',
          value: Number(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 3,
            // FIXME: should use the message currency somehow
            currency: 'USD',
            style: 'currency',
          }),
          type: 'number',
          tag: tag,
        };
      case FIXType.PriceOffset:
        return {
          label: spec?.name ?? 'Price Offset',
          value: value,
          type: 'number',
          tag: tag,
        };
      case FIXType.Quantity:
        return {
          label: spec?.name ?? 'Quantity',
          value: value,
          type: 'number',
          tag: tag,
        };
      case FIXType.Timestamp:
        return {
          label: spec?.name ?? 'Timestamp',
          value: formatDateTime(value),
          type: 'string',
          tag: tag,
        };
      case FIXType.LocalDate:
        return {
          label: spec?.name ?? 'LocalDate',
          value: formatDate(value),
          type: 'date',
          tag: tag,
        };
      case FIXType.Data:
        return {
          label: spec?.name ?? 'Data',
          value: value,
          type: 'string',
          tag: tag,
        };
      case FIXType.MultipleValueString:
        return {
          label: spec?.name ?? 'Multiple Value String',
          value: value,
          type: 'string',
          tag: tag,
        };
      case FIXType.UTCTimeOnly:
        return {
          label: spec?.name ?? 'UTC Time Only',
          value: formatTime(value, true),
          type: 'string',
          tag: tag,
        };
      case FIXType.UTCDate:
        return {
          label: spec?.name ?? 'UTC Date',
          value: formatDateTime(value, true),
          type: 'date',
          tag: tag,
        };
      case FIXType.Exchange:
        return {
          label: spec?.name ?? 'Exchange',
          value: value,
          type: 'string',
          tag: tag,
        };
      case FIXType.DayOfMonth:
        return {
          label: spec?.name ?? 'DayOfMonth',
          value: value,
          type: 'string',
          tag: tag,
        };
      case FIXType.MonthYear:
        return {
          label: spec?.name ?? 'MonthYear',
          value: value,
          type: 'string',
          tag: tag,
        };
      case FIXType.string:
        return {
          label: spec?.name ?? 'Text',
          value: value,
          type: 'string',
          tag: tag,
        };
      case FIXType.int:
        return {
          label: spec?.name ?? 'Number',
          value: value,
          type: 'number',
          tag: tag,
        };
      case FIXType.boolean:
        return {
          label: spec?.name ?? 'True/False',
          value: value,
          type: 'string',
          tag: tag,
        };
      case FIXType.number:
        return {
          label: spec?.name ?? 'Number',
          value: value,
          type: 'number',
          tag: tag,
        };
      default:
        return {
          label: tag.toString(),
          tag: tag,
          value: value,
          type: 'unknown',
        };
    }
  }

  public readonly tag: number;
  public readonly value: any;
}

const SOH = String.fromCharCode(1);
const BAD_SOH = '^A';

const isFix = (elements: readonly FIXTagValue[]): boolean => {
  const [first, second, third] = elements;
  return first.tag === 8 && second.tag === 9 && third.tag === 35;
};

export const parse = (message: string): readonly FIXTaggedField[] => {
  // eslint-disable-next-line no-control-regex
  const elements = message.replaceAll(BAD_SOH, SOH).split(SOH).map(FIXTagValue.fromString);
  const fields: FIXTaggedField[] = [];

  if (!isFix(elements)) {
    throw new Error('not a FIX message');
  }

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    // FIXME: several other tags are also repeating groups
    if (element.tag === NoLegs) {
      const length = Number(element.value);
      const anchor = elements[++i];
      const value = [];
      let children = [];
      let end: FIXTagValue | null = null;
      // Find next anchor
      children.push(anchor);
      for (let j = i + 1; j < elements.length; j++) {
        const current = elements[j];
        const next = elements[j + 1];
        if (next?.tag === anchor.tag) {
          end = current;
        }
        children.push(current);

        if (end?.tag === current.tag) {
          value.push(children);
          if (end === null) {
            end = current;
          }
          // Reset `currentValue'
          children = [];
        }
        i = j;
      }
      if (value.length !== length) {
        throw new Error(`cannot read the expected ${length} legs`);
      }
      fields.push({
        label: 'Legs',
        value: value.map((ftv: FIXTagValue[]): readonly FIXTaggedField[] => {
          return ftv.map((ftv: FIXTagValue) => ftv.toField());
        }),
        type: 'array',
        tag: NoLegs,
      });
    } else {
      fields.push(element.toField());
    }
  }

  return fields;
};
