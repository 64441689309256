import React from 'react';
import styles from 'routes/ReportsRoute/WhoIsLoggedIn/components/LastUpdateWidget/last-update-widget.module.scss';

interface Props {
  readonly value: Date;
}

export const LastUpdateWidget: React.FC<Props> = (props: Props): React.ReactElement => {
  return <div className={styles.container}>Last update: {props.value.toLocaleTimeString()}</div>;
};
