import styles from 'components/ApplicationContainer/application-container.module.scss';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchApplicationsAction,
  fetchDatabaseServersAction,
} from 'redux/services/referenceDataServices';

export const ApplicationContainer = (props: React.PropsWithChildren): React.ReactElement => {
  const dispatch = useDispatch<any>();

  useEffect((): VoidFunction => {
    const cancels = [dispatch(fetchApplicationsAction()), dispatch(fetchDatabaseServersAction())];

    return (): void => {
      cancels.forEach((cancellable: VoidFunction): void => cancellable());
    };
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};
