import { FormatFn } from 'components/Table/types/column';

interface StatusProps {
  readonly status: string;
}

export const formatStatus: FormatFn<string, StatusProps> = (value: string): StatusProps => {
  return {
    status: value,
  };
};
