import { Checkbox, FormLabel, OutlinedInput } from '@mui/material';
import React, { ChangeEvent } from 'react';
import styles from 'routes/EntityManagement/FirmsManagement/components/BrokerageField/brokerage-field.module.scss';
import { BrokerageItem } from 'types/brokerage';

interface Props {
  readonly label: string;
  readonly disabled?: boolean;
  readonly brokerage: BrokerageItem;

  onChange(value: BrokerageItem): void;
}

export const BrokerageField: React.FC<Props> = (props: Props): React.ReactElement => {
  const { brokerage, label, disabled = false } = props;
  const { onChange } = props;
  const [enabled, setEnabled] = React.useState<boolean>(
    disabled === false ? false : brokerage.enabled
  );

  React.useEffect((): void => {
    if (disabled) {
      setEnabled(false);
      return;
    }

    setEnabled(brokerage.enabled);
  }, [disabled, brokerage.enabled]);

  const onEnabledChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      setEnabled(checked);
      // Update the value too
      onChange({ ...brokerage, enabled: checked });
    },
    [brokerage, onChange]
  );

  const floor = React.useMemo(
    (): string => (brokerage.floor === null ? '' : String(brokerage.floor)),
    [brokerage.floor]
  );

  const top = React.useMemo(
    (): string => (brokerage.top === null ? '' : String(brokerage.top)),
    [brokerage.top]
  );

  const onFloorChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value: text } = event.target;
      if (text === '') {
        onChange({ ...brokerage, floor: null });
      } else {
        const numeric = Number(text);
        if (isNaN(numeric)) {
          return;
        }

        onChange({ ...brokerage, floor: numeric });
      }
    },
    [brokerage, onChange]
  );

  const onTopChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value: text } = event.target;
      const numeric = Number(text);
      if (isNaN(numeric)) {
        return;
      }

      if (text === '') {
        onChange({ ...brokerage, floor: null });
      } else {
        onChange({ ...brokerage, top: numeric });
      }
    },
    [brokerage, onChange]
  );

  return (
    <div className={styles.container}>
      <Checkbox checked={enabled} disabled={disabled} onChange={onEnabledChange} />
      <FormLabel>{label}</FormLabel>
      <OutlinedInput
        value={floor}
        disabled={!enabled}
        classes={{ input: styles.input }}
        onChange={onFloorChange}
      />
      <OutlinedInput
        value={top}
        disabled={!enabled}
        classes={{ input: styles.input }}
        onChange={onTopChange}
      />
    </div>
  );
};
