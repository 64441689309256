// noinspection JSUnreachableSwitchBranches

import { Checkbox, FormLabel } from '@mui/material';
import { ArrayField } from 'components/Form/components/Array';
import { Dropdown } from 'components/Form/components/Dropdown';
import {
  ArrayFieldDefinition,
  BasicFieldDefinition,
  CustomFieldDefinition,
  FieldDefinition,
  FieldGroupDefinition,
  FieldsetFieldDefinition,
  FieldType,
  isArrayFieldDefinition,
  isBasicFieldDefinition,
  isCustomFieldDefinition,
  isDropdownFieldDefinition,
  isFieldsetDefinition,
  isGroupDefinition,
  isMappedGroupFieldDefinition,
  isMultiBoolFieldDefinition,
  MappedGroupFieldDefinition,
  MultiBoolFieldDefinition,
} from 'components/Form/components/Field/types';
import { FieldErrorWidget } from 'components/Form/components/FieldErrorWidget';
import { MappedGroupField } from 'components/Form/components/MappedGroupField';
import { MultiBool } from 'components/Form/components/MultiBool';
import { RequiredIndicator } from 'components/Form/components/RequiredIndicator';
import { TextField } from 'components/Form/components/TextField';
import styles from 'components/Form/form.module.scss';
import { isFieldRequired } from 'components/Form/utils';
import { TimeInput } from 'components/TimeInput';
import { FormErrorsContext } from 'contexts/FormErrorsContext';
import emailValidator from 'email-validator';
import equal from 'fast-deep-equal';
import { toClassName } from 'helpers/toClassName';
import { Moment } from 'moment';
import React from 'react';
import { DropdownOption } from 'types/dropdownOption';

interface Props<T, P> {
  readonly prefix?: string;
  readonly definition: FieldDefinition<T>;
  readonly value?: any;
  readonly provider: P;
  readonly disabled?: boolean;
  readonly required: boolean;

  onChange(name: keyof T, value: unknown): void;
}

interface State {
  readonly options: readonly DropdownOption[];
}

export class Field<T, P> extends React.Component<Props<T, P>, State> {
  public static contextType = FormErrorsContext;
  public context: React.ContextType<typeof FormErrorsContext>;

  constructor(props: Props<T, P>) {
    super(props);

    this.state = {
      options: [],
    };
  }

  private applyNumericFormatter = (value: string): number | null => {
    const numericValue = Number(value);
    if (isNaN(numericValue)) {
      return null;
    }

    return numericValue;
  };

  private applyEmailFormatter = (value: string): string | null => {
    if (emailValidator.validate(value)) {
      return value;
    }

    return value;
  };

  private applyFormatterToTextValue = (value: string): string | number | null => {
    const { definition } = this.props;
    switch (definition.fieldType) {
      case FieldType.email:
        return this.applyEmailFormatter(value);
      case FieldType.phone:
        return value;
      case FieldType.numeric:
        return this.applyNumericFormatter(value);
      default:
        return value;
    }
  };

  private onMappedGroupChange = (name: string, childValue: any): void => {
    const { onChange } = this.props;
    onChange(name as keyof T, childValue);
  };

  private onFieldsetChange = (name: keyof T, childValue: any): void => {
    const { onChange } = this.props;
    onChange(name, childValue);
  };

  private onArrayRemove = (item: any): void => {
    const { onChange, definition, value } = this.props;
    // Send the value and the name of the field
    if (isArrayFieldDefinition(definition)) {
      // Filter the values
      const filtered = value.filter((each: any): boolean => !equal(item, each));
      // Notify the caller
      onChange(definition.name, filtered);
    } else {
      throw new Error('received a array remove event for a non array field');
    }
  };

  private onArrayChange = (value: readonly any[]): void => {
    const { onChange, definition } = this.props;
    if (isArrayFieldDefinition(definition)) {
      onChange(definition.name, value);
    } else {
      throw new Error('received a array add event for a non array field');
    }
  };

  private onArrayEdit = (editedItemIndex: number, editedItem: any): void => {
    const { onChange, definition, value } = this.props;
    if (isArrayFieldDefinition(definition)) {
      const newValue = value.map((item: any, index: number): any => {
        if (index === editedItemIndex) {
          return editedItem;
        } else {
          return item;
        }
      });

      onChange(definition.name, newValue);
    } else {
      throw new Error('received a array add event for a non array field');
    }
  };

  private onArrayAdd = (item: any): void => {
    const { onChange, definition, value } = this.props;
    if (isArrayFieldDefinition(definition)) {
      onChange(definition.name, [...value, item]);
    } else {
      throw new Error('received a array add event for a non array field');
    }
  };

  private onBooleanChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { onChange, definition } = this.props;
    const { checked } = event.target;
    if (isBasicFieldDefinition(definition)) {
      onChange(definition.name, checked);
    } else {
      throw new Error('only basic fields have change events');
    }
  };

  private onTimeChange = (time: Moment): void => {
    const { onChange, definition } = this.props;
    if (isBasicFieldDefinition(definition)) {
      onChange(definition.name, time);
    }
  };

  private onTextChange = (text: string): void => {
    const { onChange, definition } = this.props;

    if (isBasicFieldDefinition(definition)) {
      const value = this.applyFormatterToTextValue(text);
      if (value === null) {
        return;
      }

      onChange(definition.name, value);
    } else {
      throw new Error('only basic fields have change events');
    }
  };

  private onCustomFieldChange = (value: any): void => {
    const { onChange, definition } = this.props;
    if (isCustomFieldDefinition(definition)) {
      onChange(definition.name, value);
    } else {
      throw new Error('this is only meant for custom fields');
    }
  };

  private static computeOptions<T, P>(props: Props<T, P>): readonly DropdownOption[] {
    const { value, provider, definition } = props;

    if (isDropdownFieldDefinition(definition) || isMultiBoolFieldDefinition(definition)) {
      const { options } = definition;

      if (typeof options === 'function') {
        const result = options(value, provider);
        if (result === null) {
          return [];
        }

        return result;
      } else {
        return options;
      }
    } else {
      return [];
    }
  }

  public static getDerivedStateFromProps<T, P>(props: Props<T, P>, state: State): State {
    const { definition } = props;
    if (!isDropdownFieldDefinition(definition) && !isMultiBoolFieldDefinition(definition)) {
      return state;
    }

    return { ...state, options: Field.computeOptions(props) };
  }

  private get required(): boolean {
    const { required = false } = this.props;
    return required;
  }

  private renderBasicField = (definition: BasicFieldDefinition<T, P>): React.ReactNode => {
    const { name } = definition;
    const { value = '', disabled = false, prefix, onChange } = this.props;
    const { options } = this.state;
    const key = `${prefix ?? ''}${name}`;

    switch (definition.fieldType) {
      case FieldType.bool:
        return (
          <div className={styles.field}>
            <FormLabel htmlFor={key}>
              <span className={styles.label}>{definition.label}</span>
              <RequiredIndicator required={this.required} />
            </FormLabel>
            <div>
              <Checkbox checked={!!value} disabled={disabled} onChange={this.onBooleanChange} />
            </div>
          </div>
        );

      case FieldType.longText:
        return (
          <div className={styles.field}>
            <FormLabel htmlFor={key}>
              <span className={styles.label}>{definition.label}</span>
              <RequiredIndicator required={this.required} />
            </FormLabel>
            <TextField
              id={key}
              name={String(definition.name)}
              multiline={true}
              value={value}
              rows={2}
              disabled={disabled}
              onChange={this.onTextChange}
            />
          </div>
        );

      case FieldType.time:
        return (
          <div className={toClassName(styles.field, styles[definition.fieldType])}>
            <FormLabel htmlFor={key}>
              <span className={styles.label}>{definition.label}</span>
              <RequiredIndicator required={this.required} />
            </FormLabel>
            <div className={styles.control}>
              <TimeInput id={key} value={value} onChange={this.onTimeChange} />
            </div>
          </div>
        );

      case FieldType.phone:
      case FieldType.email:
      case FieldType.numeric:
      case FieldType.ipAddress:
      case FieldType.text:
        return (
          <div className={toClassName(styles.field, styles[definition.fieldType])}>
            <FormLabel htmlFor={key}>
              <span className={styles.label}>{definition.label}</span>
              <RequiredIndicator required={this.required} />
            </FormLabel>
            <div className={styles.control}>
              <TextField
                id={key}
                name={String(definition.name)}
                fullWidth={true}
                value={value}
                disabled={disabled}
                onChange={this.onTextChange}
              />
            </div>
          </div>
        );

      case FieldType.dropdown:
        return (
          <Dropdown
            name={definition.name}
            label={definition.label}
            options={options}
            value={value}
            required={this.required}
            disabled={disabled}
            onChange={onChange}
          />
        );

      default:
        console.warn(`trying to render a field that has no renderer ${definition.fieldType}`);
        return null;
    }
  };

  private renderArrayField = (definition: ArrayFieldDefinition<T, P>): React.ReactNode => {
    const { value = '', disabled = false } = this.props;

    if (value && !Array.isArray(value)) {
      throw new Error('array fields must have array value');
    }

    return (
      <ArrayField
        value={value}
        definition={definition}
        disabled={disabled}
        required={this.required}
        onChange={this.onArrayChange}
        onEdit={this.onArrayEdit}
        onRemove={this.onArrayRemove}
        onAdd={this.onArrayAdd}
      />
    );
  };

  private renderGroupField = (definition: FieldGroupDefinition<T, P>): React.ReactNode => {
    const { value = '', provider, disabled = false } = this.props;
    const { children } = definition;
    const required = isFieldRequired(definition, value, provider);
    const columns = definition.columns ?? 2;
    return (
      <div className={toClassName(styles.field, styles.group, styles[`group${columns}`])}>
        {children
          .filter((item: FieldDefinition<any>): boolean => {
            if (typeof item.display === 'function') {
              return item.display(value, provider);
            } else {
              return item.display;
            }
          })
          .map(
            (childDefinition: FieldDefinition<any, P>): React.ReactElement => (
              <div key={String(childDefinition.name)}>
                <Field
                  prefix={`${definition.name}.`}
                  value={value}
                  definition={childDefinition}
                  provider={provider}
                  disabled={disabled}
                  required={required}
                  onChange={this.onFieldsetChange}
                />
              </div>
            )
          )}
      </div>
    );
  };

  private renderFieldsetField = (definition: FieldsetFieldDefinition<T, P>): React.ReactNode => {
    const { value = '', provider, prefix, disabled = false } = this.props;
    const { children } = definition;
    const id = `${prefix}${definition.name}`;
    const prefixedPrefix = `${prefix}${definition.name}.`;

    return (
      <fieldset id={id} className={styles.fieldset}>
        <legend>
          <span>{definition.label}</span> <RequiredIndicator required={this.required} />
        </legend>
        {children
          .filter((item: FieldDefinition<any>): boolean => {
            if (typeof item.display === 'function') {
              return item.display(value, provider);
            } else {
              return item.display;
            }
          })
          .map((childDefinition: FieldDefinition<any>): React.ReactElement => {
            if (
              !isBasicFieldDefinition(childDefinition) &&
              !isCustomFieldDefinition(childDefinition) &&
              !isMultiBoolFieldDefinition(childDefinition)
            ) {
              throw new Error('fieldset children can only be basic fields');
            }
            const childValue = value[childDefinition.name] ?? '';
            const required = isFieldRequired(definition, value, provider);

            return (
              <Field
                key={String(childDefinition.name)}
                prefix={prefixedPrefix}
                definition={childDefinition}
                value={childValue}
                provider={provider}
                disabled={disabled}
                required={required}
                onChange={this.onFieldsetChange}
              />
            );
          })}
      </fieldset>
    );
  };

  private renderMappedGroupField = (
    definition: MappedGroupFieldDefinition<T, P>
  ): React.ReactNode => {
    const { provider, value } = this.props;

    return (
      <MappedGroupField
        definition={definition}
        value={value}
        provider={provider}
        required={this.required}
        onChange={this.onMappedGroupChange}
      />
    );
  };

  private renderCustomField = (definition: CustomFieldDefinition<T, P>): React.ReactNode => {
    const { value = '' } = this.props;
    const { component, label } = definition;
    const Component = component;

    if (label) {
      return (
        <div className={toClassName(styles.field, styles[FieldType.custom])}>
          <FormLabel>
            <span>{label}</span>
            <RequiredIndicator required={this.required} />
          </FormLabel>
          <div className={styles.control}>
            <Component value={value} onChange={this.onCustomFieldChange} />
          </div>
        </div>
      );
    } else {
      return <Component value={value} onChange={this.onCustomFieldChange} />;
    }
  };

  private renderMultiBoolField = (definition: MultiBoolFieldDefinition<T, P>): React.ReactNode => {
    const { value = [], onChange } = this.props;
    const { options } = this.state;

    return (
      <fieldset id={String(definition.name)} className={styles.fieldset}>
        <legend>
          <span>{definition.label}</span> <RequiredIndicator required={this.required} />
        </legend>
        <MultiBool
          name={definition.name}
          options={options}
          value={value}
          required={this.required}
          onChange={onChange}
        />
      </fieldset>
    );
  };

  public render(): React.ReactNode {
    const { prefix, definition } = this.props;
    const { context } = this;

    const id = `${prefix}${definition.name}`;
    const error = context[id];

    const element = ((): React.ReactNode => {
      if (isBasicFieldDefinition(definition)) {
        return this.renderBasicField(definition);
      } else if (isArrayFieldDefinition(definition)) {
        return this.renderArrayField(definition);
      } else if (isGroupDefinition(definition)) {
        return this.renderGroupField(definition);
      } else if (isFieldsetDefinition(definition)) {
        return this.renderFieldsetField(definition);
      } else if (isMultiBoolFieldDefinition(definition)) {
        return this.renderMultiBoolField(definition);
      } else if (isCustomFieldDefinition(definition)) {
        return this.renderCustomField(definition);
      } else if (isMappedGroupFieldDefinition(definition)) {
        return this.renderMappedGroupField(definition);
      } else {
        return null;
      }
    })();

    return (
      <div>
        <>{element}</>
        <FieldErrorWidget error={error} />
      </div>
    );
  }
}
