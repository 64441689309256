export interface FIXTaggedField {
  readonly label: string;
  readonly value: string | ReadonlyArray<readonly FIXTaggedField[]>;
  readonly type: 'number' | 'string' | 'date' | 'array' | 'unknown';
  readonly tag: number;
}

export interface TextField extends FIXTaggedField {
  readonly value: string;
}

export interface TableField extends FIXTaggedField {
  readonly value: ReadonlyArray<readonly FIXTaggedField[]>;
}

export const isTextField = (field: FIXTaggedField): field is TextField => {
  return typeof field.value === 'string';
};

export const isTableField = (field: FIXTaggedField): field is TableField => {
  return typeof field.value !== 'string';
};
