import { Api } from 'api';
import React from 'react';
import {
  EncryptionAlgorithm,
  HashAlgorithm,
  KeyPasswordType,
  PeerAuthenticationMethod,
} from 'types/vpn';

export class NetworkDetailsDataProvider {
  public hashAlgorithms: readonly HashAlgorithm[];
  public encryptionAlgorithms: readonly EncryptionAlgorithm[];
  public keyPasswordAlgorithms: readonly KeyPasswordType[];
  public peerAuthMethods: readonly PeerAuthenticationMethod[];

  private readonly api: Api;

  constructor(api: Api) {
    this.api = api;
    this.hashAlgorithms = [];
  }

  public initialize(): VoidFunction {
    const { api } = this;

    const task1 = api.getHashAlgorithms();
    const task2 = api.getEncryptionAlgorithms();
    const task3 = api.getKeyPass();
    const task4 = api.getPeerAuthMethods();

    task1
      .run()
      .then((algorithms: readonly HashAlgorithm[]): void => {
        this.hashAlgorithms = algorithms;
      })
      .catch(console.warn);

    task2
      .run()
      .then((algorithms: readonly EncryptionAlgorithm[]): void => {
        this.encryptionAlgorithms = algorithms;
      })
      .catch(console.warn);

    task3
      .run()
      .then((algorithms: readonly KeyPasswordType[]): void => {
        this.keyPasswordAlgorithms = algorithms;
      })
      .catch(console.warn);

    task4
      .run()
      .then((algorithms: readonly PeerAuthenticationMethod[]): void => {
        this.peerAuthMethods = algorithms;
      })
      .catch(console.warn);

    return (): void => {
      task1.cancel();
      task2.cancel();
      task3.cancel();
      task4.cancel();
    };
  }
}

export const NetworkDetailsDataProviderContext =
  React.createContext<NetworkDetailsDataProvider>(null);

export const NetworkDetailsDataProviderContextProvider = NetworkDetailsDataProviderContext.Provider;
