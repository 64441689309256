import { toClassName } from 'helpers/toClassName';
import React from 'react';
import { useSelector } from 'react-redux';
import { riskSelector } from 'redux/reducers/riskReducer';
import { AssetClassView } from 'routes/Risk/components/AssetClassView';
import classes from 'routes/Risk/risk.module.scss';
import { RiskMatrix } from 'types/risk';

interface Props {
  readonly matrix: RiskMatrix | null;
}

export const Matrix: React.FC<Props> = (props: Props): React.ReactElement => {
  const risk = useSelector(riskSelector);

  const { matrix } = props;
  const { options, equities } = matrix ?? {};

  if (matrix === null) {
    return <div className={classes.matrix} />;
  } else {
    return (
      <div
        className={toClassName(classes.matrix, {
          [classes.savingMatrix]: risk.savingRiskMatrix,
        })}
      >
        <AssetClassView label="Options" group={options} type="options" />
        <AssetClassView label="Equities" group={equities} type="equities" />
      </div>
    );
  }
};
