import { PayloadAction } from '@reduxjs/toolkit';
import { Actions, initialState, reducer, State } from 'components/Table/components/Body/reducer';
import { GrowTableRow } from 'components/Table/components/GrowTableRow';
import { Row } from 'components/Table/components/Row';
import styles from 'components/Table/table.module.scss';
import { Column } from 'components/Table/types/column';
import { RowData } from 'components/Table/types/rowData';
import { throttle } from 'lodash';
import React, { Reducer, useReducer } from 'react';
import { Identifiable } from 'types/identifiable';

interface Props<T extends Identifiable> {
  readonly columns: ReadonlyArray<Column<T>>;
  readonly rows: ReadonlyArray<RowData<T>>;
  readonly growable?: boolean;

  readonly virtualScroll: boolean;

  rowClassName?(data: T): string | undefined;
  onRowMount?(id: string, mounted: boolean): void;
  onGrow?(item: T): void;
  onRowClick?(value: any): void;
}

export function Body<T extends Identifiable>(props: Props<T>): React.ReactElement {
  const [tbody, setTableBody] = React.useState<HTMLTableSectionElement | null>(null);

  const { columns, rows, growable = false, virtualScroll, onGrow, onRowMount } = props;
  const [state, dispatch] = useReducer<Reducer<State, PayloadAction<any, Actions>>>(
    reducer,
    initialState
  );

  const mapper = React.useCallback(
    (row: RowData<T>): JSX.Element => {
      const { onRowClick, rowClassName } = props;
      const { columns } = props;

      return (
        <Row<T>
          key={row.id}
          className={rowClassName?.(row.data)}
          rowId={row.id}
          data={row.data}
          childRows={row.children}
          columns={columns}
          onMount={onRowMount}
          onClick={onRowClick}
        />
      );
    },
    [onRowMount, props]
  );

  const visibleSlice = React.useMemo((): ReadonlyArray<RowData<T>> => {
    if (!virtualScroll) {
      return rows;
    }

    return rows.slice(
      Math.max(state.firstRow - _virtualRowsOverscan, 0),
      Math.min(state.visibleRowsNumber + state.firstRow + _virtualRowsOverscan, rows.length)
    );
  }, [rows, state.firstRow, state.visibleRowsNumber, virtualScroll]);

  const updateGeometry = React.useCallback((): void | VoidFunction => {
    if (tbody === null) {
      return;
    }

    const container = findParentWithClass(tbody, styles.body);
    if (container === null) {
      return;
    }

    dispatch({
      type: Actions.updateGeometry,
      payload: {
        viewportSize: container.offsetHeight,
        contentSize: tbody.offsetHeight,
      },
    });

    const updateFirstRow = throttle(
      (): void => {
        dispatch({
          type: Actions.updateFirstRow,
          payload: container.scrollTop,
        });
      },
      40,
      {
        trailing: true,
        leading: true,
      }
    );

    container.addEventListener('scroll', updateFirstRow, true);
    return (): void => {
      container.removeEventListener('scroll', updateFirstRow, true);
    };
  }, [tbody]);

  const firstVirtualRowStyle = React.useMemo(
    (): React.CSSProperties => ({
      height: Math.max(state.firstRow - _virtualRowsOverscan, 0) * state.rowHeight,
      width: 10,
      backgroundColor: 'blue',
    }),
    [state.firstRow, state.rowHeight]
  );

  const lastVirtualRowStyle = React.useMemo(
    (): React.CSSProperties => ({
      height:
        Math.max(rows.length - state.visibleRowsNumber - state.firstRow - _virtualRowsOverscan, 0) *
        state.rowHeight,
      width: 10,
      backgroundColor: 'red',
    }),
    [rows.length, state.firstRow, state.rowHeight, state.visibleRowsNumber]
  );

  React.useLayoutEffect((): void | VoidFunction => {
    if (tbody === null) {
      return;
    }
    updateGeometry();

    const observer = new MutationObserver(updateGeometry);

    observer.observe(tbody, { childList: true });
    return (): void => {
      observer.disconnect();
    };
  }, [tbody, updateGeometry]);

  const bodyClassName = React.useMemo(
    (): string => (!virtualScroll ? styles.noVirtualScroll : undefined),
    [virtualScroll]
  );

  return (
    <tbody className={bodyClassName} ref={setTableBody}>
      {virtualScroll && <tr style={firstVirtualRowStyle} />}
      {visibleSlice.map(mapper)}
      {virtualScroll && <tr style={lastVirtualRowStyle} />}
      {growable && <GrowTableRow columns={columns} onSubmit={onGrow} />}
    </tbody>
  );
}

const findParentWithClass = (
  element: HTMLElement | null,
  className: string
): HTMLElement | null => {
  if (element !== null) {
    const { classList } = element;
    if (classList.contains(className)) {
      return element;
    }

    return findParentWithClass(element.parentElement, className);
  }

  return null;
};

const _virtualRowsOverscan = 5;
