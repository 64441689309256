import { PayloadAction } from '@reduxjs/toolkit';

export enum Actions {
  updateGeometry = '/tbody/setRowHeight',
  updateFirstRow = '/tbody/updateFirstRow',
}

export interface State {
  readonly rowHeight: number;
  readonly visibleRowsNumber: number;
  readonly firstRow: number;
}

export const reducer = (state: State, action: PayloadAction<any, Actions>): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.updateGeometry:
      return {
        ...state,
        visibleRowsNumber: Math.ceil(payload.viewportSize / state.rowHeight),
      };
    case Actions.updateFirstRow:
      return { ...state, firstRow: Math.floor(payload / state.rowHeight) };
    default:
      return state;
  }
};

export const initialState: State = {
  rowHeight: 20,
  visibleRowsNumber: 100,
  firstRow: 0,
};
