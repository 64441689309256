import { User } from 'auth/user';
import { Action } from 'redux/action';

export enum CurrentUserActions {
  setUser = '/actions/currentUser/set-user',
}

export interface CurrentUserState {
  readonly user: User;
}

const initialState: CurrentUserState = {
  user: new User({
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    logoUrl: '',
    groups: [],
  }),
};

const reducer = (state = initialState, action: Action<CurrentUserActions>): CurrentUserState => {
  const { type, data } = action;
  switch (type) {
    case CurrentUserActions.setUser:
      return { user: data };
    default:
      return state;
  }
};

export default reducer;
