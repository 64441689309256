import { Api } from 'api';
import { CRUDResponse } from 'api/interfaces/CRUDResponse';
import { Firm, FirmPayload, MPID } from 'api/interfaces/firm';
import { Dispatch } from 'redux';
import { Action } from 'redux/action';
import { createAction } from 'redux/actions/creator';
import { ApplicationState } from 'redux/applicationState';
import { FirmsActions } from 'redux/reducers/firmsReducer';

export const updateFirm =
  (firm: Firm) =>
  (dispatch: Dispatch<Action<FirmsActions>>, _: () => ApplicationState, api: Api): VoidFunction => {
    dispatch(createAction(FirmsActions.updateFirmStarted));
    const { MPIDS } = firm;
    const task = api.updateFirm(FirmPayload.fromFirm(firm));

    const newFirm = { ...firm, firmName: MPIDS.map((mpid: MPID): string => mpid.mpid).join(', ') };

    task
      .run()
      .then((result: CRUDResponse): void => {
        if (result.success) {
          dispatch(createAction(FirmsActions.updateFirmCompleted, newFirm));
        } else {
          dispatch(createAction(FirmsActions.updateFirmFailed, result.message));
        }
      })
      .catch((error: Error): void => {
        dispatch(createAction(FirmsActions.updateFirmFailed, error.message));
      });

    return (): void => {
      task.cancel();
    };
  };
