import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import deepMerge from 'deepmerge';
import { ApplicationState } from 'redux/applicationState';
import {
  Account,
  AccountsGroup,
  AssetClassType,
  RemoveSymbolLimitsRequest,
  RiskMatrix,
  SymbolLimits,
  UpdateOrCreateEntryRequest,
} from 'types/risk';

export interface AssetClassRequest<T> {
  readonly type: AssetClassType;
  readonly value: T;
}

export interface RiskEngine {
  readonly clientid: string;
  readonly sessionid: string;
}

export type RiskSession = Omit<RiskEngine, 'clientid'>;

export interface RiskState {
  clientID: string;
  sessionID: string;
  matrix: RiskMatrix | null;
  originalMatrix: RiskMatrix | null;
  fetchingRiskMatrix: boolean;
  savingRiskMatrix: boolean;
  fetchingRiskSessions: boolean;
  fetchingRiskEngines: boolean;
  engines: readonly RiskEngine[];
  sessions: readonly RiskSession[];

  successMessage: string | null;
  errorMessage: string | null;
}

const initialState: RiskState = {
  clientID: '',
  sessionID: '',
  matrix: null,
  originalMatrix: null,
  fetchingRiskMatrix: false,
  savingRiskMatrix: false,
  fetchingRiskSessions: false,
  fetchingRiskEngines: false,
  engines: [],
  sessions: [],
  successMessage: null,
  errorMessage: null,
};

const slice = createSlice({
  name: 'risk',
  initialState: initialState,
  reducers: {
    setClient: (state: RiskState, action: PayloadAction<string>): void => {
      state.clientID = action.payload;
      state.matrix = null;
      state.sessions = [];
    },
    setSessionID: (state: RiskState, action: PayloadAction<string>): void => {
      state.sessionID = action.payload;
    },
    fetchingRiskMatrixStarted: (state: RiskState): void => {
      state.fetchingRiskMatrix = true;
      state.matrix = null;
    },
    setRiskMatrix: (state: RiskState, action: PayloadAction<RiskMatrix>): void => {
      state.matrix = action.payload;
      state.originalMatrix = state.matrix;
    },
    updateSymbolLimits: (
      state: RiskState,
      action: PayloadAction<AssetClassRequest<UpdateOrCreateEntryRequest>>
    ): void => {
      const { payload } = action;
      const { matrix } = state;

      const { account, symbolLimits } = payload.value;

      const updatedMatrix = {
        [payload.type]: {
          accounts: [{ account: account, symbolLimits: [symbolLimits] }],
        },
      };

      state.matrix = deepMerge(matrix, updatedMatrix);
    },
    removeSymbolLimits: (
      state: RiskState,
      action: PayloadAction<AssetClassRequest<RemoveSymbolLimitsRequest>>
    ): void => {
      const { payload } = action;
      const { matrix } = state;

      const group: AccountsGroup = matrix[payload.type];
      const { accounts } = group;
      const request = payload.value;
      const matchingAccount = accounts.find(
        (account: Account): boolean => account.account === request.account
      );

      if (matchingAccount) {
        const { accounts } = group;
        state.matrix = {
          ...matrix,
          [payload.type]: {
            ...group,
            accounts: accounts.map((account: Account): Account => {
              if (account.account === request.account) {
                const { symbolLimits } = account;

                return {
                  account: request.account,
                  symbolLimits: symbolLimits.filter(
                    (currentEntry: SymbolLimits): boolean => currentEntry.symbol !== request.symbol
                  ),
                };
              } else {
                return account;
              }
            }),
          },
        };
      } else {
        throw new Error('missing account, cannot remove this item');
      }
    },
    replaceRiskMatrix: (state: RiskState, action: PayloadAction<RiskMatrix>): void => {
      state.matrix = action.payload;
    },
    fetchingRiskMatrixCompleted: (state: RiskState): void => {
      state.fetchingRiskMatrix = false;
    },
    savingRiskMatrixStarted: (state: RiskState): void => {
      state.savingRiskMatrix = true;
    },
    savingRiskMatrixSucceeded: (state: RiskState): void => {
      state.originalMatrix = state.matrix;
      state.successMessage = 'Risk matrix saved successfully';
    },
    restoreOriginalRiskMatrix: (state: RiskState): void => {
      state.matrix = state.originalMatrix;
    },
    savingRiskMatrixFailed: (state: RiskState, action: PayloadAction<string>): void => {
      state.errorMessage = action.payload;
    },
    savingRiskMatrixCompleted: (state: RiskState): void => {
      state.savingRiskMatrix = false;
    },
    fetchingRiskSessionsStarted: (state: RiskState): void => {
      state.fetchingRiskSessions = true;
    },
    setRiskSessions: (state: RiskState, action: PayloadAction<readonly RiskSession[]>): void => {
      state.sessions = action.payload;
    },
    fetchingRiskSessionsCompleted: (state: RiskState): void => {
      state.fetchingRiskSessions = false;
    },
    fetchingRiskEnginesStarted: (state: RiskState): void => {
      state.fetchingRiskEngines = true;
    },
    setRiskEngines: (state: RiskState, action: PayloadAction<readonly RiskEngine[]>): void => {
      state.engines = action.payload;
    },
    fetchingRiskEnginesCompleted: (state: RiskState): void => {
      state.fetchingRiskEngines = false;
    },
    setAccountIDTag: (state: RiskState, action: PayloadAction<AssetClassRequest<number>>): void => {
      const { payload } = action;
      const { matrix } = state;

      const group: AccountsGroup = matrix[payload.type];

      state.matrix = {
        ...state.matrix,
        [payload.type]: { ...group, accounttag: payload.value },
      };
    },
    resetSuccessMessage: (state: RiskState): void => {
      state.successMessage = null;
    },
    resetErrorMessage: (state: RiskState): void => {
      state.errorMessage = null;
    },
  },
});

export const {
  setClient,
  setSessionID,
  setRiskMatrix,
  replaceRiskMatrix,
  fetchingRiskMatrixStarted,
  fetchingRiskMatrixCompleted,
  savingRiskMatrixStarted,
  savingRiskMatrixCompleted,
  savingRiskMatrixSucceeded,
  savingRiskMatrixFailed,
  fetchingRiskSessionsStarted,
  setRiskSessions,
  fetchingRiskSessionsCompleted,
  fetchingRiskEnginesStarted,
  setRiskEngines,
  fetchingRiskEnginesCompleted,
  setAccountIDTag,
  resetErrorMessage,
  resetSuccessMessage,
  updateSymbolLimits,
  removeSymbolLimits,
  restoreOriginalRiskMatrix,
} = slice.actions;

export const riskSelector = (state: ApplicationState): RiskState => state.risk;

export default slice.reducer;

export const createAssetClassRequest = <T>(
  type: AssetClassType,
  value: T
): AssetClassRequest<T> => {
  return { type: type, value: value };
};
