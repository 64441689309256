import { ApplicationUser, UserPayload } from 'api/interfaces/applicationUser';
import { PrismApplication } from 'api/interfaces/prismApplication';
import { OktaGroup } from 'api/interfaces/user';
import { UsersFormState } from 'redux/reducers/usersFormReducer';
import { DropdownOption } from 'types/dropdownOption';

export const preparePayload = (form: UsersFormState, user: ApplicationUser): UserPayload => {
  const { applications } = form;
  const { oktaGroups } = user;

  const allOktaGroups = applications
    .map((application: PrismApplication): readonly OktaGroup[] => application.okta)
    .reduce(
      (groups: readonly OktaGroup[], combined: readonly OktaGroup[]): readonly OktaGroup[] => {
        return [...groups, ...combined];
      },
      []
    );

  const selectedOktaGroups = allOktaGroups
    .filter(
      (group: OktaGroup): boolean =>
        !!oktaGroups.find((option: DropdownOption): boolean => option.id === group.id)
    )
    .map((group: OktaGroup): string => group.id);

  return UserPayload.fromApplicationUser(user, selectedOktaGroups);
};
