import { TabbedFormField } from 'ComplexModals/SessionEditor/forms/TabbedFormField';
import { FieldDefinition, FieldType } from 'components/Form/components/Field/types';
import { NetworkAddressInput } from 'components/NetworkAddressInput';
import { SMNetworkAddress, SMNetworkDetails } from 'redux/definitions/central';
import { DropdownOption } from 'types/dropdownOption';

const vpnForm: FieldDefinition<SMNetworkDetails> = {
  name: 'vpnForm',
  fieldType: FieldType.custom,
  component: TabbedFormField,
  display: (networkDetails: SMNetworkDetails): boolean => networkDetails.networkVendor === 'VPN',
};

const xconnForm: FieldDefinition<SMNetworkDetails> = {
  name: 'xconnForm',
  label: 'XCONN',
  fieldType: FieldType.group,
  columns: 1,
  display: (networkDetails: SMNetworkDetails): boolean => networkDetails.networkVendor === 'Xconn',
  children: [
    {
      name: 'mediaDetails',
      label: 'Media Details',
      fieldType: FieldType.fieldset,
      display: true,
      children: [
        {
          name: 'crossConnectMediaType',
          fieldType: FieldType.text,
          label: 'Cross Connect MediaType',
          display: true,
        },
        {
          name: 'opticalSFPPlus',
          fieldType: FieldType.text,
          label: 'Optical SFP+',
          display: true,
        },
        {
          name: 'portSpeed',
          fieldType: FieldType.text,
          label: 'Port Speed',
          display: true,
        },
      ],
    },
    {
      name: 'physicalDetails',
      label: 'Physical Details',
      fieldType: FieldType.fieldset,
      display: true,
      children: [
        {
          name: 'crossConnectSwitch1',
          fieldType: FieldType.dropdown,
          options: [],
          label: 'Switch 1',
          display: true,
        },
        {
          name: 'crossConnectSwitch2',
          fieldType: FieldType.dropdown,
          options: [],
          label: 'Switch 2',
          display: true,
        },
        {
          name: 'port1',
          fieldType: FieldType.text,
          label: 'Port 1',
          display: true,
        },
        {
          name: 'port2',
          fieldType: FieldType.text,
          label: 'Port 2',
          display: true,
        },
        {
          name: 'vlan',
          fieldType: FieldType.text,
          label: 'Vlan',
          display: true,
        },
      ],
    },
    {
      name: 'primaryPeerInformation',
      label: 'Primary Peer Information',
      fieldType: FieldType.fieldset,
      display: true,
      children: [
        {
          name: 'primaryXCVolantIP',
          fieldType: FieldType.text,
          label: 'Primary XC Volant IP',
          display: true,
        },
        {
          name: 'primaryXCCIBIP',
          fieldType: FieldType.text,
          label: 'Primary XC CIB IP',
          display: true,
        },
        {
          name: 'secondaryXCVolantIP',
          fieldType: FieldType.text,
          label: 'Secondary XC Volant IP',
          display: true,
        },
        {
          name: 'secondaryXCCIBIP',
          fieldType: FieldType.text,
          label: 'Secondary XC CIB IP',
          display: true,
        },
        {
          name: 'volantBGPAs',
          fieldType: FieldType.numeric,
          label: 'Volant BGP AS',
          display: true,
        },
        {
          name: 'cibBGPAs',
          fieldType: FieldType.numeric,
          label: 'CIB BGP AS',
          display: true,
        },
        {
          name: 'bgpPassword',
          fieldType: FieldType.text,
          label: 'BGP Password',
          display: true,
        },
        {
          name: 'bgpTimers',
          fieldType: FieldType.numeric,
          label: 'BGP Timers',
          display: true,
        },
      ],
    },
    {
      name: 'advertisedRoutes',
      label: 'Advertised Routes',
      fieldType: FieldType.fieldset,
      display: true,
      children: [
        {
          name: 'volantAdvertisedNetworks',
          fieldType: FieldType.ipAddress,
          label: 'Volant Advertised Networks',
          display: true,
        },
        {
          name: 'cibAdvertisedNetworksProd',
          fieldType: FieldType.ipAddress,
          label: 'CIB Advertised Networks (Prod)',
          display: true,
        },
        {
          name: 'cibAdvertisedNetworksUAT',
          fieldType: FieldType.ipAddress,
          label: 'CIB Advertised Networks (UAT)',
          display: true,
        },
      ],
    },
    {
      name: 'FIXSessions',
      label: 'FIX Sessions',
      fieldType: FieldType.fieldset,
      display: true,
      children: [
        {
          name: 'volantTargetPartnersIP',
          label: 'Volant Target Partners IP',
          fieldType: FieldType.ipAddress,
          display: true,
        },
        {
          name: 'cibTargetVolantIP',
          label: 'CIB Target Volant IP',
          fieldType: FieldType.ipAddress,
          display: true,
        },
      ],
    },
  ],
};

export const fields: ReadonlyArray<FieldDefinition<SMNetworkDetails>> = [
  {
    name: 'ip1',
    label: 'IP 1',
    fieldType: FieldType.custom,
    component: NetworkAddressInput,
    display: true,
    isValid: (_: SMNetworkAddress): boolean => {
      return false;
    },
    required: false,
  },
  {
    name: 'ip2',
    label: 'IP 2',
    fieldType: FieldType.custom,
    component: NetworkAddressInput,
    display: true,
    isValid: (_: SMNetworkAddress): boolean => {
      return false;
    },
    required: false,
  },
  {
    name: 'ip3',
    label: 'IP 3',
    fieldType: FieldType.custom,
    component: NetworkAddressInput,
    display: true,
    isValid: (_: SMNetworkAddress): boolean => {
      return false;
    },
    required: false,
  },
  {
    name: 'networkVendor',
    label: 'Network Vendor',
    fieldType: FieldType.dropdown,
    display: true,
    isValid: (_: SMNetworkAddress): boolean => {
      return false;
    },
    options: ['TNS', 'BTR', 'Xconn', 'IPC', 'SSL', 'VPN', 'Internet'].map(
      (name: string): DropdownOption => ({ id: name, name: name })
    ),
    required: false,
  },
  {
    name: 'omsVendor',
    label: 'OMS Vendor',
    fieldType: FieldType.dropdown,
    display: true,
    isValid: (_: SMNetworkAddress): boolean => {
      return false;
    },
    options: [
      'FIDESSA',
      'TRAFFIX',
      'NEOVEST',
      'BLOOMBERG',
      'CLEARPOOL',
      'TRADEWEB',
      'CONVERGEX',
      'NEWEDGE',
      'REDI',
      'LIQUIDNET',
      'MIXIT',
      'EZECASTL',
      'FLEXTRADE',
      'Proprietary',
    ].map((name: string): DropdownOption => ({ id: name, name: name })),
    required: false,
  },
  vpnForm,
  xconnForm,
];
