import React from 'react';

interface Props {
  readonly value: '1' | '2';
}

export const SideRenderer: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value } = props;

  return <div>{labels[value]}</div>;
};

const labels: { [key in '1' | '2']: string } = {
  '1': 'Buy',
  '2': 'Sell',
};
