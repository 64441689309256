import { ColumnDefinition, ColumnType } from 'components/Table/types/column';
import { ProfitabilityReport } from 'types/profitabilityReport';

const columns: ReadonlyArray<ColumnDefinition<ProfitabilityReport>> = [
  {
    key: 'firm',
    columnType: ColumnType.text,
    header: 'Firm',
  },
  {
    key: 'execdqty',
    columnType: ColumnType.integer,
    header: 'Executed Quantity',
  },
  {
    key: 'gross',
    columnType: ColumnType.currency,
    header: 'Gross',
  },
  {
    key: 'net',
    columnType: ColumnType.currency,
    header: 'Net',
  },
  {
    key: 'network_cost',
    columnType: ColumnType.currency,
    header: 'Network Cost',
  },
];

export default columns;
