import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SMSessionDTO } from 'api/interfaces/snapshot';
import { ProcessingState } from 'enums/processingState';
import { Flow } from 'interfaces/flow';
import { Totals } from 'interfaces/totals';
import { ApplicationState } from 'redux/applicationState';
import { FlowUpdate } from 'signalR/interfaces/flowMessage';
import { computeTotals } from 'ui-utils/computeTotals';

export interface FlowsScreenState {
  processingState: ProcessingState;
  totals: Totals;
  entries: readonly Flow[];
}

const initialState: FlowsScreenState = {
  processingState: ProcessingState.idle(),
  totals: {
    all: 0,
    on: 0,
    off: 0,
    overtime: 0,
    error: 0,
    unknown: 0,
  },
  entries: [],
};

const slice = createSlice({
  name: 'flows',
  initialState: initialState,
  reducers: {
    loadFlowsStarted: (state: FlowsScreenState): void => {
      state.processingState = ProcessingState.processing();
    },
    loadFlowsCompleted: (
      state: FlowsScreenState,
      { payload }: PayloadAction<readonly Flow[]>
    ): void => {
      state.entries = payload;
      state.totals = computeTotals(state.entries);
      state.processingState = ProcessingState.idle();
    },
    loadFlowsFailed: (state: FlowsScreenState, action: PayloadAction<string>): void => {
      state.processingState = ProcessingState.error(action.payload);
    },
    flowStatusChanged: (
      state: FlowsScreenState,
      { payload }: PayloadAction<readonly FlowUpdate[]>
    ): void => {
      state.entries = updateFlowsFlowStatus(state.entries, payload);
      state.totals = computeTotals(state.entries);
    },
    sessionStatusChanged: (
      state: FlowsScreenState,
      { payload }: PayloadAction<readonly SMSessionDTO[]>
    ): void => {
      state.entries = updateFlowsSessionStatus(state.entries, payload);
      state.totals = computeTotals(state.entries);
    },
  },
});

export const flowsSelector = (state: ApplicationState): readonly Flow[] => state.flows.entries;

export const flowsTotalsSelector = (state: ApplicationState): Totals => state.flows.totals;

export const processingStateSelector = (state: ApplicationState): ProcessingState =>
  state.flows.processingState;

export const {
  loadFlowsStarted,
  loadFlowsCompleted,
  loadFlowsFailed,
  flowStatusChanged,
  sessionStatusChanged,
} = slice.actions;

export default slice.reducer;

const updateFlowsSessionStatus = (
  flows: readonly Flow[],
  data: readonly SMSessionDTO[]
): readonly Flow[] => {
  return flows.map((item: Flow): Flow => {
    const destination = data.find(
      (session: SMSessionDTO): boolean => session.SessionID === item.destinationSessionID
    );
    const client = data.find(
      (session: SMSessionDTO): boolean => session.SessionID === item.clientSessionID
    );
    if (client && destination) {
      return {
        ...item,
        clientStatus: client.Status ?? item.clientStatus,
        destinationStatus: destination.Status ?? item.destinationStatus,
        clientLastLogon: client.LastLogon ?? item.clientLastLogon,
        destinationLastLogon: destination.LastLogon ?? item.destinationLastLogon,
      };
    } else if (client) {
      return {
        ...item,
        clientStatus: client.Status ?? item.clientStatus,
        clientLastLogon: client.LastLogon ?? item.clientLastLogon,
      };
    } else if (destination) {
      return {
        ...item,
        destinationStatus: destination.Status ?? item.destinationStatus,
        destinationLastLogon: destination.LastLogon ?? item.destinationLastLogon,
      };
    } else {
      return item;
    }
  });
};

export const updateFlowsFlowStatus = (
  flows: readonly Flow[],
  updates: readonly FlowUpdate[]
): readonly Flow[] => {
  return flows.map((item: Flow): Flow => {
    const matching = updates.find((each: FlowUpdate): boolean => {
      return (
        each.ClientSessionID === item.clientSessionID &&
        each.DestinationSessionID === item.destinationSessionID
      );
    });

    if (matching !== undefined) {
      return { ...item, status: matching.Status };
    } else {
      return item;
    }
  });
};
