import { RowData } from 'components/Table/types/rowData';
import React, { useState } from 'react';
import { Identifiable } from 'types/identifiable';

export interface Page<T extends Identifiable> {
  readonly rows: Record<string, RowData<T>>;
  readonly totalPageCount: number;
  readonly currentPage: number;
  readonly totalRowsCount: number;
}

export const usePagination = <T extends Identifiable>(
  data: readonly T[],
  filterFn?: (item: T) => boolean,
  sortFn?: (a: T, b: T) => number,
  pageSize = 300
): [Page<T>, (page: number) => void] => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const resolvedFilterFn = React.useMemo(() => {
    if (filterFn) {
      return filterFn;
    } else {
      return (): boolean => true;
    }
  }, [filterFn]);

  const resolvedSortFn = React.useMemo(() => {
    if (sortFn) {
      return sortFn;
    } else {
      return (): number => 1;
    }
  }, [sortFn]);

  const rows = React.useMemo((): Record<string, RowData<T>> => {
    return data
      .slice()
      .sort(resolvedSortFn)
      .filter(resolvedFilterFn)
      .slice((currentPage - 1) * pageSize, currentPage * pageSize)
      .reduce((rows: Record<string, RowData<T>>, item: T): Record<string, RowData<T>> => {
        return {
          ...rows,
          [String(item.id)]: {
            id: String(item.id),
            data: item,
          },
        };
      }, {});
  }, [currentPage, data, pageSize, resolvedFilterFn, resolvedSortFn]);

  return [
    {
      rows: rows,
      totalPageCount: Math.ceil(data.length / pageSize),
      currentPage: currentPage,
      totalRowsCount: data.length,
    },
    setCurrentPage,
  ];
};
