import { FIXBusinessRejectionDetailsRenderer } from 'components/Table/renderers/SolaceQueueMessageRenderer/FIXBusinessRejectionDetailsRenderer';
import { FIXExecutionReportDetailsRenderer } from 'components/Table/renderers/SolaceQueueMessageRenderer/FIXExecutionReportDetailsRenderer';
import {
  FIXMessage,
  FIXMessageType,
} from 'components/Table/renderers/SolaceQueueMessageRenderer/fixMessageTypes';
import { FIXOrderSingleDetailsRenderer } from 'components/Table/renderers/SolaceQueueMessageRenderer/FIXOrderSingleDetailsRenderer';
import React from 'react';

interface Props {
  readonly message: FIXMessage;

  onShowFIXMessage(): void;
}

export const FIXMessageRendererDetailsHelper: React.FC<Props> = (
  props: Props
): React.ReactElement | null => {
  const { onShowFIXMessage } = props;
  const { message } = props;

  switch (message.MsgType) {
    case FIXMessageType.businessMessageReject:
      return (
        <FIXBusinessRejectionDetailsRenderer message={message} onActionClick={onShowFIXMessage} />
      );
    case FIXMessageType.executionReport:
      return (
        <FIXExecutionReportDetailsRenderer message={message} onActionClick={onShowFIXMessage} />
      );
    case FIXMessageType.orderSingle:
      return <FIXOrderSingleDetailsRenderer message={message} onActionClick={onShowFIXMessage} />;
  }

  return null;
};
