import { Body } from 'components/Table/components/Body';
import { ColumnGroup } from 'components/Table/components/ColumnGroup';
import { TableHeader } from 'components/Table/components/TableHeader';
import styles from 'components/Table/table.module.scss';
import { Column } from 'components/Table/types/column';
import { RowData } from 'components/Table/types/rowData';
import { SortDirection } from 'components/Table/types/sortDirection';
import { verticallyOverflows } from 'extensions-impl';
import { toClassName } from 'helpers/toClassName';
import React from 'react';
import { Identifiable } from 'types/identifiable';
import { applyFilterToRows } from 'ui-utils/applyFilterToRows';
import { applyOrderingToColumns } from 'ui-utils/applyOrderingToColumns';

interface Props<T extends Identifiable> {
  readonly rows: Record<string, RowData<T>>;
  readonly columns: ReadonlyArray<Column<T>>;
  readonly scrollable?: boolean;
  readonly headless?: boolean;

  readonly columnsOrder?: readonly number[];
  readonly filters?: Record<keyof T, string>;
  readonly sorting?: Record<keyof T, SortDirection>;

  readonly loading?: boolean;

  readonly adjustColumns?: boolean;

  readonly rowKey?: keyof T;

  readonly virtualScroll?: boolean;
  readonly growable?: boolean;
  onGrow?(item: T): void;

  rowClassName?(data: T): string | undefined;
  onColumnMoved?(from: number, to: number): void;
  onFiltered?(columnKey: keyof T, value: string): void;
  onColumnSorted?(columnKey: keyof T, value: SortDirection): void;
  onRowClick?(value: any): void;
  onRowMount?(id: string, mounted: boolean): void;
  onResetOriginalLayout?(): void;
  onColumnResized?(columnKey: keyof T, value: SortDirection): void;
}

interface State<T> {
  readonly orderedColumns: ReadonlyArray<Column<T>>;
  readonly filteredRows: ReadonlyArray<RowData<T>>;
  readonly overflowing: boolean;
}

export class Table<T extends Identifiable> extends React.Component<Props<T>, State<T>> {
  private readonly scrollContainerRef = React.createRef<HTMLDivElement>();
  private mutationObserver: MutationObserver;

  constructor(props: Props<T>) {
    super(props);

    this.state = {
      overflowing: false,
      orderedColumns: applyOrderingToColumns(props.columns, props.columnsOrder),
      filteredRows: applyFilterToRows(props.rows, props.columns, props.filters),
    };

    this.mutationObserver = new MutationObserver(this.onMutate);
  }

  private onMutate = (): void => {
    const { current: scrollContainer } = this.scrollContainerRef;

    this.setState({
      overflowing: verticallyOverflows(scrollContainer),
    });
  };

  public static getDerivedStateFromProps<T extends Identifiable>(
    props: Props<T>,
    state: State<T>
  ): Partial<State<T>> {
    return {
      ...state,
      orderedColumns: applyOrderingToColumns(props.columns, props.columnsOrder),
      filteredRows: applyFilterToRows(props.rows, props.columns, props.filters),
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props<T>>, _: State<T>): void {
    const { props } = this;
    if (props.columns !== prevProps.columns || props.columnsOrder !== prevProps.columnsOrder) {
      this.setState({
        orderedColumns: applyOrderingToColumns(props.columns, props.columnsOrder),
      });
    }

    if (props.rows !== prevProps.rows || props.filters !== prevProps.filters) {
      this.setState({
        filteredRows: applyFilterToRows(props.rows, props.columns, props.filters),
      });
    }
  }

  public componentWillUnmount(): void {
    const { mutationObserver } = this;
    mutationObserver.disconnect();
  }

  public componentDidMount(): void {
    const { props, mutationObserver } = this;
    const { current: scrollContainer } = this.scrollContainerRef;

    this.setState({
      overflowing: verticallyOverflows(scrollContainer),
      orderedColumns: applyOrderingToColumns(props.columns, props.columnsOrder),
      filteredRows: applyFilterToRows(props.rows, props.columns, props.filters),
    });

    mutationObserver.observe(scrollContainer.firstElementChild, { childList: true });
  }

  public render(): React.ReactElement {
    const { state, props } = this;
    // FIXME: there has to be a better way than "rowClassName"
    const { rowClassName, virtualScroll = true } = props;
    // Properties
    const { sorting, headless = false, filters, growable, loading = false } = props;
    // Actions
    const {
      onColumnMoved,
      onColumnResized,
      onColumnSorted,
      onFiltered,
      onRowClick,
      onGrow,
      onResetOriginalLayout,
      onRowMount,
    } = props;
    // Local State
    const { orderedColumns: columns, filteredRows: rows, overflowing } = state;

    return (
      <div
        className={toClassName(styles.container, {
          [styles.headless]: headless,
          [styles.overflowing]: overflowing,
        })}
        data-testid="table-container"
      >
        {headless ? null : (
          <div className={styles.header}>
            <TableHeader
              columns={columns}
              filters={filters}
              sorting={sorting}
              onColumnMoved={onColumnMoved}
              onFiltered={onFiltered}
              onColumnSorted={onColumnSorted}
              onResetOriginalLayout={onResetOriginalLayout}
              onColumnResized={onColumnResized}
            />
          </div>
        )}
        {/* This is the scrollable container of the actual table */}
        <div
          ref={this.scrollContainerRef}
          className={toClassName(styles.body, {
            [styles.empty]: rows.length === 0,
          })}
        >
          <table>
            <ColumnGroup columns={columns} />
            <Body
              rows={rows}
              columns={columns}
              growable={growable}
              rowClassName={rowClassName}
              virtualScroll={virtualScroll}
              onRowMount={onRowMount}
              onGrow={onGrow}
              onRowClick={onRowClick}
            />
          </table>
        </div>
        {loading && <div className={styles.loadingSpinner} />}
      </div>
    );
  }
}

export { TableMode } from 'components/Table/tableMode';
