import { DayAndTime } from 'interfaces/dayAndTime';
import moment from 'moment';
import {
  BackendSMConfig,
  findDefaultSMValueByName,
  isInitiator,
  SMBaseConfiguration,
  SMDatabaseDetails,
  SMDefaultConfiguration,
  SMDefaultEntry,
  SMFeatures,
  SMFIXSessionDetails,
  SMLogControl,
  SMNetworkDetails,
  SMSolaceDetails,
} from 'redux/definitions/central';
import { parseRefCfgContent } from 'utils/parseRefCfgContent';
import { asBool, asNumber, asString } from 'utils/smTransformationHelpers';

export interface SMConfig {
  readonly base: SMBaseConfiguration;
  readonly network: SMNetworkDetails;
  readonly solace: SMSolaceDetails;
  readonly features: SMFeatures;
  readonly logControl: SMLogControl;
  readonly fixSessionDetails: SMFIXSessionDetails;
  readonly databaseDetails: SMDatabaseDetails;

  readonly originalConfig: BackendSMConfig | null;
}

export class SMConfig {
  public static fromDefault(defaultValues: SMDefaultConfiguration): SMConfig {
    const data = defaultValues.reduce(
      (obj: Record<string, string>, entry: SMDefaultEntry): Record<string, string> => {
        const name = entry.attributename;

        return {
          ...obj,
          [name.trim()]: entry.attributedefault,
        };
      },
      {}
    );

    const network: SMNetworkDetails = {
      ip1: {
        ip: asString(findDefaultSMValueByName(data, 'SocketConnectHost')),
        port: asNumber(findDefaultSMValueByName(data, 'SocketConnectPort')),
      },
      ip2: {
        ip: '',
        port: null,
      },
      ip3: {
        ip: '',
        port: null,
      },
      networkVendor: '',
      omsVendor: '',
    };

    return {
      originalConfig: null,
      logControl: {
        dayTimeToRotate: DayAndTime.invalid(),
        fileLogPath: '$HOME/log',
        fileName: '',
        logRotateEnabled: asBool(findDefaultSMValueByName(data, 'LOG_ROTATE_ENABLE')),
        multiDayStore: asBool(findDefaultSMValueByName(data, 'MULTI_DAY_STORE')),
        sizeLimitForRotate: asNumber(findDefaultSMValueByName(data, 'SIZE_LIMIT_FOR_ROTATE')),
      },
      network: network,
      solace: {
        routerName: asString(findDefaultSMValueByName(data, 'SolaceRouterName')),
        solaceClientId: '',
        adminTopic: asString(findDefaultSMValueByName(data, 'AdminTopic')),
        heartbeatTopic: asString(findDefaultSMValueByName(data, 'HeartBeatTopic')),
        listenTopic: asString(findDefaultSMValueByName(data, 'ListenTopic')),
      },
      features: {
        dynamicDelimited: asNumber(findDefaultSMValueByName(data, 'DynamicDelimited')),
        marketDataMode: false,
        processMultiLegMode: asString(findDefaultSMValueByName(data, 'ProcessMultiLegMode')),
        refConfigFiles: [],
        refConfigFilePath: asString(findDefaultSMValueByName(data, 'RefDataConfFile')),
      },
      base: {
        enabled: true,
        connectionType: 'initiator',
        senderComp: '',
        targetComp: '',
        start: { time: moment('07:00:00', 'HH:mm:ss'), dayOfWeek: 1 },
        end: { time: moment('17:00:00', 'HH:mm:ss'), dayOfWeek: 5 },
        frequency: 'D',
      },
      fixSessionDetails: {
        fixVersion: asString(findDefaultSMValueByName(data, 'BeginString')),
        fixPassword: '',
        heartbeatInt: asNumber(findDefaultSMValueByName(data, 'HeartBtInt')) ?? 30,
        fileStorePath: asString(findDefaultSMValueByName(data, 'FileStorePath')),
        direction: asString(findDefaultSMValueByName(data, 'Direction')),
        dataDictionary: asString(findDefaultSMValueByName(data, 'DataDictionary')),
        logonTimeout: asNumber(findDefaultSMValueByName(data, 'LogonTimeout')),
        logoutTimeout: asNumber(findDefaultSMValueByName(data, 'LogoutTimeout')),
        persistPath: asString(findDefaultSMValueByName(data, 'PersistPath')),
        refreshOnLogin: asBool(findDefaultSMValueByName(data, 'RefreshOnLogon')),
        rulesInModule: asString(findDefaultSMValueByName(data, 'RulesInModules')),
        rulesOutModule: asString(findDefaultSMValueByName(data, 'RulesOutModules')),
        rulesPath: findDefaultSMValueByName(data, 'RulesPath') ?? '',
        socketNoDelay: asBool(findDefaultSMValueByName(data, 'SocketNodelay')),
        socketReuseAddress: asBool(findDefaultSMValueByName(data, 'SocketReuseAddress')),
        logonTags: asString(findDefaultSMValueByName(data, 'LogonTags')),
        useDataDictionary: false,
        // NOTE: original value from default config seems to be wrong
        validateFieldsHaveValues: false,
        validateFieldsOutOfOrder: asBool(
          findDefaultSMValueByName(data, 'ValidateFieldsOutOfOrder')
        ),
        validateUserDefinedFields: asBool(
          findDefaultSMValueByName(data, 'ValidateUserDefinedFields')
        ),
      },
      databaseDetails: {
        dbServerName: findDefaultSMValueByName(data, 'DBServerName'),
      },
    };
  }

  public static fromBackend(data: BackendSMConfig): SMConfig {
    const { SESSION, DEFAULT } = data.cfgcontent ?? { SESSION: null, DEFAULT: null };

    const network: SMNetworkDetails = isInitiator(DEFAULT)
      ? {
          ip1: {
            ip: asString(DEFAULT?.SocketConnectHost),
            port: asNumber(DEFAULT?.SocketConnectPort),
          },
          ip2: {
            ip: asString(DEFAULT?.SocketConnectHost1),
            port: asNumber(DEFAULT?.SocketConnectPort1),
          },
          ip3: {
            ip: asString(DEFAULT?.SocketConnectHost2),
            port: asNumber(DEFAULT?.SocketConnectPort2),
          },
          networkVendor: '',
          omsVendor: '',
        }
      : {
          ip1: { ip: '', port: null },
          ip2: { ip: '', port: null },
          ip3: { ip: '', port: null },
          networkVendor: '',
          omsVendor: '',
        };

    return {
      originalConfig: data,
      logControl: {
        dayTimeToRotate: DayAndTime.invalid(),
        fileLogPath: asString(DEFAULT?.FileLogPath) ?? '$HOME/log',
        fileName: asString(DEFAULT?.FileName),
        logRotateEnabled: asBool(SESSION?.LOG_ROTATE_ENABLE),
        multiDayStore: asBool(SESSION?.MULTI_DAY_STORE),
        sizeLimitForRotate: asNumber(SESSION?.SIZE_LIMIT_FOR_ROTATE),
      },
      network: network,
      solace: {
        routerName: asString(SESSION?.SolaceRouterName),
        solaceClientId: asString(SESSION?.SolaceClientID),
        adminTopic: asString(SESSION?.AdminTopic),
        heartbeatTopic: asString(SESSION?.HeartBeatTopic),
        listenTopic: asString(SESSION?.ListenTopic),
      },
      features: {
        dynamicDelimited: asNumber(SESSION?.DynamicDelimited),
        marketDataMode: false,
        processMultiLegMode: asString(SESSION?.ProcessMultiLegMode),
        refConfigFiles: parseRefCfgContent(data.refcfgcontent),
        refConfigFilePath: SESSION?.RefDataConfFile,
      },
      base: {
        enabled: asBool(data.active),
        connectionType: asString(DEFAULT?.ConnectionType) as 'acceptor' | 'initiator',
        senderComp: asString(SESSION?.SenderCompID),
        targetComp: asString(SESSION?.TargetCompID),
        start: DayAndTime.fromDayOfWeekAndTimeString(data.startday, data.starttime),
        end: DayAndTime.fromDayOfWeekAndTimeString(data.endday, data.endtime),
        frequency: data.frequency,
      },
      fixSessionDetails: {
        fixVersion: asString(SESSION?.BeginString),
        fixPassword: '',
        heartbeatInt: asNumber(SESSION?.HeartBtInt ?? DEFAULT?.HeartBtInt),
        fileStorePath: asString(SESSION?.FileStorePath),
        direction: asString(SESSION?.Direction),
        dataDictionary: asString(SESSION?.DataDictionary),
        logonTimeout: asNumber(SESSION?.LogonTimeout),
        logoutTimeout: asNumber(SESSION?.LogoutTimeout),
        persistPath: asString(SESSION?.PersistPath),
        refreshOnLogin: asBool(SESSION?.RefreshOnLogon),
        rulesInModule: asString(SESSION?.RulesInModules),
        rulesOutModule: asString(SESSION?.RulesOutModules),
        rulesPath: SESSION?.RulesPath ?? '',
        socketNoDelay: asBool(DEFAULT?.SocketNodelay),
        socketReuseAddress: asBool(DEFAULT?.SocketReuseAddress),
        logonTags: '',
        useDataDictionary: asBool(SESSION?.UseDataDictionary),
        validateFieldsHaveValues: asBool(SESSION?.ValidateFieldsHaveValues),
        validateFieldsOutOfOrder: asBool(SESSION?.ValidateFieldsOutOfOrder),
        validateUserDefinedFields: asBool(SESSION?.ValidateUserDefinedFields),
      },
      databaseDetails: {
        dbServerName: asString(DEFAULT?.DBServerName),
      },
    };
  }

  public static initial(): SMConfig {
    return {
      originalConfig: null,
      logControl: {
        dayTimeToRotate: DayAndTime.invalid(),
        fileName: '',
        fileLogPath: '',
        logRotateEnabled: false,
        multiDayStore: false,
        sizeLimitForRotate: 0,
      },
      network: {
        ip1: { ip: '', port: null },
        ip2: { ip: '', port: null },
        ip3: { ip: '', port: null },
        networkVendor: '',
        omsVendor: '',
      },
      solace: {
        routerName: '',
        solaceClientId: '',
        adminTopic: '',
        listenTopic: '',
        heartbeatTopic: '',
      },
      features: {
        dynamicDelimited: 0,
        marketDataMode: false,
        processMultiLegMode: '',
        refConfigFiles: [],
        refConfigFilePath: '',
      },
      base: {
        enabled: false,
        connectionType: 'initiator',
        senderComp: '',
        targetComp: '',
        start: DayAndTime.invalid(),
        end: DayAndTime.invalid(),
        frequency: 'D',
      },
      fixSessionDetails: {
        fixVersion: '',
        fixPassword: '',
        heartbeatInt: 0,
        fileStorePath: '',
        direction: '',
        dataDictionary: '',
        logonTimeout: 0,
        logoutTimeout: 0,
        persistPath: '',
        refreshOnLogin: false,
        rulesInModule: '',
        rulesOutModule: '',
        rulesPath: '',
        socketNoDelay: false,
        socketReuseAddress: false,
        logonTags: '',
        useDataDictionary: false,
        validateFieldsHaveValues: false,
        validateFieldsOutOfOrder: false,
        validateUserDefinedFields: false,
      },
      databaseDetails: {
        dbServerName: '',
      },
    };
  }
}
