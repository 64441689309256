import { SMRuleInput } from 'ComplexModals/SessionEditor/components/SMRuleInput';
import { FieldDefinition, FieldType } from 'components/Form/components/Field/types';
import { SMFIXSessionDetails } from 'redux/definitions/central';

export const createFields: (
  currentConfig: SMFIXSessionDetails
) => ReadonlyArray<FieldDefinition<SMFIXSessionDetails>> = (
  currentConfig: SMFIXSessionDetails
): ReadonlyArray<FieldDefinition<SMFIXSessionDetails>> => [
  {
    name: 'direction',
    label: 'Direction',
    fieldType: FieldType.dropdown,
    options: [
      { name: 'North', id: 'north' },
      { name: 'South', id: 'south' },
    ],
    required: true,
    display: true,
  },
  {
    name: 'fixVersion',
    label: 'FIX version',
    fieldType: FieldType.dropdown,
    options: [
      {
        name: '4.2',
        id: 'FIX.4.2',
      },
      {
        name: '4.3',
        id: 'FIX.4.3',
      },
      {
        name: '4.4',
        id: 'FIX.4.4',
      },
      {
        name: '5.0',
        id: 'FIX.5.0',
      },
      {
        name: 'T1.1',
        id: 'FIX.T1.1',
      },
    ],
    required: true,
    display: true,
  },

  {
    name: 'heartbeatInt',
    label: 'Heartbeat Int',
    fieldType: FieldType.numeric,
    required: true,
    display: true,
  },
  {
    name: 'socketReuseAddress',
    label: 'Socket reuse address',
    fieldType: FieldType.bool,
    required: false,
    display: true,
  },
  {
    name: 'socketNoDelay',
    label: 'Socket no delay',
    fieldType: FieldType.bool,
    required: false,
    display: true,
  },
  {
    name: 'validateFieldsOutOfOrder',
    label: 'Validate fields out of order',
    fieldType: FieldType.bool,
    required: false,
    display: true,
  },
  {
    name: 'validateFieldsHaveValues',
    label: 'Validate fields have no values',
    fieldType: FieldType.bool,
    required: false,
    display: true,
  },
  {
    name: 'validateUserDefinedFields',
    label: 'Validate user defined fields',
    fieldType: FieldType.bool,
    required: false,
    display: true,
  },
  {
    name: 'refreshOnLogin',
    label: 'Refresh on login',
    fieldType: FieldType.bool,
    required: false,
    display: true,
  },
  {
    name: 'useDataDictionary',
    label: 'Use data dictionary',
    fieldType: FieldType.bool,
    required: false,
    display: true,
  },
  {
    name: 'dataDictionary',
    label: 'Data dictionary',
    fieldType: FieldType.text,
    required: currentConfig.useDataDictionary,
    display: true,
  },
  {
    name: 'logonTimeout',
    label: 'Logon timeout',
    fieldType: FieldType.numeric,
    required: false,
    display: true,
  },
  {
    name: 'logoutTimeout',
    label: 'Logout timeout',
    fieldType: FieldType.numeric,
    required: false,
    display: true,
  },
  {
    name: 'logonTags',
    label: 'Login Info',
    fieldType: FieldType.text,
    required: false,
    display: true,
    isValid: (value: SMFIXSessionDetails): boolean => {
      const { logonTags } = value;
      if (!logonTags || logonTags.length === 0) {
        return true;
      }

      return logonTags.split(',').every((pair: string): boolean => pair.split('=').length === 2);
    },
  },
  {
    name: 'rulesInModule',
    label: 'Rules in module',
    fieldType: FieldType.custom,
    component: SMRuleInput,
    required: true,
    display: true,
    isValid(_: string): boolean {
      return true;
    },
  },
  {
    name: 'rulesOutModule',
    label: 'Rules out module',
    fieldType: FieldType.custom,
    component: SMRuleInput,
    required: true,
    display: true,
    isValid(_: string): boolean {
      return true;
    },
  },
  {
    name: 'rulesPath',
    label: 'Rules path',
    fieldType: FieldType.text,
    required: false,
    display: true,
  },
  {
    name: 'fileStorePath',
    label: 'File store path',
    fieldType: FieldType.text,
    required: false,
    display: true,
  },
  {
    name: 'persistPath',
    label: 'Persist path',
    fieldType: FieldType.text,
    required: false,
    display: true,
  },
];
