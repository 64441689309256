import { Button, FormLabel, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { CustomDatePicker } from 'components/CustomDatePicker';
import { toClassName } from 'helpers/toClassName';
import { Moment } from 'moment/moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { logsBlotterSelector } from 'redux/reducers/logsBlotterReducer';
import styles from 'styles/common-filter-forms.module.scss';
import { SolaceLogEntry } from 'types/solace';

export interface FiltersData {
  readonly sessionId: string;
  readonly fromDate: Moment | null;
  readonly toDate: Moment | null;
}

interface Props {
  readonly filters: FiltersData;

  onChange(filters: FiltersData): void;
}

export const Filters: React.FC<Props> = (props: Props): React.ReactElement => {
  const { filters } = props;
  const { onChange } = props;
  const { logs } = useSelector(logsBlotterSelector);
  const [sessions, setSessions] = React.useState<readonly string[]>([]);

  useEffect((): void => {
    setSessions((oldSessions: readonly string[]): readonly string[] => {
      const allSessions = [
        ...oldSessions,
        ...logs.map((log: SolaceLogEntry): string => log.sessionId),
      ];
      const distinctSessions = new Set<string>(allSessions);
      const sessionsArray = Array.from<string>(distinctSessions);

      return sessionsArray.sort();
    });
  }, [logs]);

  const handleFieldChange = React.useCallback(
    (name: keyof FiltersData, value: any): void => {
      onChange({ ...filters, [name]: value });
      return;
    },
    [filters, onChange]
  );

  const handleSessionIdChange = React.useCallback(
    (event: SelectChangeEvent): void => {
      const { value } = event.target;
      handleFieldChange('sessionId', value);
    },
    [handleFieldChange]
  );

  const handleResetFromDate = React.useCallback((): void => {
    handleFieldChange('fromDate', null);
  }, [handleFieldChange]);

  const handleResetToDate = React.useCallback((): void => {
    handleFieldChange('toDate', null);
  }, [handleFieldChange]);

  return (
    <form className={styles.form}>
      <div className={styles.field}>
        <FormLabel className={styles.label}>Session ID</FormLabel>
        <Select value={filters.sessionId} fullWidth={true} onChange={handleSessionIdChange}>
          <MenuItem value="">All</MenuItem>
          {sessions.map(
            (session: string): React.ReactElement => (
              <MenuItem value={session}>{session}</MenuItem>
            )
          )}
        </Select>
      </div>
      <div className={styles.field}>
        <FormLabel className={styles.label}>From Date</FormLabel>
        <CustomDatePicker
          name="fromDate"
          value={filters.fromDate}
          time={{ hour: 23, minute: 59, second: 59 }}
          className={toClassName(styles.input, styles.datePicker)}
          onDateChange={handleFieldChange}
        />

        <Button classes={resetButtonClasses} onClick={handleResetFromDate}>
          <span>RESET</span>
        </Button>
      </div>
      <div className={styles.field}>
        <FormLabel className={styles.label}>To Date</FormLabel>
        <CustomDatePicker
          name="toDate"
          value={filters.toDate}
          time={{ hour: 23, minute: 59, second: 59 }}
          className={toClassName(styles.input, styles.datePicker)}
          onDateChange={handleFieldChange}
        />

        <Button classes={resetButtonClasses} onClick={handleResetToDate}>
          <span>RESET</span>
        </Button>
      </div>
    </form>
  );
};

const resetButtonClasses = {
  root: styles.resetButton,
};
