import { DropdownOption } from 'types/dropdownOption';

export interface Company {
  readonly address: string | null;
  readonly companyid: number;
  readonly firmname: string;
  readonly firmlabel: string;
  readonly website: string;
}

export class Company {
  public static toDropdownOption(company: Company): DropdownOption {
    return {
      id: company.companyid,
      name: `${company.firmlabel} (${company.firmname})`,
    };
  }
}
