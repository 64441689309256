import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProcessingState } from 'enums/processingState';
import { ApplicationState } from 'redux/applicationState';
import { SolaceLogEntry } from 'types/solace';

export interface LogsBlotterState {
  processingState: ProcessingState;
  logs: readonly SolaceLogEntry[];
}

const initialState: LogsBlotterState = {
  processingState: ProcessingState.idle(),
  logs: [],
};

const slice = createSlice({
  name: 'solaceLogs',
  initialState: initialState,
  reducers: {
    fetchLogsFailed: (state: LogsBlotterState, action: PayloadAction<string>): void => {
      state.processingState = ProcessingState.error(action.payload);
    },
    fetchLogsCompleted: (
      state: LogsBlotterState,
      action: PayloadAction<readonly SolaceLogEntry[]>
    ): void => {
      state.processingState = ProcessingState.idle();
      state.logs = action.payload;
    },
    fetchLogsStarted: (state: LogsBlotterState): void => {
      state.processingState = ProcessingState.processing();
    },
  },
});

export const logsBlotterSelector = (state: ApplicationState): LogsBlotterState =>
  state.errorsBlotter;

export const { fetchLogsStarted, fetchLogsCompleted, fetchLogsFailed } = slice.actions;

export default slice.reducer;
