import { RawMessage } from 'api/interfaces/rawMessage';
import moment, { Moment } from 'moment';

export interface UiMessage {
  readonly clientOrderId: string;
  readonly originationId: string;
  readonly transactionTime: Moment;
  readonly venueId: string;
  readonly side: string;
  readonly messageType: string;
  readonly execType: string;
  readonly symbol: string;
  readonly orderQuantity: number;
  readonly orderPrice: number;
  readonly orderNotional: number;
  readonly executedQuantity: number;
  readonly executedPrice: number;
  readonly executedNotional: number;
  readonly id: number;
}

export class UiMessage {
  public static fromRawMessage(rawMessage: RawMessage): UiMessage {
    const message = new UiMessage();
    const {
      clientOrderID,
      originationID,
      transactionTime,
      venueID,
      side,
      msgType,
      execType,
      symbol,
      orderQuantity,
      orderPrice,
      orderNotional,
      executedQuantity,
      executedNotional,
      executedPrice,
      idlingomsg,
      ...fixTags
    } = rawMessage;

    Object.assign(message, {
      clientOrderId: clientOrderID,
      originationId: originationID,
      transactionTime: moment.utc(transactionTime, 'YYYY-MM-DDTHH:mm:ss.SSS').local(),
      venueId: venueID,
      side: side,
      messageType: msgType,
      execType: execType,
      symbol: symbol,
      orderQuantity: orderQuantity,
      orderPrice: orderPrice,
      orderNotional: orderNotional,
      executedQuantity: executedQuantity,
      executedPrice: executedPrice,
      executedNotional: executedNotional,
      id: idlingomsg,
      ...fixTags,
    });

    return message;
  }
}
