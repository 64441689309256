import { OutlinedInput } from '@mui/material';
import React from 'react';

interface Props {
  readonly value: string;
  readonly id: string;
  readonly disabled?: boolean;
  readonly multiline?: boolean;
  readonly rows?: number;
  readonly fullWidth?: boolean;
  readonly name?: string;

  onChange(value: string): void;
}

export const TextField: React.FC<Props> = (props: Props): React.ReactElement => {
  const { id, value, name, disabled = false, multiline = false, fullWidth = false, rows } = props;
  const { onChange } = props;
  const [internalValue, setInternalValue] = React.useState<string>(value);

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setInternalValue(value);
  }, []);

  React.useEffect((): void => {
    setInternalValue(value);
  }, [value]);

  React.useEffect((): VoidFunction => {
    const timeout = setTimeout((): void => {
      onChange(internalValue);
    }, 300);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [internalValue, onChange]);

  return (
    <OutlinedInput
      id={id}
      value={internalValue}
      fullWidth={fullWidth}
      name={name}
      rows={rows}
      multiline={multiline}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};
