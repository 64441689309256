import { hasRenamedFields } from 'decorators/rename';
import { isMoment } from 'moment';

const encodeValue = (value: any): string => {
  if (isMoment(value)) {
    return value.format('YYYY-MM-DDTHH:mm:ss');
  } else if (typeof value === 'number') {
    return value.toString();
  } else if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'boolean') {
    return value ? 'true' : 'false';
  } else if (value instanceof Date) {
    return value.toISOString();
  } else {
    throw new Error('cannot encode this type of value');
  }
};

const getRenamedKey = <T>(object: T, originalKey: string): string => {
  if (hasRenamedFields(object)) {
    const renamedKey = object.renamedFields[originalKey];
    if (renamedKey === undefined) {
      return originalKey;
    } else {
      return renamedKey;
    }
  }

  return originalKey;
};

const onlyNonEmpty = ([, value]: [string, any]): boolean => {
  const typeOfValue = typeof value;
  switch (typeOfValue) {
    case 'string':
      return value !== '';
    case 'undefined':
      return false;
    default:
      return true;
  }
};

export const toQueryString = <T>(object: T): string => {
  const entries = Object.entries(object);
  const elements: string[] = [];
  for (const [key, value] of entries.filter(onlyNonEmpty)) {
    const encodedKey = getRenamedKey(object, key);
    const encodedValue = encodeValue(value);
    elements.push(`${encodedKey}=${encodedValue}`);
  }
  return elements.join('&');
};
