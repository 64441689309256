import { ApplicationUser } from 'api/interfaces/applicationUser';
import { Form, FormChangeResult } from 'components/Form';
import config from 'config';
import { onIpAddressesAdded } from 'helpers/ipAddressesListParser';
import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { reloadUser } from 'redux/actions/usersForm/reloadUser';
import { ApplicationState } from 'redux/applicationState';
import { UsersFormState } from 'redux/reducers/usersFormReducer';
import fields from 'routes/EntityManagement/UsersManagement/components/UsersForm/fields';
import styles from 'routes/EntityManagement/UsersManagement/users-management.module.scss';

interface DispatchProps {
  reloadUser(email: string): any;
}

interface OwnProps {
  readonly email: string;

  onSave(user: ApplicationUser): void;

  onModifiedChanged?(modified: boolean): void;
  onErrorsChanged?(hasErrors: boolean): void;
}

type Props = OwnProps & UsersFormState & DispatchProps;
type DataProvider = Pick<UsersFormState, 'applications' | 'companies' | 'firms'>;

const UsersForm: React.FC<Props> = (props: Props): React.ReactElement => {
  const { email, user } = props;
  const { onSave, onModifiedChanged, onErrorsChanged } = props;
  const { reloadUser } = props;
  const formValue = React.useMemo(
    (): any => ({ ...user, applicationId: config.defaultApplicationId }),
    [user]
  );

  const provider: DataProvider = React.useMemo(
    (): DataProvider => ({
      applications: props.applications,
      companies: props.companies,
      firms: props.firms,
    }),
    [props.applications, props.companies, props.firms]
  );

  const onChange = React.useCallback((name: string, value: any): FormChangeResult => {
    if (name === 'whitelist') {
      return onIpAddressesAdded(value);
    }

    return { value: value, error: null };
  }, []);

  const onSaveWrapper = React.useCallback(
    (user: ApplicationUser): void => {
      onSave(user);
    },
    [onSave]
  );

  React.useEffect((): VoidFunction => {
    return reloadUser(email);
  }, [email, reloadUser]);

  return (
    <Form
      fields={fields}
      loading={props.working}
      value={formValue}
      provider={provider}
      className={styles.wide}
      onChange={onChange}
      onSubmit={onSaveWrapper}
      onErrorsChanged={onErrorsChanged}
      onModifiedChanged={onModifiedChanged}
    />
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  reloadUser,
};

const mapStateToProps: MapStateToProps<UsersFormState, OwnProps, ApplicationState> = (
  applicationState: ApplicationState
): UsersFormState => applicationState.usersForm;

export default connect(mapStateToProps, mapDispatchToProps)(UsersForm);
