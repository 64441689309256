type TokenType = 'Bearer' | 'Invalid';

export class AccessToken {
  public readonly accessToken: string;
  public readonly idToken: string;
  private readonly expiresAt: number;
  private readonly tokenType: TokenType;

  private static storageKey = 'ACCESS_TOKEN';

  constructor(accessToken: string, idToken: string, expiresAt: number, tokenType: TokenType) {
    this.accessToken = accessToken;
    this.idToken = idToken;
    this.expiresAt = expiresAt;
    this.tokenType = tokenType;
  }

  public static fromUrl(): AccessToken {
    const { hash } = window.location;
    const urlSearchParams = new URLSearchParams(hash.substring(1));

    const accessToken = urlSearchParams.get('access_token');

    if (accessToken === null) {
      const message = urlSearchParams.get('error_description');
      // Throw a new exception
      throw new Error(message ?? 'Unknown error');
    }
    const idToken = urlSearchParams.get('id_token');
    const expiresAt = AccessToken.toExpiresAt(urlSearchParams.get('expires_in'));
    const tokenType = urlSearchParams.get('token_type') as TokenType;

    return new AccessToken(accessToken, idToken, expiresAt, tokenType);
  }

  public static toExpiresAt(value: string | null): number {
    if (value === null) {
      return Date.now();
    }

    return Date.now() + 1000 * parseInt(value);
  }

  public static invalid(): AccessToken {
    return new AccessToken('', '', 0, 'Invalid');
  }

  public static load(): AccessToken {
    const data = localStorage.getItem(AccessToken.storageKey);
    if (data === null) {
      return AccessToken.invalid();
    }

    try {
      const parsed = JSON.parse(data);

      return new AccessToken(
        parsed.accessToken,
        parsed.idToken,
        parsed.expiresAt,
        parsed.tokenType
      );
    } catch {
      return AccessToken.invalid();
    }
  }

  public forget(): void {
    localStorage.removeItem(AccessToken.storageKey);
  }

  public save(): void {
    localStorage.setItem(
      AccessToken.storageKey,
      JSON.stringify({
        accessToken: this.accessToken,
        idToken: this.idToken,
        expiresAt: this.expiresAt,
        tokenType: this.tokenType,
      })
    );
  }

  public isValid(): boolean {
    if (this.tokenType !== 'Bearer') {
      return false;
    }

    return this.expiresAt > Date.now();
  }
}
