import styles from 'components/RefDataConfigurationFileView/ref-data-configuration-file-view.module.scss';
import React from 'react';
import { SMRefDataConfigFile } from 'redux/definitions/central';

interface Props {
  readonly value: SMRefDataConfigFile;
}

export const RefDataConfigurationFileView: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value } = props;

  return (
    <div className={styles.container}>
      <div className={styles.firmName}>{value.id}</div>
      <div className={styles.filePath}>{value.file}</div>
    </div>
  );
};
