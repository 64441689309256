import styles from 'ComplexModals/SessionEditor/central.module.scss';
import { Section } from 'ComplexModals/SessionEditor/components/Section';
import { DataProvider } from 'ComplexModals/SessionEditor/dataProvider';
import { fields } from 'ComplexModals/SessionEditor/forms/SolaceDetailsView/fields';
import { Form, FormChangeResult } from 'components/Form';
import React from 'react';
import { useSelector } from 'react-redux';
import { SMSolaceDetails } from 'redux/definitions/central';
import { SMConfig } from 'redux/definitions/smConfig';
import { solaceServersSelector } from 'redux/reducers/smConfigReducer';

interface Props {
  readonly value: SMSolaceDetails;

  onChange(name: keyof SMConfig, value: SMSolaceDetails): void;
}

const SolaceDetailsView: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value, onChange } = props;

  const solaceServers = useSelector(solaceServersSelector);
  const provider = React.useMemo((): DataProvider => {
    return new DataProvider(solaceServers);
  }, [solaceServers]);

  const handleChange = React.useCallback(
    (name: keyof SMSolaceDetails, fieldValue: any, _: SMSolaceDetails): FormChangeResult => {
      onChange('solace', { ...value, [name]: fieldValue });

      return {
        error: null,
        value: fieldValue,
      };
    },
    [onChange, value]
  );

  return (
    <Section title="Solace Details">
      <Form
        className={styles.form}
        fields={fields}
        value={props.value}
        provider={provider}
        onChange={handleChange}
      />
    </Section>
  );
};

export default SolaceDetailsView;
