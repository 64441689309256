import React from 'react';

interface Props {
  readonly array: readonly any[];

  onChange(array: readonly any[]): void;
}

export const DummyContainer: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>
): React.ReactElement => {
  return <>{props.children}</>;
};
