import { Dialog } from '@mui/material';
import styles from 'components/CenteredModal/centered-modal.module.scss';
import { toClassName } from 'helpers/toClassName';
import React from 'react';

interface Props {
  readonly open: boolean;
  readonly title?: string | React.ReactElement;

  readonly fullHeight?: boolean;
  readonly fullWidth?: boolean;

  onClose?(): void;
}

type CloseFn = (event: React.SyntheticEvent, reason: string) => void;

export const CenteredModal = (props: React.PropsWithChildren<Props>): React.ReactElement => {
  const { children, title, open, fullHeight = false, fullWidth = false } = props;
  const { onClose } = props;
  const content = children as React.ReactElement<string, React.JSXElementConstructor<any>>;

  const onCloseOverride = React.useMemo((): CloseFn | undefined => {
    if (!onClose) {
      return undefined;
    }

    return (_: React.SyntheticEvent, reason: string): void => {
      if (reason === 'backdropClick') {
        return;
      }

      onClose();
    };
  }, [onClose]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      classes={classes}
      open={open}
      fullWidth={fullWidth}
      disableEscapeKeyDown={true}
      onClose={onCloseOverride}
    >
      <div className={styles.titleBar}>
        <div className={styles.titleText}>{title ?? 'Message Box'}</div>
        {onClose ? (
          <div className={styles.closeButton} onClick={onClose}>
            <i className="fa fa-times-circle" />
          </div>
        ) : (
          <div className={styles.closeButton}>
            <i className="fa fa-times-circle" />
          </div>
        )}
      </div>
      <div className={toClassName(styles.content, { [styles.fullHeight]: fullHeight })}>
        {content}
      </div>
    </Dialog>
  );
};

const classes = { root: styles.container };
