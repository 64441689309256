import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { Firm } from 'api/interfaces/firm';
import { CenteredModal } from 'components/CenteredModal';
import { FormModal } from 'components/FormModal';
import { QuestionModal } from 'components/QuestionModal';
import { Scaffold } from 'components/Scaffold';
import { Table, TableMode } from 'components/Table';
import { RowData } from 'components/Table/types/rowData';
import { TaskRunningStatus } from 'components/TaskRunningStatus';
import { CRUDActionsContext } from 'contexts/CRUDActionsContext';
import { useCRUD } from 'hooks/useCRUD';
import { useTable } from 'hooks/useTable';
import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { closeTaskModal } from 'redux/actions/firms/closeTaskModal';
import { createFirm } from 'redux/actions/firms/createFirm';
import { deleteFirm } from 'redux/actions/firms/deleteFirm';
import { getFirms } from 'redux/actions/firms/getFirms';
import { updateFirm } from 'redux/actions/firms/updateFirm';
import { ApplicationState } from 'redux/applicationState';
import { FirmsState } from 'redux/reducers/firmsReducer';
import columns from 'routes/EntityManagement/FirmsManagement/columns';
import FirmsForm from 'routes/EntityManagement/FirmsManagement/components/FirmsForm';
import styles from 'routes/EntityManagement/FirmsManagement/firms-management.module.scss';

interface DispatchProps {
  createFirm(firm: Firm): any;
  updateFirm(firm: Firm): any;
  deleteFirm(firm: Firm): any;
  closeTaskModal(): void;

  getFirms(): any;
}

type Props = DispatchProps & FirmsState;

const FirmsManagement: React.FC<Props> = (props: Props): React.ReactElement => {
  const cancelTaskFnRef = React.useRef<VoidFunction>(null);
  const { createFirm, updateFirm, deleteFirm, closeTaskModal, getFirms } = props;
  const { firms, loading } = props;

  const [crud, mode, currentFirm] = useCRUD(firms, Firm.empty());
  const tableProps = useTable('firms', columns);

  const mappedFirms = React.useMemo(
    (): Record<string, RowData<Firm>> =>
      firms.reduce(
        (mapped: Record<string, RowData<Firm>>, firm: Firm): Record<string, RowData<Firm>> => ({
          ...mapped,
          [firm.firmID]: {
            id: firm.firmID,
            data: firm,
          },
        }),
        {}
      ),
    [firms]
  );

  const onTaskErrored = React.useCallback((): void => {
    closeTaskModal();
  }, [closeTaskModal]);

  const onTaskCompleted = React.useCallback((): void => {
    closeTaskModal();
    crud.reset();
  }, [crud, closeTaskModal]);

  const remove = React.useCallback((): void => {
    cancelTaskFnRef.current = deleteFirm(currentFirm);
  }, [deleteFirm, currentFirm]);

  const save = React.useCallback(
    (firm: Firm): void => {
      if (firm.firmID === -1) {
        cancelTaskFnRef.current = createFirm(firm);
      } else {
        cancelTaskFnRef.current = updateFirm(firm);
      }
    },
    [createFirm, updateFirm]
  );

  React.useEffect((): VoidFunction => {
    return getFirms();
  }, [getFirms]);

  return (
    <CRUDActionsContext.Provider value={crud}>
      <Scaffold title="Firms">
        <Box className={styles.header}>
          <Button onClick={crud.add}>
            <Add /> Add New
          </Button>
        </Box>
        <Table rowKey="firmID" rows={mappedFirms} loading={loading} {...tableProps} />
      </Scaffold>
      <FormModal
        entityName="Firm"
        open={mode === TableMode.edit || mode === TableMode.create}
        mode={mode}
        onClose={crud.reset}
      >
        <FirmsForm firm={currentFirm} onSave={save} />
      </FormModal>
      <CenteredModal title="Delete Firm" open={mode === TableMode.remove} onClose={crud.reset}>
        <QuestionModal
          title="Are you sure?"
          message={`You are going to delete ${currentFirm.firmLabel}`}
          onYes={remove}
          onNo={crud.reset}
        />
      </CenteredModal>
      <TaskRunningStatus
        message={props.taskMessage}
        modalType={props.modalType}
        onErrored={onTaskErrored}
        onSucceeded={onTaskCompleted}
        onCancel={cancelTaskFnRef.current}
      />
    </CRUDActionsContext.Provider>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, any> = {
  createFirm,
  updateFirm,
  deleteFirm,
  getFirms,
  closeTaskModal,
};
const mapStateToProps: MapStateToProps<FirmsState, any, ApplicationState> = (
  applicationState: ApplicationState
): FirmsState => applicationState.firms;

export default connect(mapStateToProps, mapDispatchToProps)(FirmsManagement);
