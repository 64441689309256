import classes from 'ComplexModals/SessionEditor/components/Section/section.module.scss';
import React from 'react';

interface Props {
  readonly title: string;
}

export const Section: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <div className={classes.container}>
      <div className={classes.sectionTitle}>{props.title}</div>
      <div className={classes.sectionContent}>{props.children}</div>
    </div>
  );
};
