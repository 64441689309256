import '@fortawesome/fontawesome-free/css/all.min.css';
import 'sass-constants.css';
import 'index.css';

import { Session, SessionContext } from 'auth/context';
import { createServiceWorker } from 'createServiceWorker';
import { insertVersionMetaTag } from 'helpers/versioning';
import Main from 'main';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from 'reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <SessionContext.Provider value={new Session()}>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </SessionContext.Provider>
);

// Add a version meta tag to know where we stand
insertVersionMetaTag();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Create the cache service worker
createServiceWorker();
