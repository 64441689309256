import { debounce } from '@mui/material';
import styles from 'components/Table/components/HeaderCell/header-cell.module.scss';
import { useDragAndDrop } from 'components/Table/components/HeaderCell/hooks/useDragAndDrop';
import { Column } from 'components/Table/types/column';
import { SortDirection } from 'components/Table/types/sortDirection';
import { toClassName } from 'helpers/toClassName';
import strings from 'locales';
import React from 'react';

interface Props<T> {
  readonly column: Column<T>;
  readonly filter?: string;
  readonly sortDirection: SortDirection;

  onDragStart(): void;
  onDragEnd(): void;
  onColumnMoved?(from: number, to: number): void;
  onFiltered?(columnKey: keyof T, value: string): void;
  onColumnSorted?(columnKey: keyof T, sortDirection: SortDirection): void;
}

export function HeaderCell<T>(props: Props<T>): React.ReactElement {
  // Properties
  const { column, filter = '', sortDirection } = props;
  // Actions
  const { onFiltered, onColumnSorted, onColumnMoved, onDragEnd, onDragStart } = props;

  const reference = useDragAndDrop(onDragStart, onDragEnd, onColumnMoved);

  const onClickPreventDragging = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
  };

  const onFilterChanged = debounce((event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    if (onFiltered) {
      onFiltered(column.key, target.value);
    }
  }, 300);

  const onSortIndicatorClick = React.useCallback((): void => {
    if (onColumnSorted === undefined) {
      return;
    }
    switch (sortDirection) {
      case SortDirection.None:
        onColumnSorted(column.key, SortDirection.Ascending);
        break;
      case SortDirection.Ascending:
        onColumnSorted(column.key, SortDirection.Descending);
        break;
      case SortDirection.Descending:
        onColumnSorted(column.key, SortDirection.None);
        break;
    }
  }, [column.key, onColumnSorted, sortDirection]);

  const handleStartResize = React.useCallback((event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const sortingIndicator = React.useMemo((): string => {
    switch (sortDirection) {
      case SortDirection.None:
        return 'fa-sort';
      case SortDirection.Ascending:
        return 'fa-sort-up';
      case SortDirection.Descending:
        return 'fa-sort-down';
    }
  }, [sortDirection]);

  return (
    <th className={styles.cell} ref={reference}>
      <div className={styles.container}>
        <div className={styles.resizeHandle} onMouseDownCapture={handleStartResize} />
        <div className={styles.label}>
          <div className={styles.labelContent}>{column.header}</div>
          {column.sortable && (
            <div
              className={toClassName(styles.sortHandle, {
                [styles.isSorted]: sortDirection !== SortDirection.None,
              })}
              onMouseDownCapture={onClickPreventDragging}
              onClick={onSortIndicatorClick}
            >
              <i className={'fa ' + sortingIndicator} />
            </div>
          )}
        </div>
        {column.filterable && (
          <div className={styles.filter} onMouseDownCapture={onClickPreventDragging}>
            <input
              placeholder={strings.Filter}
              defaultValue={filter}
              onChange={debounce(onFilterChanged, 100)}
            />
          </div>
        )}
      </div>
    </th>
  );
}
