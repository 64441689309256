import { Api } from 'api';
import { BackendFirm, Firm } from 'api/interfaces/firm';
import { Dispatch } from 'redux';
import { Action } from 'redux/action';
import { createAction } from 'redux/actions/creator';
import { ApplicationState } from 'redux/applicationState';
import { FirmsActions } from 'redux/reducers/firmsReducer';

export const getFirms =
  () =>
  (dispatch: Dispatch<Action<FirmsActions>>, _: () => ApplicationState, api: Api): VoidFunction => {
    dispatch(createAction(FirmsActions.getFirmsStarted));
    const task = api.getFXOFirms();

    task
      .run()
      .then((firms: readonly BackendFirm[]): void => {
        dispatch(createAction(FirmsActions.getFirmsCompleted, firms.map(Firm.fromBackendFirm)));
      })
      .catch((error: any): void => {
        dispatch(createAction(FirmsActions.getFirmsFailed, error));
      });

    return (): void => {
      task.cancel();
    };
  };
