import { DeleteOutline, DriveFileRenameOutline } from '@mui/icons-material';
import { ButtonBase } from '@mui/material';
import styles from 'components/CRUDButtons/crud-buttons.module.scss';
import { CRUDActions, CRUDActionsContext } from 'contexts/CRUDActionsContext';
import React from 'react';

interface Props {
  readonly value: string | number;
}

export const CRUDButtons: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value } = props;
  // We do not care if context is not provided, it will
  // simply DO NOTHING in that case
  const crud = React.useContext<CRUDActions>(CRUDActionsContext);

  const handleEdit = React.useCallback((): void => {
    crud?.edit(value);
  }, [crud, value]);

  const handleRemove = React.useCallback((): void => {
    crud?.remove(value);
  }, [crud, value]);

  return (
    <div className={styles.container}>
      <ButtonBase id={`edit_${value}`} className={styles.button} onClick={handleEdit}>
        <DriveFileRenameOutline className={styles.edit} fontSize="small" />
      </ButtonBase>
      <ButtonBase id={`delete_${value}`} className={styles.button} onClick={handleRemove}>
        <DeleteOutline className={styles.delete} fontSize="small" />
      </ButtonBase>
    </div>
  );
};
