import { Api } from 'api';
import { ApplicationUser } from 'api/interfaces/applicationUser';
import { Dispatch } from 'redux';
import { createAction } from 'redux/actions/creator';
import { ApplicationState } from 'redux/applicationState';
import { UsersFormActions } from 'redux/reducers/usersFormReducer';
import { WhitelistItem } from 'types/whitelistItem';

export const reloadUser =
  (email: string) =>
  (dispatch: Dispatch, getState: () => ApplicationState, api: Api): VoidFunction => {
    dispatch(createAction(UsersFormActions.reloadUserStarted));

    const task1 = email === '' ? null : api.getUserDetails(email);

    const run = async (): Promise<any> => {
      const userDetails = await task1?.run();
      const user = !userDetails
        ? ApplicationUser.defaultUser()
        : ApplicationUser.fromUserDetails(userDetails);

      return {
        user: {
          ...user,
          whitelist: userDetails?.sonic_group
            ? Array.from(
                new Set(
                  Object.values(userDetails.sonic_group).reduce(
                    (all: readonly string[], next: readonly string[]): readonly string[] => [
                      ...all,
                      ...next,
                    ],
                    []
                  )
                )
              ).map((ip: string): WhitelistItem => ({ ipAddress: ip }))
            : [],
        },
      };
    };

    run()
      .then((data: any): void => {
        dispatch(createAction(UsersFormActions.reloadUserCompleted, data));
      })
      .catch((error: any): void => {
        dispatch(createAction(UsersFormActions.resetWorkingState));
        console.warn(error);
      })
      .finally((): void => {
        dispatch(createAction(UsersFormActions.resetWorkingState));
      });

    return (): void => {
      task1?.cancel();
    };
  };
