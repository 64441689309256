import { Clickable } from 'components/Clickable';
import { Field } from 'components/Form/components/Field';
import {
  FieldDefinition,
  FieldType,
  MappableFieldsArray,
  MappedGroup,
  MappedGroupFieldDefinition,
} from 'components/Form/components/Field/types';
import styles from 'components/Form/form.module.scss';
import { FormChangeResult } from 'components/Form/index';
import { toClassName } from 'helpers/toClassName';
import { useApi } from 'hooks/useApi';
import React from 'react';

interface Props<T, P> {
  readonly definition: MappedGroupFieldDefinition<T, P>;
  readonly value: any;
  readonly provider: P;
  readonly required: boolean;

  onChange(name: string, value: any): void;
}

export function MappedGroupField<T, P>(props: Props<T, P>): React.ReactElement {
  const [currentGroup, setCurrentGroup] = React.useState<string | null>(null);
  const [fields, setFields] = React.useState<MappedGroup<T, P>>({});

  const { definition, value, provider, required, onChange } = props;
  const { label } = definition;

  const api = useApi();
  // FIXME: use this at some point
  void required;

  React.useEffect((): void => {
    if (typeof definition.fields === 'function') {
      definition.fields(api, value, provider).then(setFields).catch(console.warn);
    } else {
      setFields(definition.fields);
    }
  }, [api, definition, provider, value]);

  React.useEffect((): void => {
    if (currentGroup !== null) {
      return;
    }

    const ids = Object.keys(fields);
    if (ids.length === 0) {
      return;
    }

    setCurrentGroup(ids[0]);
  }, [currentGroup, fields]);

  const handleChange = React.useCallback(
    (name: string | number | symbol, value: any): FormChangeResult => {
      onChange(name as string, value);

      return { error: null, value: value };
    },
    [onChange]
  );

  const entries = React.useMemo(
    (): ReadonlyArray<[string, MappableFieldsArray<T, P>]> => Object.entries(fields),
    [fields]
  );

  const ids = React.useMemo((): readonly string[] => Object.keys(fields), [fields]);

  return (
    <fieldset className={styles.fieldset}>
      <legend className={toClassName(styles.field, styles[FieldType.mappedGroup])}>
        <span>{label}</span>
      </legend>
      {definition.description && (
        <p className={styles.fieldDescription}>{definition.description}</p>
      )}
      <div className={styles.mappedGroup}>
        <div className={styles.mappedGroupItemList}>
          {ids.map((key: string): React.ReactElement => {
            return (
              <Clickable key={key} data={key} onClick={setCurrentGroup}>
                <div className={key === currentGroup ? styles.selected : undefined}>
                  {definition.renderListItem(value, provider, key)}
                </div>
              </Clickable>
            );
          })}
        </div>
        <div className={styles.mappedGroupFieldsContainer}>
          {entries.map(
            (
              [key, fields]: [string, MappableFieldsArray<T, P>],
              index: number
            ): React.ReactElement => {
              const visible = currentGroup === key;

              return (
                <div
                  key={key}
                  className={toClassName(
                    styles.container,
                    styles.formContainer,
                    styles.mappedGroupFields,
                    visible ? styles.visible : undefined
                  )}
                  style={{
                    transform: visible ? `translateX(-${100 * index}%)` : undefined,
                  }}
                >
                  {fields.map((definition: FieldDefinition<T>): React.ReactElement => {
                    const isRequired =
                      typeof definition.required === 'function'
                        ? definition.required(value, provider)
                        : definition.required;

                    return (
                      <Field
                        key={definition.name as string}
                        definition={definition}
                        value={value[definition.name] ?? value}
                        provider={provider}
                        required={isRequired}
                        onChange={handleChange}
                      />
                    );
                  })}
                </div>
              );
            }
          )}
        </div>
      </div>
    </fieldset>
  );
}
