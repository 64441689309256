import { FieldDefinition, FieldType } from 'components/Form/components/Field/types';
import { RefDataConfigurationFileView } from 'components/RefDataConfigurationFileView';
import { weekDays } from 'constants/weekDays';
import React from 'react';
import {
  SMFeatures,
  SMRefDataConfigFile,
  SMRefDataConfigFilePeriodicUpdate,
  SMRefDataConfigFileUpdateMode,
  SMRefDataConfigFileWeeklyUpdate,
} from 'redux/definitions/central';
import { DropdownOption } from 'types/dropdownOption';

const whenValidator = (value: SMRefDataConfigFile): boolean => {
  if (value.updateMode === undefined) {
    return false;
  }

  return value.updateMode !== SMRefDataConfigFileUpdateMode.periodic;
};

const intervalValidator = (
  value: SMRefDataConfigFile
): value is SMRefDataConfigFilePeriodicUpdate => {
  if (value.updateMode === undefined) {
    return false;
  }

  return value.updateMode === SMRefDataConfigFileUpdateMode.periodic;
};

const dayOfWeekValidator = (
  value: SMRefDataConfigFile
): value is SMRefDataConfigFileWeeklyUpdate => {
  if (value.updateMode === undefined) {
    return false;
  }

  return value.updateMode === SMRefDataConfigFileUpdateMode.weekly;
};

export const fields: ReadonlyArray<FieldDefinition<SMFeatures>> = [
  {
    name: 'processMultiLegMode',
    label: 'Process MultiLeg Mode',
    fieldType: FieldType.dropdown,
    options: [
      {
        id: 'Inbound',
        name: 'Inbound',
      },
      {
        id: 'Outbound',
        name: 'Outbound',
      },
      {
        id: 'Both',
        name: 'Both',
      },
    ],
    display: true,
    required: false,
  },
  {
    name: 'marketDataMode',
    label: 'Market data mode',
    fieldType: FieldType.bool,
    display: true,
    required: false,
  },
  {
    name: 'dynamicDelimited',
    label: 'Dynamic delimited',
    fieldType: FieldType.numeric,
    display: true,
    required: false,
  },
  {
    name: 'refConfigFilePath',
    label: 'Ref Data Configuration File',
    fieldType: FieldType.text,
    display: true,
    required: true,
  },
  {
    name: 'refConfigFiles',
    label: 'Ref data configuration file',
    fieldType: FieldType.array,
    display: true,
    required: false,
    render: (value: SMRefDataConfigFile): React.ReactElement => (
      <RefDataConfigurationFileView value={value} />
    ),
    fields: [
      {
        name: 'id',
        fieldType: FieldType.text,
        label: 'Firm Name',
        required: true,
        display: true,
        readOnly: false,
      },
      {
        name: 'file',
        fieldType: FieldType.text,
        label: 'File',
        required: true,
        display: true,
        readOnly: false,
      },
      {
        name: 'keys',
        fieldType: FieldType.text,
        label: 'Keys',
        display: true,
        isValid: (value: string): boolean => {
          if (value.trim() === '') {
            return true;
          }

          return value
            .trim()
            .split(',')
            .every((item: string): boolean => /[a-z]+/.test(item));
        },
        required: true,
      },
      {
        name: 'updateMode',
        fieldType: FieldType.dropdown,
        label: 'Update Mode',
        required: true,
        display: true,
        options: [
          {
            id: SMRefDataConfigFileUpdateMode.periodic,
            name: 'Periodic',
          },
          {
            id: SMRefDataConfigFileUpdateMode.daily,
            name: 'Daily',
          },
          {
            id: SMRefDataConfigFileUpdateMode.weekly,
            name: 'Weekly',
          },
          {
            id: SMRefDataConfigFileUpdateMode.monthly,
            name: 'Monthly',
          },
        ],
      },
      {
        name: 'interval',
        label: 'Interval',
        fieldType: FieldType.numeric,
        display: intervalValidator,
        isValid: (value: SMRefDataConfigFile): boolean => {
          if (!intervalValidator(value)) {
            return true;
          }

          return value.interval > 0;
        },
      },
      {
        name: 'dayOfWeek',
        fieldType: FieldType.dropdown,
        display: dayOfWeekValidator,
        isValid: (value: SMRefDataConfigFile): boolean => {
          return !dayOfWeekValidator(value);
        },
        options: Object.entries(weekDays).map(
          ([key, value]: [string, string]): DropdownOption => ({
            id: Number(key),
            name: value,
          })
        ),
      },
      {
        name: 'when',
        label: 'When',
        fieldType: FieldType.time,
        display: whenValidator,
        isValid: (value: SMRefDataConfigFile): boolean => {
          return !whenValidator(value);
        },
      },
    ],
  },
];
