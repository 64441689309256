const sides: Record<string, string> = {
  '1': 'Buy',
  '2': 'Sell',
  '3': 'Buy minus',
  '4': 'Sell plus',
  '5': 'Sell short',
  '6': 'Sell short exempt',
  '7': 'Undisclosed',
  '8': 'Cross',
  '9': 'Cross short',
};

export default sides;
