import { Api } from 'api';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { AsyncAction, isAsyncAction } from 'redux/asyncAction';

export const asyncMiddleware =
  (api: Api) =>
  (_: MiddlewareAPI) =>
  (next: Dispatch) =>
  (action: AnyAction | AsyncAction): AnyAction | VoidFunction => {
    if (isAsyncAction(action)) {
      return action(next, api);
    }

    return next(action);
  };
