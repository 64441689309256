import { Api } from 'api';
import { AnyAction } from 'redux';
import { AsyncAction, createAsyncAction } from 'redux/asyncAction';
import {
  fetchExecutionsCompleted,
  fetchExecutionsFailed,
  fetchExecutionsStarted,
} from 'redux/reducers/executionsReducer';
import { Execution, SummaryReportQuery } from 'types/summaryReport';

async function* fetchExecutions(
  api: Api,
  signal: AbortSignal,
  query: SummaryReportQuery
): AsyncGenerator<AnyAction> {
  yield fetchExecutionsStarted();

  try {
    const response = await api.getSummaryReport(signal, query);
    const { Records: records } = response;
    yield fetchExecutionsCompleted(records.map(Execution.fromRawMessage));
  } catch (error: any) {
    yield fetchExecutionsFailed(error);
  }
}

export const fetchExecutionsAction = (query: SummaryReportQuery): AsyncAction =>
  createAsyncAction(fetchExecutions, query);
