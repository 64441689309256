import { DataProvider } from 'ComplexModals/SessionEditor/dataProvider';
import { FieldDefinition, FieldType } from 'components/Form/components/Field/types';
import { SMSolaceDetails } from 'redux/definitions/central';
import { SolaceServer } from 'redux/reducers/smConfigReducer';
import { DropdownOption } from 'types/dropdownOption';

export const fields: ReadonlyArray<FieldDefinition<SMSolaceDetails, DataProvider>> = [
  {
    name: 'solaceClientId',
    label: 'Solace Client ID',
    fieldType: FieldType.text,
    display: true,
    required: false,
  },
  {
    name: 'routerName',
    label: 'Router Name',
    fieldType: FieldType.dropdown,
    options: (
      solaceDetails: SMSolaceDetails,
      provider: DataProvider
    ): readonly DropdownOption[] => {
      const { solaceServers } = provider;
      return solaceServers.map(
        (server: SolaceServer): DropdownOption => ({
          name: server.routername,
          id: server.routername,
        })
      );
    },
    display: true,
    required: false,
  },
  {
    label: 'Listen Topics',
    name: 'listenTopic',
    fieldType: FieldType.text,
    display: true,
    required: false,
  },
  {
    name: 'adminTopic',
    label: 'Admin Topic',
    fieldType: FieldType.text,
    display: true,
    required: false,
  },
  {
    name: 'heartbeatTopic',
    label: 'Heartbeat Topic',
    fieldType: FieldType.text,
    display: true,
    required: false,
  },
];
