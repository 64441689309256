import { Api } from 'api';
import { OktaGroup, User as BackendUser } from 'api/interfaces/user';
import { User } from 'auth/user';
import { Dispatch } from 'redux';
import { createAction } from 'redux/actions/creator';
import { ApplicationState } from 'redux/applicationState';
import { CurrentUserActions } from 'redux/reducers/currentUserReducer';

export const getCurrentUser = () => {
  return (dispatch: Dispatch<any>, _: () => ApplicationState, api: Api): VoidFunction => {
    const task = api.me();
    task
      .run()
      .then((me: BackendUser): void => {
        dispatch(
          createAction(
            CurrentUserActions.setUser,
            new User({
              id: me.email_address,
              email: me.email_address,
              firstName: me.first_name,
              lastName: me.last_name,
              groups: me.okta_group.map((group: OktaGroup): string => group.name),
              logoUrl: '',
            })
          )
        );
      })
      .catch((error: any): void => {
        console.warn(error);
      });

    return (): void => {
      task.cancel();
    };
  };
};
