export enum ProcessingType {
  idle,
  success,
  error,
  processing,
}

export interface ProcessingState {
  readonly processingType: ProcessingType;
}

export interface ErrorProcessingState extends ProcessingState {
  readonly processingType: ProcessingType.error;
  readonly message: string;
}

export interface SuccessProcessingState extends ProcessingState {
  readonly processingType: ProcessingType.success;
  readonly message?: string;
}

export interface ProcessingProcessingState extends ProcessingState {
  readonly processingType: ProcessingType.processing;
  readonly data?: any;
}

export type IdleProcessingState = ProcessingState;

export type StatelessProcessingState =
  | ErrorProcessingState
  | SuccessProcessingState
  | IdleProcessingState;

export class ProcessingState {
  public static idle(): StatelessProcessingState {
    return {
      processingType: ProcessingType.idle,
    };
  }

  public static success(message?: string): StatelessProcessingState {
    return {
      processingType: ProcessingType.success,
      message: message,
    };
  }

  public static error(message: string): ErrorProcessingState {
    return {
      processingType: ProcessingType.error,
      message: message,
    };
  }

  public static processing(data?: any): ProcessingProcessingState {
    return {
      processingType: ProcessingType.processing,
      data: data,
    };
  }

  public static isError(processingState: ProcessingState): processingState is ErrorProcessingState {
    return processingState.processingType === ProcessingType.error;
  }

  public static isSuccess(
    processingState: ProcessingState
  ): processingState is SuccessProcessingState {
    return processingState.processingType === ProcessingType.success;
  }

  public static isIdle(processingState: ProcessingState): processingState is IdleProcessingState {
    return processingState.processingType === ProcessingType.idle;
  }

  public static isProcessing(
    processingState: ProcessingState
  ): processingState is ProcessingProcessingState {
    return processingState.processingType === ProcessingType.processing;
  }
}
