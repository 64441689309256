import { parse } from 'FIX/parser';
import { FIXTaggedField } from 'interfaces/FIXTaggedField';

export interface RawSolaceLogEntry {
  readonly logentryid: string;
  readonly environment: string;
  readonly loglevel: string;
  readonly clientid: string;
  readonly message: string;
  readonly sessionid: string;
  readonly timestamp: string;
}

export type LogLevelValue = 'E' | 'I' | 'W';

export interface SolaceLogEntry {
  readonly id: string;
  readonly environment: string;
  readonly logLevel: LogLevelValue;
  readonly clientId: string;
  readonly message: string;
  readonly sessionId: string;
  readonly timestamp: Date;
}

export class SolaceLogEntry {
  public static fromRaw(raw: RawSolaceLogEntry): SolaceLogEntry {
    return {
      id: raw.logentryid,
      environment: raw.environment,
      logLevel: raw.loglevel as LogLevelValue,
      clientId: raw.clientid,
      message: raw.message,
      sessionId: raw.sessionid,
      timestamp: new Date(raw.timestamp),
    };
  }
}

export interface RawSolaceQueueEntry {
  readonly msgid: string;
  readonly topic: string;
  readonly msg: string;
}

export enum SolaceMessageType {
  fix = 'fix',
  json = 'json',
  error = 'error',
}

export interface SolaceQueueMessageBase {
  readonly type: SolaceMessageType;
}

export interface ErrorSolaceQueueMessage extends SolaceQueueMessageBase {
  readonly type: SolaceMessageType.error;
  readonly content: string;
}

export interface FIXSolaceQueueMessage extends SolaceQueueMessageBase {
  readonly type: SolaceMessageType.fix;
  readonly content: readonly FIXTaggedField[];
}

export interface JSONSolaceQueueMessage extends SolaceQueueMessageBase {
  readonly type: SolaceMessageType.json;
  readonly content: Record<string, any>;
}

export type SolaceQueueMessage =
  | FIXSolaceQueueMessage
  | JSONSolaceQueueMessage
  | ErrorSolaceQueueMessage;

export interface SolaceQueueEntry {
  readonly id: string;
  readonly messageId: string;
  readonly topic: string;
  readonly message: SolaceQueueMessage;
}

export class SolaceQueueEntry {
  public static fromRaw(raw: RawSolaceQueueEntry): SolaceQueueEntry {
    try {
      return {
        id: raw.msgid,
        messageId: raw.msgid,
        topic: raw.topic,
        message: {
          type: SolaceMessageType.fix,
          content: parse(raw.msg),
        },
      };
    } catch (error: any) {
      try {
        return {
          id: raw.msgid,
          messageId: raw.msgid,
          topic: raw.topic,
          message: {
            type: SolaceMessageType.json,
            content: JSON.parse(raw.msg),
          },
        };
      } catch (error: any) {
        return {
          id: raw.msgid,
          messageId: raw.msgid,
          topic: raw.topic,
          message: {
            type: SolaceMessageType.error,
            content: raw.msg,
          },
        };
      }
    }
  }
}
