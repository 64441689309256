import { ColumnDefinition } from 'components/Table/types/column';
import { RowData } from 'components/Table/types/rowData';
import { ProcessingState } from 'enums/processingState';
import { UiMessage } from 'interfaces/message';
import moment, { Moment } from 'moment';
import { Reducer } from 'redux';
import { Action } from 'redux/action';

export enum MessagesScreenAction {
  setVenues = '/actions/messages/set-venues',
  setOrderOriginationIds = '/actions/messages/set-order-origination-ids',
  setFormValue = '/actions/messages/set-form-value',
  startSearch = '/actions/messages/start-search',
  endSearch = '/actions/messages/end-search',
  setMessages = '/actions/messages/set-messages',
}

export interface MessageSearchForm {
  readonly orderOriginationId: readonly string[];
  readonly venue: readonly string[];
  readonly side: string;
  readonly symbols: string;
  readonly clientOrderId: string;
  readonly fromDate: Moment;
  readonly toDate: Moment;
}

export interface MessagesScreenState {
  // Reference data
  readonly venues: readonly string[];
  readonly orderOriginationIds: readonly string[];
  // FirmsForm state
  readonly form: MessageSearchForm;
  // State
  readonly processingState: ProcessingState;
  // Messages
  readonly messages: Record<string, RowData<UiMessage>> | null;
  // Row processing states
  readonly rowsProcessingState: Record<string, ProcessingState>;

  readonly columns: ReadonlyArray<ColumnDefinition<any>>;
}

const initialState: MessagesScreenState = {
  venues: [],
  orderOriginationIds: [],
  form: {
    orderOriginationId: [],
    venue: [],
    side: '',
    symbols: '',
    clientOrderId: '',
    fromDate: moment().set({ hour: 0, minute: 0, second: 0 }),
    toDate: moment().set({ hour: 23, minute: 59, second: 59 }),
  },
  rowsProcessingState: {},
  processingState: ProcessingState.idle(),
  columns: [],
  messages: null,
};

const reducer: Reducer<MessagesScreenState, Action<MessagesScreenAction>> = (
  state: MessagesScreenState = initialState,
  action: Action<MessagesScreenAction>
): MessagesScreenState => {
  const { type, data } = action;
  switch (type) {
    case MessagesScreenAction.setOrderOriginationIds:
      return { ...state, orderOriginationIds: data };
    case MessagesScreenAction.setFormValue:
      return {
        ...state,
        form: { ...state.form, [data.key]: data.value },
      };
    case MessagesScreenAction.startSearch:
      return { ...state, processingState: ProcessingState.processing(), messages: null };
    case MessagesScreenAction.setVenues:
      return { ...state, venues: data };
    case MessagesScreenAction.setMessages:
      return { ...state, messages: data };
    case MessagesScreenAction.endSearch:
      return {
        ...state,
        processingState:
          data !== null
            ? ProcessingState.error('Could not complete search')
            : ProcessingState.idle(),
      };
    default:
      return state;
  }
};

export default reducer;
