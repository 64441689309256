import styles from 'components/Table/renderers/DayAndTimeCellRenderer/date-and-time-cell-renderer.module.scss';
import { weekDays } from 'constants/weekDays';
import { DayAndTime } from 'interfaces/dayAndTime';
import React from 'react';

interface Props {
  readonly value: DayAndTime;
}

export const DayAndTimeCell = (props: Props): React.ReactElement => {
  const { time, dayOfWeek } = props.value;
  if (time === undefined) {
    return <div>?</div>;
  }

  return (
    <div className={styles.cell}>
      <span>{weekDays[dayOfWeek]}</span>
      <span> </span>
      <span>{time.format('LT')}</span>
    </div>
  );
};
