import { AsyncAction, createAsyncAction } from 'redux/asyncAction';
import {
  fetchRiskEnginesService,
  fetchRiskMatrixService,
  fetchRiskSessionsService,
  updateRiskMatrixService,
} from 'redux/services/riskServices';
import { RiskMatrix } from 'types/risk';

export const fetchRiskMatrixAction = (sessionID: string, engineID: string): AsyncAction =>
  createAsyncAction(fetchRiskMatrixService, sessionID, engineID);

export const updateRiskMatrixAction = (matrix: RiskMatrix): AsyncAction =>
  createAsyncAction(updateRiskMatrixService, matrix);

export const fetchRiskSessionsAction = (riskEngineID: string): AsyncAction =>
  createAsyncAction(fetchRiskSessionsService, riskEngineID);

export const fetchRiskEnginesAction = (): AsyncAction => createAsyncAction(fetchRiskEnginesService);
