import { Column, ColumnDefinition, createFromDefinition } from 'components/Table/types/column';
import { SortDirection } from 'components/Table/types/sortDirection';
import { Action } from 'redux/action';

export enum TableAction {
  resetColumns = '/actions/table/reset-columns',
  setColumnsOrder = '/actions/table/set-columns-order',
  setFilters = '/actions/table/set-filters',
  setSorting = '/actions/table/set-sorted-columns',
  setColumns = '/actions/table/set-columns',
}

export interface TableState<T> {
  readonly columns: ReadonlyArray<Column<T>>;
  readonly columnsOrder?: readonly number[];
  readonly filters?: Record<keyof T, string>;
  readonly sortingOrder: Record<keyof T, SortDirection>;
  readonly key: string;
}

export const initialTableState = <T>(
  key: string,
  originalColumns: ReadonlyArray<ColumnDefinition<T>>
): TableState<T> => {
  const savedState = localStorage.getItem(`/tables/${key}`);
  if (savedState === null) {
    return {
      columns: [],
      sortingOrder: {} as Record<keyof T, SortDirection>,
      key: key,
    };
  } else {
    const { columns, ...settings } = JSON.parse(savedState);
    const mergedColumns = columns.map((savedColumn: Record<string, any>): any => {
      const originalColumn: ColumnDefinition<T> = originalColumns.find(
        (each: ColumnDefinition<T>): boolean => each.key === savedColumn?.key
      );

      return originalColumn ?? savedColumn;
    });

    return {
      ...settings,
      columns: mergedColumns.map(createFromDefinition),
    };
  }
};

const persist = <T>(state: TableState<T>): TableState<T> => {
  localStorage.setItem(`/tables/${state.key}`, JSON.stringify(state));

  return state;
};

function reducer<T>(
  state: TableState<T> = initialTableState('INVALID', []),
  action: Action<TableAction>
): TableState<T> {
  const { type, data } = action;
  switch (type) {
    case TableAction.resetColumns:
      return persist({
        ...state,
        columns: data,
        columnsOrder: data.map((_: Column<T>, index: number): number => index),
        sortingOrder: {} as Record<keyof T, SortDirection>,
        filters: undefined,
      });
    case TableAction.setColumns:
      return persist({ ...state, columns: data });
    case TableAction.setColumnsOrder:
      return persist({ ...state, columnsOrder: data });
    case TableAction.setFilters:
      return persist({ ...state, filters: data });
    case TableAction.setSorting:
      return persist({ ...state, sortingOrder: data });
    default:
      return state;
  }
}

export default reducer;
