// noinspection JSUnreachableSwitchBranches

import { Api } from 'api';
import React from 'react';
import { DropdownOption } from 'types/dropdownOption';

export type BooleanResolvable<T, P> = boolean | ((value: T, provider?: P) => boolean);

export enum FieldType {
  ipAddress = 'ip-address',
  text = 'text',
  time = 'time',
  longText = 'long-text',
  dropdown = 'dropdown',
  custom = 'custom',
  numeric = 'numeric',
  bool = 'bool',
  email = 'email',
  phone = 'phone',
  multiBool = 'multi-bool',
  array = 'array',
  group = 'group',
  fieldset = 'fieldset',
  mappedGroup = 'mapped-group',
}

export interface CustomFieldProps<T> {
  readonly value: T;

  onChange(value: T): void;
}

export interface ArrayContainer<T = any> {
  readonly array: readonly T[];

  onChange(array: readonly T[]): void;
}

export interface FieldDefinitionBase<T, P> {
  readonly name: keyof T | string;
  readonly fieldType: FieldType;
  readonly label?: string;
  readonly display: BooleanResolvable<T, P>;
  readonly required?: BooleanResolvable<T, P>;
  readonly readOnly?: boolean;
  readonly description?: string;

  isValid?(value: unknown): boolean;
}

export interface CustomFieldDefinition<T, P> extends FieldDefinitionBase<T, P> {
  readonly name: keyof T;
  readonly fieldType: FieldType.custom;
  readonly component:
    | React.FunctionComponent<CustomFieldProps<any>>
    | React.ComponentClass<CustomFieldProps<any>>;
}

export type BasicFieldDefinition<T, P> =
  | PrimitiveFieldDefinition<T, P>
  | DropdownFieldDefinition<T, P>
  | MultiBoolFieldDefinition<T, P>
  | CustomFieldDefinition<T, P>;

export interface FieldsetFieldDefinition<T, P> extends FieldDefinitionBase<T, P> {
  readonly name: string;
  readonly label: string;
  readonly fieldType: FieldType.fieldset;
  readonly children: ReadonlyArray<BasicFieldDefinition<any, P> | CustomFieldDefinition<any, P>>;
}

export type FieldDefinition<T, P = any> =
  | BasicFieldDefinition<T, P>
  | CustomFieldDefinition<T, P>
  | ArrayFieldDefinition<T, P>
  | FieldGroupDefinition<T, P>
  | FieldsetFieldDefinition<T, P>
  | MappedGroupFieldDefinition<T, P>;

export interface FieldGroupDefinition<T, P> extends FieldDefinitionBase<T, P> {
  readonly name: string;
  readonly fieldType: FieldType.group;
  readonly children: ReadonlyArray<FieldDefinition<any, P>>;
  readonly columns?: number;
}

export interface ArrayFieldDefinition<T, P> extends FieldDefinitionBase<T, P> {
  readonly name: keyof T;
  readonly fieldType: FieldType.array;
  readonly label: string;
  readonly fields: ReadonlyArray<FieldDefinition<any, P>>;

  readonly container?:
    | React.FunctionComponent<ArrayContainer>
    | React.ComponentClass<ArrayContainer>;

  render(value: unknown): React.ReactNode;
}

export interface MultiBoolFieldDefinition<T, P> extends FieldDefinitionBase<T, P> {
  readonly name: keyof T;
  readonly fieldType: FieldType.multiBool;
  readonly options:
    | readonly DropdownOption[]
    | ((value: T, provider: P) => readonly DropdownOption[]);
}

export type MappableFieldsArray<T, P> = Array<
  | BasicFieldDefinition<T, P>
  | CustomFieldDefinition<T, P>
  | ArrayFieldDefinition<T, P>
  | FieldGroupDefinition<T, P>
  | MultiBoolFieldDefinition<T, P>
  | FieldsetFieldDefinition<T, P>
>;

export type MappedGroup<T, P> = Record<string, MappableFieldsArray<T, P>>;

export interface MappedGroupFieldDefinition<T, P> extends FieldDefinitionBase<T, P> {
  readonly name: string;
  readonly fieldType: FieldType.mappedGroup;
  readonly label: string;
  readonly fields:
    | ((api: Api, value: T, provider: P) => Promise<MappedGroup<T, P>>)
    | MappedGroup<T, P>;

  // FIXME: probably we can specify what group is
  renderListItem(value: T, provider: P, key: string): React.ReactNode;
}

export interface PrimitiveFieldDefinition<T, P> extends FieldDefinitionBase<T, P> {
  readonly name: keyof T;
}

export interface DropdownFieldDefinition<T, P> extends FieldDefinitionBase<T, P> {
  readonly name: keyof T;
  readonly fieldType: FieldType.dropdown;
  readonly options?:
    | readonly DropdownOption[]
    | ((value: T, provider: P) => readonly DropdownOption[]);
}

export const isCustomFieldDefinition = <T, P>(
  value: FieldDefinition<T, P>
): value is CustomFieldDefinition<T, P> => value.fieldType === FieldType.custom;

export const isGroupDefinition = <T, P>(
  value: FieldDefinition<T, P>
): value is FieldGroupDefinition<T, P> => value.fieldType === FieldType.group;

export const isFieldsetDefinition = <T, P>(
  value: FieldDefinition<T, P>
): value is FieldsetFieldDefinition<T, P> => value.fieldType === FieldType.fieldset;

export const isArrayFieldDefinition = <T, P>(
  value: FieldDefinition<T, P>
): value is ArrayFieldDefinition<T, P> => value.fieldType === FieldType.array;

export const isMappedGroupFieldDefinition = <T, P>(
  value: FieldDefinition<T, P>
): value is MappedGroupFieldDefinition<T, P> => value.fieldType === FieldType.mappedGroup;

export const isBasicFieldDefinition = <T, P>(
  value: FieldDefinition<T, P>
): value is BasicFieldDefinition<T, P> => {
  switch (value.fieldType) {
    case FieldType.fieldset:
    case FieldType.array:
    case FieldType.group:
    case FieldType.custom:
    case FieldType.multiBool:
    case FieldType.mappedGroup:
      return false;
  }

  return true;
};

export function isDropdownFieldDefinition<T, P>(
  value: FieldDefinition<T, P>
): value is DropdownFieldDefinition<T, P> {
  return value.fieldType === FieldType.dropdown;
}

export function isMultiBoolFieldDefinition<T, P>(
  value: FieldDefinition<T, P>
): value is MultiBoolFieldDefinition<T, P> {
  return value.fieldType === FieldType.multiBool;
}
