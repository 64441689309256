import { FormLabel } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createAssetClassRequest, setAccountIDTag } from 'redux/reducers/riskReducer';
import { NumericInput } from 'routes/Risk/components/NumericInput';
import { SymbolLimitsTable } from 'routes/Risk/components/SymbolLimitsTable';
import classes from 'routes/Risk/risk.module.scss';
import { AccountsGroup, AssetClassType } from 'types/risk';

interface Props {
  readonly label: string;
  readonly group: AccountsGroup;
  readonly type: AssetClassType;
}

export const AssetClassView: React.FC<Props> = (props: Props): React.ReactElement => {
  const { label, type, group } = props;
  const { accounttag, accounts } = group;

  const dispatch = useDispatch();

  const handleAccountTagIDChange = React.useCallback(
    (_: any, value: number): void => {
      dispatch(setAccountIDTag(createAssetClassRequest(type, value)));
    },
    [dispatch, type]
  );

  return (
    <div className={classes.assetClassView}>
      <h6 className={classes.assetClassLabel}>{label}</h6>
      <div className={classes.groupHeading}>
        <div className={classes.groupHeadingItem}>
          <div className={classes.groupHeadingItemLabel}>
            <FormLabel>Account ID Tag</FormLabel>
          </div>
          <div className={classes.groupHeadingItemEditor}>
            <NumericInput
              name="accounttag"
              value={accounttag}
              onChange={handleAccountTagIDChange}
            />
          </div>
        </div>
      </div>

      <SymbolLimitsTable items={accounts} type={type} label={label} />
      <div className={classes.submitRowNotice}>
        Press ENTER/TAB to move to the next cell. If it is the last cell, the row will be submitted
      </div>
    </div>
  );
};
