import { Checkbox, FormLabel } from '@mui/material';
import styles from 'components/Form/form.module.scss';
import React from 'react';
import { DropdownOption } from 'types/dropdownOption';

interface Props<T> {
  readonly options: readonly DropdownOption[];
  readonly value: readonly DropdownOption[];
  readonly name: keyof T;
  readonly required: boolean;

  onChange(name: keyof T, value: readonly DropdownOption[]): void;
}

export class MultiBool<T> extends React.Component<Props<T>> {
  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, options, value } = this.props;
    const { onChange } = this.props;
    const { checked, value: id } = event.target;
    const itemValue = options.find((option: DropdownOption): boolean => option.id === id);
    if (itemValue === undefined) {
      throw new Error('attempting to set a boolean from an unknown item');
    }

    const newValue = checked
      ? [...value, itemValue]
      : value.filter((item: DropdownOption): boolean => item.id !== id);

    onChange(name, newValue);
  };

  public render(): React.ReactElement {
    const { options, value } = this.props;

    return (
      <div className={styles.multiBoolean}>
        {options.map((option: DropdownOption): React.ReactElement => {
          const isChecked = Array.isArray(value)
            ? value.map((item: DropdownOption): string | number => item.id).includes(option.id)
            : false;

          return (
            <div key={option.id} className={styles.multiBooleanItem}>
              <Checkbox
                id={option.name}
                checked={isChecked}
                value={option.id}
                onChange={this.onChange}
              />
              <FormLabel htmlFor={option.name}>{option.name}</FormLabel>
            </div>
          );
        })}
      </div>
    );
  }
}
