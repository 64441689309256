import { CenteredModal as Modal } from 'components/CenteredModal';
import { FIXMessageView } from 'components/FIXMessage';
import { FIXTagValue } from 'FIX/parser';
import { FIXTaggedField } from 'interfaces/FIXTaggedField';
import React from 'react';
import { useSelector } from 'react-redux';
import { executionsSelector } from 'redux/reducers/executionsReducer';
import styles from 'routes/Executions/executions.module.scss';
import { Execution } from 'types/summaryReport';

interface Props {
  readonly value: string;
}

export const ExecutionRowTool: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value } = props;

  const executions = useSelector(executionsSelector);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const openModal = React.useCallback((): void => setIsOpen(true), []);
  const closeModal = React.useCallback((): void => setIsOpen(false), []);

  const currentExecution = React.useMemo((): Execution => {
    return executions.find((execution: Execution): boolean => execution.id === value);
  }, [executions, value]);

  const message = React.useMemo((): readonly FIXTaggedField[] => {
    const { raw } = currentExecution;

    const entries = Object.entries(raw);
    return entries
      .map(([key, value]: [string, string]): [number, string] => {
        return [Number(key), value];
      })
      .filter(([tag]: [number, string]): boolean => {
        return !isNaN(tag);
      })
      .map(([tag, value]: [number, string]): FIXTaggedField => {
        const tagValue = new FIXTagValue(tag, value);
        return tagValue.toField();
      });
  }, [currentExecution]);

  return (
    <>
      <div className={styles.expandButton} onClick={openModal}>
        <i className="fa fa-external-link-alt" />
      </div>

      <Modal open={isOpen} title={`Details of Execution: ${value}`} onClose={closeModal}>
        <FIXMessageView message={message} />
      </Modal>
    </>
  );
};
