import { TableMode } from 'components/Table';
import { CRUDActions } from 'contexts/CRUDActionsContext';
import React from 'react';
import { Identifiable } from 'types/identifiable';

export const useCRUD = <T extends Identifiable>(
  items: readonly T[],
  empty: T
): [CRUDActions, TableMode, T] => {
  const [mode, setMode] = React.useState<TableMode>(TableMode.none);
  const [item, setItem] = React.useState<T>(empty);

  class ActionsContext implements CRUDActions {
    public edit(id: string | number): void {
      const targetObject = items.find((item: T): boolean => id === item.id);

      if (targetObject) {
        setMode(TableMode.edit);
        setItem(targetObject);
      }
    }

    public clone(id: string | number): void {
      const targetItem = items.find((item: T): boolean => id === item.id);

      if (targetItem) {
        setMode(TableMode.clone);
        setItem(targetItem);
      }
    }

    public add(): void {
      setMode(TableMode.create);
    }

    public remove(id: string | number): void {
      const targetItem = items.find((item: T): boolean => id === item.id);

      if (targetItem) {
        setMode(TableMode.remove);
        setItem(targetItem);
      }
    }

    public reset(): void {
      setMode(TableMode.none);
      setItem(empty);
    }
  }

  return [new ActionsContext(), mode, item];
};
