import { DayTimeInput } from 'components/DayTimeInput';
import { FieldDefinition, FieldType } from 'components/Form/components/Field/types';
import { SMBaseConfiguration } from 'redux/definitions/central';

export const fields: ReadonlyArray<FieldDefinition<SMBaseConfiguration>> = [
  {
    name: 'enabled',
    label: 'Enabled',
    fieldType: FieldType.bool,
    display: true,
    required: false,
  },
  {
    name: 'connectionType',
    label: 'Connection Type',
    fieldType: FieldType.dropdown,
    options: [
      {
        name: 'Initiator',
        id: 'initiator',
      },
      {
        name: 'Acceptor',
        id: 'acceptor',
      },
    ],
    required: true,
    display: true,
  },
  {
    name: 'senderComp',
    label: 'Sender Comp',
    fieldType: FieldType.text,
    required: true,
    display: true,
  },
  {
    name: 'targetComp',
    label: 'Target Comp',
    fieldType: FieldType.text,
    required: true,
    display: true,
  },
  {
    name: 'frequency',
    label: 'Frequency',
    fieldType: FieldType.dropdown,
    options: [
      { name: 'Daily', id: 'D' },
      { name: 'Weekly', id: 'W' },
    ],
    display: true,
    required: true,
  },
  {
    name: 'start',
    label: 'Start Day/Time',
    fieldType: FieldType.custom,
    required: false,
    display: true,
    component: DayTimeInput,
    isValid: (_: unknown): boolean => true,
  },
  {
    name: 'end',
    label: 'End Day/Time',
    fieldType: FieldType.custom,
    required: false,
    display: true,
    component: DayTimeInput,
    isValid: (_: unknown): boolean => true,
  },
];
