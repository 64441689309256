import { FormChangeResult } from 'components/Form';
import { WhitelistItem } from 'types/whitelistItem';

export const onIpAddressesAdded = (item: readonly WhitelistItem[]): FormChangeResult => {
  // TODO: evaluate ip addresses to check for errors

  return {
    value: Array.from(
      new Set(
        item
          .map((each: WhitelistItem): string => each.ipAddress)
          .map((each: string): readonly string[] =>
            each.split(',').map((ip: string): string => ip.trim())
          )
          .reduce(
            (flat: readonly string[], next: readonly string[]): readonly string[] => [
              ...flat,
              ...next,
            ],
            []
          )
          .map((ip: string): WhitelistItem => ({ ipAddress: ip }))
      )
    ),
    error: null,
  };
};
