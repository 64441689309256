import { Box, Checkbox } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  entriesSelected,
  entriesUnselected,
  solaceQueueSelectionSelector,
  solaceQueueSelector,
} from 'redux/reducers/solaceQueueReducer';
import { SolaceQueueEntry } from 'types/solace';

interface Props {
  readonly value: string;
}

export const CheckBoxCellRenderer: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value } = props;
  const [shift, setShift] = React.useState<boolean>(false);
  const page = useSelector(solaceQueueSelector);
  const selection = useSelector(solaceQueueSelectionSelector);
  const dispatch = useDispatch();

  React.useEffect((): VoidFunction => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      setShift(event.shiftKey);
    };

    const handleKeyUp = (event: KeyboardEvent): void => {
      setShift(event.shiftKey);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return (): void => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const getSelectedEntries = React.useCallback(
    (checked: boolean): readonly string[] => {
      if (!shift) {
        return [value];
      }

      const { records } = page;

      const endIndex = records.findIndex((entry: SolaceQueueEntry): boolean => entry.id === value);
      if (endIndex === -1) {
        return [];
      }

      const startIndex = ((): number => {
        for (let index = endIndex; index >= 0; index--) {
          if (!!selection[records[index]?.id ?? ''] === checked) {
            return index;
          }
        }

        return 0;
      })();
      const firstIndex = Math.min(startIndex, endIndex + 1);
      const lastIndex = Math.max(startIndex, endIndex + 1);

      const targetSlice = records.slice(firstIndex, lastIndex);
      return targetSlice.map((entry: SolaceQueueEntry): string => entry.id);
    },
    [page, selection, shift, value]
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { target: input } = event;

      const entries = getSelectedEntries(input.checked);
      if (input.checked) {
        dispatch(entriesSelected(entries));
      } else {
        dispatch(entriesUnselected(entries));
      }
    },
    [dispatch, getSelectedEntries]
  );

  const isChecked = React.useMemo((): boolean => !!selection[value], [selection, value]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Checkbox checked={isChecked} onChange={handleChange} />
    </Box>
  );
};
