import { Api } from 'api';
import { GetMessagesParams } from 'api/interfaces/getMessagesParams';
import { RawMessage } from 'api/interfaces/rawMessage';
import config from 'config';
import { UiMessage } from 'interfaces/message';
import { MessageSearchForm } from 'redux/reducers/messagesReducer';

export const search = async (api: Api, form: MessageSearchForm): Promise<readonly UiMessage[]> => {
  const { fromDate, toDate } = form;
  const query = new GetMessagesParams({
    fromTime: fromDate,
    toTime: toDate,
    symbols: form.symbols,
    venues: form.venue.join(','),
    orderOriginationId: form.orderOriginationId.join(','),
    clientOrderId: form.clientOrderId,
    sides: form.side,
    environment: config.environment,
  });
  const task = api.getMessages(query);
  const messages = await task.run();
  const uiMessages = messages
    .map(RawMessage.fromFIX)
    .map(UiMessage.fromRawMessage)
    .reduce((aggregated: Record<string, UiMessage>, next: UiMessage): Record<string, UiMessage> => {
      const current = aggregated[next.clientOrderId];

      if (current === undefined) {
        return { ...aggregated, [next.clientOrderId]: next };
      } else if (current.id < next.id) {
        return { ...aggregated, [next.clientOrderId]: { ...current, venueId: next.venueId } };
      } else {
        return aggregated;
      }
    }, {});

  return Object.values(uiMessages).sort((a, b) => -a.transactionTime.diff(b.transactionTime));
};
