import React from 'react';

export abstract class CRUDActions {
  public abstract clone(id: string | number): void;
  public abstract edit(id: string | number): void;
  public abstract remove(id: string | number): void;
  public abstract add(): void;
  public abstract reset(): void;
}

export const CRUDActionsContext = React.createContext<CRUDActions | null>(null);
