import { Box, Button, Pagination } from '@mui/material';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { Table } from 'components/Table';
import { RowData } from 'components/Table/types/rowData';
import { ProcessingType } from 'enums/processingState';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteProcessingStateSelector,
  deleteSolaceMessagesCompleted,
  reset,
  solaceQueueDeletedRowsSelector,
  solaceQueueProcessingStateSelector,
  solaceQueueSelectionSelector,
  solaceQueueSelector,
} from 'redux/reducers/solaceQueueReducer';
import {
  deleteSolaceMessagesAction,
  fetchSolaceQueueAction,
} from 'redux/services/solaceQueueServices';
import { columns } from 'routes/SessionsScreen/components/SolaceQueue/columns';
import styles from 'routes/SessionsScreen/components/SolaceQueue/solace-queue.module.scss';
import { SolaceQueueEntry } from 'types/solace';
import { SolaceBrowserQuery } from 'types/solaceBrowser';

interface Props {
  readonly sessionId: string;
}

export const SolaceQueueBrowser: React.FC<Props> = (props: Props): React.ReactElement => {
  const [query, setQuery] = React.useState<SolaceBrowserQuery>(initialQuery(props.sessionId));

  const selection = useSelector(solaceQueueSelectionSelector);
  const dispatch = useDispatch<any>();
  const page = useSelector(solaceQueueSelector);
  const deletedEntries = useSelector(solaceQueueDeletedRowsSelector);
  const processingState = useSelector(solaceQueueProcessingStateSelector);
  const deleteProcessingState = useSelector(deleteProcessingStateSelector);

  React.useEffect((): VoidFunction | void => {
    if (deleteProcessingState.processingType === ProcessingType.success) {
      // We can't do cancellation in this case
      dispatch(fetchSolaceQueueAction(query));
      dispatch(deleteSolaceMessagesCompleted());
    }
  }, [deleteProcessingState.processingType, dispatch, query]);

  React.useEffect((): VoidFunction => {
    dispatch(reset());
    return dispatch(fetchSolaceQueueAction(query));
  }, [dispatch, query]);

  const rows = useMemo((): Record<string, RowData<SolaceQueueEntry>> => {
    return page.records.reduce(
      (
        rows: Record<string, RowData<SolaceQueueEntry>>,
        entry: SolaceQueueEntry
      ): Record<string, RowData<SolaceQueueEntry>> => ({
        ...rows,
        [entry.id]: {
          id: entry.id,
          data: entry,
        },
      }),
      {}
    );
  }, [page.records]);

  const handlePageChange = useCallback((_: React.ChangeEvent<unknown>, page: number): void => {
    setQuery(
      (query: SolaceBrowserQuery): SolaceBrowserQuery => ({
        ...query,
        nPage: page,
      })
    );
  }, []);

  const totalPageCount = React.useMemo((): number => {
    return Math.ceil(page.total / query.nRecordPerPage);
  }, [page.total, query.nRecordPerPage]);

  const selectedForDeletionCount = React.useMemo((): number => {
    return Object.values(selection).filter((selected: boolean): boolean => selected).length;
  }, [selection]);

  const handleRemoveMessages = React.useCallback((): void => {
    const ids = Object.keys(selection).filter((id: string): boolean => selection[id]);
    dispatch(deleteSolaceMessagesAction(ids, query));
  }, [dispatch, query, selection]);

  const rowClassName = React.useCallback(
    (entry: SolaceQueueEntry): string | undefined => {
      if (deletedEntries[entry.id]) {
        return styles.deleted;
      }

      return undefined;
    },
    [deletedEntries]
  );

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Box display="flex" alignItems="center" justifyContent="flex-end" marginBottom={1}>
        <Button
          variant="contained"
          color="error"
          disabled={selectedForDeletionCount === 0}
          onClick={handleRemoveMessages}
        >
          Remove {selectedForDeletionCount} message{selectedForDeletionCount > 1 ? 's' : ''}
        </Button>
      </Box>
      <Table columns={columns} rows={rows} rowClassName={rowClassName} virtualScroll={false} />
      {totalPageCount > 1 && (
        <Box display="flex" alignItems="center" marginY={1} marginX="auto">
          <Pagination count={totalPageCount} page={query.nPage} onChange={handlePageChange} />
        </Box>
      )}
      <LoadingOverlay
        loading={
          processingState.processingType === ProcessingType.processing ||
          deleteProcessingState.processingType === ProcessingType.processing ||
          deleteProcessingState.processingType === ProcessingType.success
        }
      />
    </Box>
  );
};

const initialQuery = (sessionId: string): SolaceBrowserQuery => ({
  session_or_clientid: sessionId,
  isSession: false,
  nPage: 1,
  nRecordPerPage: 100,
});
