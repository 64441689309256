import styles from 'ComplexModals/SessionEditor/central.module.scss';
import { Section } from 'ComplexModals/SessionEditor/components/Section';
import { createFields } from 'ComplexModals/SessionEditor/forms/FIXSessionDetailsView/fields';
import { Form, FormChangeResult } from 'components/Form';
import { FieldDefinition } from 'components/Form/components/Field/types';
import React from 'react';
import { SMFIXSessionDetails } from 'redux/definitions/central';
import { SMConfig } from 'redux/definitions/smConfig';

interface Props {
  readonly value: SMFIXSessionDetails;

  onChange(name: keyof SMConfig, value: SMFIXSessionDetails): void;
}

const FIXSessionDetailsView: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value, onChange } = props;

  const fields = React.useMemo(
    (): ReadonlyArray<FieldDefinition<SMFIXSessionDetails>> => createFields(value),
    [value]
  );

  const handleChange = React.useCallback(
    (
      name: keyof SMFIXSessionDetails,
      fieldValue: any,
      _: SMFIXSessionDetails
    ): FormChangeResult => {
      onChange('fixSessionDetails', { ...value, [name]: fieldValue });

      return {
        error: null,
        value: fieldValue,
      };
    },
    [onChange, value]
  );

  return (
    <Section title="FIX Session Details">
      <Form className={styles.form} value={props.value} fields={fields} onChange={handleChange} />
    </Section>
  );
};

export default FIXSessionDetailsView;
