import styles from 'components/Form/components/RequiredIndicator/required-indicator.module.scss';
import React from 'react';

interface Props {
  readonly required: boolean;
}

export const RequiredIndicator: React.FC<Props> = (props: Props): React.ReactElement => {
  if (props.required) {
    return <span className={styles.requiredIndicator}>*</span>;
  }

  return null;
};
