import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Typography } from '@mui/material';
import styles from 'components/modal-content.module.scss';
import React from 'react';

interface Props {
  readonly title: string;
  readonly message: string;

  onClose(): void;
}

export const SuccessModal: React.FC<Props> = React.forwardRef(function SuccessModal(
  props: Props,
  _: React.Ref<HTMLDivElement>
): React.ReactElement {
  return (
    <Box p={2} className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.icon}>
            <CheckCircleIcon fontSize="inherit" color="success" />
          </div>
          <div className={styles.titleText}>
            <div>
              <Typography variant="h1">{props.title}</Typography>
            </div>
            <Typography color="secondary">{props.message}</Typography>
          </div>
        </div>

        <div className={styles.buttons}>
          <Button onClick={props.onClose}>Close</Button>
        </div>
      </div>
    </Box>
  );
});
