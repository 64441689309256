import { Session, SessionContext } from 'auth/context';
import React from 'react';

export const useSession = (): Session => {
  const session = React.useContext<Session | null>(SessionContext);
  if (session === null) {
    throw new Error('no session context provided');
  }

  return session;
};
