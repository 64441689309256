import { Box, MenuItem, Typography } from '@mui/material';
import React from 'react';
import styles from 'routes/SessionsScreen/components/Actions/actions.module.scss';

interface Props {
  readonly label: string;
  readonly icon: React.FunctionComponent<any> | React.ReactElement;
  readonly color?: 'error' | 'success';

  onClick(): void;
}

export const ActionMenuItem: React.FC<Props> = (props: Props): React.ReactElement => {
  const { color = 'inherit' } = props;
  const Icon = props.icon;

  return (
    <MenuItem onClick={props.onClick}>
      <Box className={styles.actionItem}>
        {React.isValidElement(Icon) ? (
          Icon
        ) : (
          <Icon className={styles.icon} color={color} fontSize="small" />
        )}
        <Typography variant="button" className={styles.label}>
          {props.label}
        </Typography>
      </Box>
    </MenuItem>
  );
};
