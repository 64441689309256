import { MPID } from 'api/interfaces/firm';
import { PrismApplication } from 'api/interfaces/prismApplication';
import React from 'react';
import { useSelector } from 'react-redux';
import { applicationsListSelector } from 'redux/reducers/referenceDataReducer';
import classes from 'routes/EntityManagement/FirmsManagement/components/FirmsForm/components/MPIDItem/MPIDItem.module.scss';

interface Props {
  readonly mpid: MPID;
}

export const MPIDItem: React.FC<Props> = (props: Props): React.ReactElement => {
  const { mpid } = props;
  const applications = useSelector(applicationsListSelector);

  const applicationName = React.useMemo((): string => {
    const found = applications.find((application: PrismApplication): boolean => {
      return application.app_id === mpid.appid;
    });

    return found?.app_name ?? 'Unknwon Application';
  }, [applications, mpid.appid]);

  return (
    <div className={classes.container}>
      <div className={classes.mpid}>{mpid.mpid}</div>
      <div className={classes.applicationName}>{applicationName}</div>
    </div>
  );
};
