import { Button, ButtonBase } from '@mui/material';
import { Dispatch } from '@reduxjs/toolkit';
import classes from 'ComplexModals/SessionEditor/central.module.scss';
import { createMenu } from 'ComplexModals/SessionEditor/menu';
import { SaveDialog } from 'ComplexModals/SessionEditor/SaveDialog';
import { CenteredModal } from 'components/CenteredModal';
import { Clickable } from 'components/Clickable';
import { ErrorModal } from 'components/ErrorModal';
import { RunningTaskModal } from 'components/RunningTaskModal';
import { SuccessModal } from 'components/SuccessModal';
import { ProcessingState } from 'enums/processingState';
import { toClassName } from 'helpers/toClassName';
import ImperativeModal, { ImperativeModalProps } from 'ImperativeModal';
import { ApplicationRouteDef } from 'interfaces/applicationRouteDef';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { AsyncAction } from 'redux/asyncAction';
import { SMConfig } from 'redux/definitions/smConfig';
import {
  ProcessingOperationType,
  processingStateSelector,
  resetAll,
  resetProcessingState,
  smConfigSelector,
  smModifiedSelector,
  updateSection,
} from 'redux/reducers/smConfigReducer';
import {
  createSMConfigurationAction,
  fetchDefaultSMConfigurationAction,
  fetchSMConfigurationAction,
  fetchSolaceServersAction,
  updateSMConfigurationAction,
} from 'redux/services/sessionsServices';

interface Props {
  readonly sessionId?: string;
  readonly create?: boolean;
  onClose(): void;
}

export const SessionEditor: React.FC<Props> = (props: Props): React.ReactElement => {
  const dispatch = useDispatch<Dispatch<AsyncAction | AnyAction>>();
  const processingState = useSelector(processingStateSelector);
  const smConfig = useSelector(smConfigSelector);
  const modified = useSelector(smModifiedSelector);
  const menu = React.useMemo(
    (): ReadonlyArray<ApplicationRouteDef<SMConfig>> => createMenu(smConfig),
    [smConfig]
  );

  const [activeTab, setActiveTab] = React.useState<ApplicationRouteDef>(menu[0]);
  const { sessionId = '', create = false, onClose } = props;

  const Component = React.useMemo(
    (): React.ComponentType<any> => activeTab.component,
    [activeTab.component]
  );

  const handleResetProcessingState = React.useCallback((): void => {
    dispatch(resetProcessingState());
  }, [dispatch]);

  const handleResetAll = React.useCallback((): void => {
    dispatch(resetAll());
  }, [dispatch]);

  const handleClose = React.useCallback((): void => {
    handleResetAll();
    onClose();
  }, [handleResetAll, onClose]);

  const handleFormSubmit = React.useCallback(
    function (sync: boolean): void {
      if (create) {
        dispatch(createSMConfigurationAction(smConfig, sync));
      } else {
        dispatch(updateSMConfigurationAction(sessionId, smConfig, sync));
      }
    },
    [create, dispatch, sessionId, smConfig]
  );

  const handleFormSubmitRequested = React.useCallback((): void => {
    ImperativeModal.execute<'all' | 'db-only'>(
      (props: ImperativeModalProps<'all' | 'db-only'>): React.ReactElement => {
        return <SaveDialog onRespond={props.onRespond} onDiscard={props.onDiscard} />;
      }
    )
      .then((result: 'all' | 'db-only'): void => {
        handleFormSubmit(result === 'all');
      })
      .catch((): void => {
        return;
      });
    void handleFormSubmit;
  }, [handleFormSubmit]);

  const handleSectionChange = React.useCallback(
    (name: keyof SMConfig, section: any): void => {
      dispatch(updateSection({ name, section }));
    },
    [dispatch]
  );

  const setCurrentTab = React.useCallback((item: ApplicationRouteDef): void => {
    setActiveTab(item);
  }, []);

  React.useEffect((): VoidFunction => {
    dispatch(fetchSolaceServersAction());
    if (sessionId === '') {
      dispatch(fetchDefaultSMConfigurationAction());
    } else {
      dispatch(fetchSMConfigurationAction(sessionId, create));
    }

    return (): void => {
      handleResetAll();
    };
  }, [create, dispatch, handleResetAll, sessionId]);

  return (
    <>
      <CenteredModal
        open={true}
        title={`UPDATE SESSION (${sessionId})`}
        fullHeight={true}
        onClose={handleClose}
      >
        <div
          className={toClassName(classes.container, {
            [classes.loading]: ProcessingState.isProcessing(processingState),
          })}
        >
          <div className={classes.menu}>
            <ul>
              {menu.map((item: ApplicationRouteDef): React.ReactElement => {
                return (
                  <Clickable key={String(item.key)} data={item} onClick={setCurrentTab}>
                    <ButtonBase
                      component="li"
                      disabled={!item.enabled}
                      classes={{
                        root: toClassName(classes.menuItem, {
                          [classes.active]: item.path === activeTab.path,
                        }),
                        disabled: classes.disabled,
                      }}
                    >
                      {item.label}
                    </ButtonBase>
                  </Clickable>
                );
              })}
            </ul>
          </div>
          <div className={classes.content}>
            <Component
              value={smConfig[activeTab.key as keyof SMConfig]}
              onChange={handleSectionChange}
            />

            <div className={classes.buttons} onClick={handleFormSubmitRequested}>
              <Button variant="contained" disabled={!modified}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </CenteredModal>

      <CenteredModal
        open={ProcessingState.isSuccess(processingState)}
        title="Success"
        onClose={handleClose}
      >
        <SuccessModal
          title="Saved"
          message="Configuration saved successfully"
          onClose={handleClose}
        />
      </CenteredModal>

      <CenteredModal
        open={
          ProcessingState.isProcessing(processingState) &&
          processingState.data === ProcessingOperationType.updating
        }
        title="Updating"
      >
        <RunningTaskModal title="Updating" body="Please wait while we update the configuration" />
      </CenteredModal>

      <CenteredModal
        open={ProcessingState.isError(processingState)}
        onClose={handleResetProcessingState}
      >
        <ErrorModal
          title="Failed to save"
          message="An error occurred and we were not able to save the configuration"
          error={ProcessingState.isError(processingState) && processingState.message}
          onClose={handleResetProcessingState}
        />
      </CenteredModal>
    </>
  );
};
