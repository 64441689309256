import { Refresh } from '@mui/icons-material';
import { ButtonBase, OutlinedInput } from '@mui/material';
import styles from 'ComplexModals/SessionEditor/components/SMRuleInput/sm-rule-input.module.scss';
import React from 'react';

interface Props {
  readonly value: string;

  onChange(text: string): void;
}

export const SMRuleInput: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value, onChange } = props;

  const handleTextChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      onChange(value);
    },
    [onChange]
  );

  return (
    <div className={styles.smRuleInput}>
      <div className={styles.content}>
        <div className={styles.inputBox}>
          <OutlinedInput value={value} onChange={handleTextChange} fullWidth />
        </div>
        <ButtonBase className={styles.refreshButton}>
          <Refresh color="primary" fontSize="small" />
        </ButtonBase>
      </div>
    </div>
  );
};
