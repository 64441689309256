import { OrderOriginationIdItem } from 'api/interfaces/orderOriginationIds';
import { useApi } from 'hooks/useApi';
import React from 'react';

export const useVenuesInitializer = (setter: (_: readonly string[]) => void): void => {
  const api = useApi();

  React.useEffect((): VoidFunction => {
    const task = api.getVenues();
    task
      .run()
      .then((orderOriginationIdItems: readonly OrderOriginationIdItem[]): void => {
        const list = orderOriginationIdItems
          .filter((item: OrderOriginationIdItem): boolean => item.originationid !== null)
          .map((item: OrderOriginationIdItem): string => item.originationid);
        setter(list);
      })
      .catch((error: any): void => {
        if (error.code !== 20) {
          console.warn(error);
        }
      });

    return (): void => task.cancel();
  }, [api, setter]);
};
