import { Firm } from 'api/interfaces/firm';
import { ModalType } from 'components/TaskRunningStatus/reducer';
import { Reducer } from 'redux';
import { Action } from 'redux/action';

export enum FirmsActions {
  getFirmsStarted = '/actions/firms/get-firms-started',
  getFirmsCompleted = '/actions/firms/get-firms-completed',
  getFirmsFailed = '/actions/firms/get-firms-failed',

  createFirmStarted = '/actions/firms/create-firm-started',
  createFirmCompleted = '/actions/firms/create-firm-completed',
  createFirmFailed = '/actions/firms/create-firm-failed',

  updateFirmStarted = '/actions/firms/update-firm-started',
  updateFirmCompleted = '/actions/firms/update-firm-completed',
  updateFirmFailed = '/actions/firms/update-firm-failed',

  deleteFirmStarted = '/actions/firms/delete-firm-started',
  deleteFirmCompleted = '/actions/firms/delete-firm-completed',
  deleteFirmFailed = '/actions/firms/delete-firm-failed',

  closeTaskModal = '/actions/firms/close-task-modal',
}

export interface FirmsState {
  readonly firms: readonly Firm[];
  readonly taskMessage: string | null;
  readonly modalType: ModalType;
  readonly loading: boolean;
}

const initialState: FirmsState = {
  firms: [],
  taskMessage: null,
  modalType: ModalType.none,
  loading: false,
};

const reducer: Reducer<FirmsState, Action<FirmsActions>> = (
  state: FirmsState = initialState,
  action: Action<FirmsActions>
): FirmsState => {
  const { type, data } = action;
  const { firms } = state;

  switch (type) {
    case FirmsActions.getFirmsStarted:
      return { ...state, loading: true };
    case FirmsActions.getFirmsCompleted:
      return { ...state, firms: data, loading: false };
    case FirmsActions.getFirmsFailed:
      return { ...state, loading: false };
    case FirmsActions.updateFirmFailed:
    case FirmsActions.deleteFirmFailed:
    case FirmsActions.createFirmFailed:
      return { ...state, taskMessage: data, modalType: ModalType.error };
    case FirmsActions.createFirmStarted:
      return {
        ...state,
        taskMessage: 'Creating firm, please wait',
        modalType: ModalType.progress,
      };
    case FirmsActions.createFirmCompleted:
      return {
        ...state,
        firms: [data, ...firms],
        taskMessage: 'Firm updated successfully',
        modalType: ModalType.success,
      };
    case FirmsActions.updateFirmStarted:
      return {
        ...state,
        taskMessage: 'Updating firm, please wait',
        modalType: ModalType.progress,
      };
    case FirmsActions.updateFirmCompleted:
      return {
        ...state,
        firms: firms.map((firm: Firm): Firm => (firm.firmID === data.firmID ? data : firm)),
        taskMessage: 'Firm updated successfully',
        modalType: ModalType.success,
      };
    case FirmsActions.deleteFirmStarted:
      return {
        ...state,
        taskMessage: 'Deleting user, please wait',
        modalType: ModalType.progress,
      };
    case FirmsActions.deleteFirmCompleted:
      return {
        ...state,
        firms: firms.filter((firm: Firm): boolean => firm.firmID !== data.firmID),
        taskMessage: 'Firm deleted successfully',
        modalType: ModalType.success,
      };
    case FirmsActions.closeTaskModal:
      return { ...state, taskMessage: null, modalType: ModalType.none };
    default:
      return state;
  }
};

export default reducer;
