import { Button, ButtonBase, Checkbox, FormControlLabel, Menu } from '@mui/material';
import styles from 'components/FIXFieldsSelector/field-selector.module.scss';
import fixMap from 'data/fix';
import strings from 'locales';
import React from 'react';
import { FixTagEntry } from 'types/fixEntry';

const sourceFields = Object.entries(fixMap);

interface Props {
  readonly selection: readonly string[];
  onSelectionChange(selection: readonly string[]): void;
}

export const FIXFieldsSelector = (props: Props): React.ReactElement => {
  // Properties
  const { selection } = props;
  // Actions
  const { onSelectionChange } = props;
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);
  const buttonRef = React.useRef<HTMLDivElement>(null);

  const toggleMenuIsOpen = React.useCallback((): void => setMenuIsOpen(!menuIsOpen), [menuIsOpen]);

  const isSelected = React.useCallback(
    (name: string): boolean => {
      return selection.includes(name);
    },
    [selection]
  );

  const onChangeHandler = (tag: string): ((event: React.ChangeEvent<HTMLInputElement>) => void) => {
    return (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      const set = new Set(selection);
      if (checked) {
        set.add(tag);
      } else {
        set.delete(tag);
      }
      onSelectionChange(Array.from(set));
    };
  };

  const preventClose = (event: React.MouseEvent): void => {
    event.stopPropagation();
  };

  return (
    <div className={styles.container} onClick={toggleMenuIsOpen} ref={buttonRef}>
      <Button variant="contained">
        <div className={styles.buttonContent}>
          <i className="fa fa-eye-slash" />
          <span>{strings.HideShowColumns}</span>
        </div>
      </Button>
      <Menu anchorEl={buttonRef.current} open={menuIsOpen}>
        <div onClick={preventClose} className={styles.fieldList}>
          {sourceFields.map(
            ([tag, entry]: [string, FixTagEntry]): React.ReactElement => (
              <ButtonBase key={tag} className={styles.fieldItem}>
                <FormControlLabel
                  control={<Checkbox checked={isSelected(tag)} onChange={onChangeHandler(tag)} />}
                  label={`${entry.name} (${tag})`}
                />
              </ButtonBase>
            )
          )}
        </div>
      </Menu>
    </div>
  );
};
