import { RowData } from 'components/Table/types/rowData';
import { UiMessage } from 'interfaces/message';
import { Action } from 'redux/action';
import { createAction } from 'redux/actions/creator';
import { MessagesScreenAction } from 'redux/reducers/messagesReducer';

export const setMessages = (
  messages: Record<string, RowData<UiMessage>>
): Action<MessagesScreenAction, Record<string, RowData<UiMessage>>> =>
  createAction(MessagesScreenAction.setMessages, messages);
