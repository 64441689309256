import { CenteredModal as Modal } from 'components/CenteredModal';
import { FIXMessageView } from 'components/FIXMessage';
import styles from 'components/MessagesActionsCell/messages-actions-cell.module.scss';
import { RowData } from 'components/Table/types/rowData';
import {
  ChildRowContext,
  ChildRowContextType,
  ChildRowContextValue,
} from 'contexts/ChildRowContext';
import { ProcessingState } from 'enums/processingState';
import { parse } from 'FIX/parser';
import { toClassName } from 'helpers/toClassName';
import { useApi } from 'hooks/useApi';
import { FIXTaggedField } from 'interfaces/FIXTaggedField';
import { UiMessage } from 'interfaces/message';
import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Action } from 'redux/action';
import { setMessages } from 'redux/actions/messages/setMessages';
import { ApplicationState } from 'redux/applicationState';
import { MessagesScreenAction, MessagesScreenState } from 'redux/reducers/messagesReducer';
import { expandRow } from 'routes/Messages/helpers/expandRow';

interface DispatchProps {
  setMessages(
    rows: Record<string, RowData<UiMessage>>
  ): Action<MessagesScreenAction, Record<string, RowData<UiMessage>>>;
}

// FIXME: this should be part of a configuration
const excludedTags: readonly number[] = [8, 9, 10, 49, 56];

interface OwnProps {
  readonly value: number;
}

type Props = OwnProps & DispatchProps & MessagesScreenState;

const MessagesActionsCell: React.FC<Props> = (props: Props): React.ReactElement => {
  const childRowContext = React.useContext<ChildRowContextType>(ChildRowContext);

  const [processingState, setProcessingState] = React.useState<ProcessingState>(
    ProcessingState.idle()
  );
  const [detailedMessage, setDetailedMessage] = React.useState<readonly FIXTaggedField[] | null>(
    null
  );
  const { value, messages } = props;
  const { setMessages } = props;
  const api = useApi();
  const clientOrderId = React.useMemo(
    (): string | undefined => findClientOrderIdFromMessageId(messages, value),
    [messages, value]
  );
  const message: RowData<UiMessage> | null = React.useMemo(
    (): RowData<UiMessage> => messages[clientOrderId],
    [messages, clientOrderId]
  );

  const messageHasChildren = React.useMemo((): boolean => hasChildren(message), [message]);

  const onLoadChildrenRows = React.useCallback((): void => {
    if (!messageHasChildren) {
      setProcessingState(ProcessingState.processing());

      expandRow(api, clientOrderId)
        .then((children: Record<string, RowData<UiMessage>>): void => {
          setMessages({
            ...messages,
            [clientOrderId]: {
              ...message,
              children: children,
            },
          });
          setProcessingState(ProcessingState.idle());
        })
        .catch((): void => {
          setProcessingState(ProcessingState.error('Failed to load'));
        });
    } else {
      setMessages({
        ...messages,
        [clientOrderId]: {
          ...message,
          children: {},
        },
      });
    }
  }, [messageHasChildren, api, clientOrderId, setMessages, messages, message]);

  const onLoadRowDetails = React.useCallback((): void => {
    const task = api.getMessage(value);
    setProcessingState(ProcessingState.processing());
    task
      .run()
      .then((response: [{ readonly rawmsg: string }]): void => {
        const { rawmsg: message } = response[0];
        // Parse and filter the response
        setDetailedMessage(parse(message).filter(shouldExcludeTag));
        setProcessingState(ProcessingState.idle());
      })
      .catch((error: any): void => {
        setProcessingState(ProcessingState.error(error.toString()));
      });
  }, [api, value]);

  return (
    <div className={styles.container}>
      {messageHasChildren || message === undefined ? (
        <button onClick={onLoadRowDetails} title="Show Details">
          <i className="fa fa-external-link-alt" />
        </button>
      ) : (
        <button disabled={true} />
      )}
      {message && childRowContext !== ChildRowContextValue ? (
        <button onClick={onLoadChildrenRows} title="Expand">
          {messageHasChildren ? (
            <i className="fa fa-chevron-down" />
          ) : (
            <i className="fa fa-chevron-right" />
          )}
        </button>
      ) : (
        <button disabled={true} />
      )}
      <div
        className={toClassName(styles.spinner, {
          [styles.spinning]: processingState && ProcessingState.isProcessing(processingState),
        })}
      />

      <Modal open={detailedMessage !== null} onClose={(): void => setDetailedMessage(null)}>
        <FIXMessageView message={detailedMessage} />
      </Modal>
    </div>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, any> = {
  setMessages,
};

const mapStateToProps: MapStateToProps<MessagesScreenState, any, ApplicationState> = (
  applicationState: ApplicationState
): MessagesScreenState => applicationState.messages;

export default connect(mapStateToProps, mapDispatchToProps)(MessagesActionsCell);

const findClientOrderIdFromMessageId = (
  messages: Record<string, RowData<UiMessage>>,
  messageId: number
): string => {
  const found = Object.values(messages).find(
    (message: RowData<UiMessage>): boolean => message.data.id === messageId
  )?.data.clientOrderId;
  if (found === undefined) {
    return '';
  }

  return found;
};

const hasChildren = (message?: RowData<UiMessage>): boolean =>
  message !== undefined && Object.values(message.children).length > 0;

const shouldExcludeTag = (field: FIXTaggedField): boolean =>
  !excludedTags.includes(field.tag) || (field.tag > 9701 && field.tag < 9704);
