import { rename } from 'decorators/rename';
import { Moment } from 'moment';

export class GetMessagesParams {
  @rename('from_time') readonly fromTime: Moment;
  @rename('to_time') readonly toTime: Moment;
  @rename('originationids') readonly orderOriginationId?: string;
  @rename('clientorderid') readonly clientOrderId?: string;

  readonly environment: string;
  readonly symbols?: string;
  readonly venues?: string;
  readonly sides?: string;
  readonly msgType?: string;
  readonly page?: number;
  readonly pageSize?: number;

  constructor(values: GetMessagesParams) {
    this.environment = values.environment;
    this.fromTime = values.fromTime;
    this.toTime = values.toTime;
    this.symbols = values.symbols;
    this.orderOriginationId = values.orderOriginationId;
    this.venues = values.venues;
    this.sides = values.sides;
    this.clientOrderId = values.clientOrderId;
    this.msgType = values.msgType ?? 'D,AB';
    this.page = values.page;
    this.pageSize = values.pageSize;
  }
}
