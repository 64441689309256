export enum FIXMessageType {
  executionReport = 'EXECUTION_REPORT',
  businessMessageReject = 'BUSINESS_MESSAGE_REJECT',
  orderSingle = 'ORDER_SINGLE',
}

interface FIXMessageBase {
  readonly MsgType: FIXMessageType;
  readonly SenderCompID: string;
  readonly TargetCompID: string;
}

export interface BusinessReject extends FIXMessageBase {
  readonly MsgType: FIXMessageType.businessMessageReject;
  readonly Text: string;
  readonly BusinessRejectReason: string;
}

export interface ExecutionReport extends FIXMessageBase {
  readonly MsgType: FIXMessageType.executionReport;
  readonly LastPx: string;
  readonly OrdType: 'MARKET' | 'LIMIT';
  readonly Side: 'BUY' | 'SELL';
  readonly ClOrdID: string;
  readonly OrderQty: string;
  readonly Symbol: string;
}

export interface Order extends FIXMessageBase {
  readonly MsgType: FIXMessageType.orderSingle;
  readonly OrdType: 'MARKET' | 'LIMIT';
  readonly Side: 'BUY' | 'SELL';
  readonly ClOrdID: string;
  readonly MDMkt: string;
  readonly OrderQty: string;
  readonly Symbol: string;
}

export type FIXMessage = BusinessReject | ExecutionReport | Order;
