import { QuestionMarkTwoTone } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import styles from 'components/modal-content.module.scss';
import React from 'react';

interface Props {
  readonly title: string;
  readonly message: string;

  onYes(): void;
  onNo(): void;
}

export const QuestionModal: React.FC<Props> = React.forwardRef(function QuestionModal(
  props: Props,
  _: React.Ref<HTMLDivElement>
): React.ReactElement {
  return (
    <Box p={2} className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.icon}>
            <QuestionMarkTwoTone fontSize="inherit" color="primary" />
          </div>
          <div className={styles.titleText}>
            <div>
              <Typography variant="h1">{props.title}</Typography>
            </div>
            <Typography className={styles.message} color="secondary">
              {props.message}
            </Typography>
          </div>
        </div>

        <div className={styles.buttons}>
          <Button color="secondary" onClick={props.onNo}>
            Not sure
          </Button>
          <Button variant="contained" color="error" onClick={props.onYes}>
            Yes, proceed
          </Button>
        </div>
      </div>
    </Box>
  );
});
