import { OktaGroup } from 'api/interfaces/user';

export interface UserDetails {
  readonly first_name: string;
  readonly last_name: string;
  readonly work_phone: string;
  readonly cell: string;
  readonly business_address: string;
  readonly email_address: string;
  readonly companyid: string;
  readonly status: 'ACTIVE' | 'INACTIVE';
  readonly recovery_question: string;
  readonly recovery_answer: string | null;
  readonly okta_group: readonly OktaGroup[];
  readonly FirmID: string;
  readonly FirmName: string;
  readonly NameCode: string;
  readonly MSTraderID: string;
  readonly UBSTraderID: string;
  readonly MSCOTraderID: string;
  readonly sonic_group: Record<string, readonly string[]>;
  readonly IPAddresses: readonly string[];
  readonly TRTNTraderID: string;
  readonly TradingEntityCode: string;
}

export class UserDetails {
  public static empty(): UserDetails {
    return {
      first_name: '',
      last_name: '',
      work_phone: '',
      cell: '',
      business_address: '',
      email_address: '',
      companyid: '',
      status: 'INACTIVE',
      recovery_question: '',
      recovery_answer: null,
      okta_group: [],
      FirmID: '',
      FirmName: '',
      NameCode: '',
      MSTraderID: '',
      UBSTraderID: '',
      MSCOTraderID: '',
      sonic_group: {},
      IPAddresses: [],
      TradingEntityCode: '',
      TRTNTraderID: '',
    };
  }
}
