import { ChildRows } from 'components/Table/components/ChildRows';
import { ParentRow } from 'components/Table/components/ParentRow';
import { Column } from 'components/Table/types/column';
import { RowData } from 'components/Table/types/rowData';
import { ChildRowContext, ChildRowContextValue } from 'contexts/ChildRowContext';
import React from 'react';
import { Identifiable } from 'types/identifiable';

interface Props<T extends Identifiable> {
  readonly columns: ReadonlyArray<Column<T>>;
  readonly data: Record<keyof T, any>;
  readonly childRows: Record<string, RowData<T>>;
  readonly rowId: string;
  readonly className?: string;
  readonly rowRef?: React.Ref<HTMLTableRowElement>;

  onMount?(id: string, mounted: boolean): void;
  onClick?(value: any): void;
}

export function Row<T extends Identifiable>(props: Props<T>): React.ReactElement {
  const { rowRef } = props;
  const { columns, childRows, data, className } = props;
  const { onClick, onMount } = props;

  const forwardClick = React.useCallback((): void => {
    if (onClick !== undefined) {
      onClick(data);
    }
  }, [onClick, data]);

  React.useEffect((): VoidFunction => {
    onMount?.(data.id, true);

    return (): void => {
      onMount?.(data.id, false);
    };
  }, [data.id, onMount]);

  return (
    <>
      <ParentRow<T>
        data={data}
        rowRef={rowRef}
        columns={columns}
        className={className}
        onClick={forwardClick}
      />

      <ChildRowContext.Provider value={ChildRowContextValue}>
        <ChildRows columns={columns} rows={childRows} />
      </ChildRowContext.Provider>
    </>
  );
}
