interface RawUser {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly logoUrl: string;
  readonly groups: readonly string[];
}

export class User {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly logoUrl: string;
  readonly groups: readonly string[];

  constructor(raw: RawUser) {
    this.id = raw.id;
    this.email = raw.email;
    this.firstName = raw.firstName;
    this.lastName = raw.lastName;
    this.logoUrl = raw.logoUrl;
    this.groups = raw.groups;
  }
}
