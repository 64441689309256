import { OutlinedInput } from '@mui/material';
import styles from 'components/ClearableInput/clearable-input.module.scss';
import React from 'react';

interface Props {
  readonly value: string;
  readonly className?: string;
  readonly name: string;

  onChange(name: string, value: string): void;
}

export const ClearableInput = (props: Props): React.ReactElement => {
  const { name, value, className, onChange } = props;

  const inputRef = React.createRef<HTMLInputElement>();

  const onChangeWrapper = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      onChange(target.name, target.value);
    },
    [onChange]
  );
  const clearInput = React.useCallback((): void => {
    const { current } = inputRef;
    onChange(name, '');
    if (current !== null) {
      current.focus();
    }
  }, [inputRef, name, onChange]);

  return (
    <OutlinedInput
      name={name}
      className={className}
      value={value}
      endAdornment={
        value.trim() !== '' ? (
          <div className={styles.adornment} onClick={clearInput}>
            <i className="fa fa-backspace" />
          </div>
        ) : null
      }
      inputRef={inputRef}
      onChange={onChangeWrapper}
    />
  );
};
