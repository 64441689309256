import MessagesActionsCell from 'components/MessagesActionsCell';
import { Alignment, ColumnDefinition, ColumnType } from 'components/Table/types/column';
import execTypes from 'constants/execTypes';
import messageTypes from 'constants/messageTypes';
import sides from 'constants/sides';
import { UiMessage } from 'interfaces/message';

const columns: ReadonlyArray<ColumnDefinition<UiMessage>> = [
  {
    header: '',
    key: 'id',
    columnType: ColumnType.custom,
    weight: 0.75,
    filterable: false,
    component: MessagesActionsCell,
  },
  {
    header: 'Client Order ID',
    key: 'clientOrderId',
    columnType: ColumnType.text,
    weight: 2.25,
  },
  {
    header: 'Order Origination ID',
    key: 'originationId',
    columnType: ColumnType.text,
    weight: 1.2,
  },
  {
    header: 'Last Update',
    key: 'transactionTime',
    columnType: ColumnType.dateTime,
    weight: 1.6,
  },
  {
    header: 'Venue',
    key: 'venueId',
    columnType: ColumnType.text,
  },
  {
    header: 'Side',
    key: 'side',
    columnType: ColumnType.mapped,
    data: sides,
  },
  {
    header: 'Message Type',
    key: 'messageType',
    columnType: ColumnType.mapped,
    data: messageTypes,
    weight: 1.75,
  },
  {
    header: 'Exec Type',
    key: 'execType',
    columnType: ColumnType.mapped,
    data: execTypes,
    weight: 1,
  },
  {
    header: 'Symbol',
    key: 'symbol',
    columnType: ColumnType.text,
    alignment: Alignment.center,
    weight: 0.75,
  },
  {
    header: 'Order Quantity',
    key: 'orderQuantity',
    columnType: ColumnType.integer,
    weight: 0.75,
  },
  {
    header: 'Order Price',
    key: 'orderPrice',
    columnType: ColumnType.currency,
    weight: 1.25,
  },
  {
    header: 'Order Notional',
    key: 'orderNotional',
    columnType: ColumnType.currency,
    weight: 1.45,
  },
  {
    header: 'Executed Quantity',
    key: 'executedQuantity',
    columnType: ColumnType.integer,
    weight: 0.75,
  },
  {
    header: 'Executed Price',
    key: 'executedPrice',
    columnType: ColumnType.currency,
    weight: 1.25,
  },
  {
    header: 'Executed Notional',
    key: 'executedNotional',
    columnType: ColumnType.currency,
    weight: 1.45,
  },
];

export default columns;
