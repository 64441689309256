import { rawMessageToFixMap } from 'api/interfaces/rawMessage';
import { FIXFieldsSelector } from 'components/FIXFieldsSelector';
import { Scaffold } from 'components/Scaffold';
import { Table } from 'components/Table';
import {
  Column,
  ColumnDefinition,
  ColumnType,
  createFromDefinition,
} from 'components/Table/types/column';
import { RowData } from 'components/Table/types/rowData';
import { Toolbar } from 'components/Toolbar';
import dictionary from 'data/fixDictionary';
import { ProcessingState } from 'enums/processingState';
import { messagesArrayToMessagesRows } from 'helpers/messagesArrayToMessagesRows';
import { useApi } from 'hooks/useApi';
import { useTable } from 'hooks/useTable';
import { KeyValue } from 'interfaces/keyValue';
import { UiMessage } from 'interfaces/message';
import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { endSearch } from 'redux/actions/messages/endSearch';
import { setFormValue } from 'redux/actions/messages/setFormValue';
import { setMessages } from 'redux/actions/messages/setMessages';
import { setOrderOriginationIds } from 'redux/actions/messages/setOrderOriginationIds';
import { setVenues } from 'redux/actions/messages/setVenues';
import { startSearch } from 'redux/actions/messages/startSearch';
import { ApplicationState } from 'redux/applicationState';
import { MessagesScreenState } from 'redux/reducers/messagesReducer';
import baseColumns from 'routes/Messages/columns';
import { Form } from 'routes/Messages/form';
import { search } from 'routes/Messages/helpers/search';
import { useOrderOriginationIdsInitializer } from 'routes/Messages/hooks/useOrderOriginationIdsInitializer';
import { useVenuesInitializer } from 'routes/Messages/hooks/useVenuesInitializer';
import styles from 'routes/Messages/messages-route.module.scss';

interface DispatchProps {
  setVenues(_: readonly string[]): void;
  setOrderOriginationIds(_: readonly string[]): void;
  setFormValue(_: KeyValue): void;
  startSearch(): void;
  endSearch(reason: Error | string | null): void;
  setSearchResult(messages: Record<string, RowData<UiMessage>>): void;
}

type Props = MessagesScreenState & DispatchProps;

const Messages = (props: Props): React.ReactElement => {
  const { venues, orderOriginationIds, form, messages, processingState } = props;
  const { setFormValue } = props;

  const api = useApi();
  const tableProps = useTable<UiMessage>('messages', baseColumns);

  const { columns } = tableProps;

  const fields = React.useMemo(
    (): readonly string[] =>
      columns.map((column: Column<UiMessage>): string => {
        if (column.key in rawMessageToFixMap) {
          return rawMessageToFixMap[column.key];
        } else {
          return column.key as string;
        }
      }),
    [columns]
  );

  useVenuesInitializer(props.setVenues);
  useOrderOriginationIdsInitializer(props.setOrderOriginationIds);

  const onFieldChange = (name: string, value: any): void =>
    setFormValue({ key: name, value: value });

  const onFieldSelectionChange = React.useCallback(
    (selection: readonly string[]): void => {
      const newColumns = selection.map((tag: string): Column<UiMessage> => {
        const base: ColumnDefinition<UiMessage> | undefined = baseColumns.find(
          (definition: ColumnDefinition<UiMessage>): boolean => {
            return rawMessageToFixMap[definition.key] === tag;
          }
        );
        if (base === undefined) {
          const field = dictionary[tag] as {
            readonly name: string;
            readonly type: string;
          };

          return createFromDefinition<UiMessage>({
            key: tag as keyof UiMessage,
            header: field.name,
            columnType: ColumnType.text,
            weight: 1,
          } as ColumnDefinition<any>);
        } else {
          return createFromDefinition(base);
        }
      });

      tableProps.onColumnsChanged(newColumns);
    },
    [tableProps]
  );

  const onSearch = async (): Promise<void> => {
    const { form } = props;
    props.startSearch();
    // Do the fetch here
    try {
      const results = await search(api, form);

      props.setSearchResult(messagesArrayToMessagesRows(results));
      props.endSearch(null);
    } catch (error: any) {
      props.endSearch(error);
    }
  };

  return (
    <Scaffold title="Messages Search">
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <Form
            form={form}
            orderOriginationIds={orderOriginationIds}
            venues={venues}
            onFieldChange={onFieldChange}
            onSearch={onSearch}
          />
          <div className={styles.toolbarContainer}>
            <div className={styles.toolbarFooter}>
              <Toolbar>
                <FIXFieldsSelector selection={fields} onSelectionChange={onFieldSelectionChange} />
              </Toolbar>
            </div>
          </div>
        </div>
        {/* FIXME: show an empty table message when messages === null */}
        <Table
          rowKey="clientOrderId"
          rows={messages ?? {}}
          loading={ProcessingState.isProcessing(processingState)}
          {...tableProps}
        />
      </div>
    </Scaffold>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, any> = {
  setVenues,
  setOrderOriginationIds,
  setFormValue,
  startSearch,
  endSearch,
  setSearchResult: setMessages,
};

const mapStateToProps: MapStateToProps<MessagesScreenState, any, ApplicationState> = (
  applicationState: ApplicationState
): MessagesScreenState => applicationState.messages;

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
