import {
  ADD_USER,
  BROWSE_SOLACE_QUEUE,
  DELETE_SOLACE_MESSAGES,
  GET_ACTIVE_LOGIN,
  GET_CHAINED_MESSAGES,
  GET_CUSTOMERS,
  GET_ERRORS,
  GET_FLOW,
  GET_MESSAGES,
  GET_ORDER_ORIGINATION_IDS,
  GET_PROFITABILITY_REPORT,
  GET_RAW_MESSAGE,
  GET_SNAPSHOT,
  GET_SOLACE_SERVERS,
  GET_SUMMARY_REPORT,
  GET_USERS,
  GET_VENUES,
  OKTA_GET_GROUPS_BY_APP,
  PRISM_DOWNLOAD_OUTER_SM_CONFIG,
  PRISM_FXO_CREATE_FIRM,
  PRISM_FXO_CREATE_USER,
  PRISM_FXO_DELETE_FIRM,
  PRISM_FXO_DELETE_USER,
  PRISM_FXO_GET_FIRMS,
  PRISM_FXO_UPDATE_FIRM,
  PRISM_FXO_UPDATE_USER,
  PRISM_GET_APPS,
  PRISM_GET_COMPANIES,
  PRISM_GET_DATABASE_SERVERS,
  PRISM_GET_ROLE,
  PRISM_GET_USER_DETAILS,
  PRISM_ME,
  PRISM_REFRESH_CACHE,
  RISK_GET_MATRIX,
  RISK_GET_SESSIONS,
  RISK_GET_SESSIONS_BY_RISK_ENGINE_ID,
  SM_DELETE_CONFIG,
  SM_GET_CONFIG,
  SM_GET_DEFAULTS,
  SM_IMPORT_CONFIG,
  SM_RELOAD_RULE,
  SM_SAVE_CONFIG,
  SM_SYNC_CONFIG,
  SM_UPDATE_SEQUENCE_NUMBERS,
  SONIC_GET_GROUPS,
  UPDATE_RISK_MATRIX,
} from 'api/constants';
import { Delete, Get, Post } from 'api/http';
import { DELETE, GET, POST } from 'api/httpNg';
import { ActiveLogin } from 'api/interfaces/activeLogin';
import { ApplicationUser, UserPayload } from 'api/interfaces/applicationUser';
import { CRUDResponse, defaultResponseValue } from 'api/interfaces/CRUDResponse';
import { BackendFirm, FirmPayload } from 'api/interfaces/firm';
import { FlowItem } from 'api/interfaces/flowItem';
import { GetMessagesParams } from 'api/interfaces/getMessagesParams';
import { OrderOriginationIdItem } from 'api/interfaces/orderOriginationIds';
import { PrismApplication } from 'api/interfaces/prismApplication';
import { RawMessage } from 'api/interfaces/rawMessage';
import { RoleResponse } from 'api/interfaces/roleResponse';
import { SnapshotResponse } from 'api/interfaces/snapshot';
import { SonicGroup } from 'api/interfaces/sonicGroup';
import { OktaGroup, User } from 'api/interfaces/user';
import { UserDetails } from 'api/interfaces/userDetails';
import config from 'config';
import { toQueryString } from 'helpers/toQueryString';
import React from 'react';
import { BackendSMConfig, SMDefaultConfiguration } from 'redux/definitions/central';
import { SolaceServer } from 'redux/reducers/smConfigReducer';
import { AddUserForm } from 'redux/reducers/whitelistReducer';
import { NoopTask, Task } from 'task';
import { AccessToken } from 'types/accessToken';
import { DatabaseServer } from 'types/databaseServer';
import { RecordsPage } from 'types/page';
import { ProfitabilityReport, ProfitabilityReportQuery } from 'types/profitabilityReport';
import { RiskMatrix } from 'types/risk';
import { SequenceNumberUpdatePayload } from 'types/sequenceNumberUpdatePayload';
import { RawSolaceLogEntry, RawSolaceQueueEntry } from 'types/solace';
import { SolaceBrowserQuery } from 'types/solaceBrowser';
import { SummaryReportQuery, SummaryReportResponse } from 'types/summaryReport';
import {
  EncryptionAlgorithm,
  HashAlgorithm,
  KeyPasswordType,
  PeerAuthenticationMethod,
} from 'types/vpn';

export class Api {
  private readonly url: string;
  private accessToken: AccessToken | null = null;

  constructor(url: string) {
    this.url = url;
  }

  public setAccessToken(accessToken: AccessToken): void {
    this.accessToken = accessToken;
  }

  private authorizationHeader = (): Record<string, string> => {
    if (this.accessToken === null) {
      return {};
    }

    const { accessToken } = this.accessToken;
    return { authorization: `Bearer ${accessToken}` };
  };

  private _post = <T>(url: string, data: Record<string, any> | string, empty: T): Task<T> => {
    return Post(url, data, empty, this.authorizationHeader());
  };

  private _delete = <T>(url: string, empty: T): Task<T> => {
    return Delete(url, empty, this.authorizationHeader());
  };

  private _get = <T>(url: string, empty: T): Task<T> => {
    return Get(url, empty, this.authorizationHeader());
  };

  public getMessages = (params: GetMessagesParams): Task<readonly RawMessage[]> => {
    // noinspection SpellCheckingInspection
    const url = `${this.url}/${GET_MESSAGES}?${toQueryString(params)}`;
    return this._get(url, []);
  };

  public getMessage = (id: number): Task<[{ readonly rawmsg: string }]> => {
    // noinspection SpellCheckingInspection
    const url = `${this.url}/${GET_RAW_MESSAGE}?${toQueryString({
      msgid: id,
    })}`;

    return this._get<[{ rawmsg: string }]>(url, [{ rawmsg: '' }]);
  };

  public getChainedMessages = (clOrdId: string): Task<readonly any[]> => {
    // noinspection SpellCheckingInspection
    const params = { environment: config.environment, clordid: clOrdId };
    const url = `${this.url}/${GET_CHAINED_MESSAGES}?${toQueryString(params)}`;
    return this._get(url, []);
  };

  public syncSMConfiguration = async (
    signal: AbortSignal,
    sessionId: string
  ): Promise<CRUDResponse> => {
    const params = { sessionid: sessionId, fromdb: true };
    const url = `${this.url}/${SM_SYNC_CONFIG}?${toQueryString(params)}`;

    return POST(url, params, defaultResponseValue, this.authorizationHeader(), signal);
  };

  public getSolaceServers = (signal: AbortSignal): Promise<readonly SolaceServer[]> => {
    const url = `${this.url}/${GET_SOLACE_SERVERS}`;
    return GET(url, [], this.authorizationHeader(), signal);
  };

  public getSMDefaults = async (signal: AbortSignal): Promise<SMDefaultConfiguration> => {
    const url = `${this.url}/${SM_GET_DEFAULTS}`;

    return GET<SMDefaultConfiguration>(url, [], this.authorizationHeader(), signal);
  };

  public getSMConfiguration = async (
    signal: AbortSignal,
    sessionId: string
  ): Promise<BackendSMConfig> => {
    const params = { sessionid: sessionId, fromdb: true };
    const url = `${this.url}/${SM_GET_CONFIG}?${toQueryString(params)}`;

    return POST<BackendSMConfig>(
      url,
      {},
      BackendSMConfig.empty(),
      this.authorizationHeader(),
      signal
    );
  };

  public createSMConfiguration = async (
    signal: AbortSignal,
    data: BackendSMConfig,
    sync: boolean
  ): Promise<CRUDResponse> => {
    const params = { syncfile: sync, json_content: JSON.stringify(data) };
    const url = `${this.url}/${SM_SAVE_CONFIG}?${toQueryString(params)}`;

    return POST(
      url,
      {},
      { success: false, message: 'no response' },
      this.authorizationHeader(),
      signal
    );
  };

  public deleteSMConfiguration = async (
    signal: AbortSignal,
    sessionId: string
  ): Promise<CRUDResponse> => {
    const params = { sessionid: sessionId };
    const url = `${this.url}/${SM_DELETE_CONFIG}?${toQueryString(params)}`;

    const task = this._delete(url, { success: false, message: 'no response' });
    return task.run();
  };

  public resetSMSequence = async (
    signal: AbortSignal,
    data: SequenceNumberUpdatePayload
  ): Promise<CRUDResponse> => {
    const url = `${this.url}/${SM_UPDATE_SEQUENCE_NUMBERS}`;

    return POST(
      url,
      data,
      { success: false, message: 'no response' },
      this.authorizationHeader(),
      signal
    );
  };

  public importSMConfiguration = async (
    signal: AbortSignal,
    sessionId: string
  ): Promise<CRUDResponse> => {
    const params = { sessionid: sessionId };
    const url = `${this.url}/${SM_IMPORT_CONFIG}?${toQueryString(params)}`;

    return POST(
      url,
      params,
      { success: false, message: 'no response' },
      this.authorizationHeader(),
      signal
    );
  };

  public reloadSMRule = async (signal: AbortSignal, sessionId: string): Promise<CRUDResponse> => {
    const url = `${this.url}/${SM_RELOAD_RULE}`;
    const formData = new FormData();
    // Update session id
    formData.set('sessionid', sessionId);

    return POST(
      url,
      formData,
      { success: false, message: 'no response' },
      this.authorizationHeader(),
      signal
    );
  };

  public updateSMConfiguration = async (
    signal: AbortSignal,
    sessionId: string,
    data: BackendSMConfig,
    sync = false
  ): Promise<CRUDResponse> => {
    const params = { sessionid: sessionId, syncfile: sync, json_content: JSON.stringify(data) };
    const url = `${this.url}/${SM_SAVE_CONFIG}?${toQueryString(params)}`;

    return POST(
      url,
      params,
      { success: false, message: 'no response' },
      this.authorizationHeader(),
      signal
    );
  };

  public getSnapshot = (signal: AbortSignal): Promise<SnapshotResponse> => {
    const url = `${this.url}/${GET_SNAPSHOT}?environment=${config.environment}`;
    return GET(
      url,
      {
        SequenceNum: 0,
        Sessions: [],
      } as SnapshotResponse,
      this.authorizationHeader(),
      signal
    );
  };

  public deleteSolaceMessages = (
    signal: AbortSignal,
    ids: readonly string[],
    query: SolaceBrowserQuery
  ): Promise<RecordsPage<RawSolaceQueueEntry>> => {
    const fullQuery = { ...query, solacemsgid: ids.join(',') };
    const url = `${this.url}/${DELETE_SOLACE_MESSAGES}?${toQueryString(fullQuery)}`;
    return DELETE(url, RecordsPage.empty(), this.authorizationHeader(), signal);
  };

  public browseSolaceQueue = (
    signal: AbortSignal,
    query: SolaceBrowserQuery
  ): Promise<RecordsPage<RawSolaceQueueEntry>> => {
    const url = `${this.url}/${BROWSE_SOLACE_QUEUE}?${toQueryString(query)}`;
    return GET(url, RecordsPage.empty(), this.authorizationHeader(), signal);
  };

  public getSummaryReport = (
    signal: AbortSignal,
    query: SummaryReportQuery
  ): Promise<SummaryReportResponse> => {
    const url = `${this.url}/${GET_SUMMARY_REPORT}?${toQueryString(query)}`;
    return GET<SummaryReportResponse>(
      url,
      {
        Records: [],
        Total: 0,
      },
      this.authorizationHeader(),
      signal
    );
  };

  public getSolaceLogs = (signal: AbortSignal): Promise<readonly RawSolaceLogEntry[]> => {
    const url = `${this.url}/${GET_ERRORS}`;
    return GET(url, [], this.authorizationHeader(), signal);
  };

  public getFlows = (signal: AbortSignal): Promise<readonly FlowItem[]> => {
    const params = { environment: config.environment };
    const url = `${this.url}/${GET_FLOW}?${toQueryString(params)}`;

    return GET(url, [], this.authorizationHeader(), signal);
  };

  public getVenues = (): Task<readonly OrderOriginationIdItem[]> => {
    const params = { environment: config.environment };
    const url = `${this.url}/${GET_VENUES}?${toQueryString(params)}`;
    return this._get(url, []);
  };

  public getOrderOriginationIds = (): Task<readonly OrderOriginationIdItem[]> => {
    const params = { environment: config.environment };
    const url = `${this.url}/${GET_ORDER_ORIGINATION_IDS}?${toQueryString(params)}`;
    return this._get(url, []);
  };

  public getActiveLogin = (): Task<readonly ActiveLogin[]> => {
    const url = `${this.url}/${GET_ACTIVE_LOGIN}`;
    return this._get(url, []);
  };

  public getUsers = (): Task<readonly User[]> => {
    const url = `${this.url}/${GET_USERS}`;
    return this._get(url, []);
  };

  public addUser = (data: AddUserForm, user: string): Task<void> => {
    const url = `${this.url}/${ADD_USER}`;
    return this._post(url, { ...data, User: user }, null);
  };

  public getApplications(signal: AbortSignal): Promise<PrismApplication[]> {
    const url = `${this.url}/${PRISM_GET_APPS}`;
    return GET(url, [], this.authorizationHeader(), signal);
  }

  public getDatabaseServers(signal: AbortSignal): Promise<DatabaseServer[]> {
    const url = `${this.url}/${PRISM_GET_DATABASE_SERVERS}`;
    return GET(url, [], this.authorizationHeader(), signal);
  }

  public downloadSMOuterConfig(sessionId: string): Task<null> {
    const url = `${this.url}/${PRISM_DOWNLOAD_OUTER_SM_CONFIG}?sessionid=${sessionId}`;
    return this._get(url, null);
  }

  public getUserDetails = (email: string): Task<UserDetails> => {
    const url = `${this.url}/${PRISM_GET_USER_DETAILS}/?email_address=${email}`;
    return this._get(url, UserDetails.empty());
  };

  public getSonicGroupsByApp = (applicationId: string): Task<readonly SonicGroup[]> => {
    const url = `${this.url}/${SONIC_GET_GROUPS}/?appid=${applicationId}`;
    return this._get(url, []);
  };

  public getSonicGroupsForUser = (email: string): Task<readonly SonicGroup[]> => {
    if (email === '') {
      return NoopTask([]);
    }

    const url = `${this.url}/${SONIC_GET_GROUPS}/?email_address=${email}`;
    return this._get(url, []);
  };

  public getOktaGroupsByApp = (appId: string): Task<readonly OktaGroup[]> => {
    const url = `${this.url}/${OKTA_GET_GROUPS_BY_APP}/?appid=${appId}`;
    return this._get(url, []);
  };

  public getRole = (userId: string): Task<RoleResponse> => {
    const url = `${this.url}/${PRISM_GET_ROLE}/?userid=${userId}`;
    return this._get(url, {} as RoleResponse);
  };

  public me = (): Task<User> => {
    const url = `${this.url}/${PRISM_ME}?prismOnlyRoles=false`;
    return this._get(url, {} as User);
  };

  // FXO Firms
  public getFXOFirms = (): Task<readonly BackendFirm[]> => {
    const url = `${this.url}/${PRISM_FXO_GET_FIRMS}`;
    return this._get(url, []);
  };

  public deleteFirm = (firm: FirmPayload): Task<CRUDResponse> => {
    const url = `${this.url}/${PRISM_FXO_DELETE_FIRM}/?firmid=${firm.FirmID}`;
    return this._delete(url, defaultResponseValue);
  };

  public updateFirm = (firm: FirmPayload): Task<CRUDResponse> => {
    const url = `${this.url}/${PRISM_FXO_UPDATE_FIRM}`;
    return this._post(url, firm, defaultResponseValue);
  };

  public createFirm = (firm: FirmPayload): Task<CRUDResponse> => {
    const url = `${this.url}/${PRISM_FXO_CREATE_FIRM}`;
    return this._post(url, firm, defaultResponseValue);
  };

  public deleteUser = (user: ApplicationUser): Task<CRUDResponse> => {
    const url = `${this.url}/${PRISM_FXO_DELETE_USER}?email_address=${user.email}`;
    return this._delete(url, defaultResponseValue);
  };

  public createUser = (user: UserPayload): Task<CRUDResponse> => {
    const url = `${this.url}/${PRISM_FXO_CREATE_USER}`;
    return this._post(url, user, defaultResponseValue);
  };

  public updateUser = (user: UserPayload): Task<CRUDResponse> => {
    const url = `${this.url}/${PRISM_FXO_UPDATE_USER}`;
    return this._post(url, user, defaultResponseValue);
  };

  public getCompanies = (): Task<any> => {
    const url = `${this.url}/${PRISM_GET_COMPANIES}`;
    return this._get(url, defaultResponseValue);
  };

  public refreshUserGroupCache = (email?: string): Task<any> => {
    const query = { email_address: email };
    const url = email
      ? `${this.url}/${PRISM_REFRESH_CACHE}?${query}`
      : `${this.url}/${PRISM_REFRESH_CACHE}`;

    return this._post(url, {}, {});
  };

  public getRiskEngines = (): Task<any> => {
    const query = toQueryString({ environment: config.environment });
    const url = `${this.url}/${RISK_GET_SESSIONS}?${query}`;

    return this._get(url, null);
  };

  public getRiskSessions = (riskEngineID: string): Task<any> => {
    const query = toQueryString({
      environment: config.environment,
      riskengineid: riskEngineID,
    });
    const url = `${this.url}/${RISK_GET_SESSIONS_BY_RISK_ENGINE_ID}?${query}`;

    return this._get(url, null);
  };

  public getRiskMatrix = (
    sessionID: string,
    riskEngineID: string
  ): Task<RiskMatrix | CRUDResponse> => {
    const query = toQueryString({
      sessionid: sessionID,
      riskengineid: riskEngineID,
    });
    const url = `${this.url}/${RISK_GET_MATRIX}?${query}`;

    return this._get<RiskMatrix>(url, null);
  };

  public updateRiskMatrix = (matrix: RiskMatrix): Task<CRUDResponse> => {
    const url = `${this.url}/${UPDATE_RISK_MATRIX}`;
    return this._post(url, matrix, null);
  };

  public getProfitabilityReports = (
    query: ProfitabilityReportQuery
  ): Task<readonly ProfitabilityReport[]> => {
    const queryObject = {
      from_time: query.fromTime,
      to_time: query.toTime,
      customer: query.customer,
    };
    const url = `${this.url}/${GET_PROFITABILITY_REPORT}?${toQueryString(queryObject)}`;

    return this._get(url, []);
  };

  public getCustomers = (): Task<readonly string[]> => {
    const url = `${this.url}/${GET_CUSTOMERS}`;

    return this._get(url, []);
  };

  public signOut = (): Task<void> => {
    const { accessToken } = this;
    const { okta } = config;

    const parameters = {
      token: accessToken.accessToken,
      token_type_hint: 'access_token',
    };

    return this._post(okta.logoutUrl, toQueryString(parameters), null);
  };

  public getEncryptionAlgorithms(): Task<readonly EncryptionAlgorithm[]> {
    const url = `${this.url}/prism/sm/nv/getencryptalgo`;
    return this._get(url, []);
  }

  public getHashAlgorithms(): Task<readonly HashAlgorithm[]> {
    const url = `${this.url}/prism/sm/nv/gethashalgo`;
    return this._get(url, []);
  }

  public getKeyPass(): Task<readonly KeyPasswordType[]> {
    const url = `${this.url}/prism/sm/nv/getkeypass`;
    return this._get(url, []);
  }

  public getPeerAuthMethods(): Task<readonly PeerAuthenticationMethod[]> {
    const url = `${this.url}/prism/sm/nv/getpeerauthmethod`;
    return this._get(url, []);
  }

  public flushSubscribedSymbols(signal: AbortSignal, sessionId: string): Promise<void> {
    const url = `${this.url}/prism/sm/flushsubscribedsymbols?sessionid=${sessionId}`;
    return DELETE(url, null, this.authorizationHeader(), signal);
  }
}

export const ApiContext = React.createContext<Api | undefined>(undefined);
