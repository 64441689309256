import { Report } from '@mui/icons-material';
import { Button } from '@mui/material';
import config from 'config';
import { useApi } from 'hooks/useApi';
import { useSession } from 'hooks/useSession';
import React from 'react';
import { useNavigate } from 'react-router';
import styles from 'routes/LoginCallback/login-callback.module.scss';
import { AccessToken } from 'types/accessToken';

const LoginCallback = (): React.ReactElement => {
  const [error, setError] = React.useState<string | null>(null);

  const api = useApi();
  const session = useSession();
  const navigate = useNavigate();

  React.useEffect((): void => {
    try {
      const accessToken = AccessToken.fromUrl();

      api.setAccessToken(accessToken);

      session.setAccessToken(accessToken);
      navigate('/flows', { replace: true });
    } catch (ex: any) {
      if ('message' in ex && typeof ex.message === 'string') {
        setError(ex.message);
      } else if (typeof ex === 'string') {
        setError(ex);
      }
    }
  }, [api, navigate, session]);

  const onSignOut = (): void => {
    session
      .signOut()
      .then((): void => {
        window.location.href = config.loginUrl;
      })
      .catch(console.warn);
  };

  // FIXME: show loading screen
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Report className={styles.icon} color="error" />
        <div className={styles.text}>
          <h1>Something went wrong</h1>
          <h2>Sorry, there was an error in the authentication process</h2>
          <h3>{error}</h3>
        </div>
      </div>
      <Button onClick={onSignOut}>Click here to sign out</Button>
    </div>
  );
};

export default LoginCallback;
