import { WithID } from 'types/identifiable';

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export interface SymbolLimits {
  readonly symbol: string;
  readonly maxnotional: number;
  readonly maxqty: number;
  readonly maxpercentaway: number;
}

export interface Account {
  readonly account: string;
  readonly symbolLimits: readonly SymbolLimits[];
}

export interface AccountsGroup {
  readonly accounttag: number;
  readonly accounts: readonly Account[];
}

export interface RiskMatrix {
  readonly riskengineid: string;
  readonly sessionid: string;
  readonly equities: AccountsGroup;
  readonly options: AccountsGroup;
}

export interface UpdateOrCreateEntryRequest {
  readonly account: string;
  readonly assetClassType: AssetClassType;
  readonly symbolLimits: SymbolLimits;
}

export interface RemoveSymbolLimitsRequest {
  readonly account: string;
  readonly symbol: string;
}

export type SymbolTableRow = WithID<SymbolLimits & { readonly account: string }>;

export const emptySymbolTableRow: Nullable<SymbolTableRow> = {
  id: '',
  account: '',
  symbol: null,
  maxnotional: null,
  maxqty: null,
  maxpercentaway: null,
};

export type AssetClassType = keyof Pick<RiskMatrix, 'equities' | 'options'>;
