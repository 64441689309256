import config from 'config';
import { useApi } from 'hooks/useApi';
import { useSession } from 'hooks/useSession';
import strings from 'locales';
import React from 'react';
import { Navigate } from 'react-router';
import styles from 'routes/LoginFlow/login-flow.module.scss';
import { QueryString } from 'types/queryString';
import { randomString } from 'utils/randomString';

const LoginFlow = (): React.ReactElement | null => {
  const session = useSession();
  const api = useApi();

  React.useEffect((): void => {
    if (session.isValid()) {
      api.setAccessToken(session.accessToken);
      return;
    }

    void authenticate();
  }, [api, session]);

  if (session.isValid()) {
    return <Navigate to="/flows" replace />;
  } else {
    return <div className={styles.container}>{strings.WeAreRedirectingYou}</div>;
  }
};

export default LoginFlow;

const authenticate = async (): Promise<void> => {
  const { location } = window;
  const { okta } = config;
  const { scopes } = okta;

  const params = new QueryString({
    client_id: okta.clientId,
    redirect_uri: okta.redirectUri,
    // TODO: we could use this, by storing it somewhere
    state: randomString(),
    response_type: 'token id_token',
    nonce: randomString(),
    scope: scopes.join(' '),
  });

  location.href = `${okta.authorizeUrl}?${params.toString()}`;
};
