import { createStatusReducer, Status } from 'enums/status';
import { Totals } from 'interfaces/totals';
import { WithStatus } from 'interfaces/withStatus';

export const computeTotals = (data: readonly WithStatus[]): Totals => {
  return {
    all: data.length,
    on: data.reduce(createStatusReducer(Status.On), 0),
    off: data.reduce(createStatusReducer(Status.Off), 0),
    overtime: data.reduce(createStatusReducer(Status.Overtime), 0),
    error: data.reduce(createStatusReducer(Status.Down), 0),
    unknown: data.reduce(createStatusReducer(Status.Unknown), 0),
  };
};
