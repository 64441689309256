import moment from 'moment';
import { SMRefDataConfigFile, SMRefDataConfigFileUpdateMode } from 'redux/definitions/central';

export const parseRefCfgContent = (data: any): readonly SMRefDataConfigFile[] => {
  if (data === undefined || data === null) {
    return [];
  }

  return Object.entries(data).map(([key, value]: [string, any]): SMRefDataConfigFile => {
    const updateMode = value.UpdateMode;
    const base = {
      id: key,
      file: value.File,
      keys: value.Keys,
    };

    switch (updateMode as SMRefDataConfigFileUpdateMode) {
      case SMRefDataConfigFileUpdateMode.periodic:
        return { ...base, updateMode: updateMode, interval: value.Interval };
      case SMRefDataConfigFileUpdateMode.daily:
        return { ...base, updateMode: updateMode, when: moment(value.When, 'HH:mm:ss') };
      case SMRefDataConfigFileUpdateMode.weekly:
        return {
          ...base,
          updateMode: updateMode,
          dayOfWeek: value.Day,
          when: moment(value.When, 'HH:mm:ss'),
        };
      case SMRefDataConfigFileUpdateMode.monthly:
        return { ...base, updateMode: updateMode, day: '30', when: moment(value.When, 'HH:mm:ss') };
      case undefined:
        return { ...base, updateMode: updateMode };
      default:
        throw new Error(`unknown update mode: ${updateMode}`);
    }
  });
};
