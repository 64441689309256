import { parseResponse, wrapResolver } from 'api/parseResponse';
import { Task } from 'task';

const requestWithoutBody = <T>(
  url: string,
  empty: T,
  headers: Record<string, string>,
  method: 'GET' | 'DELETE'
): Task<T> => {
  const controller = new AbortController();
  return new Task<T>(
    (resolve: (result: T) => void, reject: (reason: any) => void): void => {
      const resolver = wrapResolver(resolve, reject, empty);
      fetch(url, {
        method: method,
        headers: headers,
        signal: controller.signal,
      })
        .then(parseResponse)
        .then(resolver)
        .catch((reason: any): void => {
          if (reason?.name === 'AbortError') {
            resolve(empty);
            return;
          }

          reject(reason);
        });
    },
    (): void => controller.abort()
  );
};

export const Delete = <T>(url: string, empty: T, headers: Record<string, string>): Task<T> => {
  return requestWithoutBody(url, empty, headers, 'DELETE');
};

export const Get = <T>(url: string, empty: T, headers: Record<string, string>): Task<T> => {
  return requestWithoutBody(url, empty, headers, 'GET');
};

export const Post = <T>(
  url: string,
  data: Record<string, any> | string,
  empty: T,
  headers: Record<string, string>
): Task<T> => {
  const controller = new AbortController();
  return new Task<T>(
    (resolve: (result: T) => void, reject: (reason: any) => void): void => {
      const resolver = wrapResolver(resolve, reject, empty);
      fetch(url, {
        method: 'POST',
        signal: controller.signal,
        body: typeof data === 'string' ? data : JSON.stringify(data),
        headers: {
          ...headers,
          // Force content type
          'content-type':
            typeof data === 'string' ? 'application/x-www-form-urlencoded' : 'application/json',
        },
      })
        .then(parseResponse)
        .then(resolver)
        .catch(reject);
    },
    (): void => controller.abort()
  );
};
