import { UserDetails } from 'api/interfaces/userDetails';
import config from 'config';
import { DropdownOption } from 'types/dropdownOption';
import { WhitelistItem } from 'types/whitelistItem';

export interface ApplicationUser {
  readonly id: string;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly password: string;
  readonly workPhone: string;
  readonly cellPhone: string;
  readonly businessAddress: string;
  readonly recoveryQuestion: string;
  readonly recoveryAnswer: string;
  readonly oktaGroups: readonly DropdownOption[];
  readonly sonicGroups: readonly DropdownOption[];
  readonly companyId: string;
  readonly firmId: string;
  readonly whitelist: readonly WhitelistItem[];
  readonly MSTraderID?: string;
  readonly UBSTraderID: string;
  readonly TradingEntityCode?: string;
  readonly MSCOTraderID?: string;
  readonly TRTNTraderID?: string;

  readonly applicationId: string;
}

export class ApplicationUser {
  public static defaultUser(): ApplicationUser {
    return {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      workPhone: '',
      cellPhone: '',
      businessAddress: '',
      recoveryQuestion: '',
      recoveryAnswer: '',
      oktaGroups: [],
      sonicGroups: [],
      companyId: '',
      firmId: '',
      whitelist: [],
      MSCOTraderID: '',
      MSTraderID: '',
      UBSTraderID: '',
      TradingEntityCode: '',
      applicationId: config.defaultApplicationId,
    };
  }

  public static fromUserDetails(details: UserDetails): ApplicationUser {
    return {
      id: details.email_address,
      email: details.email_address,
      firstName: details.first_name,
      lastName: details.last_name,
      password: '',
      workPhone: details.work_phone,
      cellPhone: details.cell,
      businessAddress: details.business_address,
      recoveryQuestion: details.recovery_question,
      recoveryAnswer: details.recovery_answer,
      oktaGroups: details.okta_group,
      sonicGroups: [],
      companyId: details.companyid,
      firmId: details.FirmID,
      whitelist: [],
      MSCOTraderID: details.MSCOTraderID,
      MSTraderID: details.MSTraderID,
      UBSTraderID: details.UBSTraderID,
      TradingEntityCode: details.TradingEntityCode,
      TRTNTraderID: details.TRTNTraderID,
      applicationId: config.defaultApplicationId,
    };
  }
}

export interface UserPayload {
  readonly password: string;
  readonly email_address: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly work_phone: string;
  readonly cell: string;
  readonly business_address: string;
  readonly recovery_question: string;
  readonly recovery_answer: string;
  readonly okta_groupids: readonly string[];
  readonly sonic_groupids: readonly string[];
  readonly appid: string;
  readonly companyid: string;
  readonly IPAddresses: readonly string[];
  // FIXME: FXO specifics
  readonly NameCode?: string;
  readonly FirmID?: string;
  readonly MSTraderID?: string;
  readonly UBSTraderID: string;
  readonly TradingEntityCode?: string;
  readonly MSCOTraderID: string;
  readonly TRTNTraderID: string;
}

const generateNameCode = (firstName: string, lastName: string): string => {
  return `${firstName.slice(0, 3)}${lastName.slice(0, 3)}`.toUpperCase();
};

export class UserPayload {
  public static fromApplicationUser(
    data: ApplicationUser,
    applicationSpecificOktaGroupsIds: readonly string[]
  ): UserPayload {
    const { oktaGroups, sonicGroups } = data;

    return {
      email_address: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      work_phone: data.workPhone,
      cell: data.cellPhone,
      business_address: data.businessAddress,
      recovery_question: data.recoveryQuestion,
      recovery_answer: data.recoveryAnswer,
      okta_groupids: oktaGroups
        .map((group: DropdownOption): string => String(group.id))
        .filter((id: string): boolean => applicationSpecificOktaGroupsIds.includes(id)),
      sonic_groupids: sonicGroups.map((group: DropdownOption): string => String(group.id)),
      companyid: data.companyId,
      appid: data.applicationId,
      IPAddresses: data.whitelist.map((item: WhitelistItem): string => item.ipAddress),
      FirmID: data.firmId,
      NameCode: generateNameCode(data.firstName, data.lastName),
      MSTraderID: data.MSTraderID,
      TradingEntityCode: data.TradingEntityCode,
      MSCOTraderID: data.MSCOTraderID,
      UBSTraderID: data.UBSTraderID,
      TRTNTraderID: data.TRTNTraderID,
      password: data.password,
    };
  }
}
