import { CenteredModal } from 'components/CenteredModal';
import styles from 'components/FormModal/form-modal.module.scss';
import { TableMode } from 'components/Table';
import React from 'react';

interface Props {
  readonly entityName: string;
  readonly mode: TableMode;
  readonly open: boolean;

  onClose(): void;
}

export const FormModal: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>
): React.ReactElement => {
  const [modified, setModified] = React.useState<boolean>(false);
  const [errored, setErrored] = React.useState<boolean>(false);

  const { open, mode, entityName, children } = props;
  const { onClose } = props;

  React.useEffect((): VoidFunction => {
    return (): void => {
      setModified(false);
      setErrored(false);
    };
  }, []);

  const titleSuffix = React.useMemo((): React.ReactElement | null => {
    if (errored && modified) {
      return (
        <>
          <span>*</span>
          <span className={styles.error}>
            <i className="fa fa-exclamation-triangle" />
          </span>
        </>
      );
    } else if (modified) {
      return <span>*</span>;
    } else if (errored) {
      return <span color="crimson">!</span>;
    }

    return null;
  }, [errored, modified]);

  const title = React.useMemo((): React.ReactElement | null => {
    switch (mode) {
      case TableMode.create:
        return (
          <>
            <span>Create {entityName}</span>
            {titleSuffix}
          </>
        );
      case TableMode.edit:
        return (
          <>
            <span>Update {entityName}</span>
            {titleSuffix}
          </>
        );
      default:
        return null;
    }
  }, [entityName, mode, titleSuffix]);

  if (!React.isValidElement(children)) {
    throw new Error('FormModal children must be a valid React element');
  }

  return (
    <CenteredModal title={title} open={open} onClose={onClose}>
      {React.cloneElement(children, {
        onErrorsChanged: setErrored,
        onModifiedChanged: setModified,
      })}
    </CenteredModal>
  );
};
