import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { NumericInput } from 'routes/Risk/components/NumericInput';
import { TextInput } from 'routes/Risk/components/TextInput';
import {
  AssetClassType,
  emptySymbolTableRow,
  SymbolLimits,
  SymbolTableRow,
  UpdateOrCreateEntryRequest,
} from 'types/risk';

interface Props {
  readonly type: AssetClassType;
  readonly initialData?: SymbolTableRow;

  onClose(): void;
  onSubmit(request: UpdateOrCreateEntryRequest): void;
}

export const EditSymbolModal: React.FC<Props> = (props: Props): React.ReactElement => {
  const { type, initialData = emptySymbolTableRow, onClose, onSubmit } = props;
  const [form, setForm] = React.useState<SymbolLimits>(initialData);

  const handleCreate = React.useCallback((): void => {
    onSubmit({
      assetClassType: type,
      account: initialData.account,
      symbolLimits: form,
    });
  }, [onSubmit, type, initialData.account, form]);

  const handleChange = React.useCallback(
    (name: keyof SymbolLimits, value: number | string | null): void => {
      setForm((form: SymbolLimits): SymbolLimits => ({ ...form, [name]: value }));
    },
    []
  );

  return (
    <Grid padding={2} direction="column" gap={2} container>
      <Grid item>
        <TextInput
          name="symbol"
          value={form.symbol}
          label="Symbol"
          readOnly={initialData !== emptySymbolTableRow}
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <NumericInput<SymbolLimits>
          name="maxqty"
          value={form.maxqty}
          label="Max Quantity"
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <NumericInput<SymbolLimits>
          name="maxpercentaway"
          value={form.maxpercentaway}
          label="Max Price (% Away)"
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <NumericInput
          name="maxnotional"
          value={form.maxnotional}
          label="Max Notional"
          onChange={handleChange}
        />
      </Grid>

      <Grid display="flex" alignItems="center" justifyContent="end" marginTop={2} gap={2}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleCreate}>
          {initialData === emptySymbolTableRow ? 'Add' : 'Update'}
        </Button>
      </Grid>
    </Grid>
  );
};
