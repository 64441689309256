import React from 'react';

interface Props<T> {
  readonly data: T;

  onClick(data: T): void;
}

export function Clickable<T>(props: React.PropsWithChildren<Props<T>>): React.ReactElement {
  const { data, children } = props;
  const { onClick } = props;

  const handleClick = React.useCallback((): void => {
    onClick(data);
  }, [data, onClick]);

  if (React.isValidElement(children)) {
    return React.cloneElement(children, {
      ...children.props,
      onClick: handleClick,
    });
  } else {
    throw new Error('only one child allowed and must be an element');
  }
}
