import { ErrorRounded } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import styles from 'components/modal-content.module.scss';
import React from 'react';

interface Props {
  readonly title: string;
  readonly message: string;

  readonly error: any;

  onClose(): void;
}

export const ErrorModal: React.FC<Props> = React.forwardRef(function ErrorModal(
  props: Props,
  _: React.Ref<HTMLDivElement>
): React.ReactElement {
  const { error } = props;

  const errorMessage: string = React.useMemo((): string => {
    if (typeof error === 'string') {
      return error;
    } else if (typeof error.message === 'string') {
      return error.message;
    } else {
      return 'Unknown error';
    }
  }, [error]);

  return (
    <Box p={2} className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.icon}>
            <ErrorRounded fontSize="inherit" color="error" />
          </div>
          <div className={styles.titleText}>
            <div>
              <Typography variant="h1">{props.title}</Typography>
            </div>
            <Typography className={styles.message} color="secondary">
              {props.message}
            </Typography>
            <div className={styles.error}>{errorMessage}</div>
          </div>
        </div>

        <div className={styles.buttons}>
          <Button onClick={props.onClose}>Close</Button>
        </div>
      </div>
    </Box>
  );
});
