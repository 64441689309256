import moment from 'moment';

const FIX_DATE_FORMAT = 'YYYYMMDD-HH:mm:ss.SSS';
const DISPLAY_DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm:ss.SSS';
const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';

export const formatDateTime = (value: string, utc = false): string => {
  if (utc) {
    return moment.utc(value, FIX_DATE_FORMAT).format(DISPLAY_DATE_TIME_FORMAT);
  }
  return moment(value, FIX_DATE_FORMAT).format(DISPLAY_DATE_TIME_FORMAT);
};

export const formatDate = (value: string, utc = false): string => {
  if (utc) {
    return moment.utc(value, FIX_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
  }
  return moment(value, FIX_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
};

export const formatTime = (value: string, _ = false): string => {
  return 'NOT_IMPLEMENTED';
};
