import { SideRenderer } from 'components/Table/renderers/SideRenderer';
import {
  Alignment,
  ColumnDefinition,
  ColumnType,
  createFromDefinition,
} from 'components/Table/types/column';
import { ExecutionRowTool } from 'routes/Executions/ExecutionRowTool';
import { Execution } from 'types/summaryReport';

const columns: ReadonlyArray<ColumnDefinition<Execution>> = [
  {
    columnType: ColumnType.custom,
    header: '',
    key: 'id',
    component: ExecutionRowTool,
    minWidth: 40,
    weight: 0.25,
    filterable: false,
  },
  {
    columnType: ColumnType.dateTime,
    header: 'Transaction Time',
    key: 'transactionTime',
    weight: 2,
    filterable: true,
    sortable: true,
  },
  {
    columnType: ColumnType.text,
    header: 'Venue',
    key: 'venue',
    alignment: Alignment.center,
    weight: 1,
    filterable: true,
    sortable: true,
  },
  {
    columnType: ColumnType.text,
    header: 'Client Order ID',
    key: 'clientOrderId',
    alignment: Alignment.center,
    weight: 2,
    filterable: true,
    sortable: true,
  },
  {
    columnType: ColumnType.text,
    header: 'Symbol',
    key: 'symbol',
    alignment: Alignment.center,
    weight: 1,
    filterable: true,
    sortable: true,
  },
  {
    columnType: ColumnType.text,
    header: 'Quantity',
    key: 'orderQuantity',
    alignment: Alignment.center,
    weight: 1,
    filterable: true,
    sortable: true,
  },
  {
    columnType: ColumnType.text,
    header: 'Price',
    key: 'price',
    alignment: Alignment.center,
    weight: 1,
    filterable: true,
    sortable: true,
  },
  {
    columnType: ColumnType.custom,
    header: 'Side',
    key: 'side',
    alignment: Alignment.center,
    weight: 1,
    component: SideRenderer,
    filterable: true,
    sortable: true,
  },
  {
    columnType: ColumnType.text,
    header: 'Sender Comp ID',
    key: 'senderCompId',
    alignment: Alignment.center,
    weight: 3,
    filterable: true,
    sortable: true,
  },
  {
    columnType: ColumnType.text,
    header: 'Target Comp ID',
    key: 'targetCompId',
    alignment: Alignment.center,
    weight: 3,
    filterable: true,
    sortable: true,
  },
];

export default columns.map(createFromDefinition);
