import config from 'config';
import React from 'react';
import { AccessToken } from 'types/accessToken';
import { QueryString } from 'types/queryString';

export class Session {
  public accessToken: AccessToken;

  constructor() {
    this.accessToken = AccessToken.load();
  }

  public setAccessToken(accessToken: AccessToken): void {
    accessToken.save();
    // Now make it available through the member
    this.accessToken = accessToken;
  }

  public isValid(): boolean {
    return this.accessToken.isValid();
  }

  public async signOut(): Promise<void> {
    const { idToken } = this.accessToken;
    const { okta, loginUrl } = config;
    const { location } = window;
    // Remove the stored token. This is only so that we initiate the process again
    // because the step below will actually invalidate this token
    this.accessToken.forget();
    this.accessToken = AccessToken.invalid();
    // Set up the query string according to
    // https://developer.okta.com/docs/reference/api/oidc/#logout
    const params = new QueryString({
      id_token_hint: idToken,
      post_logout_redirect_uri: loginUrl,
      client_id: okta.clientId,
    });
    // According to the documentation we have to redirect to this URL
    // instead of sending a (AJAX/fetch) request to it
    setTimeout((): void => {
      location.href = `${okta.logoutUrl}/?${params.toString()}`;
    }, 0);
  }
}

export const SessionContext = React.createContext<Session | null>(null);
