import { Cell } from 'components/Table/components/Cell';
import { Column } from 'components/Table/types/column';
import React from 'react';
import { Identifiable } from 'types/identifiable';

interface Props<T extends Identifiable> {
  readonly data: Record<keyof T, any>;
  readonly columns: ReadonlyArray<Column<T>>;
  readonly className?: string;
  readonly rowRef?: React.Ref<HTMLTableRowElement>;

  onClick?(): void;
}

export function ParentRow<T extends Identifiable>(props: Props<T>): React.ReactElement {
  const { rowRef } = props;
  const { columns, data, className } = props;
  const { onClick } = props;

  const mapper = React.useCallback(
    (column: Column<T>): JSX.Element => {
      const { key } = column;

      return <Cell key={key as string} column={column} cellValue={data[key]} data={data} />;
    },
    [data]
  );

  return (
    <tr ref={rowRef} id={data.id} className={className} onClick={onClick}>
      {columns.map(mapper)}
    </tr>
  );
}
