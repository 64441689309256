import moment from 'moment';
import { Moment } from 'moment';

export interface DayAndTime {
  readonly dayOfWeek: number;
  readonly time: Moment | null;
}

export class DayAndTime {
  public static invalid(): DayAndTime {
    return {
      dayOfWeek: -1,
      time: null,
    };
  }

  public static fromDayOfWeekAndTimeString(dayOfWeek: number, timeString: string): DayAndTime {
    const asMoment = moment(timeString + ':00', 'HH:mm:ss');

    return {
      dayOfWeek: dayOfWeek,
      time: asMoment,
    };
  }

  public static fromDayOfWeekAndMoment(dayOfWeek: number, time: Moment): DayAndTime {
    return {
      dayOfWeek: dayOfWeek,
      time: time,
    };
  }
}
