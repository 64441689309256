import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { CenteredModal } from 'components/CenteredModal';
import { Scaffold } from 'components/Scaffold';
import { StatusBar } from 'components/StatusBar';
import { Table, TableMode } from 'components/Table';
import { ProcessingState } from 'enums/processingState';
import { useCRUD } from 'hooks/useCRUD';
import { useStatusFilter } from 'hooks/useStatusFilter';
import { useTable } from 'hooks/useTable';
import { Flow } from 'interfaces/flow';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncDispatch } from 'redux/asyncAction';
import {
  flowsSelector,
  flowsTotalsSelector,
  processingStateSelector,
} from 'redux/reducers/flowsReducer';
import { statusBarSelector } from 'redux/reducers/statusBarReducer';
import { fetchFlowsAction } from 'redux/services/flowsServices';
import columns from 'routes/FlowsScreen/columns';
import { CreateFlowForm } from 'routes/FlowsScreen/createFlowForm';

const FlowsScreen: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AsyncDispatch>();
  const flows = useSelector(flowsSelector);
  const statusBar = useSelector(statusBarSelector);
  const totals = useSelector(flowsTotalsSelector);
  const processingState = useSelector(processingStateSelector);
  const [crud, mode] = useCRUD(flows, Flow.empty());
  // Properties
  const tableProps = useTable('flows', columns);
  const [filteredFlows, filter, setFilter] = useStatusFilter<Flow>(flows);

  React.useEffect((): void => {
    dispatch(fetchFlowsAction());
  }, [dispatch]);

  return (
    <Scaffold title="Flows Monitor">
      <StatusBar
        totals={totals}
        filter={filter}
        lastUpdate={statusBar.lastUpdate}
        connected={statusBar.connected}
        onFiltered={setFilter}
      />
      <Box marginBottom={1}>
        <Button onClick={crud.add}>
          <Add /> Add New
        </Button>
      </Box>
      <Table
        rowKey="id"
        rows={filteredFlows}
        loading={ProcessingState.isProcessing(processingState)}
        {...tableProps}
      />

      <CenteredModal open={mode === TableMode.create} onClose={crud.reset}>
        <CreateFlowForm />
      </CenteredModal>
    </Scaffold>
  );
};

export default FlowsScreen;
