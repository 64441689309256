import styles from 'ComplexModals/SessionEditor/central.module.scss';
import { Section } from 'ComplexModals/SessionEditor/components/Section';
import { fields } from 'ComplexModals/SessionEditor/forms/NetworkDetailsView/fields';
import {
  NetworkDetailsDataProvider,
  NetworkDetailsDataProviderContextProvider,
} from 'ComplexModals/SessionEditor/forms/NetworkDetailsView/providerStore';
import { Form, FormChangeResult } from 'components/Form';
import { useApi } from 'hooks/useApi';
import React, { useLayoutEffect, useState } from 'react';
import { SMNetworkDetails } from 'redux/definitions/central';
import { SMConfig } from 'redux/definitions/smConfig';

interface Props {
  readonly value: SMNetworkDetails;

  onChange(name: keyof SMConfig, value: SMNetworkDetails): void;
}

const NetworkDetailsView: React.FC<Props> = (props: Props): React.ReactElement => {
  const api = useApi();

  const [provider] = useState<NetworkDetailsDataProvider>(new NetworkDetailsDataProvider(api));
  const { value, onChange } = props;

  useLayoutEffect((): VoidFunction => {
    return provider.initialize();
  }, [provider]);

  const handleChange = React.useCallback(
    (name: keyof SMNetworkDetails, fieldValue: any, _: SMNetworkDetails): FormChangeResult => {
      onChange('network', { ...value, [name]: fieldValue });

      return {
        error: null,
        value: fieldValue,
      };
    },
    [onChange, value]
  );

  return (
    <NetworkDetailsDataProviderContextProvider value={provider}>
      <Section title="Network Details">
        <Form className={styles.form} fields={fields} value={props.value} onChange={handleChange} />
      </Section>
    </NetworkDetailsDataProviderContextProvider>
  );
};

export default NetworkDetailsView;
