import { FIXField } from 'components/FIXFIeld';
import styles from 'components/FIXMessage/fix-message.module.scss';
import { FIXTableField } from 'components/FIXTableField';
import { FIXTaggedField } from 'interfaces/FIXTaggedField';
import React from 'react';

interface Props {
  readonly message?: readonly FIXTaggedField[];
}

export const FIXMessageView = React.forwardRef(function FIXMessageView(
  props: Props,
  _: React.Ref<HTMLElement>
): React.ReactElement {
  const { message } = props;

  const arrays = React.useMemo(
    (): readonly FIXTaggedField[] =>
      message ? message.filter((field: FIXTaggedField): boolean => field.type === 'array') : [],
    [message]
  );

  const fields = React.useMemo(
    (): readonly FIXTaggedField[] =>
      message
        ? message.filter(
            (field: FIXTaggedField): boolean => field.type !== 'array' && field.type !== 'unknown'
          )
        : [],
    [message]
  );

  return (
    <div className={styles.container}>
      {fields.map(
        (field: FIXTaggedField): React.ReactElement => (
          <FIXField key={field.label} {...field} />
        )
      )}
      <div>
        {arrays.map(
          (field: FIXTaggedField): React.ReactElement => (
            <FIXTableField key={field.label} {...field} />
          )
        )}
      </div>
    </div>
  );
});
