import { Column } from 'components/Table/types/column';
import { RowData } from 'components/Table/types/rowData';

export const applyFilterToRows = <T>(
  rows: Record<string, RowData<T>>,
  columns?: ReadonlyArray<Column<T>>,
  filters?: Record<keyof T, string>
): ReadonlyArray<RowData<T>> => {
  if (columns === undefined) {
    return [];
  }
  if (filters === undefined) {
    return Object.values(rows);
  } else {
    const entries = Object.entries(rows);
    const columnKeys = Object.keys(filters);
    return entries
      .filter(([, rowData]: [string, RowData<T>]): boolean => {
        return columnKeys.every((columnName: string): boolean => {
          const columnKey = columnName as keyof T;
          const column = columns.find(({ key }: Column<any>): boolean => key === columnKey);
          if (!column) {
            return true;
          }

          const keyword = filters[columnKey];
          if (keyword === undefined || keyword.trim() === '') {
            return true;
          }
          const value = rowData.data[columnKey];

          return column.textuallyOverlap(value, keyword);
        });
      })
      .map(([, rowData]: [string, RowData<T>]): RowData<T> => rowData);
  }
};
