import { ColumnDefinition, ColumnType } from 'components/Table/types/column';
import { Flow } from 'interfaces/flow';
import strings from 'locales';

const columns: ReadonlyArray<ColumnDefinition<Flow>> = [
  {
    header: strings.FlowType,
    key: 'flowType',
    columnType: ColumnType.text,
  },
  {
    header: strings.FlowStatus,
    key: 'status',
    columnType: ColumnType.status,
  },
  {
    header: strings.Client,
    key: 'client',
    columnType: ColumnType.text,
  },
  {
    header: strings.ClientStatus,
    key: 'clientStatus',
    columnType: ColumnType.status,
  },
  {
    header: strings.ClientLastLogon,
    key: 'clientLastLogon',
    columnType: ColumnType.dateTime,
  },
  {
    header: strings.Desk,
    key: 'desk',
    columnType: ColumnType.text,
  },
  {
    header: strings.Destination,
    key: 'destination',
    columnType: ColumnType.text,
  },
  {
    header: strings.DestinationStatus,
    key: 'destinationStatus',
    columnType: ColumnType.status,
  },
  {
    header: strings.DestinationLastLogon,
    key: 'destinationLastLogon',
    columnType: ColumnType.dateTime,
  },
  {
    header: strings.Start,
    key: 'startDayTime',
    columnType: ColumnType.dayAndTime,
  },
  {
    header: strings.End,
    key: 'endDayTime',
    columnType: ColumnType.dayAndTime,
  },
];

export default columns;
