import { FormatFn, FormatterOptions } from 'components/Table/types/column';

export const formatCurrency: FormatFn<string> = (
  value: string,
  options?: FormatterOptions
): string => {
  const numeric = Number(value);
  if (isNaN(numeric)) {
    return '';
  }
  return numeric.toLocaleString(undefined, {
    minimumFractionDigits: options?.precision,
    maximumFractionDigits: options?.precision,
    style: 'currency',
    currency: options?.currency,
  });
};
