import { SolaceQueueMessageRenderer } from 'components/Table/renderers/SolaceQueueMessageRenderer';
import {
  Alignment,
  ColumnDefinition,
  ColumnType,
  createFromDefinition,
} from 'components/Table/types/column';
import { CheckBoxCellRenderer } from 'routes/SessionsScreen/components/SolaceQueue/CheckBoxCellRenderer';
import { SolaceQueueEntry } from 'types/solace';

export const columnDefinitions: ReadonlyArray<ColumnDefinition<SolaceQueueEntry>> = [
  {
    columnType: ColumnType.custom,
    header: '',
    weight: 0.05,
    key: 'messageId',
    component: CheckBoxCellRenderer,
    minWidth: 30,
    filterable: false,
  },
  {
    columnType: ColumnType.text,
    header: 'ID',
    weight: 2,
    key: 'id',
    alignment: Alignment.center,
    filterable: false,
  },
  {
    columnType: ColumnType.text,
    header: 'Topic',
    weight: 5,
    key: 'topic',
    alignment: Alignment.left,
    sortable: true,
    filterable: false,
  },
  {
    columnType: ColumnType.custom,
    header: 'Message',
    weight: 12,
    key: 'message',
    sortable: true,
    component: SolaceQueueMessageRenderer,
    alignment: Alignment.center,
    filterable: false,
  },
];

export const columns = columnDefinitions.map(createFromDefinition);
