import { Checkbox } from '@mui/material';
import { ApplicationUser } from 'api/interfaces/applicationUser';
import { PrismApplication } from 'api/interfaces/prismApplication';
import React from 'react';
import { UsersFormState } from 'redux/reducers/usersFormReducer';
import styles from 'routes/EntityManagement/UsersManagement/components/UsersForm/users-form.module.scss';
import { DropdownOption } from 'types/dropdownOption';

interface Props {
  readonly state: UsersFormState;
  readonly user: ApplicationUser;
  readonly itemKey: string;
}

// FIXME: this is not generic as it is now, it is specific for our current
//        ONLY use case
export const MappedGroupListItem: React.FC<Props> = (props: Props): React.ReactElement => {
  const { state, user, itemKey } = props;
  const { applications } = state;
  const { oktaGroups: userOktaGroups } = user;

  const matchingApplication = applications.find(
    (application: PrismApplication): boolean => application.app_id === itemKey
  );

  const oktaGroups = React.useMemo(
    (): readonly DropdownOption[] => (matchingApplication ? matchingApplication.okta : []),
    [matchingApplication]
  );

  const checkedCount: number = React.useMemo(
    (): number =>
      oktaGroups.reduce((totalCount: number, group: DropdownOption): number => {
        return (
          totalCount +
          (userOktaGroups.some((userGroup: DropdownOption): boolean => userGroup.id === group.id)
            ? 1
            : 0)
        );
      }, 0),
    [oktaGroups, userOktaGroups]
  );

  const allChecked = React.useMemo(
    (): boolean => oktaGroups.length > 0 && checkedCount === oktaGroups.length,
    [checkedCount, oktaGroups.length]
  );

  const someChecked = React.useMemo(
    (): boolean => oktaGroups.length > 0 && checkedCount > 0 && checkedCount < oktaGroups.length,
    [checkedCount, oktaGroups.length]
  );

  if (!matchingApplication) {
    return null;
  }

  return (
    <div className={styles.applicationItem}>
      <div>
        <Checkbox checked={allChecked} indeterminate={someChecked} />
      </div>
      <div>
        <div className={styles.applicationName}>{matchingApplication.app_name}</div>
        <div className={styles.applicationProduct}>Product: {matchingApplication.product}</div>
      </div>
    </div>
  );
};
