import styles from 'components/SideBar/application-side-bar.module.scss';
import { SubMenu } from 'components/SideBar/components/SubMenu';
import { ApplicationRouteDef } from 'interfaces/applicationRouteDef';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  readonly item: ApplicationRouteDef;
}

export const ToolbarItem: React.FC<Props> = (props: Props): React.ReactElement => {
  const { item } = props;

  const classResolver = React.useCallback(
    ({ isActive }: { isActive: boolean }): string => (isActive ? [styles.active].join(' ') : ''),
    []
  );

  return (
    <li className={styles.item} key={item.path}>
      {item.children === undefined ? (
        <NavLink className={classResolver} to={item.path}>
          {item.label}
        </NavLink>
      ) : (
        <SubMenu label={item.label} items={item.children} />
      )}
    </li>
  );
};
