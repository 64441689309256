import { Api } from 'api';
import { User } from 'api/interfaces/user';
import { reject } from 'lodash';
import moment from 'moment';
import { WhoIsLoggedInUser } from 'redux/reducers/whoIsLoggedInReducer';
import { Task } from 'task';

const parseDateOrNull = (date?: string): moment.Moment | undefined =>
  date ? moment(date) : undefined;

export const getLogins = (api: Api): Task<readonly WhoIsLoggedInUser[]> => {
  const task1 = api.getUsers();
  const task2 = api.getActiveLogin();

  return new Task(
    async (resolve: (result: readonly WhoIsLoggedInUser[]) => void): Promise<void> => {
      try {
        const users = await task1.run();
        const logins = await task2.run();

        resolve(
          users.map((user: User): WhoIsLoggedInUser => {
            const login = logins.find((each) => each.mem_email === user.email_address);

            return {
              application: login?.app_name ?? '',
              ipAddress: login?.mem_ip_address ?? '',
              lastActivity: parseDateOrNull(login?.create_date),
              loggedIn: login?.okta_loggedin ?? false,
              userID: user.email_address,
              userName: `${user.first_name} ${user.last_name}`,
            };
          })
        );
      } catch (error: any) {
        if (error.message === 'The user aborted a request.') {
          resolve([]);
          return;
        } else {
          console.dir(error);
          reject(error);
        }
      }
    },
    (): void => {
      task1.cancel();
      task2.cancel();
    }
  );
};
