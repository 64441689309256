interface KeyMappedObject {
  renamedFields: Record<string, string>;
}

export const hasRenamedFields = (obj: unknown): obj is KeyMappedObject => {
  // FIXME: check that it's actually of the right type
  return 'renamedFields' in (obj as KeyMappedObject);
};

export const rename =
  (name: string): PropertyDecorator =>
  (targetObject: any, propertyName: string | symbol): void => {
    if (hasRenamedFields(targetObject)) {
      targetObject.renamedFields[propertyName as string] = name;
    } else {
      Object.defineProperty(targetObject, 'renamedFields', {
        enumerable: false,
        value: { [propertyName]: name },
        writable: true,
      });
    }
  };
