import { FormControl, OutlinedInput } from '@mui/material';
import styles from 'components/NetworkAddressInput/network-address-input.module.scss';
import React from 'react';
import { SMNetworkAddress } from 'redux/definitions/central';

interface Props {
  readonly value: SMNetworkAddress;

  onChange(ipAddress: SMNetworkAddress): void;
}

export const NetworkAddressInput: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value = { ip: '', port: -1 }, onChange } = props;

  const onIPAddressChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      onChange({ ...value, ip: target.value });
    },
    [onChange, value]
  );

  const onPortChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value: rawValue } = event.target;
      const port = rawValue.trim();
      if (port === '') {
        onChange({ ...value, port: null });
      } else {
        const numericValue = Number(port);
        if (isNaN(numericValue)) return;
        if (numericValue > 0xffff) return;
        if (numericValue < 1) return;

        onChange({ ...value, port: numericValue });
      }
    },
    [onChange, value]
  );

  const portStringValue = React.useMemo((): string => {
    if (value.port === null) {
      return '';
    } else {
      return String(value.port);
    }
  }, [value.port]);

  const nonNullValue = React.useMemo((): string => value.ip ?? '', [value.ip]);

  return (
    <FormControl className={styles.inputBox} fullWidth={true}>
      <OutlinedInput
        classes={{ input: styles.ipInput }}
        value={nonNullValue}
        endAdornment={
          <input
            className={styles.portInput}
            value={portStringValue}
            placeholder="Port"
            onChange={onPortChange}
          />
        }
        placeholder="IP Address"
        fullWidth={true}
        onChange={onIPAddressChange}
      />
    </FormControl>
  );
};
