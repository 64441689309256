import { RawMessage } from 'api/interfaces/rawMessage';
import { RowData } from 'components/Table/types/rowData';
import { UiMessage } from 'interfaces/message';

export const fixMessagesToRowChildren = (
  messages: readonly UiMessage[]
): Record<string, RowData<UiMessage>> => {
  return messages
    .map((rawData: any, index: number): RowData<UiMessage> => {
      const message = UiMessage.fromRawMessage(RawMessage.fromFIX(rawData));

      return {
        data: message,
        id: `${message.clientOrderId}${index}`,
      };
    })
    .reduce(
      (
        rows: Record<string, RowData<UiMessage>>,
        item: RowData<UiMessage>
      ): Record<string, RowData<UiMessage>> => ({
        ...rows,
        [item.id]: item,
      }),
      {}
    );
};
