import strings from 'locales';

export const weekDays: Record<number, string> = {
  1: strings.Monday,
  2: strings.Tuesday,
  3: strings.Wednesday,
  4: strings.Thursday,
  5: strings.Friday,
  6: strings.Saturday,
  7: strings.Sunday,
};
