import { Api } from 'api';
import { ApplicationUser } from 'api/interfaces/applicationUser';
import { CRUDResponse } from 'api/interfaces/CRUDResponse';
import { Dispatch } from 'redux';
import { Action } from 'redux/action';
import { createAction } from 'redux/actions/creator';
import { ApplicationState } from 'redux/applicationState';
import { UsersActions } from 'redux/reducers/usersReducer';

export const deleteUser = (user: ApplicationUser) => {
  return (
    dispatch: Dispatch<Action<UsersActions>>,
    getState: () => ApplicationState,
    api: Api
  ): VoidFunction => {
    const task = api.deleteUser(user);

    dispatch(createAction(UsersActions.deleteUserStarted));
    task
      .run()
      .then((result: CRUDResponse): void => {
        if (result.success) {
          dispatch(createAction(UsersActions.deleteUserCompleted, user));
        } else {
          dispatch(createAction(UsersActions.deleteUserFailed, result.message));
        }
      })
      .catch((error: Error): void => {
        dispatch(createAction(UsersActions.deleteUserFailed, error.message));
      });

    return (): void => {
      return;
    };
  };
};
