import React, { CSSProperties } from 'react';

const computeAutomaticStyles = (sourceElement: HTMLElement): CSSProperties => {
  return {
    position: 'absolute',
    left: sourceElement.offsetLeft + 'px',
    width: sourceElement.offsetWidth + 'px',
    top: sourceElement.offsetTop + 'px',
    height: sourceElement.offsetHeight + 'px',
    opacity: 1,
  };
};

export const useDragAndDrop = (
  onDragStart: VoidFunction,
  onDragEnd: VoidFunction,
  onColumnMoved?: (from: number, to: number) => void
): React.Ref<HTMLTableHeaderCellElement> => {
  const [originalCell, setOriginalCell] = React.useState<HTMLTableHeaderCellElement | null>(null);

  const movingCell = React.useMemo(
    (): HTMLElement | undefined => originalCell?.cloneNode(true) as HTMLElement | undefined,
    [originalCell]
  );

  React.useEffect((): void => {
    if (movingCell === undefined || originalCell === null) return;
    const styles = computeAutomaticStyles(originalCell);
    // Locate it at the right spot
    Object.assign(movingCell.style, styles);
  }, [movingCell, originalCell]);

  React.useEffect((): VoidFunction | void => {
    if (movingCell === undefined || originalCell === null) {
      return;
    }

    // Install the move handler
    const onMove = (event: MouseEvent): void => {
      const { style } = movingCell;
      style.left = movingCell.offsetLeft + event.movementX + 'px';
    };

    const onStopDragging = (): void => {
      document.removeEventListener('mousemove', onMove);
      document.removeEventListener('mouseup', onStopDragging);
      document.removeEventListener('mouseleave', onStopDragging);
      const container = originalCell.parentElement;
      if (container === null) {
        // This is of course "impossible"
        return;
      }

      onDragEnd();

      const cells = Array.from(container.children);
      const fromIndex = cells.findIndex((c) => c === originalCell);
      const toIndex = cells.findIndex((c) => {
        const element = c as HTMLElement;
        const position = movingCell.offsetLeft;
        const left = element.offsetLeft;
        const right = left + element.offsetWidth;
        return position > left && position < right;
      });

      if (onColumnMoved) {
        onColumnMoved(fromIndex, toIndex);
      }

      container.removeChild(movingCell);
    };

    const onStartDragging = (event: MouseEvent): void => {
      event.preventDefault();
      event.stopPropagation();
      const container = originalCell.parentElement;
      if (container === null) {
        // This is of course "impossible"
        return;
      }

      const { style } = movingCell;
      style.left = originalCell.offsetLeft + 'px';

      onDragStart();

      container.appendChild(movingCell);
      // Install event listeners
      document.addEventListener('mousemove', onMove);
      document.addEventListener('mouseup', onStopDragging);
      document.addEventListener('mouseleave', onStopDragging);
    };

    originalCell.addEventListener('mousedown', onStartDragging);
    return (): void => {
      originalCell.removeEventListener('mousedown', onStartDragging);
    };
  }, [movingCell, onColumnMoved, onDragEnd, onDragStart, originalCell]);

  return setOriginalCell;
};
