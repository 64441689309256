import { FormLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { RequiredIndicator } from 'components/Form/components/RequiredIndicator';
import styles from 'components/Form/form.module.scss';
import React from 'react';
import { DropdownOption } from 'types/dropdownOption';

interface Props<T> {
  readonly options: readonly DropdownOption[];
  readonly label: string;
  readonly name: keyof T;
  readonly value: any;
  readonly disabled: boolean;
  readonly required: boolean;

  readonly error?: React.ReactElement;

  onChange(name: keyof T, value: any, required: boolean): void;
}

export class Dropdown<T> extends React.Component<Props<T>> {
  private onChange = (event: SelectChangeEvent, _: React.ReactNode): void => {
    const { onChange, name, required } = this.props;
    const { value } = event.target;

    onChange(name, value, required);
  };

  public render(): React.ReactElement {
    const { options, name, label, value, disabled, error, required } = this.props;
    const id = name as string;

    return (
      <div className={styles.field}>
        <FormLabel htmlFor={id}>
          <span>{label}</span> <RequiredIndicator required={required} />
        </FormLabel>
        <div>
          <Select
            id={id}
            value={value}
            disabled={disabled}
            renderValue={(value: string | number): React.ReactNode => {
              if (value === '') {
                return <span className={styles.pickOne}>Pick one</span>;
              } else if (typeof value === 'string' || typeof value === 'number') {
                // Sadly, we need to do this kind of ugly stuff!
                const currentItem = options?.find(
                  (option: DropdownOption): boolean => String(option.id) === String(value)
                );

                return currentItem?.name ?? '';
              }

              return '?';
            }}
            fullWidth={true}
            onChange={this.onChange}
          >
            <MenuItem value={''} disabled>
              Pick one
            </MenuItem>
            {options?.map((value: DropdownOption) => (
              <MenuItem key={value.id} value={value.id}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
          {error}
        </div>
      </div>
    );
  }
}
