import { ApplicationUser } from 'api/interfaces/applicationUser';
import { ModalType } from 'components/TaskRunningStatus/reducer';
import { Action } from 'redux/action';

export enum UsersActions {
  getUsersStarted = '/actions/users/get-users-started',
  getUsersCompleted = '/actions/users/get-users-completed',
  getUsersFailed = '/actions/users/get-users-failed',

  deleteUserStarted = '/actions/users/delete-user-started',
  deleteUserCompleted = '/actions/users/delete-user-completed',
  deleteUserFailed = '/actions/users/delete-user-failed',

  updateUserStarted = '/actions/users/update-user-started',
  updateUserCompleted = '/actions/users/update-user-completed',
  updateUserFailed = '/actions/users/update-user-failed',

  createUserStarted = '/actions/users/create-user-started',
  createUserCompleted = '/actions/users/create-user-completed',
  createUserFailed = '/actions/users/create-user-failed',

  closeTaskModal = '/actions/users/close-task-modal',
}

export interface UsersState {
  readonly users: readonly ApplicationUser[];
  readonly modalType: ModalType;
  readonly taskMessage: string | null;
  readonly loading: boolean;
}

const initialState: UsersState = {
  users: [],
  modalType: ModalType.none,
  taskMessage: null,
  loading: false,
};

const reducer = (state: UsersState = initialState, action: Action<UsersActions>): UsersState => {
  const { type, data } = action;
  const { users } = state;
  switch (type) {
    case UsersActions.getUsersStarted:
      return { ...state, users: [], loading: true };
    case UsersActions.getUsersFailed:
      // FIXME: should show some error
      return { ...state, loading: false };
    case UsersActions.getUsersCompleted:
      return { ...state, users: data, loading: false };
    case UsersActions.updateUserFailed:
    case UsersActions.deleteUserFailed:
    case UsersActions.createUserFailed:
      return { ...state, taskMessage: data, modalType: ModalType.error };
    case UsersActions.updateUserStarted:
      return {
        ...state,
        taskMessage: 'Updating user, please wait',
        modalType: ModalType.progress,
      };
    case UsersActions.updateUserCompleted:
      return {
        ...state,
        taskMessage: 'User updated successfully',
        modalType: ModalType.success,
        users: users.map(
          (user: ApplicationUser): ApplicationUser => (user.id === data.id ? data : user)
        ),
      };
    case UsersActions.createUserStarted:
      return {
        ...state,
        taskMessage: 'Creating new user, please wait',
        modalType: ModalType.progress,
      };
    case UsersActions.createUserCompleted:
      return {
        ...state,
        taskMessage: 'User created successfully',
        modalType: ModalType.success,
        users: [data, ...users],
      };
    case UsersActions.closeTaskModal:
      return { ...state, taskMessage: null, modalType: ModalType.none };
    case UsersActions.deleteUserStarted:
      return {
        ...state,
        taskMessage: 'Deleting user, please wait',
        modalType: ModalType.progress,
      };
    case UsersActions.deleteUserCompleted:
      return {
        ...state,
        taskMessage: 'User deleted successfully',
        modalType: ModalType.success,
        users: users.filter((user: ApplicationUser): boolean => user.id !== data.id),
      };
    default:
      return state;
  }
};

export default reducer;
