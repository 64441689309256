import styles from 'components/FIXMessage/fix-message.module.scss';
import { toClassName } from 'helpers/toClassName';
import { FIXTaggedField, isTextField } from 'interfaces/FIXTaggedField';
import React from 'react';

type Props = FIXTaggedField;

interface ValueProps {
  readonly value: string;
}

const FIXFieldValue: React.FC<ValueProps> = (props: ValueProps): React.ReactElement => {
  const { value } = props;

  if (typeof value === 'string') {
    return <span>{value}</span>;
  } else {
    return <span className={styles.na}>N/A</span>;
  }
};

export const FIXField: React.FC<Props> = (props: Props): React.ReactElement | null => {
  if (props.value === null) {
    return (
      <div className={styles.field} key={props.label}>
        <div className={styles.label}>{props.label}</div>
        <div className={toClassName(styles.value, styles[props.type])}>
          <span className={styles.na}>N/A</span>
        </div>
      </div>
    );
  } else if (!isTextField(props)) {
    return (
      <div className={styles.field} key={props.label}>
        <div className={styles.label}>{props.label}</div>
        <div className={toClassName(styles.value, styles[props.type])}>
          <span>?</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.field} key={props.label}>
        <div className={styles.label}>{props.label}</div>
        <div className={toClassName(styles.value, styles[props.type])}>
          <FIXFieldValue value={props.value} />
        </div>
      </div>
    );
  }
};
