import { WithStatus } from 'interfaces/withStatus';
import { SumReducer } from 'types/sumReducer';

export enum Status {
  None = 0,
  On = 1,
  Down = 2,
  Overtime = 3,
  Off = 4,
  Unknown = 5,
}

export const createStatusReducer =
  <T extends WithStatus>(status: Status): SumReducer<T> =>
  (count: number, item: T): number => {
    if (status === Status.Unknown) {
      switch (item.status) {
        case Status.On:
        case Status.Off:
        case Status.Overtime:
        case Status.Down:
          return count;
        default:
          return count + 1;
      }
    } else {
      return count + (item.status === status ? 1 : 0);
    }
  };
