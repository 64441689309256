import { RawMessage } from 'api/interfaces/rawMessage';
import { Moment } from 'moment';

export interface SummaryReportQuery {
  readonly from_time: Moment;
  readonly to_time: Moment;
  readonly assetType: '';
}

export interface SummaryReportResponse {
  readonly Records: RawMessage[];
  readonly Total: number;
}

export const fixMap: Record<keyof Execution, string> = {
  id: '',
  account: '1',
  clientOrderId: '11',
  handlingInstructions: '21',
  orderQuantity: '38',
  orderType: '40',
  price: '44',
  senderCompId: '49',
  sendingTime: '52',
  side: '54',
  symbol: '55',
  targetCompId: '56',
  timeInForce: '59',
  transactionTime: '60',
  onBehalfOfCompId: '115',
  venue: '128',
  securityType: '167',

  raw: '',
};

export interface Execution {
  readonly id: string;
  readonly account: string;
  readonly clientOrderId: string;
  readonly handlingInstructions: string;
  readonly orderQuantity: number;
  readonly orderType: string;
  readonly price: number;
  readonly senderCompId: string;
  readonly targetCompId: string;
  readonly sendingTime: string;
  readonly side: string;
  readonly symbol: string;
  readonly timeInForce: string;
  readonly transactionTime: Date;
  readonly onBehalfOfCompId: string;
  readonly venue: string;
  readonly securityType: string;

  readonly raw: RawMessage;
}

export class Execution {
  public static fromRawMessage(rawMessage: RawMessage): Execution {
    const execution = Object.fromEntries(
      Object.entries(fixMap).map(([key, tagNumber]: [string, string]): [keyof Execution, any] => {
        if (key === 'transactionTime') {
          return [key as keyof Execution, new Date(rawMessage[tagNumber])];
        } else {
          return [key as keyof Execution, rawMessage[tagNumber]];
        }
      })
    ) as Execution;

    return {
      ...execution,
      id: String(rawMessage.idlingomsg),
      raw: rawMessage,
    };
  }
}
