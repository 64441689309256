import { ApplicationUser } from 'api/interfaces/applicationUser';
import { Company } from 'api/interfaces/company';
import { BackendFirm } from 'api/interfaces/firm';
import { PrismApplication } from 'api/interfaces/prismApplication';
import { Action } from 'redux/action';
import { DropdownOption } from 'types/dropdownOption';

export enum UsersFormActions {
  initializeCompleted = '/actions/users-form/initialize-completed',
  reloadUserStarted = '/actions/users-form/set-user-started',
  reloadUserCompleted = '/actions/users-form/set-user-completed',
  resetWorkingState = '/actions/users-form/reset-working-state',
}

export interface UsersFormState {
  readonly firms: readonly DropdownOption[];
  readonly companies: readonly DropdownOption[];
  readonly applications: readonly PrismApplication[];
  readonly user: ApplicationUser;
  readonly working: boolean;
}

const initialState: UsersFormState = {
  firms: [],
  companies: [],
  applications: [],
  user: ApplicationUser.defaultUser(),
  working: false,
};

const reducer = (
  state: UsersFormState = initialState,
  action: Action<UsersFormActions>
): UsersFormState => {
  const { type, data } = action;
  switch (type) {
    case UsersFormActions.initializeCompleted:
      return initializeCompletedReducer(state, action);
    case UsersFormActions.reloadUserStarted:
      return { ...state, user: ApplicationUser.defaultUser(), working: true };
    case UsersFormActions.reloadUserCompleted:
      return { ...state, user: data.user };
    case UsersFormActions.resetWorkingState:
      return { ...state, working: false };
    default:
      return state;
  }
};

const initializeCompletedReducer = (
  state: UsersFormState,
  action: Action<UsersFormActions>
): UsersFormState => {
  const { companies, applications, firms } = action.data;

  return {
    ...state,
    companies: companies.map(Company.toDropdownOption),
    applications: applications,
    firms: firms.map(BackendFirm.toDropdownOption),
  };
};

export default reducer;
