import { Api, ApiContext } from 'api';
import React from 'react';

export const useApi = (): Api => {
  const api = React.useContext<Api | undefined>(ApiContext);
  if (api === undefined) {
    throw new Error('you must wrap this component in a `Api.Provider`');
  }

  return api;
};
