import rawJson from 'data/fixDictionary.json';

export interface FIXEnum {
  readonly value: string;
  readonly description: string;
}

export enum FIXType {
  Amt = 'Amt',
  Currency = 'Currency',
  Price = 'Price',
  PriceOffset = 'PriceOffset',
  Quantity = 'Quantity',
  Timestamp = 'Timestamp',
  LocalDate = 'LocalDate',
  Data = 'Data',
  MultipleValueString = 'MultipleValueString',
  UTCTimeOnly = 'UTCTimeOnly',
  UTCDate = 'UTCDate',
  Exchange = 'Exchange',
  DayOfMonth = 'DayOfMonth',
  MonthYear = 'MonthYear',
  string = 'string',
  int = 'int',
  boolean = 'boolean',
  number = 'number',
}

export interface FIXTag {
  readonly name: string;
  readonly type: FIXType;
  readonly enum?: readonly FIXEnum[];
}

const typedJson = rawJson as { [tag: string]: FIXTag };

export default typedJson;
