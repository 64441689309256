import {
  CheckCircleOutline,
  CopyAll,
  DeleteOutline,
  Download,
  DriveFileRenameOutline,
  MailOutlined,
  ManageHistory,
  Refresh,
  Warning,
} from '@mui/icons-material';
import { Box, ButtonBase, Menu } from '@mui/material';
import { CenteredModal } from 'components/CenteredModal';
import { ErrorModal } from 'components/ErrorModal';
import { SuccessModal } from 'components/SuccessModal';
import { CRUDActions, CRUDActionsContext } from 'contexts/CRUDActionsContext';
import { ProcessingState } from 'enums/processingState';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  flushSubscribedSymbolsCompleted,
  flushSubscribedSymbolsProcessingStateSelector,
  sessionActionStopped,
  sessionProcessingStateSelector,
} from 'redux/reducers/sessionsReducer';
import {
  flushSubscribedSymbolsAction,
  importSMConfigurationAction,
  reloadSMRuleAction,
} from 'redux/services/sessionsServices';
import styles from 'routes/SessionsScreen/components/Actions/actions.module.scss';
import { ActionMenuItem } from 'routes/SessionsScreen/components/Actions/MenuItem';
import { SolaceQueueBrowser } from 'routes/SessionsScreen/components/SolaceQueue';

interface Props {
  readonly value: string;
}

export const Actions: React.FC<Props> = (props: Props): React.ReactElement => {
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const [showQueue, setShowQueue] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const processingStateMap = useSelector(sessionProcessingStateSelector);
  const flushSubscribedSymbolsProcessingState = useSelector(
    flushSubscribedSymbolsProcessingStateSelector
  );
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const { value } = props;
  const crud = React.useContext<CRUDActions>(CRUDActionsContext);

  const processingState = React.useMemo(
    (): ProcessingState => processingStateMap[value] ?? ProcessingState.idle(),
    [processingStateMap, value]
  );

  const openMenu = React.useCallback((): void => {
    setMenuOpen(true);
  }, []);

  const closeMenu = React.useCallback((): void => {
    setMenuOpen(false);
  }, []);

  const resetActionProcessingState = React.useCallback((): void => {
    dispatch(sessionActionStopped(value));
  }, [dispatch, value]);

  const handleEdit = React.useCallback(async (): Promise<void> => {
    closeMenu();
    crud?.edit(value);
  }, [closeMenu, crud, value]);

  const handleRemove = React.useCallback(async (): Promise<void> => {
    closeMenu();
    crud?.remove(value);
  }, [closeMenu, crud, value]);

  const handleReloadSO = React.useCallback(async (): Promise<void> => {
    closeMenu();
    dispatch(reloadSMRuleAction(value));
  }, [closeMenu, dispatch, value]);

  const handleClone = React.useCallback(async (): Promise<void> => {
    closeMenu();
    crud?.clone(value);
  }, [closeMenu, crud, value]);

  const handleDownload = React.useCallback(async (): Promise<void> => {
    closeMenu();
    dispatch(importSMConfigurationAction(value));
  }, [closeMenu, dispatch, value]);

  const handleFlushSubscribedSymbols = React.useCallback(async (): Promise<void> => {
    // closeMenu();
    dispatch(flushSubscribedSymbolsAction(value));
  }, [dispatch, value]);

  const handleShowQueue = React.useCallback((): void => {
    closeMenu();
    setShowQueue(true);
  }, [closeMenu]);

  const handleCloseQueue = React.useCallback((): void => {
    setShowQueue(false);
  }, []);

  React.useEffect((): void => {
    if (ProcessingState.isSuccess(flushSubscribedSymbolsProcessingState)) {
      setTimeout((): void => {
        dispatch(flushSubscribedSymbolsCompleted());
        setTimeout((): void => {
          closeMenu();
        }, 0);
      }, 4000);
    } else if (ProcessingState.isError(flushSubscribedSymbolsProcessingState)) {
      setTimeout((): void => {
        dispatch(flushSubscribedSymbolsCompleted());
        setTimeout((): void => {
          closeMenu();
        }, 0);
      }, 4000);
    }
  }, [closeMenu, dispatch, flushSubscribedSymbolsProcessingState]);

  const flushIcon = React.useMemo((): {
    readonly color?: 'error' | 'success';
    readonly icon: React.FunctionComponent<any> | React.ReactElement;
  } => {
    if (
      ProcessingState.isProcessing(flushSubscribedSymbolsProcessingState) &&
      flushSubscribedSymbolsProcessingState.data === value
    ) {
      return {
        icon: (
          <Box className={styles.refresh}>
            <Box className={styles.spinner} />
          </Box>
        ),
      };
    } else if (ProcessingState.isError(flushSubscribedSymbolsProcessingState)) {
      return {
        color: 'error',
        icon: Warning,
      };
    } else if (ProcessingState.isSuccess(flushSubscribedSymbolsProcessingState)) {
      return {
        color: 'success',
        icon: CheckCircleOutline,
      };
    }
    return { icon: ManageHistory };
  }, [flushSubscribedSymbolsProcessingState, value]);

  return (
    <div className={styles.container}>
      <ButtonBase ref={buttonRef} onClick={openMenu}>
        {ProcessingState.isProcessing(processingState) ? (
          <Box className={styles.refresh}>
            <Box className={styles.spinner} />
          </Box>
        ) : (
          <i className="fa fa-ellipsis-h" />
        )}
      </ButtonBase>

      <Menu open={menuOpen} anchorEl={buttonRef.current} onClose={closeMenu}>
        <ActionMenuItem label="Clone" icon={CopyAll} onClick={handleClone} />
        <ActionMenuItem label="Edit" icon={DriveFileRenameOutline} onClick={handleEdit} />
        <ActionMenuItem label="Delete" icon={DeleteOutline} onClick={handleRemove} />
        <ActionMenuItem label="Import" icon={Download} onClick={handleDownload} />
        <ActionMenuItem label="Reload-SO" icon={Refresh} onClick={handleReloadSO} />
        <ActionMenuItem label="Queue" icon={MailOutlined} onClick={handleShowQueue} />
        <ActionMenuItem
          label="Flush"
          icon={flushIcon.icon}
          color={flushIcon.color}
          onClick={handleFlushSubscribedSymbols}
        />
      </Menu>

      <CenteredModal open={ProcessingState.isError(processingState)}>
        <ErrorModal
          title="Failed to sync"
          message="An error occurred when trying to sync the configuration"
          error={
            ProcessingState.isError(processingState) ? processingState.message : 'Unknown error'
          }
          onClose={resetActionProcessingState}
        />
      </CenteredModal>

      <CenteredModal open={ProcessingState.isSuccess(processingState)}>
        <SuccessModal
          title={
            ProcessingState.isSuccess(processingState) ? processingState.message : 'Operation OK'
          }
          message={
            ProcessingState.isSuccess(processingState)
              ? processingState.message ?? 'No errors occurred'
              : 'No errors occurred'
          }
          onClose={resetActionProcessingState}
        />
      </CenteredModal>

      <CenteredModal
        open={showQueue}
        onClose={handleCloseQueue}
        title={`Queue for session: ${value}`}
        fullWidth={true}
      >
        <Box width="100%" height="90vh" padding={1}>
          <SolaceQueueBrowser sessionId={value} />
        </Box>
      </CenteredModal>
    </div>
  );
};
