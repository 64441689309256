import styles from 'ComplexModals/SessionEditor/central.module.scss';
import { Section } from 'ComplexModals/SessionEditor/components/Section';
import { fields } from 'ComplexModals/SessionEditor/forms/LogControlView/fields';
import { Form, FormChangeResult } from 'components/Form';
import React from 'react';
import { SMLogControl } from 'redux/definitions/central';
import { SMConfig } from 'redux/definitions/smConfig';

interface Props {
  readonly value: SMLogControl;

  onChange(name: keyof SMConfig, value: SMLogControl): void;
}

const LogControlView: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value, onChange } = props;

  const handleChange = React.useCallback(
    (
      name: keyof SMLogControl,
      fieldValue: SMLogControl[keyof SMLogControl],
      _: SMLogControl
    ): FormChangeResult => {
      onChange('logControl', { ...value, [name]: fieldValue });

      return {
        error: null,
        value: fieldValue,
      };
    },
    [onChange, value]
  );

  return (
    <Section title="Log Control">
      <Form className={styles.form} value={value} fields={fields} onChange={handleChange} />
    </Section>
  );
};

export default LogControlView;
