export const verticallyOverflows = (element: HTMLElement): boolean => {
  return element.scrollHeight > element.offsetHeight;
};

export const isVerticallyScrollable = (element: HTMLElement): boolean => {
  const style = getComputedStyle(element);
  return style.overflowY === 'scroll' || style.overflowY === 'auto';
};

String.prototype.isSimilarTo = function (keyword: string): boolean {
  const normalized = this.toLowerCase();
  const normalizedKeyword = keyword.toLowerCase();
  return normalized.includes(normalizedKeyword) || normalizedKeyword.includes(normalized);
};

const nothing = {};

export default nothing;
