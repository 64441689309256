export const asBool = (value: string | number | undefined): boolean => {
  if (!value) {
    return false;
  }

  if (typeof value === 'number') {
    return value === 1;
  } else if (typeof value === 'boolean') {
    return value;
  }

  const lowerCaseValue = value.toLowerCase();
  if (lowerCaseValue === 'null') {
    return false;
  }

  return lowerCaseValue.startsWith('y') || lowerCaseValue === 'true';
};

export const asNumber = (value: string | undefined): number | null => {
  if (!value) {
    return null;
  }
  const lowerCaseValue = value.toLowerCase();
  if (lowerCaseValue === 'null') {
    return null;
  }

  const numeric = Number(lowerCaseValue);
  if (isNaN(numeric)) {
    return null;
  }

  return numeric;
};

export const asString = (value: string | undefined): string | null => {
  if (!value) {
    return null;
  }

  const lowerCaseValue = value.toLowerCase();
  if (lowerCaseValue === 'null') {
    return null;
  }

  return value;
};
