import { UiMessage } from 'interfaces/message';

export interface RawMessage {
  clientOrderID: string;
  msgKey: string;
  executedPrice: number;
  executedQuantity: number;
  msgType: string;
  orderQuantity: number;
  ordStatus: string;
  orderPrice: number;
  orderNotional: number;
  executedNotional: number;
  side: string;
  symbol: string;
  transactionTime: string;
  originationID: any;
  venueID: string;
  execType: string;
  idlingomsg: number;

  [key: string]: any;
}

export const rawMessageToFixMap: Record<keyof UiMessage, string> = {
  clientOrderId: '11',
  executedPrice: '31',
  executedQuantity: '32',
  orderQuantity: '38',
  orderPrice: '44',
  messageType: '35',
  side: '54',
  symbol: '55',
  transactionTime: '60',
  originationId: '115',
  venueId: '128',
  execType: '150',
  id: 'idlingomsg',

  orderNotional: '-1',
  executedNotional: '-2',
};

export class RawMessage {
  public static fromFIX(fixMessage: Record<string, any>): RawMessage {
    const message = new RawMessage();
    const {
      [rawMessageToFixMap.clientOrderId]: clientOrderId,
      '1': msgKey,
      [rawMessageToFixMap.executedPrice]: executedPrice,
      [rawMessageToFixMap.executedQuantity]: executedQuantity,
      [rawMessageToFixMap.messageType]: msgType,
      [rawMessageToFixMap.orderQuantity]: orderQuantity,
      '39': orderStatus,
      [rawMessageToFixMap.orderPrice]: orderPrice,
      [rawMessageToFixMap.side]: side,
      [rawMessageToFixMap.symbol]: symbol,
      [rawMessageToFixMap.transactionTime]: transactionTime,
      [rawMessageToFixMap.originationId]: originationId,
      [rawMessageToFixMap.venueId]: venueId,
      '52': creationTime,
      [rawMessageToFixMap.execType]: execType,
      idlingomsg,
      ...fixTags
    } = fixMessage;

    message.clientOrderID = clientOrderId;
    message.msgKey = msgKey;
    message.executedPrice = executedPrice;
    message.executedQuantity = executedQuantity;
    message.msgType = msgType;
    message.orderQuantity = orderQuantity;
    message.ordStatus = orderStatus;
    message.orderPrice = orderPrice;
    message.orderNotional = orderPrice * orderQuantity;
    message.executedNotional = executedPrice * executedQuantity;
    message.side = side;
    message.symbol = symbol;
    message.transactionTime = transactionTime || creationTime;
    message.originationID = originationId;
    message.venueID = venueId;
    message.execType = execType;
    message.idlingomsg = idlingomsg;

    Object.assign(message, fixTags);

    return message;
  }
}
