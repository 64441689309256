import { ApplicationUser } from 'api/interfaces/applicationUser';
import { CRUDButtons } from 'components/CRUDButtons';
import { ColumnDefinition, ColumnType } from 'components/Table/types/column';
import { OktaGroupsCell } from 'routes/EntityManagement/UsersManagement/components/OktaGroupsCell';

const columns: ReadonlyArray<ColumnDefinition<ApplicationUser>> = [
  {
    minWidth: 48,
    columnType: ColumnType.custom,
    key: 'id',
    filterable: false,
    header: '',
    component: CRUDButtons,
  },
  {
    columnType: ColumnType.text,
    header: 'Email',
    key: 'email',
    weight: 8,
  },
  {
    columnType: ColumnType.text,
    header: 'First Name',
    key: 'firstName',
    weight: 8,
  },
  {
    columnType: ColumnType.text,
    header: 'Last Name',
    key: 'lastName',
    weight: 8,
  },
  {
    columnType: ColumnType.custom,
    header: 'Groups',
    key: 'oktaGroups',
    weight: 9,
    component: OktaGroupsCell,
  },
];

export default columns;
