import styles from 'components/LogLevel/log-level.module.scss';
import React from 'react';

type LogLevelValue = 'I' | 'E' | 'W' | 'A';

interface Props {
  readonly value: LogLevelValue;
  readonly disabled?: boolean;

  onClick?(): void;
}

export const LogLevel: React.FC<Props> = (props: Props): React.ReactElement => {
  const { onClick } = props;
  const { value, disabled = false } = props;

  const disabledStyle = React.useMemo(
    (): string | undefined => (disabled ? styles.disabled : undefined),
    [disabled]
  );

  return (
    <div className={[styles.container, classes[value], disabledStyle].join(' ')} onClick={onClick}>
      <i className={icons[value]} /> <span>{text[value]}</span>
    </div>
  );
};

const classes: { [key in LogLevelValue]: string } = {
  W: styles.warning,
  I: styles.info,
  E: styles.error,
  A: styles.unknown,
};

const icons: { [key in LogLevelValue]: string } = {
  W: 'fa fa-exclamation-triangle',
  E: 'fa fa-exclamation-circle',
  I: 'fa fa-exclamation-circle',
  A: 'fa fa-question-circle',
};

const text: { [key in LogLevelValue]: string } = {
  I: 'Info',
  W: 'Warning',
  E: 'Error',
  A: 'Unknown',
};
