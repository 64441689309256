import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { PrismApplication } from 'api/interfaces/prismApplication';
import React from 'react';
import { useSelector } from 'react-redux';
import { applicationsListSelector } from 'redux/reducers/referenceDataReducer';

interface Props {
  readonly value: string;

  onChange(value: string): void;
}

export const ApplicationsDropdown: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value, onChange } = props;
  const applications = useSelector(applicationsListSelector);

  const handleApplicationChange = React.useCallback(
    (event: SelectChangeEvent<string>): void => {
      const { value } = event.target;
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select value={value} onChange={handleApplicationChange} fullWidth={true}>
      <MenuItem disabled>Select One</MenuItem>

      {applications.map(
        (application: PrismApplication): React.ReactElement => (
          <MenuItem key={application.app_id} value={application.app_id}>
            {application.app_name}
          </MenuItem>
        )
      )}
    </Select>
  );
};
