import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProcessingState } from 'enums/processingState';
import { ApplicationState } from 'redux/applicationState';
import { RecordsPage } from 'types/page';
import { SolaceQueueEntry } from 'types/solace';

export interface SolaceQueueState {
  processingState: ProcessingState;
  solaceQueue: RecordsPage<SolaceQueueEntry>;
  selectedEntries: Record<string, boolean>;
  deletedEntries: Record<string, boolean>;
  deleteProcessingState: ProcessingState;
}

const initialState: SolaceQueueState = {
  processingState: ProcessingState.idle(),
  solaceQueue: RecordsPage.empty(),
  selectedEntries: {},
  deletedEntries: {},
  deleteProcessingState: ProcessingState.idle(),
};

const slice = createSlice({
  name: 'solaceQueue',
  initialState: initialState,
  reducers: {
    fetchSolaceQueueStarted: (state: SolaceQueueState): void => {
      state.selectedEntries = {};
      state.processingState = ProcessingState.processing();
    },
    fetchSolaceQueueCompleted: (
      state: SolaceQueueState,
      action: PayloadAction<RecordsPage<SolaceQueueEntry>>
    ): void => {
      state.deletedEntries = {};
      state.processingState = ProcessingState.idle();
      state.solaceQueue = action.payload;
    },
    fetchSolaceQueueFailed: (state: SolaceQueueState, action: PayloadAction<string>): void => {
      state.processingState = ProcessingState.error(action.payload);
    },
    entriesSelected: (state: SolaceQueueState, action: PayloadAction<readonly string[]>): void => {
      const { payload: ids } = action;

      for (const id of ids) {
        state.selectedEntries[id] = true;
      }
    },
    entriesUnselected: (
      state: SolaceQueueState,
      action: PayloadAction<readonly string[]>
    ): void => {
      const { payload: ids } = action;

      for (const id of ids) {
        state.selectedEntries[id] = false;
      }
    },
    reset: (state: SolaceQueueState): void => {
      state.deletedEntries = {};
      state.selectedEntries = {};
      state.solaceQueue = RecordsPage.empty();
    },
    deleteSolaceMessagesStarted: (state: SolaceQueueState): void => {
      state.deleteProcessingState = ProcessingState.processing();
    },
    deleteSolaceMessagesCompleted: (state: SolaceQueueState): void => {
      state.deleteProcessingState = ProcessingState.idle();
    },
    deleteSolaceMessagesSucceeded: (
      state: SolaceQueueState,
      action: PayloadAction<readonly string[]>
    ): void => {
      const { payload: ids } = action;

      state.deleteProcessingState = ProcessingState.success();
      for (const id of ids) {
        state.deletedEntries[id] = true;
      }
    },
    deleteSolaceMessagesFailed: (state: SolaceQueueState, action: PayloadAction<any>): void => {
      state.deleteProcessingState = ProcessingState.error(action.payload);
    },
  },
});

export const solaceQueueSelector = (state: ApplicationState): RecordsPage<SolaceQueueEntry> =>
  state.solaceQueue.solaceQueue;

export const solaceQueueProcessingStateSelector = (state: ApplicationState): ProcessingState =>
  state.solaceQueue.processingState;

export const solaceQueueSelectionSelector = (state: ApplicationState): Record<string, boolean> =>
  state.solaceQueue.selectedEntries;

export const solaceQueueDeletedRowsSelector = (state: ApplicationState): Record<string, boolean> =>
  state.solaceQueue.deletedEntries;

export const deleteProcessingStateSelector = (state: ApplicationState): ProcessingState =>
  state.solaceQueue.deleteProcessingState;

export const {
  fetchSolaceQueueStarted,
  fetchSolaceQueueFailed,
  fetchSolaceQueueCompleted,
  entriesSelected,
  entriesUnselected,
  reset,
  deleteSolaceMessagesFailed,
  deleteSolaceMessagesSucceeded,
  deleteSolaceMessagesCompleted,
  deleteSolaceMessagesStarted,
} = slice.actions;

export default slice.reducer;
