import { createSlice } from '@reduxjs/toolkit';
import { ProcessingState } from 'enums/processingState';

export interface ExecutionsBlotterState {
  processingState: ProcessingState;
}

const initialState: ExecutionsBlotterState = {
  processingState: ProcessingState.idle(),
};

const slice = createSlice({
  name: 'solaceQueue',
  initialState: initialState,
  reducers: {},
});

// export const {} = slice.actions;

export default slice.reducer;
