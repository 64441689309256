import { AbortSignal } from '@microsoft/signalr';
import { Api } from 'api';
import { isCRUDResponse } from 'api/interfaces/CRUDResponse';
import { AnyAction } from 'redux';
import {
  fetchingRiskEnginesCompleted,
  fetchingRiskEnginesStarted,
  fetchingRiskMatrixCompleted,
  fetchingRiskMatrixStarted,
  fetchingRiskSessionsCompleted,
  fetchingRiskSessionsStarted,
  savingRiskMatrixCompleted,
  savingRiskMatrixFailed,
  savingRiskMatrixStarted,
  savingRiskMatrixSucceeded,
  setRiskEngines,
  setRiskMatrix,
  setRiskSessions,
} from 'redux/reducers/riskReducer';
import { RiskMatrix } from 'types/risk';

export async function* fetchRiskMatrixService(
  api: Api,
  signal: AbortSignal,
  sessionID: string,
  engineID: string
): AsyncGenerator<AnyAction> {
  void signal;
  yield fetchingRiskMatrixStarted();

  try {
    const task = api.getRiskMatrix(sessionID, engineID);
    const response = await task.run();
    if (response === null) {
      yield setRiskMatrix(null);
    } else if (isCRUDResponse(response)) {
      console.warn(response);
    } else {
      // We always have to do strange things
      const { equities, options } = response;

      yield setRiskMatrix({
        ...response,
        riskengineid: engineID,
        options: {
          ...options,
          accounts: options?.accounts ?? [],
        },
        equities: {
          ...equities,
          accounts: equities?.accounts ?? [],
        },
      });
    }
  } catch (error: any) {
    console.warn(error);
  } finally {
    yield fetchingRiskMatrixCompleted();
  }
}

export async function* updateRiskMatrixService(
  api: Api,
  signal: AbortSignal,
  matrix: RiskMatrix
): AsyncGenerator<AnyAction> {
  void signal;

  yield savingRiskMatrixStarted();
  try {
    const task = api.updateRiskMatrix(matrix);
    const response = await task.run();

    // WARNING: sometimes there's an error, but it still returns
    //          success true
    if (!response.success) {
      yield savingRiskMatrixFailed(response.message);
    } else {
      yield savingRiskMatrixSucceeded();
    }
  } catch (error: any) {
    console.warn(error);
  } finally {
    yield savingRiskMatrixCompleted();
  }
}

export async function* fetchRiskSessionsService(
  api: Api,
  signal: AbortSignal,
  riskEngineID: string
): AsyncGenerator<AnyAction> {
  void signal;

  yield fetchingRiskSessionsStarted();
  try {
    const task = api.getRiskSessions(riskEngineID);
    const response = await task.run();

    yield setRiskSessions(response);
  } catch (error: any) {
    console.warn(error);
  } finally {
    yield fetchingRiskSessionsCompleted();
  }
}

export async function* fetchRiskEnginesService(
  api: Api,
  signal: AbortSignal
): AsyncGenerator<AnyAction> {
  void signal;

  yield fetchingRiskEnginesStarted();
  try {
    const task = api.getRiskEngines();
    const response = await task.run();

    yield setRiskEngines(response);
  } catch (error: any) {
    console.warn(error);
  } finally {
    yield fetchingRiskEnginesCompleted();
  }
}
