import { Api } from 'api';
import { ApplicationUser } from 'api/interfaces/applicationUser';
import { User } from 'api/interfaces/user';
import { Action, Dispatch } from 'redux';
import { createAction } from 'redux/actions/creator';
import { ApplicationState } from 'redux/applicationState';
import { UsersActions } from 'redux/reducers/usersReducer';

export const getUsers =
  () =>
  (dispatch: Dispatch<Action<UsersActions>>, _: () => ApplicationState, api: Api): VoidFunction => {
    const task = api.getUsers();
    dispatch(createAction(UsersActions.getUsersStarted));
    task
      .run()
      .then((users: readonly User[]): void => {
        const transformedUsers = users
          .map(
            (user: User): ApplicationUser => ({
              id: user.email_address,
              email: user.email_address,
              firstName: user.first_name,
              lastName: user.last_name,
              password: '',
              workPhone: user.work_phone,
              cellPhone: user.cell,
              businessAddress: user.business_address,
              recoveryQuestion: user.recovery_question,
              recoveryAnswer: user.recovery_answer,
              oktaGroups: user.okta_group,
              sonicGroups: [],
              companyId: user.companyid,
              firmId: user.FirmID,
              whitelist: [],
              applicationId: user.appid,
              UBSTraderID: '',
            })
          )
          .sort((u1: ApplicationUser, u2: ApplicationUser): number => u1.id.localeCompare(u2.id));

        dispatch(createAction(UsersActions.getUsersCompleted, transformedUsers));
      })
      .catch((error: any): void => console.warn(error));

    return (): void => {
      task.cancel();
    };
  };
