/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Task } from 'task';

export const useFetchAndPollWithTask = <T>(
  createTask: (...args: any[]) => Task<T>,
  setData: (value: T) => void,
  ...args: any[]
): [boolean, Date] => {
  const [lastUpdate, setLastUpdate] = React.useState<Date>(new Date());
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect((): VoidFunction => {
    const task = createTask(...args);
    setLoading(true);

    task
      .run()
      .then((data: T): void => {
        setTimeout((): void => {
          setLastUpdate(new Date());
        }, 60000);

        setData(data);
      })
      .catch(console.warn)
      .finally((): void => {
        setLoading(false);
      });

    return (): void => {
      task.cancel();
    };
  }, [createTask, setData, lastUpdate, ...args]);

  return [loading, lastUpdate];
};
