import { Api } from 'api';
import { ProcessingState } from 'enums/processingState';
import { SMSession } from 'interfaces/session';
import { AnyAction } from 'redux';
import { AsyncAction, createAsyncAction } from 'redux/asyncAction';
import { BackendSMConfig } from 'redux/definitions/central';
import { SMConfig } from 'redux/definitions/smConfig';
import {
  flushSubscribedSymbolsFailed,
  flushSubscribedSymbolsStarted,
  flushSubscribedSymbolsSucceeded,
  importSessionFailed,
  importSessionStarted,
  importSessionSucceeded,
  loadSessionsCompleted,
  loadSessionsFailed,
  loadSessionsStarted,
  sessionActionFailed,
  sessionActionStarted,
  sessionActionSucceeded,
  sessionAdded,
} from 'redux/reducers/sessionsReducer';
import {
  ProcessingOperationType,
  resetProcessingState,
  saveSMConfigStart,
  saveSMConfigStopWithError,
  saveSMConfigStopWithSuccess,
  setProcessingState,
  setSMConfig,
  setSolaceServers,
} from 'redux/reducers/smConfigReducer';
import { SequenceNumberUpdatePayload } from 'types/sequenceNumberUpdatePayload';

async function* fetchSessions(
  api: Api,
  signal: AbortSignal
): AsyncGenerator<AnyAction | VoidFunction> {
  yield loadSessionsStarted();

  try {
    const { Sessions } = await api.getSnapshot(signal);
    yield loadSessionsCompleted(Sessions);
  } catch (error) {
    yield loadSessionsFailed(error.toString());
  }
}

async function* fetchSMConfiguration(
  api: Api,
  signal: AbortSignal,
  sessionId: string
): AsyncGenerator<AnyAction> {
  yield setProcessingState(ProcessingState.processing(ProcessingOperationType.loading));
  const response = await api.getSMConfiguration(signal, sessionId);
  yield setSMConfig(SMConfig.fromBackend(response));
  yield setProcessingState(ProcessingState.idle());
}

async function* fetchDefaultSMConfiguration(
  api: Api,
  signal: AbortSignal
): AsyncGenerator<AnyAction> {
  yield setProcessingState(ProcessingState.processing(ProcessingOperationType.loading));
  const response = await api.getSMDefaults(signal);
  yield setSMConfig(SMConfig.fromDefault(response));
  yield setProcessingState(ProcessingState.idle());
}
async function* flushSubscribedSymbols(
  api: Api,
  signal: AbortSignal,
  sessionId: string
): AsyncGenerator<AnyAction> {
  yield flushSubscribedSymbolsStarted(sessionId);
  try {
    await api.flushSubscribedSymbols(signal, sessionId);
    yield flushSubscribedSymbolsSucceeded();
  } catch {
    yield flushSubscribedSymbolsFailed();
  }
}

async function* importSMConfiguration(
  api: Api,
  signal: AbortSignal,
  sessionId: string
): AsyncGenerator<AnyAction> {
  try {
    yield importSessionStarted();
    const response = await api.importSMConfiguration(signal, sessionId);

    if (response.success) {
      yield importSessionSucceeded();
    } else {
      yield importSessionFailed(response.message);
    }
  } catch (error: any) {
    const message = 'message' in error ? error : undefined ?? 'Unknown Error';
    yield importSessionFailed(message);
  }
}

async function* syncSMConfigurationFileWithDatabase(
  api: Api,
  signal: AbortSignal,
  sessionId: string,
  data: BackendSMConfig
): AsyncGenerator<AnyAction> {
  try {
    yield sessionActionStarted(sessionId);
    const response = await api.updateSMConfiguration(signal, sessionId, data, true);

    if (response.success) {
      yield sessionActionSucceeded({
        actionType: 'sync',
        sessionId: sessionId,
        message: 'Successfully synced SM configuration file with database',
      });
    } else {
      yield sessionActionFailed({
        sessionID: sessionId,
        message: response.message,
      });
    }
  } catch (error: any) {
    const message = 'message' in error ? error : undefined ?? 'Unknown Error';
    yield sessionActionFailed({
      sessionID: sessionId,
      message: message,
    });
  }
}

async function* reloadSMRule(
  api: Api,
  signal: AbortSignal,
  sessionId: string
): AsyncGenerator<AnyAction> {
  try {
    yield sessionActionStarted(sessionId);
    const response = await api.reloadSMRule(signal, sessionId);

    if (response.success) {
      yield sessionActionSucceeded({
        actionType: 'reload',
        sessionId: sessionId,
        message: 'Successfully reloaded',
      });
    } else {
      yield sessionActionFailed({
        sessionID: sessionId,
        message: response.message,
      });
    }
  } catch (error: any) {
    const message = 'message' in error ? error : undefined ?? 'Unknown Error';
    yield sessionActionFailed({
      sessionID: sessionId,
      message: message,
    });
  }
}

async function* createSMConfiguration(
  api: Api,
  signal: AbortSignal,
  data: SMConfig,
  sync: boolean
): AsyncGenerator<AnyAction> {
  yield saveSMConfigStart();

  const newConfig = BackendSMConfig.fromSMConfig(data);
  const response = await api.createSMConfiguration(signal, newConfig, sync);
  const transformedConfig = SMConfig.fromBackend(newConfig);

  if (response.success) {
    yield setSMConfig(transformedConfig);
    yield saveSMConfigStopWithSuccess();
    yield sessionAdded(SMSession.fromConfig(transformedConfig));
  } else {
    yield saveSMConfigStopWithError(response.message);
  }
}

async function* updateSMConfiguration(
  api: Api,
  signal: AbortSignal,
  sessionId: string,
  data: SMConfig,
  sync = false
): AsyncGenerator<AnyAction> {
  yield saveSMConfigStart();

  const newConfig = BackendSMConfig.fromSMConfig(data);
  const response = await api.updateSMConfiguration(signal, sessionId, newConfig, sync);
  const transformedConfig = SMConfig.fromBackend(newConfig);

  if (response.success) {
    yield setSMConfig(transformedConfig);
    yield saveSMConfigStopWithSuccess();
    yield fetchSessionsAction();
    yield sessionAdded(SMSession.fromConfig(transformedConfig));
  } else {
    yield saveSMConfigStopWithError(response.message);
  }
}

async function* resetSMSequence(
  api: Api,
  signal: AbortSignal,
  data: SequenceNumberUpdatePayload
): AsyncGenerator<AnyAction> {
  await api.resetSMSequence(signal, data);
  yield resetProcessingState();
}

async function* fetchSolaceServers(api: Api, signal: AbortSignal): AsyncGenerator<AnyAction> {
  const servers = await api.getSolaceServers(signal);
  yield setSolaceServers(servers);
}

async function* deleteSession(
  api: Api,
  signal: AbortSignal,
  sessionId: string
): AsyncGenerator<AnyAction> {
  yield sessionActionStarted(sessionId);
  try {
    const response = await api.deleteSMConfiguration(signal, sessionId);
    if (response.success) {
      yield sessionActionSucceeded({
        actionType: 'delete',
        sessionId: sessionId,
        message: 'Session deleted successfully',
      });
    } else {
      yield sessionActionFailed({
        sessionID: sessionId,
        message: response.message,
      });
    }
  } catch (error: any) {
    const message = 'message' in error ? error : undefined ?? 'Unknown Error';
    yield sessionActionFailed({
      sessionID: sessionId,
      message: message,
    });
  } finally {
    yield resetProcessingState();
  }
}
export const fetchSessionsAction = (): AsyncAction => createAsyncAction(fetchSessions);

export const deleteSessionAction = (sessionId: string): AsyncAction =>
  createAsyncAction(deleteSession, sessionId);

export const fetchSMConfigurationAction = (sessionId: string, cloned: boolean): AsyncAction =>
  createAsyncAction(fetchSMConfiguration, sessionId, cloned);

export const fetchDefaultSMConfigurationAction = (): AsyncAction =>
  createAsyncAction(fetchDefaultSMConfiguration);

export const updateSMConfigurationAction = (
  sessionId: string,
  data: SMConfig,
  sync = false
): AsyncAction => createAsyncAction(updateSMConfiguration, sessionId, data, sync);

export const createSMConfigurationAction = (data: SMConfig, sync = false): AsyncAction =>
  createAsyncAction(createSMConfiguration, data, sync);

export const reloadSMRuleAction = (sessionId: string): AsyncAction =>
  createAsyncAction(reloadSMRule, sessionId, false);

export const syncSMConfigurationFileWithDatabaseAction = (
  sessionId: string,
  data: BackendSMConfig
): AsyncAction => createAsyncAction(syncSMConfigurationFileWithDatabase, sessionId, data);

export const flushSubscribedSymbolsAction = (sessionId: string): AsyncAction =>
  createAsyncAction(flushSubscribedSymbols, sessionId);

export const importSMConfigurationAction = (sessionId: string): AsyncAction =>
  createAsyncAction(importSMConfiguration, sessionId);

export const resetSMSequenceAction = (data: SequenceNumberUpdatePayload): AsyncAction =>
  createAsyncAction(resetSMSequence, data);

export const fetchSolaceServersAction = (): AsyncAction => createAsyncAction(fetchSolaceServers);
