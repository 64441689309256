import { Column } from 'components/Table/types/column';

export const applyOrderingToColumns = <T>(
  columns: ReadonlyArray<Column<T>>,
  currentColumnsOrder?: readonly number[]
): ReadonlyArray<Column<T>> => {
  const columnsOrder = currentColumnsOrder ?? columns.map((_, index) => index);

  const reordered = columnsOrder
    .filter((index: number): boolean => columns[index] !== undefined)
    .map((index: number): Column<T> => {
      return columns[index];
    });

  if (reordered.length < columns.length) {
    return [...reordered, ...columns.slice(reordered.length + 1)];
  } else {
    return reordered;
  }
};
