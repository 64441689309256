import styles from 'components/Table/renderers/StatusCellRenderer/status-renderer.module.scss';
import { statuses, statusesIcons } from 'constants/statuses';
import { Status } from 'enums/status';
import React from 'react';

interface Props {
  readonly value: {
    readonly status: Status;
  };
}

export const StatusCell = (props: Props): React.ReactElement => {
  const {
    value: { status },
  } = props;

  const label = statuses[status] ?? '';
  const key = label.toLowerCase();
  const icon = statusesIcons[status] ?? 'question-circle';

  return (
    <div className={[styles.container, styles[key]].join(' ')}>
      {label !== '' && <i className={`fa fa-${icon}`} />}
      <span>{label}</span>
    </div>
  );
};
