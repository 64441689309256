import { Box, FormLabel, Pagination } from '@mui/material';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { LogLevel } from 'components/LogLevel';
import { Scaffold } from 'components/Scaffold';
import { Table } from 'components/Table';
import { SortDirection } from 'components/Table/types/sortDirection';
import { ProcessingType } from 'enums/processingState';
import { usePagination } from 'hooks/usePagination';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logsBlotterSelector } from 'redux/reducers/logsBlotterReducer';
import { fetchErrorsAction } from 'redux/services/logBlotterServices';
import { columns } from 'routes/SolaceLogs/columns';
import { Filters, FiltersData } from 'routes/SolaceLogs/Filters';
import { LogLevelValue, SolaceLogEntry } from 'types/solace';

export const SolaceLogs: React.FC = (): React.ReactElement => {
  const { logs, processingState } = useSelector(logsBlotterSelector);
  const [statusFilters, setStatusFilters] =
    React.useState<Record<LogLevelValue, boolean>>(defaultStatusFilter);

  const dispatch = useDispatch<any>();

  const [filters, setFilters] = React.useState<FiltersData>(emptyFilters);

  const applyFilter = React.useCallback(
    (row: SolaceLogEntry): boolean => {
      const { sessionId, timestamp } = row;
      const { fromDate, toDate } = filters;

      const sessionIdMatches = filters.sessionId === '' || filters.sessionId === sessionId;
      const fromDateMatches = fromDate === null || fromDate.isBefore(timestamp);
      const toDateMatches = toDate === null || toDate.isAfter(timestamp);
      const statusMatches = statusFilters[row.logLevel];

      return sessionIdMatches && fromDateMatches && toDateMatches && statusMatches;
    },
    [filters, statusFilters]
  );

  const [page, setPage] = usePagination(logs, applyFilter);

  React.useEffect((): VoidFunction => dispatch(fetchErrorsAction()), [dispatch]);

  const handlePageChange = React.useCallback(
    (_: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [setPage]
  );

  const handleSort = React.useCallback((_: keyof SolaceLogEntry, __: SortDirection): void => {
    return;
  }, []);

  const statusFilterToggleFn = React.useCallback((level: LogLevelValue): VoidFunction => {
    return (): void => {
      setStatusFilters(
        (currentFilters: Record<LogLevelValue, boolean>): Record<LogLevelValue, boolean> => ({
          ...currentFilters,
          [level]: !currentFilters[level],
        })
      );
    };
  }, []);

  return (
    <Scaffold title="Logs">
      <Filters filters={filters} onChange={setFilters} />
      <Box display="flex" alignItems="center" gap={1} marginBottom={1}>
        <FormLabel>
          <span>Show Only </span>
          <i className="fa fa-filter" />
        </FormLabel>

        {logLevels.map((value: LogLevelValue) => (
          <LogLevel
            value={value}
            disabled={!statusFilters[value]}
            onClick={statusFilterToggleFn(value)}
          />
        ))}
      </Box>
      <Table columns={columns} rows={page.rows} onColumnSorted={handleSort} />
      <Box sx={paginatorSx}>
        <Pagination
          count={page.totalPageCount}
          page={page.currentPage}
          onChange={handlePageChange}
        />
      </Box>
      <LoadingOverlay loading={processingState.processingType === ProcessingType.processing} />
    </Scaffold>
  );
};

const paginatorSx = {
  marginTop: 2,
  display: 'flex',
  justifyContent: 'center',
};

const emptyFilters: FiltersData = { sessionId: '', fromDate: null, toDate: null };

const defaultStatusFilter: Record<LogLevelValue, boolean> = { E: true, I: false, W: false };

const logLevels: readonly LogLevelValue[] = ['E', 'W', 'I'];
