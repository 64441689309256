import { formatCurrency } from 'components/Table/formatters/currency';
import { formatNumber } from 'components/Table/formatters/numeric';
import { formatStatus } from 'components/Table/formatters/status';
import { DateTimeCellProps } from 'components/Table/renderers/DateTimeCellRenderer';
import { Formatter } from 'components/Table/types/column';

const formatters: Record<string, Formatter<any>> = {
  currency: {
    format: formatCurrency,
    options: {
      currency: 'USD',
      precision: 2,
    },
  },
  status: {
    format: formatStatus,
  },
  integer: {
    format: formatNumber,
    options: {
      precision: 0,
    },
  },
  dateTime: {
    format: (value: any): DateTimeCellProps => ({
      value,
    }),
  },
};

export default formatters;
