import { Api } from 'api';
import { ApplicationUser } from 'api/interfaces/applicationUser';
import { CRUDResponse } from 'api/interfaces/CRUDResponse';
import { Dispatch } from 'redux';
import { Action } from 'redux/action';
import { createAction } from 'redux/actions/creator';
import { preparePayload } from 'redux/actions/users/common';
import { ApplicationState } from 'redux/applicationState';
import { UsersActions } from 'redux/reducers/usersReducer';

export const createUser = (user: ApplicationUser) => {
  return (
    dispatch: Dispatch<Action<UsersActions>>,
    getState: () => ApplicationState,
    api: Api
  ): VoidFunction => {
    const state = getState();
    const payload = preparePayload(state.usersForm, user);
    const task = api.createUser(payload);

    dispatch(createAction(UsersActions.createUserStarted));
    const run = async (): Promise<CRUDResponse> => {
      const result = await task.run();
      const create = api.getUsers();

      await new Promise<void>((resolve: () => void): void => {
        setTimeout((): void => {
          create.run().then(resolve);
        }, 500);
      });

      return result;
    };

    run()
      .then((result: CRUDResponse): void => {
        if (result.success) {
          dispatch(createAction(UsersActions.createUserCompleted, user));
        } else {
          dispatch(createAction(UsersActions.createUserFailed, result.message));
        }
      })
      .catch((error: Error): void => {
        dispatch(createAction(UsersActions.createUserFailed, error.message));
      });

    return (): void => {
      task.cancel();
    };
  };
};
