import dev from 'config/dev.json';
import local from 'config/local.json';
import prod from 'config/prod.json';
import { Config, EnvSpecificConfig } from 'config/types';
import uat from 'config/uat.json';
import { OKTA_CALLBACK } from 'constants/routes';

const protocol = window.location.protocol;
const host = window.location.host;

const envSpecificConfig = ((): EnvSpecificConfig => {
  switch (process.env.REACT_APP_BUILD_ENV) {
    case 'dev':
      return dev;
    case 'uat':
      return uat;
    case 'prod':
      return prod;
    default:
      return local;
  }
})();

const config: Config = {
  loginUrl: envSpecificConfig.loginUrl,
  signalRUrl: `${envSpecificConfig.apiUrl}/liveUpdateSignalRHub`,
  apiUrl: `${envSpecificConfig.apiUrl}`,
  environment: `${envSpecificConfig.environment}`,
  okta: {
    authorizeUrl: `${envSpecificConfig.oktaUrl}/oauth2/default/v1/authorize`,
    meUrl: `${envSpecificConfig.oktaUrl}/oauth2/default/v1/userinfo`,
    logoutUrl: `${envSpecificConfig.oktaUrl}/oauth2/default/v1/logout`,
    redirectUri: `${protocol}//${host}${OKTA_CALLBACK}`,
    clientId: envSpecificConfig.oktaClientId,
    scopes: ['openid', 'email'],
  },
  messagesAndFlowsGroups: envSpecificConfig.messagesAndFlowsGroups,
  fxoGroups: envSpecificConfig.fxoGroups,
  adminGroup: envSpecificConfig.adminGroup,
  fxoGroupPrefix: envSpecificConfig.fxoGroupPrefix,
  defaultApplicationId: envSpecificConfig.defaultApplicationId,
};

export default config;
