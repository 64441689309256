import { Button } from '@mui/material';
import { Field } from 'components/Form/components/Field';
import {
  FieldDefinition,
  FieldType,
  isArrayFieldDefinition,
  isBasicFieldDefinition,
  isCustomFieldDefinition,
  isFieldsetDefinition,
  isGroupDefinition,
  isMultiBoolFieldDefinition,
} from 'components/Form/components/Field/types';
import styles from 'components/Form/form.module.scss';
import { isFieldRequired, isFieldValid, isFieldVisible } from 'components/Form/utils';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { FormErrorsContext } from 'contexts/FormErrorsContext';
import equal from 'fast-deep-equal';
import { ignoreFormSubmitEvent } from 'helpers/ignoreFormSubmitEvent';
import { toClassName } from 'helpers/toClassName';
import React from 'react';

export interface FormChangeResult {
  readonly error: string | null;
  readonly value: any;
}

export enum FieldErrorType {
  none = 'none',
  requiredMissing = 'required missing',
  badValue = 'bad value',
}

export interface FieldError {
  readonly message: string;
  readonly errorType: FieldErrorType;
}

interface Props<T, P> {
  readonly fields: ReadonlyArray<FieldDefinition<T>>;
  readonly value: T;

  readonly loading?: boolean;
  readonly submitLabel?: string;
  readonly cancelLabel?: string;

  readonly provider?: P;
  readonly className?: string;

  readonly embedded?: boolean;
  readonly component?: 'form' | 'fieldset';

  onChange(name: keyof T, value: any, data: T): FormChangeResult;
  onSubmit?(value: T): void;
  onCancel?(): void;

  onModifiedChanged?(modified: boolean): void;
  onErrorsChanged?(hasErrors: boolean): void;
}

interface State<T> {
  readonly internalValue: T;
  readonly modified: boolean;
  readonly errors: Partial<Record<string, FieldError>>;
}

export class Form<T, P> extends React.PureComponent<Props<T, P>, State<T>> {
  private readonly formRef: React.RefObject<any>;

  constructor(props: Props<T, P>) {
    super(props);

    this.formRef = React.createRef();
    this.state = {
      errors: {},
      internalValue: props.value,
      modified: false,
    };
  }

  private isFieldRequired(definition: FieldDefinition<any>): boolean {
    const { internalValue } = this.state;
    const { provider } = this.props;

    return isFieldRequired(definition, internalValue, provider);
  }

  private renderField = (definition: FieldDefinition<T>): React.ReactElement => {
    const { provider } = this.props;
    const { internalValue } = this.state;
    const { name } = definition;

    const fieldKey = name.toString();
    const required = this.isFieldRequired(definition);

    if (
      isBasicFieldDefinition(definition) ||
      isArrayFieldDefinition(definition) ||
      isCustomFieldDefinition(definition) ||
      isMultiBoolFieldDefinition(definition)
    ) {
      const fieldValue = internalValue[name as keyof T] ?? '';

      return (
        <div key={fieldKey}>
          <Field
            prefix={String(name)}
            value={fieldValue}
            definition={definition}
            provider={provider}
            required={required}
            onChange={this.onChange}
          />
        </div>
      );
    } else {
      return (
        <div key={fieldKey}>
          <Field
            prefix={String(name)}
            value={internalValue}
            definition={definition}
            provider={provider}
            required={required}
            onChange={this.onChange}
          />
        </div>
      );
    }
  };

  private onChange = (name: keyof T, value: any): void => {
    const { internalValue } = this.state;
    const { value: originalValue } = this.props;
    const { onChange } = this.props;

    const result = onChange(name, value, internalValue);
    const computedValue = { ...internalValue, [name]: result.value };

    this.setState({
      internalValue: computedValue,
      modified: !equal(computedValue, originalValue),
    });
  };

  private resetState(): void {
    const { value } = this.props;

    this.setState({
      internalValue: value,
    });
  }

  public componentDidUpdate(prevProps: Readonly<Props<T, P>>, prevState: Readonly<State<T>>): void {
    const { props, state } = this;
    const { onModifiedChanged } = props;

    if (prevProps.value !== props.value || prevProps.fields !== props.fields) {
      this.resetState();
    }

    if (prevState.modified !== state.modified) {
      onModifiedChanged?.(state.modified);
      this.setState({ errors: {} });
    }
  }

  public componentDidMount(): void {
    const { current: form } = this.formRef;
    if (form === null) {
      return;
    }

    if (form instanceof HTMLFormElement || form instanceof HTMLFieldSetElement) {
      const { elements } = form;
      if (elements.length === 0) {
        return;
      }

      const first = Array.from(elements)
        .map((element: Element): HTMLInputElement | null =>
          element instanceof HTMLInputElement ? element : null
        )
        .find((element: HTMLInputElement | null) => element !== null);
      // Focus first element
      if (first !== undefined) {
        first.select();
        first.focus();
      }

      this.resetState();
    } else {
      console.warn('unexpected error: the form is not a form or a fieldset?');
    }
  }

  private handleSubmit = (): void => {
    const { internalValue, modified } = this.state;
    const { fields, provider, onSubmit } = this.props;

    const errors = computeErrors(fields, modified, internalValue, provider);
    if (hasErrors(errors)) {
      this.setState({ errors: errors });
      return;
    }

    onSubmit(internalValue);
  };

  public render(): React.ReactElement {
    // Properties
    const {
      provider,
      fields,
      submitLabel = 'Save',
      cancelLabel = 'Cancel',
      loading = false,
      className,
      component = 'form',
      embedded,
    } = this.props;
    // State
    const { internalValue, modified, errors } = this.state;
    // Callbacks
    const { onSubmit, onCancel } = this.props;
    const filteredFields = fields.filter(isFieldVisible(internalValue, provider));
    const errored = hasErrors(errors);
    const Component = component;

    return (
      <FormErrorsContext.Provider value={errors}>
        <div
          className={toClassName(
            styles.container,
            className,
            embedded ? styles.embedded : undefined
          )}
        >
          <Component
            className={styles[[component, 'Component'].join('')]}
            ref={this.formRef}
            onSubmit={ignoreFormSubmitEvent}
            autoComplete="off"
          >
            <fieldset disabled={loading} className={toClassName(styles.fieldset, styles.wrapper)}>
              {filteredFields.map(this.renderField)}
            </fieldset>
            <div className={styles.buttons}>
              {onSubmit && (
                <div className={styles.stillErrorsNotice}>
                  {errored && modified ? 'There are some errors or missing fields' : null}
                </div>
              )}
              {onCancel ? (
                <Button color="secondary" onClick={onCancel}>
                  {cancelLabel}
                </Button>
              ) : null}
              {onSubmit && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!onSubmit || !modified}
                  onClick={this.handleSubmit}
                >
                  <i className="fa fa-save" /> {submitLabel}
                </Button>
              )}
            </div>
            <LoadingOverlay loading={loading} />
          </Component>
        </div>
      </FormErrorsContext.Provider>
    );
  }
}

function hasErrors<T>(errors: Partial<Record<keyof T, FieldError>>): boolean {
  const values = Object.values(errors);
  return values.some((value: any): boolean => value.errorType !== FieldErrorType.none);
}

const isEmpty = (value: any): boolean => {
  if (value === undefined || value === null) {
    return true;
  } else if (Array.isArray(value)) {
    return value.length === 0;
  } else if (typeof value === 'string') {
    return value === '';
  }

  return false;
};

function computeErrors<T, P>(
  fields: ReadonlyArray<FieldDefinition<T, P>>,
  modified: boolean,
  formValue: T,
  provider: P,
  prefix = ''
): Partial<Record<string, FieldError>> {
  return fields.reduce(
    (
      errors: Partial<Record<keyof T, FieldError>>,
      definition: FieldDefinition<T>
    ): Partial<Record<keyof T, FieldError>> => {
      const isRequired = isFieldRequired(definition, formValue, provider);
      const isValid = isFieldValid(definition, formValue);

      const name = `${prefix}${definition.name}`;
      if (isGroupDefinition(definition) || isFieldsetDefinition(definition)) {
        const { children } = definition;

        return {
          ...errors,
          ...computeErrors(
            children.filter((child: FieldDefinition<any>): boolean => {
              if (typeof child.display === 'function') {
                return child.display(formValue, provider);
              } else {
                return child.display;
              }
            }),
            modified,
            formValue,
            provider,
            `${name}.`
          ),
        };
      } else if (!isValid) {
        return {
          ...errors,
          [name]: { message: 'Invalid value', errorType: FieldErrorType.badValue },
        };
      } else if (!isRequired) {
        return errors;
      } else {
        // FIXME: this is not the only case right?
        if (definition.fieldType === FieldType.mappedGroup) {
          return errors;
        }

        const key = definition.name;

        return {
          ...errors,
          [name]: {
            message: '',
            errorType:
              modified && isRequired && isEmpty(formValue[key])
                ? FieldErrorType.requiredMissing
                : FieldErrorType.none,
          },
        };
      }
    },
    {}
  );
}

export { OnlyBasicFieldsHaveEventsError } from 'components/Form/common';
