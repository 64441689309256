import styles from 'components/FIXTableField/fix-table-field.module.scss';
import { FIXTableFieldCell } from 'components/FIXTableFieldCell';
import { FIXTaggedField, isTableField } from 'interfaces/FIXTaggedField';
import React from 'react';

type Props = FIXTaggedField;

interface SimpleColumn {
  readonly type: string;
  readonly label: string;
}

export const FIXTableField: React.FC<Props> = (props: Props): React.ReactElement => {
  const field = props;

  if (!isTableField(field)) {
    throw new Error('only table fieldsDefinition are permitted');
  }
  const { value } = field;
  const columns = React.useMemo((): readonly SimpleColumn[] => extractColumns(value), [value]);
  const rows = React.useMemo(
    (): ReadonlyArray<Record<string, FIXTaggedField>> => convertToObjects(value),
    [value]
  );

  return (
    <div className={styles.container}>
      <h4>{field.label}</h4>
      <table className={styles.table}>
        <thead>
          <tr>
            {columns.map(
              (column: SimpleColumn): React.ReactElement => (
                <th key={column.label}>{column.label}</th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {rows.map(
            (row: Record<string, FIXTaggedField>, index: number): React.ReactElement => (
              <tr key={index}>
                {columns.map((column: SimpleColumn): React.ReactElement => {
                  const field = row[column.label];
                  return <FIXTableFieldCell {...field} key={column.label} />;
                })}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

const extractColumns = (value: ReadonlyArray<readonly FIXTaggedField[]>): readonly SimpleColumn[] =>
  Object.values(
    value.reduce(
      (
        columns: Record<string, SimpleColumn>,
        field: readonly FIXTaggedField[]
      ): Record<string, SimpleColumn> => {
        return field.reduce(
          (
            columns: Record<string, SimpleColumn>,
            field: FIXTaggedField
          ): Record<string, SimpleColumn> => ({
            ...columns,
            [field.label]: { type: field.type, label: field.label },
          }),
          columns
        );
      },
      {}
    )
  );

const convertToObjects = (
  value: ReadonlyArray<readonly FIXTaggedField[]>
): ReadonlyArray<Record<string, FIXTaggedField>> =>
  value.map(
    (array: readonly FIXTaggedField[]): Record<string, FIXTaggedField> =>
      array.reduce(
        (
          mapped: Record<string, FIXTaggedField>,
          field: FIXTaggedField
        ): Record<string, FIXTaggedField> => ({
          ...mapped,
          [field.label]: field,
        }),
        {}
      )
  );
