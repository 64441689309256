import { Firm } from 'api/interfaces/firm';
import { CRUDButtons } from 'components/CRUDButtons';
import { Alignment, ColumnDefinition, ColumnType } from 'components/Table/types/column';

const creteColumns: ReadonlyArray<ColumnDefinition<Firm>> = [
  {
    minWidth: 48,
    columnType: ColumnType.custom,
    key: 'firmID',
    filterable: false,
    header: '',
    component: CRUDButtons,
  },
  {
    columnType: ColumnType.text,
    key: 'firmName',
    filterable: true,
    header: 'MPID',
    weight: 4,
    alignment: Alignment.center,
  },
  {
    columnType: ColumnType.text,
    key: 'firmLabel',
    filterable: true,
    header: 'Name',
    weight: 15,
  },
  {
    columnType: ColumnType.text,
    key: 'firmType',
    filterable: true,
    header: 'Type',
    weight: 4,
    alignment: Alignment.center,
  },
  {
    columnType: ColumnType.text,
    key: 'businessAddress',
    filterable: true,
    header: 'Business Address',
    weight: 4,
    alignment: Alignment.center,
  },
];

export default creteColumns;
