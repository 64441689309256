import { Box, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { Scaffold } from 'components/Scaffold';
import { Table } from 'components/Table';
import { RowData } from 'components/Table/types/rowData';
import { useApi } from 'hooks/useApi';
import { useFetchAndPollWithTask } from 'hooks/useFetchAndPollWithTask';
import { useTable } from 'hooks/useTable';
import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Action } from 'redux/action';
import { setUsers } from 'redux/actions/whoIsLoggedIn/setUsers';
import { ApplicationState } from 'redux/applicationState';
import {
  WhoIsLoggedInActions,
  WhoIsLoggedInState,
  WhoIsLoggedInUser,
} from 'redux/reducers/whoIsLoggedInReducer';
import columns from 'routes/ReportsRoute/WhoIsLoggedIn/columns';
import { LastUpdateWidget } from 'routes/ReportsRoute/WhoIsLoggedIn/components/LastUpdateWidget';
import { getLogins } from 'routes/ReportsRoute/WhoIsLoggedIn/helpers';
import styles from 'routes/ReportsRoute/WhoIsLoggedIn/who-is-logged-in.module.scss';
import { WithID } from 'types/identifiable';

interface DispatchProps {
  setUsers(
    users: readonly WhoIsLoggedInUser[]
  ): Action<WhoIsLoggedInActions, readonly WhoIsLoggedInUser[]>;
}

type Props = WhoIsLoggedInState & DispatchProps;

const WhoIsLoggedIn: React.FC<Props> = (props: Props): React.ReactElement => {
  const { users } = props;
  const { setUsers } = props;
  const tableProps = useTable<WithID<WhoIsLoggedInUser>>('who-is-logged-in', columns);
  const api = useApi();
  // FIXME: this one needs update (we need a more reduxish implementation)
  const [loading, lastUpdate] = useFetchAndPollWithTask(getLogins, setUsers, api);
  const [showInactive, setShowInactive] = React.useState<boolean>(false);

  const handleShowInactive = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      setShowInactive(checked);
    },
    []
  );

  const rows = React.useMemo((): Record<string, RowData<any>> => {
    return users
      .filter((whoIsLoggedInUser: WhoIsLoggedInUser): boolean => {
        return showInactive || whoIsLoggedInUser.loggedIn;
      })
      .reduce(
        (
          all: Record<string, RowData<WithID<WhoIsLoggedInUser>>>,
          user: WhoIsLoggedInUser
        ): Record<string, RowData<WithID<WhoIsLoggedInUser>>> => {
          return {
            ...all,
            [user.userID]: {
              id: user.userID,
              data: { ...user, id: user.userID },
              children: {},
            },
          };
        },
        {}
      );
  }, [showInactive, users]);

  const rowClassName = React.useCallback((data: WithID<WhoIsLoggedInUser>): string | undefined => {
    if (!data.loggedIn) {
      return styles.inactive;
    }

    return undefined;
  }, []);

  const Title: React.FC = React.useCallback((): React.ReactElement => {
    return (
      <Box sx={titleSx}>
        <Grid xs={9} item={true}>
          <Typography variant="h1">Who is logged in?</Typography>
          <LastUpdateWidget value={lastUpdate} />
        </Grid>
        <Grid xs={3} item={true}>
          <FormControlLabel
            control={<Switch checked={showInactive} onChange={handleShowInactive} />}
            label="Show inactive"
          />
        </Grid>
      </Box>
    );
  }, [handleShowInactive, lastUpdate, showInactive]);

  return (
    <Scaffold title={Title}>
      <Table rows={rows} rowClassName={rowClassName} {...tableProps} />
      <LoadingOverlay loading={loading} />
    </Scaffold>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, any> = {
  setUsers,
};

const mapStateToProps: MapStateToProps<WhoIsLoggedInState, any, ApplicationState> = (
  state: ApplicationState
) => state.whoIsLoggedIn;

export default connect(mapStateToProps, mapDispatchToProps)(WhoIsLoggedIn);

const titleSx = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' };
