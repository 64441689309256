import { Button } from '@mui/material';
import { ExecutionReport } from 'components/Table/renderers/SolaceQueueMessageRenderer/fixMessageTypes';
import styles from 'components/Table/renderers/SolaceQueueMessageRenderer/solace-queue-message-renderer.module.scss';
import React from 'react';

interface Props {
  readonly message: ExecutionReport;

  onActionClick(): void;
}

export const FIXExecutionReportDetailsRenderer: React.FC<Props> = (
  props: Props
): React.ReactElement => {
  const { onActionClick } = props;
  const { message } = props;

  return (
    <div className={styles.fixMessageDetails}>
      <div className={styles[message.Side]}>
        <span>{message.Side}</span>
      </div>
      <div>{message.OrderQty}</div>
      <div>{message.Symbol}</div>
      <div>@</div>
      <div>{message.LastPx}</div>
      <div className={styles.senderTarget}>
        <div>{message.SenderCompID ?? '?'}</div>
        <div>&rarr;</div>
        <div>{message.TargetCompID ?? '?'}</div>
      </div>
      <div className={styles.viewDetailsButton}>
        <Button classes={viewDetailsButton} onClick={onActionClick}>
          Show FIX message
        </Button>
      </div>
    </div>
  );
};

const viewDetailsButton = { root: styles.viewDetailsButtonRoot };
