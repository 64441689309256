import { Button, FormLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ClearableInput } from 'components/ClearableInput';
import { CustomDatePicker } from 'components/CustomDatePicker';
import { MultiSelect } from 'components/MultiSelect';
import sides from 'constants/sides';
import { toClassName } from 'helpers/toClassName';
import strings from 'locales';
import React from 'react';
import { MessageSearchForm } from 'redux/reducers/messagesReducer';
import styles from 'routes/Messages/messages-route.module.scss';

interface Props {
  readonly form: MessageSearchForm;
  readonly orderOriginationIds: readonly string[];
  readonly venues: readonly string[];

  onFieldChange(name: string, value: any): void;
  onSearch(): void;
}

export const Form = (props: Props): React.ReactElement => {
  const { form, orderOriginationIds, venues } = props;
  const { onFieldChange, onSearch } = props;

  const onMaterialSelectChange = React.useCallback(
    (event: SelectChangeEvent): void => {
      const { name, value } = event.target;
      if (name === undefined) {
        throw new Error(
          'provide a name to all the fieldsDefinition in order for this trick to work'
        );
      }
      onFieldChange(name, value);
    },
    [onFieldChange]
  );

  const onMultiSelectChange = React.useCallback(
    (name: string, list: readonly string[]): void => {
      onFieldChange(name, list);
    },
    [onFieldChange]
  );

  const onInputChange = React.useCallback(
    (name: string, value: string): void => {
      // Update the form with redux
      onFieldChange(name, value);
    },
    [onFieldChange]
  );

  return (
    <form className={styles.form}>
      <div className={styles.field}>
        <FormLabel className={styles.label}>{strings.OrderOriginationId}</FormLabel>
        <MultiSelect
          name="orderOriginationId"
          className={styles.input}
          value={form.orderOriginationId}
          items={orderOriginationIds}
          onChange={onMultiSelectChange}
        />
      </div>

      <div className={styles.field}>
        <FormLabel className={styles.label}>{strings.Venues}</FormLabel>
        <MultiSelect
          name="venue"
          className={styles.input}
          value={form.venue}
          items={venues}
          onChange={onMultiSelectChange}
        />
      </div>

      <div className={styles.field}>
        <FormLabel className={styles.label}>{strings.Sides}</FormLabel>
        <Select
          name="side"
          className={styles.input}
          value={form.side}
          onChange={onMaterialSelectChange}
        >
          <MenuItem value={''}>{strings.All}</MenuItem>
          {Object.entries(sides).map(
            ([value, label]: [string, string]): React.ReactElement => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            )
          )}
        </Select>
      </div>

      <div className={styles.field}>
        <FormLabel className={styles.label}>{strings.Symbols}</FormLabel>
        <ClearableInput
          name="symbols"
          className={styles.input}
          value={form.symbols}
          onChange={onInputChange}
        />
      </div>

      <div className={styles.field}>
        <FormLabel className={styles.label}>{strings.ClientOrderId}</FormLabel>
        <ClearableInput
          name="clientOrderId"
          className={styles.input}
          value={form.clientOrderId}
          onChange={onInputChange}
        />
      </div>
      {/* Just occupy the last column of the second row */}
      <div />

      <div className={styles.field}>
        <FormLabel className={styles.label}>{strings.From}</FormLabel>
        <CustomDatePicker
          name="fromDate"
          value={form.fromDate}
          time={{ hour: 0, minute: 0, second: 0 }}
          className={toClassName(styles.input, styles.datePicker)}
          onDateChange={onFieldChange}
        />
      </div>

      <div className={styles.field}>
        <FormLabel className={styles.label}>{strings.To}</FormLabel>
        <CustomDatePicker
          name="toDate"
          value={form.toDate}
          time={{ hour: 23, minute: 59, second: 59 }}
          className={toClassName(styles.input, styles.datePicker)}
          onDateChange={onFieldChange}
        />
      </div>

      <div className={styles.searchContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSearch}
          disabled={orderOriginationIds.length === 0 || venues.length === 0}
        >
          {strings.Search}
        </Button>
      </div>
    </form>
  );
};
